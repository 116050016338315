import * as Yup from 'yup';
import { LanagugesArrayToValidationSchema } from 'helpers/FromTo';
import { safeJsonParse } from 'helpers';

export const multiLangValidation = () =>
    Yup.object().shape({
        value: Yup.string().required(`required`),
        thesaurus: Yup.object().shape({
            ...LanagugesArrayToValidationSchema(safeJsonParse(localStorage.getItem('languages')))
        })
    });

export const langValidation = () =>
    Yup.object().shape({
        value: Yup.string().required(`required`)
    });

export const validationInputNumber = (value, min) => {
    if (Number.isNaN(Number(value))) {
        return false;
    }
    if (Number(value) < 0) {
        return false;
    }
    if (Number(value) >= (min ? min : 0)) {
        return true;
    }
};
