import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';

export const OpenCell = ({ row, url }) => {
    const navigate = useNavigate();
    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Button onClick={() => navigate(`/${url}/?id=${row.original.id}`)} variant={'contained'} color="info">
                View
            </Button>
        </Box>
    );
};
export const openCell = (url) => ({
    header: 'View',
    Cell: ({ row }) => <OpenCell row={row} url={url} />,
    size: 120
});
