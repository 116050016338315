import React, { useEffect } from 'react';

// MUI components
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { addDialog, toggleDialog } from 'features/store';

const AnimatedDialog = ({ actions, contents, name, title, props }) => {
    const open = useSelector((state) => state.chat?.dialogs[name]?.open);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(addDialog(name));
    }, [dispatch, name]);

    return (
        <Dialog open={open} onClose={() => dispatch(toggleDialog({ name }))} {...props}>
            <Grow in={open} timeout={400} unmountOnExit>
                <DialogTitle>{title}</DialogTitle>
            </Grow>
            <DialogContent sx={{ paddingBottom: 0 }}>
                {contents.map((content, i) => (
                    <Grow in={true} timeout={400 * (i + 1) + 400} unmountOnExit>
                        <Box>{content}</Box>
                    </Grow>
                ))}
            </DialogContent>
            <Grow in={open} timeout={(contents.length + 2) * 400} unmountOnExit>
                <DialogActions>{actions}</DialogActions>
            </Grow>
        </Dialog>
    );
};

export default AnimatedDialog;
