import React from 'react';

// MUI components
import Typography from '@mui/material/Typography';

// Redux Hooks
import { useSelector } from 'react-redux';

const Accounts = () => {
    const currentChat = useSelector((state) => state.chat.currentChat);
    return (
        <Typography component="p" color="text.secondary" fontSize="smaller">
            {currentChat?.isGroup
                ? currentChat?.chatAccounts
                    ? currentChat.chatAccounts.map(
                          (account, i) => `${account.customeName}${i !== currentChat.chatAccounts.length - 1 ? ', ' : ''}`
                      )
                    : '...'
                : currentChat?.status
                ? currentChat.status
                : '...'}
        </Typography>
    );
};

export default Accounts;
