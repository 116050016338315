import React from 'react';

// MUI components
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

let ChatItem = ({ icon, title, desc, topRight, bottomRight }) => {
    return (
        <Box sx={{ width: '100%', paddingTop: '10px', paddingBottom: '10px' }}>
            <Grid container sx={{ width: '100%' }}>
                <Grid item xs={2} sm={1} md={2} display="flex" justifyContent="center">
                    {icon && icon}
                </Grid>
                <Grid item xs={10} sm={11} md={10} sx={{ paddingRight: '15px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography component="p">{title && title}</Typography>
                        <Typography component="p" color="text.secondary" fontSize="small">
                            {topRight && topRight}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2px' }}>
                        <Typography component="p" color="text.secondary">
                            {desc && desc}
                        </Typography>
                        <Typography component="p" color="text.secondary" fontSize="small">
                            {bottomRight && bottomRight}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ChatItem;
