import React from 'react';

// Icons
import { IconUser, IconUserCircle, IconUserPlus, IconBriefcase, IconNewSection } from '@tabler/icons';

import { roles } from 'env/roles';

const icons = { IconUserCircle, IconUserPlus, IconUser, IconNewSection, IconBriefcase };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import { ReceptionDepartmentsPageConstant } from 'constant/Translations/reception-management/reception';
import { useTranslation } from 'react-i18next';
import { ReservationTypePageConstant } from 'constant/Translations/reception-management/reception/reservation-type';
import { MedicalVisitsPageConstant } from 'constant/Translations/reception-management/reception/medical-visits';
import { MedicalVisitDescriptionPageConstant } from 'constant/Translations/reception-management/reception/medical-visit-description';
import { MedicalVisitTypePageConstant } from 'constant/Translations/reception-management/reception/medical-visit-type';
import { AppointmentTypePageConstant } from 'constant/Translations/reception-management/reception/appointment-type';
const useReceptionDepartment = () => {
    const { t } = useTranslation();
    return {
        id: 'reception-management',
        title: 'Reception Management',
        type: 'group',
        icon: <ReceiptLongRoundedIcon />,
        children: [
            {
                id: 'reception',
                title: 'Reception',
                type: 'group',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                children: [
                    {
                        id: 'reception-departments',
                        title: t(ReceptionDepartmentsPageConstant.receptionDepartments),
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/reception-departments',
                        breadcrumbs: false
                    },
                    {
                        id: 'reservation-type',
                        title: t(ReservationTypePageConstant.reservationType),
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/reservation-type',
                        breadcrumbs: false
                    },
                    {
                        id: 'medical-visits',
                        title: t(MedicalVisitsPageConstant.medicalVisits),
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/medical-visits',
                        breadcrumbs: false
                    },
                    {
                        id: 'medical-visit-type',
                        title: t(MedicalVisitTypePageConstant.medicalVisitType),
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/medical-visit-type',
                        breadcrumbs: false
                    },
                    {
                        id: 'medical-visit-description',
                        title: t(MedicalVisitDescriptionPageConstant.medicalVisitDescription),
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/medical-visit-description',
                        breadcrumbs: false
                    },
                    ,
                    {
                        id: 'appointment-type',
                        title: t(AppointmentTypePageConstant.appointmentType),
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/appointment-type',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'appointment-management',
                title: 'Appointment Management',
                type: 'item',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                url: '/appointment-management',
                breadcrumbs: false
            },
            {
                id: 'daily-schedule-management',
                title: 'Daily Schedule Management',
                type: 'item',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                url: '/daily-schedule-management',
                breadcrumbs: false
            }
        ]
    };
};

export default useReceptionDepartment;
