import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { authAxios } from 'config/axios';
import { CENTER, SECTION } from 'env/server';

const initialState = {
    loading: false,
    error: false,
    centerSections: [],
    centerCombinSections: [],
    sections: []
};
export const getCenterSections = createAsyncThunk('sections/getCenterSections', () => {
    return authAxios.get(CENTER.GET_CENTERS).then((res) => res.data?.data);
});
export const getCenterComibeSections = createAsyncThunk('sections/getCenterComibeSections', async () => {
    return authAxios.get(CENTER.GET_CENTER_COMBINES).then((res) => res.data?.data);
});
export const getSections = createAsyncThunk('sections/getSections', async () => {
    return authAxios.get(SECTION.GET).then((res) => res.data?.data);
});

export const sectionsSlice = createSlice({
    name: 'sections',
    initialState,
    extraReducers: (builder) => {
        function onError(state, action) {
            state.loading = false;
            state.error = action.payload;
        }
        function onPending(state, action) {
            state.loading = true;
        }
        //getAllStaticData
        builder.addCase(getCenterSections.pending, onPending);
        builder.addCase(getCenterSections.fulfilled, (state, action) => {
            state.centerSections = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getCenterSections.rejected, onError);
        //getSections
        builder.addCase(getCenterComibeSections.pending, onPending);
        builder.addCase(getCenterComibeSections.fulfilled, (state, action) => {
            state.centerCombinSections = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getCenterComibeSections.rejected, onError);
        builder.addCase(getSections.pending, onPending);
        builder.addCase(getSections.fulfilled, (state, action) => {
            state.sections = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getSections.rejected, onError);
    }
});

export default sectionsSlice.reducer;
