import { DEFAULT_TEXT_SETTINGS } from './textSettings';

export const VIEW_TYPES = {
    HEADER: 'header',
    FOOTER: 'footer',
    PAGE_SETTINGS: 'pageSettings',
    WATERMARK: 'watermark'
};
export const DEFAULT_WATERMARK = {
    type: VIEW_TYPES.WATERMARK,
    image: null,
    opacity: 0.5,
    width: 100,
    height: 100
};
export const DEFAULT_HEADER = {
    type: VIEW_TYPES.HEADER,
    text: '',
    enablePageNumbring: false,
    pageNumbring: {
        x: 0,
        y: 0,
        style: DEFAULT_TEXT_SETTINGS
    }
};
export const DEFAULT_FOOTER = { ...DEFAULT_HEADER, type: VIEW_TYPES.FOOTER };

export const PAGE_SIZES = {
    A1: 'a1',
    A2: 'a2',
    A3: 'a3',
    A4: 'a4',
    A5: 'a5',
    A6: 'a6',
    A7: 'a7',
    A8: 'a8',
    A9: 'a9'
};

export const PAGE_SETTINGS_TYPES = {
    ONE_PAGE: 'One Page',
    MULTIPLE_PAGES: 'Multiple Pages'
};

export const DEFAULT_PAGE_SETTINGS = {
    type: PAGE_SETTINGS_TYPES.ONE_PAGE,
    pageSize: PAGE_SIZES.A1
};

export const DEFAULT_VIEW = {
    waterMark: DEFAULT_WATERMARK,
    pageSettings: DEFAULT_PAGE_SETTINGS,
    footer: DEFAULT_FOOTER,
    header: DEFAULT_HEADER
};
