import React from 'react';

// MUI components
import IconButton from '@mui/material/IconButton';

// hooks
import useSidebar from 'hooks/useSidebar';
import useTheme from '@mui/styles/useTheme';

// MUI icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const ToggleSidebar = () => {
    const { open, handleToggle } = useSidebar();
    const theme = useTheme();
    return (
        <IconButton
            sx={{
                borderRadius: '12px',
                overflow: 'hidden',
                transition: '.1s all',
                margin: 0
            }}
            color="primary"
            onClick={handleToggle}
        >
            {theme.direction === 'rtl' ? (
                open ? (
                    <ChevronRightIcon />
                ) : (
                    <ChevronLeftIcon />
                )
            ) : open ? (
                <ChevronLeftIcon />
            ) : (
                <ChevronRightIcon />
            )}
        </IconButton>
    );
};
export default ToggleSidebar;
