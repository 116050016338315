/* eslint-disable no-unused-vars */
import React from 'react';

// MUI Components
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Progress from 'ui-component/Progress';

let UploadFileProgress = ({ isUploading, progress, uploadedFile }) => {
    return (
        <Collapse in={isUploading} unmountOnExit>
            <Box
                sx={{
                    padding: '10px',
                    width: `100%`,
                    borderRadius: 0,
                    background: '#FFF'
                }}
                id="reply"
            >
                <Typography variant="h4" color="#301b42">
                    {uploadedFile?.name}
                </Typography>
                <Box sx={{ width: '100%' }}>
                    <Progress value={progress} />
                </Box>
            </Box>
        </Collapse>
    );
};

export default UploadFileProgress;
