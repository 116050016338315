let deleteMessage = (state, { payload }) => {
    if (state.currentChat?.id === payload.chatId) {
        state.currentChat.messages = state.currentChat.messages.filter((message) => message.id !== payload.messageId);
        state.currentChat.pins = state.currentChat.pins.filter((message) => message.id !== payload.messageId);
    }
    let chat = state.chats.find((chat) => chat.id === payload.chatId);
    chat.messages = chat.messages.filter((message) => message.id !== payload.messageId);
    chat.pins = chat.pins.filter((message) => message.id !== payload.messageId);
    state.stars = state.stars.filter((message) => message.messageId !== payload.messageId);
    state.searchedMessages = state.searchedMessages.filter((message) => message.id !== payload.messageId);
};
export default deleteMessage;
