/* eslint-disable no-unused-vars */
import * as React from 'react';

// MUI Components

import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EditIcon from '@mui/icons-material/Edit';
import Grow from '@mui/material/Grow';

// store
import { useDispatch, useSelector } from 'react-redux';
import { toggle, setEditedMessage } from 'features/store';

// constants
import { INITIAL_MENU, TOGGLE, MESSAGE_TYPE } from 'constant';

let ContextEdit = ({ message, setMenu, menu, senderIsYou, sender }) => {
    let dispatch = useDispatch();
    let editMode = useSelector((state) => state.chat.editMode);
    let starsOpen = useSelector((state) => state.chat.starsOpen);

    const handleEdit = () => {
        if (!editMode) dispatch(toggle({ type: TOGGLE.EDIT_MODE }));
        dispatch(setEditedMessage({ message, sender }));
        setMenu(INITIAL_MENU);
    };

    return senderIsYou ? (
        <Grow unmountOnExit in={menu.mouseX !== null && message.type === MESSAGE_TYPE.TEXT && !starsOpen} timeout={1200}>
            <MenuItem onClick={handleEdit}>
                <ListItemIcon>
                    <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
            </MenuItem>
        </Grow>
    ) : (
        ''
    );
};

export default ContextEdit;
