export const ClinicalDepartmentsPageConstant = {
    clinicalDepartments: 'Medical_Management_Clinical_Departments.clinicaldepartments',
    name: 'Medical_Management_Clinical_Departments.name',
    icon: 'Medical_Management_Clinical_Departments.icon',
    image: 'Medical_Management_Clinical_Departments.image',
    description: 'Medical_Management_Clinical_Departments.description',
    title: 'Medical_Management_Clinical_Departments.title',
    parentName: 'Medical_Management_Clinical_Departments.parentname',
    code: 'Medical_Management_Clinical_Departments.code',
    shape: 'Medical_Management_Clinical_Departments.shape',
    tasks: 'Medical_Management_Clinical_Departments.tasks',
    isActive: 'Medical_Management_Clinical_Departments.isactive',
    treeList: 'Medical_Management_Clinical_Departments.treelist',
    info: 'Medical_Management_Clinical_Departments.info',
    clinicalDepartmentParent: 'Medical_Management_Clinical_Departments.clinicaldepartmentparent',
    uploadIcon: 'Medical_Management_Clinical_Departments.uploadicon',
    updateOperations: 'Medical_Management_Clinical_Departments.updateoperations',
    updateDoctors: 'Medical_Management_Clinical_Departments.updatedoctors',

    unassignToClinicalDepartment: 'Medical_Management_Clinical_Departments.unassigntoclinicaldepartment',
    unassign: 'Medical_Management_Clinical_Departments.unassign',
    assignToClinicalDepartment: 'Medical_Management_Clinical_Departments.assigntoclinicaldepartment',
    assignDoctorToClinicalDepartment: 'Medical_Management_Clinical_Departments.assigndoctortoclinicaldepartment',
    chooseClinicalDepartments: 'Medical_Management_Clinical_Departments.chooseclinicaldepartments',
    pickClinicalDepartmentsFromTree: 'Medical_Management_Clinical_Departments.pickclinicaldepartmentsfromtree',
    pickClinicalDepartmentsFromTreeList: 'Medical_Management_Clinical_Departments.pickclinicaldepartmentsfromtreelist',
    assign: 'Medical_Management_Clinical_Departments.assign',

    edit: 'Medical_Management_Clinical_Departments.edit',
    delete: 'Medical_Management_Clinical_Departments.delete',
    membersCount: 'Medical_Management_Clinical_Departments.memberscount',
    treeViewer: 'Medical_Management_Clinical_Departments.treeviewer',
    treeViewerAdd: 'Medical_Management_Clinical_Departments.treevieweradd',
    treeViewerDelete: 'Medical_Management_Clinical_Departments.treeviewerdelete',
    treeViewerEdit: 'Medical_Management_Clinical_Departments.treevieweredit',
    treeViewerActions: 'Medical_Management_Clinical_Departments.treevieweractions',
    isSectionActive: 'Medical_Management_Clinical_Departments.issectionactive',
    actions: 'Medical_Management_Clinical_Departments.actions',
    search: 'Medical_Management_Clinical_Departments.search',
    reset: 'Medical_Management_Clinical_Departments.reset',
    doctors: 'Medical_Management_Clinical_Departments.doctors',
    operations: 'Medical_Management_Clinical_Departments.operations',
    operationsCount: 'Medical_Management_Clinical_Departments.operationscount',
    doctorsCount: 'Medical_Management_Clinical_Departments.doctorscount',
    receptionDepartments: 'Medical_Management_Clinical_Departments.receptiondepartments',
    selectLanguage: 'Medical_Management_Clinical_Departments.selectlanguage',
    history: 'Medical_Management_Clinical_Departments.history'
};
