import { objectToArrayWithKeyValue } from 'helpers';

export const roles = {
    Demo: 0,
    Admin: 1,
    Center: 2,
    Branch: 3,
    Section: 4,
    CommonCityGet: 5,
    CommonCityAdd: 6,
    CommonCityUpdate: 7,
    CommonCityDelete: 8,
    CommonCountryGetStates: 9,
    CommonCountryGet: 10,
    CommonCountryAdd: 11,
    CommonCountryUpdate: 12,
    CommonCountryDelete: 13,
    EducationAllMaster: 14,
    MaritalStatusAllMaster: 15,
    NicknameAllMaster: 16,
    ReligionAllMaster: 17,
    CommonStateGetCites: 18,
    CommonStateGet: 19,
    CommonStateAdd: 20,
    CommonStateUpdate: 21,
    CommonStateDelete: 22,
    StudyAllMaster: 23,
    MainBranchGet: 24,
    MainBranchGetDecision: 25,
    MainCenterGet: 26,
    MainCenterUploadLogo: 27,
    MainCenterUploadOwnerImage: 28,
    MainCenterOwnerAttachmentAddOrUpdateOrRemove: 29,
    MainCenterGetDecision: 30,
    MainCenterAddDecision: 31,
    MainCenterUpdateDecision: 32,
    MainCenterDeleteDecision: 33,
    MainCenterGetTask: 34,
    MainCenterAddTask: 35,
    MainCenterUpdateTask: 36,
    MainCenterDeleteTask: 37,
    MainCenterGetGoal: 38,
    MainCenterAddGoal: 39,
    MainCenterUpdateGoal: 40,
    MainCenterDeleteGoal: 41,
    MainCenterGetFeatureSection: 42,
    MainCenterAddFeatureSection: 43,
    MainMemberGet: 44,
    MainMemberAdd: 45,
    MainMemberAddPersonalFile: 46,
    MainMemberAddCareerFile: 47,
    MainMemberAddContactFile: 48,
    MainMemberAddGeneralCareerFile: 49,
    MainMemberAddProfessionalFile: 50,
    MainMemberAddSalaryFile: 51,
    MainMemberAddScientificFile: 52,
    MainMemberDelete: 53,
    MainSectionGet: 54,
    MainSectionAdd: 55,
    MainSectionUpdate: 56,
    MainSectionDelete: 57,
    MainSectionGetSections: 58,
    MainAccountCreateAdmin: 59,
    MainAccountUploadLogoAdmin: 60,
    MainAccountUpdateAdmin: 61,
    MainAccountDeleteAdmin: 62,
    MainAccountCreateCenterBranches: 63,
    MainAccountCreateCenter: 64,
    MainAccountAddCenter: 65,
    MainAccountUpdateCenter: 66,
    MainAccountDeleteCenter: 67,
    MainAccountCreateBranch: 68,
    MainAccountAddBranch: 69,
    MainAccountUpdateBranch: 70,
    MainAccountDeleteBranch: 71,
    SettingAttachmentUpload: 72,
    JobTitleMasterAll: 73,
    SettingLanguageGet: 74,
    SettingLanguageAdd: 75,
    SettingLanguageUpdate: 76,
    SettingLanguageDelete: 77,
    MainCenterAddSection: 78,
    MainCenterAddCombineSection: 79,
    MainCenterRemoveCombineSection: 80,
    MainSectionGetCombineSections: 81,
    MainWebsiteGet: 82,
    MainWebsiteAdd: 83,
    MainWebsiteUpdate: 84,
    MainWebsiteDelete: 85,
    MainWebsiteGetWebsites: 86,
    GetMemberNumbringPattern: 87,
    SocialMediaAllMaster: 88,
    AcademicAllMaster: 89,
    MainMemberAddTask: 90,
    MainMemberAddGoal: 91,
    MemberCategoryMasterAll: 92,
    MemberKindMasterAll: 93,
    MemberTypeMasterAll: 94,
    DynamicFieldMasterAll: 95
};
export const keyValueRoles = objectToArrayWithKeyValue(roles);

export const inRole = (myRoles, role) => {
    const _inRole = myRoles?.find((r) => r == role);
    return Boolean(_inRole);
};
