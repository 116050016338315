export const SectionsManagementPageConstant = {
    sectionMangment: 'System_Administrator_Section_Mangment.sectionmangment',
    sections: 'System_Administrator_Section_Mangment.sections',
    section: 'System_Administrator_Section_Mangment.section',
    name: 'System_Administrator_Section_Mangment.name',
    parentName: 'System_Administrator_Section_Mangment.parentname',
    code: 'System_Administrator_Section_Mangment.code',
    jobTitles: 'System_Administrator_Section_Mangment.jobtitles',
    jobTitlesCount: 'System_Administrator_Section_Mangment.jobtitlescount',
    membersCount: 'System_Administrator_Section_Mangment.memberscount',
    members: 'System_Administrator_Section_Mangment.members',
    description: 'System_Administrator_Section_Mangment.description',
    shape: 'System_Administrator_Section_Mangment.shape',
    isMain: 'System_Administrator_Section_Mangment.ismain',
    title: 'System_Administrator_Section_Mangment.title',
    viewJobTiltes: 'System_Administrator_Section_Mangment.viewjobtiltes',
    fullName: 'System_Administrator_Section_Mangment.fullname',
    sureName: 'System_Administrator_Section_Mangment.surename',
    viewMembers: 'System_Administrator_Section_Mangment.viewmembers',
    activationFrom: 'System_Administrator_Section_Mangment.activationfrom',
    activationTo: 'System_Administrator_Section_Mangment.activationto',
    isActive: 'System_Administrator_Section_Mangment.isactive',
    viewJobTitles: 'System_Administrator_Section_Mangment.viewjobtitles',
    edit: 'System_Administrator_Section_Mangment.edit',
    delete: 'System_Administrator_Section_Mangment.delete',
    actions: 'System_Administrator_Section_Mangment.actions',
    memberSections: 'System_Administrator_Section_Mangment.membersections',
    history: 'System_Administrator_Section_Mangment.history',
    viewSections: 'System_Administrator_Section_Mangment.viewsections',
    sectionsHistroy: 'System_Administrator_Section_Mangment.sectionshistroy',
    activeMembersCount: 'System_Administrator_Section_Mangment.activememberscount',
    unactiveMembersCount: 'System_Administrator_Section_Mangment.unactivememberscount',
    icon: 'System_Administrator_Section_Mangment.icon',
    info: 'System_Administrator_Section_Mangment.info',
    treeViewer: 'System_Administrator_Section_Mangment.treeviewer',
    treeViewerAdd: 'System_Administrator_Section_Mangment.treevieweradd',
    treeViewerDelete: 'System_Administrator_Section_Mangment.treeviewerdelete',
    treeViewerEdit: 'System_Administrator_Section_Mangment.treevieweredit',
    treeViewerActions: 'System_Administrator_Section_Mangment.treevieweractions',
    reset: 'System_Administrator_Section_Mangment.reset',
    search: 'System_Administrator_Section_Mangment.search',
    moreFilter: 'System_Administrator_Section_Mangment.morefilter',
    selectLanguage: 'System_Administrator_Section_Mangment.selectlanguage',
    lessFilter: 'System_Administrator_Section_Mangment.lessfilter'
};
