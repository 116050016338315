import { starMessage, unStarMessage } from 'features/store';
import { authAxios } from 'config/axios';
import { STAR_MESSAGE, UN_STAR_MESSAGE } from 'env/server';
import { useDispatch, useSelector } from 'react-redux';

let useStarMessage = () => {
    const dispatch = useDispatch();
    const stars = useSelector((state) => state.chat.stars);
    const starsOpen = useSelector((state) => state.chat.starsOpen);
    return (messages) => {
        messages.forEach(({ id, messageId }) => {
            const starred = stars?.find((star) => {
                if (starsOpen) return star?.messageId === messageId;
                else return star?.id === id;
            });
            const fetch = async () =>
                await authAxios.put(
                    starred ? UN_STAR_MESSAGE(starsOpen ? messageId : id) : STAR_MESSAGE(starsOpen ? messageId : id)
                );
            fetch();
            dispatch(
                starred ? unStarMessage({ messageId: starsOpen ? messageId : id }) : starMessage({ messageId: starsOpen ? messageId : id })
            );
        });
    };
};

export default useStarMessage;
