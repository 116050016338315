import React from 'react';
import PropTypes from 'prop-types';

// constants
import { MESSAGE_TYPE } from 'constant';

// third party libraries
import { motion } from 'framer-motion';

// MUI Components
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// ============= || components || ============= //

// message types
import File from '../MessageTypes/File';
import Audio from '../MessageTypes/Audio';
import Text from '../MessageTypes/Text';
import Video from '../MessageTypes/Video';
import Image from '../MessageTypes/Image';

// others
import Reactions from '../Reactions';
import SenderAvatar from '../SenderAvatar';
import Header from '../Header';
import Reply from '../Reply';
import Footer from '../Footer';

// hooks
import useGetUser from 'views/chat/hooks/useGetUser';

const MessageContent = ({ message, lastSenderIsSame }) => {
    let sender = useGetUser()(message.accountId);
    let senderIsYou = message.accountId === localStorage.getItem('accountId');
    const messageTypeProps = {
        message,
        lastSenderIsSame,
        senderIsYou,
        sender
    };

    const messageTypeComponent = () => {
        switch (message.type) {
            case MESSAGE_TYPE.TEXT:
                return <Text {...messageTypeProps} />;
            case MESSAGE_TYPE.IMAGE:
                return <Image {...messageTypeProps} />;
            case MESSAGE_TYPE.VIDEO:
                return <Video {...messageTypeProps} />;
            case MESSAGE_TYPE.AUDIO:
                return <Audio {...messageTypeProps} />;
            case MESSAGE_TYPE.FILE:
                return <File {...messageTypeProps} />;
        }
    };

    const messageTypeStyle = () => {
        switch (message.type) {
            case MESSAGE_TYPE.TEXT:
                return {
                    margin: '2px 0',
                    padding: 2,
                    background: senderIsYou ? '#435f7a' : '#fff',
                    color: senderIsYou ? '#f5f5f5' : '#333',
                    display: ' inline-block',
                    padding: '10px 15px',
                    borderRadius: '20px',
                    maxWidth: '300px',
                    lineHeight: ' 130%',
                    wordBreak: 'break-word'
                };
            case MESSAGE_TYPE.IMAGE:
                return {
                    background: '#fff',
                    borderRadius: '5px',
                    padding: '0',
                    margin: '2px 0',
                    width: '100%'
                };
            case MESSAGE_TYPE.VIDEO:
                return {
                    background: '#fff',
                    borderRadius: '5px',
                    padding: '0',
                    margin: '2px 0',
                    width: '100%'
                };
            case MESSAGE_TYPE.AUDIO:
                return {
                    background: '#fff',
                    borderRadius: '5px',
                    padding: '0',
                    margin: '2px 0'
                };
            case MESSAGE_TYPE.FILE:
                return {
                    background: '#fff',
                    borderRadius: '5px',
                    padding: '5px 10px 5px 10px',
                    margin: '2px 0'
                };
        }
    };

    const reactionsProps = { message };

    return (
        <Grid
            container
            sx={{
                fontSize: '0.9em',
                width: 'auto !important',
                flexWrap: 'nowrap'
            }}
        >
            <Grid item order={{ xs: senderIsYou ? 1 : 2 }}>
                <SenderAvatar {...messageTypeProps} />
            </Grid>
            <Grid
                item
                order={{ xs: senderIsYou ? 2 : 1 }}
                sx={{ width: message.type === MESSAGE_TYPE.IMAGE || message.type === MESSAGE_TYPE.VIDEO ? '80%' : 'auto' }}
            >
                <motion.div
                    initial={{ translateX: senderIsYou ? -10 : 10, opacity: 0, transformOrigin: '0 0' }}
                    animate={{ translateX: senderIsYou ? 0 : 0, opacity: 1 }}
                >
                    <Box sx={messageTypeStyle()}>
                        <Header {...{ lastSenderIsSame, message, senderIsYou, sender }} />
                        <Reply {...{ message, senderIsYou }} />
                        {messageTypeComponent()}
                        <Reactions {...reactionsProps} />
                        <Footer {...{ message, senderIsYou }} />
                    </Box>
                </motion.div>
            </Grid>
        </Grid>
    );
};

MessageContent.propTypes = {
    message: {
        accountId: PropTypes.string,
        forward: PropTypes.object,
        type: PropTypes.string
    },
    senderIsYou: PropTypes.bool,
    lastSenderIsSame: PropTypes.bool
};

export default MessageContent;
