// a function that takes a string like "a1" or "a2" to "a9" and returns an object with width and height in pixels
export const aSizeToPixels = (str) => {
    // an object that stores the dimensions of A sizes in pixels
    const sizes = {
        a1: { width: 2245, height: 3179 },
        a2: { width: 1587, height: 2245 },
        a3: { width: 1123, height: 1587 },
        a4: { width: 794, height: 1123 },
        a5: { width: 559, height: 794 },
        a6: { width: 397, height: 559 },
        a7: { width: 280, height: 397 },
        a8: { width: 197, height: 280 },
        a9: { width: 140, height: 197 }
    };

    // convert the string to lowercase
    str = str.toLowerCase();

    // check if the string is a valid A size
    if (sizes[str]) {
        // return an object with width and height in pixels
        return sizes[str];
    } else {
        // return an error message if the string is not a valid A size
        return 'Invalid size';
    }
};
