import React from 'react';
import Avatar from '@mui/material/Avatar';
import Grow from '@mui/material/Grow';

let GroupAvatar = ({ img }) => (
    <Grow in={true} timeout={800}>
        <Avatar src={img} />
    </Grow>
);

export default GroupAvatar;
