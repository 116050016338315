import React from 'react';

// MUI components
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { toggle } from 'features/store';

// Components
import MessageType from 'views/chat/Components/Messages/Message/MessageType';

// Constants
import { TOGGLE } from 'constant';

/* eslint-disable no-unused-vars */

let Edit = () => {
    let dispatch = useDispatch();
    let editMode = useSelector((state) => state.chat.editMode);
    let editedMessage = useSelector((state) => state.chat.editedMessage);
    let message, sender;
    if (editedMessage) {
        message = editedMessage.message;
        sender = editedMessage.sender;
    }
    let handleCloseEdit = () => {
        dispatch(toggle({ type: TOGGLE.EDIT_MODE }));
    };

    return (
        <Collapse in={editMode} unmountOnExit>
            <Box
                sx={{
                    padding: '10px',
                    width: `100%`,
                    borderRadius: 0,
                    background: '#FFF'
                }}
                id="Edit"
            >
                <Box sx={{ borderLeft: '4px solid #ccc', background: '#EEE', height: '100%', padding: '1vw', position: 'relative' }}>
                    <IconButton onClick={handleCloseEdit} sx={{ position: 'absolute', top: 0, right: 0 }}>
                        <ClearIcon fontSize="small" />{' '}
                    </IconButton>
                    <Typography fontWeight="bold">{sender?.customeName ? sender.customeName : '...'}</Typography>
                    <MessageType message={message} />
                </Box>
            </Box>
        </Collapse>
    );
};

export default Edit;
