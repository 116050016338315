import React from 'react';

// MUI components
import Box from '@mui/material/Box';

// NPM packages
import { MESSAGE_TYPE } from 'constant';

// Components
import ItemSkeleton from 'ui-component/Skeletons/ItemSkeleton';
import Doc from '../../Components/Doc';
import LoadMore from '../../Components/LoadMore';

// Hooks
import useGetMedia from 'layout/ChatLayout/hooks/useGetMedia';

let Docs = () => {
    let { isFetching, isError, media, disabled, setPage, page } = useGetMedia(MESSAGE_TYPE.FILE);
    if (isError) return 'error';
    return (
        <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', position: 'relative' }}>
            {isFetching
                ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, i) => <ItemSkeleton key={i} />)
                : media.map((doc, i) => <Doc doc={doc} key={i} timeout={(i + 3) * 200} />)}
            <LoadMore {...{ isFetching, disabled, setPage, page }} />
        </Box>
    );
};

export default Docs;
