import React from 'react';

// Icons
import { IconBriefcase, IconHomeHeart } from '@tabler/icons';
import { roles } from 'env/roles';
const icons = { IconBriefcase, IconHomeHeart };
import MedicationLiquidRoundedIcon from '@mui/icons-material/MedicationLiquidRounded';
import { useTranslation } from 'react-i18next';
import { OperationsDepartmentsPageConstant } from 'constant/Translations/medical-management/operations-departments';
import { ClinicalDepartmentsPageConstant } from 'constant/Translations/medical-management/clinical-departments';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const useMedicalData = () => {
    const { t } = useTranslation();
    return {
        id: 'medical-management',
        title: 'Medical Management',
        type: 'group',
        icon: <MedicationLiquidRoundedIcon />,
        children: [
            {
                id: 'physicians',
                title: 'Physicians',
                type: 'item',
                icon: icons.IconBriefcase,
                url: '/physicians',
                breadcrumbs: false
            },
            {
                id: 'nurses',
                title: 'Nurses',
                type: 'item',
                icon: icons.IconBriefcase,
                url: '/nurses',
                breadcrumbs: false
            },
            {
                id: 'clinical-departments',
                title: t(ClinicalDepartmentsPageConstant.clinicalDepartments),
                type: 'item',
                icon: icons.IconBriefcase,
                url: '/clinical-departments',
                breadcrumbs: false
            },
            {
                id: 'operations-departments',
                title: t(OperationsDepartmentsPageConstant.operationsDepartments),
                type: 'item',
                icon: icons.IconHomeHeart,
                url: '/operations-departments',
                breadcrumbs: false
            },
            {
                id: 'radiology-and-imaging',
                title: 'Radiology and Imaging',
                type: 'item',
                icon: icons.IconBriefcase,
                url: '/radiology-and-imaging',
                breadcrumbs: false
            },
            {
                id: 'pharmacy',
                title: 'Pharmacy',
                type: 'group',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                children: [
                    {
                        id: 'medical-warehouses',
                        title: 'Medical Warehouses',
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/medical-warehouses',
                        breadcrumbs: false
                    },
                    {
                        id: 'medical-companies',
                        title: 'Medical Companies',
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/medical-companies',
                        breadcrumbs: false
                    },
                    {
                        id: 'medications',
                        title: 'Medications',
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/medications',
                        breadcrumbs: false
                    },
                    {
                        id: 'medications-types',
                        title: 'MedicationsTypes',
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/medications-types',
                        breadcrumbs: false
                    },
                    {
                        id: 'medical-formulations',
                        title: 'Medical Formulations',
                        type: 'item',
                        icon: icons.IconBriefcase,
                        role: [roles.Admin],
                        url: '/medical-formulations',
                        breadcrumbs: false
                    }
                ]
            }
        ]
    };
};

export default useMedicalData;
