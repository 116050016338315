import React, { useState } from 'react';

// constants
import { REACTIONS } from 'constant';

// MUI Components
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Zoom from '@mui/material/Zoom';

// components
import ReactionTabs from './ReactionsTabs';

let StyledBox = ({ children }) => (
    <Zoom in={true} timeout={400} unmountOnExit>
        <Box
            sx={{
                background: '#ccc',
                borderRadius: '20px',
                padding: '4px',
                marginRight: '4px',
                color: '#333'
            }}
        >
            {children}
        </Box>
    </Zoom>
);

const Reactions = ({ message }) => {
    const [open, setOpen] = useState(false);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    if (!message.reactions || message.reactions?.length === 0) return '';

    let like = [];
    let funny = [];
    let sad = [];
    let amaze = [];
    let love = [];
    let wish = [];

    message.reactions.forEach((reaction) => {
        switch (reaction.key) {
            case REACTIONS.LIKE:
                like.push(reaction);
                break;
            case REACTIONS.FUNNY:
                funny.push(reaction);
                break;
            case REACTIONS.SAD:
                sad.push(reaction);
                break;
            case REACTIONS.AMAZE:
                amaze.push(reaction);
                break;
            case REACTIONS.WISH:
                wish.push(reaction);
                break;
            case REACTIONS.LOVE:
                love.push(reaction);
                break;
        }
    });

    return (
        <Box display="flex" alignItems="center" marginTop="1px" marginBottom="2px">
            <ButtonBase sx={{ borderRadius: '20px' }} onClick={toggleDrawer}>
                {like.length !== 0 && <StyledBox>{`${like.length}👍`}</StyledBox>}
                {funny.length !== 0 && <StyledBox>{`${funny.length}😂`}</StyledBox>}
                {amaze.length !== 0 && <StyledBox>{`${amaze.length}😮`}</StyledBox>}
                {sad.length !== 0 && <StyledBox>{`${sad.length}😢`}</StyledBox>}
                {wish.length !== 0 && <StyledBox>{`${wish.length}🙏`}</StyledBox>}
                {love.length !== 0 && <StyledBox>{`${love.length}❤️`}</StyledBox>}
            </ButtonBase>
            <Drawer anchor={'bottom'} open={open} onClose={toggleDrawer}>
                <ReactionTabs message={message} />
            </Drawer>
        </Box>
    );
};

export default Reactions;
