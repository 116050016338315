import React from 'react';
import Dialog from '@mui/material/Dialog';
import useMatchDownMd from 'hooks/useMatchDownMd';
import './index.css';
const HCDialog = ({ fullScreen, ...props }) => {
    const matchDownMd = useMatchDownMd();
    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                PaperProps={{
                    sx: { minWidth: '40vw' }
                }}
                className="dialog"
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                {...props}
                maxWidth={'lg'}
                onClose={(e, reason) => console.log(reason)}
            />
        </React.Fragment>
    );
};

export default HCDialog;
