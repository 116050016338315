import { COMPONENT_ARRAY_NAMES, HISTORY_ACTIONS, INITIALIAZE_HISTORY_RECORD, iconMap, messageMap } from 'constant';
import { getItemFromArray } from 'helpers';
import { assignItemToTargetDescriptionTranslation } from '../functions/history/assignItemToTarget';
import { editBasicDescriptionTranslation } from '../functions/history/addOrEditBasicDescriptionTranslation';
import { editLayoutDescriptionTranslation } from '../functions/history/addOrEditLayoutDescriptionTranslation';

export const generateRandomBasicId = () => `BasicId-${Math.random().toFixed(5)}`;
export const generateRandomLayoutId = () => `LayoutId-${Math.random().toFixed(5)}`;
export const generateRandomPageId = () => `PageId-${Math.random().toFixed(5)}`;
export const generateRandomTableColId = () => `TableCol-${Math.random().toFixed(5)}`;
// History Logic
export const addHistoryRecord = ({ state, action = INITIALIAZE_HISTORY_RECORD, from, to, description }) => {
    state.historyId = state.historyId + 1;
    let arr = state.history;
    arr = arr.filter((p) => p.historyId < state.historyId);
    arr.push({ action, from, to, description, historyId: state.historyId });
    state.history = arr;
};
/**
 * Swaps the positions of two selected items in the given array in the state object.
 *
 * @param {Object} state - The current state object.
 * @param {Array} payload - An array containing the two items to swap.
 * @param {string} arrayName - The name of the array to swap items in.
 */
export const swapItems = (state, { payload }, arrayName, action) => {
    // Destructure the payload array to get the two items to swap
    const { item1, item2 } = payload;

    // check if the two items are of the basic type
    const isBasic = arrayName === COMPONENT_ARRAY_NAMES.BASIC_COMPONENT;
    const isLayout = arrayName === COMPONENT_ARRAY_NAMES.LAYOUT_COMPONENT;
    // Swap the positions of the two selected items in the array
    state[arrayName] = state[arrayName].map(({ id, ...rest }) => {
        if (id === item1.id) return item2;
        else if (id === item2.id) return item1;
        else return { id, ...rest };
    });

    const updateArray = (array) =>
        array.map((ele) => ({
            ...ele,
            items: ele.items.map((id) => {
                if (id === item1.id) return item2.id;
                else if (id === item2.id) return item1.id;
                else return id;
            })
        }));

    // if basic update the layout array
    if (isBasic) state.layout = updateArray(state.layout);

    // if layout update the page array
    if (isLayout) state.page = updateArray(state.page);

    // Clear the selected items array
    state[`selected${toFirstLetterUpperCase(arrayName)}`] = [];

    const historyRecordMap = {
        [HISTORY_ACTIONS.BASIC_SWIPE.label]: HISTORY_ACTIONS.BASIC_SWIPE.description,
        [HISTORY_ACTIONS.LAYOUT_SWIPE.label]: HISTORY_ACTIONS.LAYOUT_SWIPE.description,
        [HISTORY_ACTIONS.PAGE_SWIPE.label]: HISTORY_ACTIONS.PAGE_SWIPE.description
    };
    // Add a history record for Swipe
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action,
            description: historyRecordMap[action.label],
            from: null,
            to: {
                item1,
                item2
            }
        });
};
/**
 * Sets the current item ID in the state and updates the current item object based on the ID and array name.
 *
 * @param {Object} state - The current state object.
 * @param {Object} payload - The ID of the current item.
 * @param {string} arrayName - The name of the array to search for the current item.
 */
export const setCurrentItem = (state, { payload }, arrayName) => {
    // Set the current item ID in the state
    state[`current${toFirstLetterUpperCase(arrayName)}Id`] = payload;

    // Update the current item object based on the ID
    state[`current${toFirstLetterUpperCase(arrayName)}`] = getItemFromArray({
        array: state[arrayName],
        id: payload
    });
};
/**
 * Adds or removes an item from the selected items array in the state based on whether the item is already selected.
 *
 * @param {Object} state - The current state object.
 * @param {Object} payload - An object containing the ID of the item to select or deselect.
 * @param {string} arrayName - The name of the array to search for the item.
 */
export const selectItem = (state, { payload }, arrayName) => {
    // Check if the item is already selected
    let isSelected = state[`selected${toFirstLetterUpperCase(arrayName)}`].find(({ id }) => payload.id === id);

    // If the item is not already selected, add it to the selected array
    if (!isSelected) {
        state[`selected${toFirstLetterUpperCase(arrayName)}`].push(payload);
    }
    // If the item is already selected, remove it from the selected array
    else {
        state[`selected${toFirstLetterUpperCase(arrayName)}`] = state[`selected${toFirstLetterUpperCase(arrayName)}`].filter(
            (item) => item.id !== payload.id
        );
    }
};
/**
 * Updates a specific attribute of an item in the given array in the state and adds a history record for the update.
 *
 * @param {Object} state - The current state object.
 * @param {Object} payload - An object containing the attribute to update, the ID of the item to update, and the new value for the attribute.
 * @param {string} arrayName - The name of the array to search for the item.
 * @param {string} itemType - The type of item being updated.
 */
export const updateAttribute = (state, { payload }, arrayName) => {
    const { attr, id, value } = payload;

    // Get the item to update
    const item = getItemFromArray({ array: state[arrayName], id });
    const oldItem = { ...item };
    const itemType = item.type;
    // Update the attribute of the item in the array in state
    item[attr] = value;

    // Map the attribute name to the corresponding message and icon
    const message = messageMap[attr] || '';

    let action = arrayName === COMPONENT_ARRAY_NAMES.BASIC_COMPONENT ? HISTORY_ACTIONS.EDIT_BASIC : HISTORY_ACTIONS.EDIT_LAYOUT;
    // Add a history record for the update
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action,
            description:
                arrayName === COMPONENT_ARRAY_NAMES.BASIC_COMPONENT
                    ? editBasicDescriptionTranslation(`${itemType}`)
                    : editLayoutDescriptionTranslation(`${itemType}`),
            from: oldItem,
            to: item
        });
};

/**
 * Assigns an item to a target array in the state object.
 *
 * @param {Object} state - The current state object.
 * @param {string} targetArray - The name of the target array in the state object.
 * @param {string} sourceArray - The name of the source array in the state object.
 * @param {string} messageTarget - A string indicating the target of the assignment operation, for use in the history message.
 */
export const assignItemToTarget = (state, { payload }, targetArray, sourceArray, messageTarget) => {
    const { itemId } = payload;
    // Get the type of the source item
    const type = getItemFromArray({ array: state[sourceArray], id: itemId }).type;

    const oldParent = { ...state[targetArray].find((item) => item.items.find((item) => item === itemId) !== undefined) };
    // Remove the item from the items array of all target items
    for (let i = 0; i < state[targetArray].length; i++) {
        state[targetArray][i].items = state[targetArray][i].items.filter((item) => item !== itemId);
    }

    // Add the item to the items array of the current target item
    const newParent = getItemFromArray({ array: state[targetArray], id: state[`current${toFirstLetterUpperCase(targetArray)}Id`] });

    newParent.items.push(itemId);

    const isBasicToLayoutAssignment = targetArray === COMPONENT_ARRAY_NAMES.LAYOUT_COMPONENT;
    const action = isBasicToLayoutAssignment ? HISTORY_ACTIONS.ASSIGN_BASIC_TO_LAYOUT : HISTORY_ACTIONS.ASSIGN_LAYOUT_TO_PAGE;
    // Add a history record for the assignment
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action,
            description: assignItemToTargetDescriptionTranslation(messageTarget, type),
            from: { oldParent, newParent: { ...newParent, items: newParent.items.filter((item) => item !== itemId) } },
            to: { oldParent: { ...oldParent, items: oldParent.items.filter((item) => item !== itemId) }, newParent }
        });
};

export const toFirstLetterUpperCase = (str) => `${str?.charAt(0).toUpperCase()}${str?.slice(1)}`;
