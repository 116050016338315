import React from 'react';

// MUI components
import ButtonBase from '@mui/material/ButtonBase';

// Components
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={'a'} target="_blank" href="/">
        <Logo />
    </ButtonBase>
);

export default LogoSection;
