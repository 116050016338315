import React from 'react';
import { useSelector } from 'react-redux';

// MUI components
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

// Components
import Edit from '../Edit';

let Bio = () => {
    let chatInfoOpen = useSelector((state) => state.chat.chatInfoOpen);
    let currentChat = useSelector((state) => state.chat.currentChat);
    return (
        <Grow in={chatInfoOpen} timeout={1000} unmountOnExit>
            <ListItem disablePadding>
                <ListItemButton>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography variant="h2" fontWeight="100">
                                    Bio
                                </Typography>
                                <Box>This is Test</Box>
                            </React.Fragment>
                        }
                    />
                    <Edit />
                </ListItemButton>
            </ListItem>
        </Grow>
    );
};

export default Bio;
