// connection
import { connection } from 'layout/ChatLayout';

// store
import { addMessage, changeId, toggle } from 'features/store';
import { useDispatch, useSelector } from 'react-redux';
// constants
import { TOGGLE } from 'constant';

let useSendMessage = () => {
    let currentChat = useSelector((state) => state.chat.currentChat);
    let replyMessage = useSelector((state) => state.chat.replyMessage);
    let replyMode = useSelector((state) => state.chat.replyMode);

    let dispatch = useDispatch();
    localStorage.setItem('chatId', currentChat?.id);

    let sendMessage = async (message) => {
        let randomId = `RANDOM_${Math.random() * 1000}`;
        dispatch(
            addMessage({
                chatId: localStorage.getItem('chatId'),
                accountId: localStorage.getItem('accountId'),
                text: message.text,
                type: message.type,
                url: message?.url,
                id: randomId,
                reply: replyMode
                    ? { messageId: replyMessage?.message?.id, type: replyMessage?.message.type, text: replyMessage?.message.text }
                    : null,
                forward: message.forwardMessageId,
                reactions: null
            })
        );
        if (replyMode) {
            dispatch(toggle({ type: TOGGLE.REPLY_MODE }));
        }
        let id = await connection.invoke('SendMessage', {
            chatId: localStorage.getItem('chatId'),
            accountId: localStorage.getItem('accountId'),
            text: message.text,
            type: message.type,
            url: message?.url,
            replyMessageId: replyMode ? replyMessage?.message?.id : null,
            forwardMessageId: message.forwardMessageId
        });
        dispatch(changeId({ oldId: randomId, newId: id, chatId: localStorage.getItem('chatId') }));
    };

    return sendMessage;
};

export default useSendMessage;
