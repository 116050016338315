import * as React from 'react';

// MUI components
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Grow from '@mui/material/Grow';

// MUI icons
import AttachFileIcon from '@mui/icons-material/AttachFile';

// NPM packages
// import moment from 'moment';
import { useSelector } from 'react-redux';
import { Notification } from 'helpers';

// components
import useSendMessage from 'layout/ChatLayout/hooks/useSendMessage';
import FileUploadService from 'config/FileUploadService';

// constants
import { STATIC_FILES_URL } from 'env/server';

let AttachFile = ({ setUploadedFile, setIsUploading, setProgress, recording }) => {
    let sendMessage = useSendMessage();
    const replyTo = useSelector((state) => state.chat.replyTo);

    const selectFile = (event) => {
        if (event.target.files[0]) upload(event.target.files[0]);
    };

    const upload = (file) => {
        setIsUploading(true);
        setUploadedFile({ name: 'voice' });
        const reader = new FileReader();
        let type;
        reader.onloadend = () => {
            switch (file.type.slice(0, 5)) {
                case 'image':
                    type = 1;
                    break;
                case 'video':
                    type = 2;
                    break;
                case 'audio':
                    type = 3;
                    break;
                default:
                    type = 4;
                    break;
            }
        };
        setUploadedFile(file);
        reader.readAsDataURL(file);

        Notification.fire({
            icon: 'info',
            title: 'Uploading File'
        });
        setProgress(0);
        FileUploadService.upload(file, localStorage.getItem('token'), (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total));
        })
            .then((response) => {
                Notification.fire({
                    title: response.data.message,
                    timer: 2000,
                    icon: 'success'
                });
                let URL = STATIC_FILES_URL + response.data.data;
                sendMessage({
                    accountId: localStorage.getItem('accountId'),
                    chatId: localStorage.getItem('chatId'),
                    text: file.name + 'THIS_IS_SEPREATOR' + file.size,
                    url: URL,
                    type: type,
                    replyMessageId: null,
                    forwardMessageId: null
                });
                setIsUploading(false);
            })
            .catch(() => {
                setProgress(0);
                Notification.fire({
                    title: 'did not uploaded',
                    timer: 2000,
                    icon: 'error'
                });
                setIsUploading(false);
            });
    };

    return (
        <Grow in={!recording} unmountOnExit>
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IconButton onClick={() => document.getElementById('fileUpload').click()}>
                    <AttachFileIcon />
                </IconButton>
                <input type="file" id="fileUpload" style={{ display: 'none' }} onChange={selectFile} />
            </Box>
        </Grow>
    );
};

export default AttachFile;
