import React from 'react';

// MUI components
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';

// Components
import More from '../More';
import LogoSection from 'layout/ChatLayout/Components/LogoSection';

// Redux
import { useSelector } from 'react-redux';

const Header = () => {
    const state = useSelector((state) => state.chat.connection);
    return (
        <React.Fragment>
            <Grow in={true} timeout={800}>
                <Box
                    id="sideBarHeader"
                    sx={{
                        padding: '0 10px',
                        display: 'flex',
                        height: '60px',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #EEE'
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <LogoSection />
                        <Typography variant="h4" marginLeft="10px" color="primary">
                            {state !== 'Connected' && state}
                        </Typography>
                    </Box>
                    <More />
                </Box>
            </Grow>
            <Grow in={true} timeout={1000}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '10px',
                        borderBottom: '1px solid #EEE'
                    }}
                >
                    <TextField
                        type="search"
                        fullWidth
                        placeholder="Search.."
                        sx={{
                            '& .MuiInputBase-input': {
                                padding: '7px !important'
                            }
                        }}
                    />
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Box>
            </Grow>
        </React.Fragment>
    );
};

export default Header;
