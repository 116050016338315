import React from 'react';

// MUI Components
import Box from '@mui/material/Box';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Videocam from '@mui/icons-material/Videocam';
import Headphones from '@mui/icons-material/Headphones';
import Image from '@mui/icons-material/Image';

// constants
import { MESSAGE_TYPE } from 'constant';

let MessageType = ({ message }) => {
    let messageType = () => {
        if (!message) return 'No Message...';
        if (message.forward) message = message.forward;
        switch (message.type) {
            case MESSAGE_TYPE.TEXT:
                return message?.text?.slice(0, 30) + '...';
            case MESSAGE_TYPE.IMAGE:
                return (
                    <React.Fragment>
                        <Image sx={{ maringRight: '10px' }} /> Image
                    </React.Fragment>
                );
            case MESSAGE_TYPE.VIDEO:
                return (
                    <React.Fragment>
                        <Videocam sx={{ maringRight: '10px' }} /> Video
                    </React.Fragment>
                );
            case MESSAGE_TYPE.AUDIO:
                return (
                    <React.Fragment>
                        <Headphones sx={{ maringRight: '10px' }} /> Record
                    </React.Fragment>
                );
            case MESSAGE_TYPE.FILE:
                return (
                    <React.Fragment>
                        <InsertDriveFileIcon sx={{ maringRight: '10px' }} /> File
                    </React.Fragment>
                );
        }
    };
    return (
        <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
            {messageType()}
        </Box>
    );
};

export default MessageType;
