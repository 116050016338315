import * as React from 'react';

// MUI components
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ButtonBase from '@mui/material/ButtonBase';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import useTheme from '@mui/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
// MUI Icons
import DeleteIcon from '@mui/icons-material/Delete';

// Third-party packages
import moment from 'moment';
import { TransitionGroup } from 'react-transition-group';

// Components
import ChatItem from 'ui-component/ChatItem';
import GroupAvatar from 'views/chat/Components/GroupAvatar';
import UserAvatar from 'views/chat/Components/UserAvatar';
import MessageType from 'views/chat/Components/Messages/Message/MessageType';
import Notification from 'layout/ChatLayout/Components/Sidebar/Content/Chats/SideBarChat/Notifications';

// Hooks
import useForwardMessage from 'layout/ChatLayout/hooks/useForwardMessage';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import AnimatedDialog from 'ui-component/AnimatedDialog';
import { toggleDialog } from 'features/store';

// Constants
import { DIALOGS } from 'constant';

/* eslint-disable no-unused-vars */

function renderItem({ chat, handleRemoveChat }) {
    return (
        <Box sx={{ width: '100px', padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Avatar src={chat.image} />
            </Box>
            <Box sx={{ width: '100%', textAlign: 'center' }}>
                <Typography>{chat.groupName}</Typography>
                <IconButton aria-label="delete" title="Delete" onClick={() => handleRemoveChat(chat)}>
                    <DeleteIcon />
                </IconButton>
            </Box>
        </Box>
    );
}

let ForwardDialog = () => {
    const name = DIALOGS.FORWARD;
    // hooks
    const [selectedChats, setSelectedChats] = React.useState([]);

    // store
    const messages = useSelector((state) => state.chat?.dialogs[name]?.data) || [];
    const chats = useSelector((state) => state.chat.chats) || [];
    const dispatch = useDispatch();

    // custom hooks
    const forwardMessage = useForwardMessage();

    // theme
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleForward = () => {
        forwardMessage(
            messages,
            selectedChats.map(({ id }) => id)
        );
        dispatch(toggleDialog({ name, data: messages }));
    };

    const handleAddChat = (chat) => {
        setSelectedChats((prev) => [chat, ...prev]);
    };

    const handleRemoveChat = (chat) => {
        setSelectedChats((prev) => [...prev.filter((i) => i !== chat)]);
    };

    const handleClose = () => {
        dispatch(toggleDialog({ name, data: messages }));
    };

    return (
        <AnimatedDialog
            {...{
                props: {
                    sx: {
                        '.MuiPaper-root': {
                            width: !fullScreen ? '45%' : '100%'
                        }
                    },
                    fullScreen
                },
                title: 'Choose Chat To Forward',
                contents: [
                    <React.Fragment>
                        <TransitionGroup style={{ display: 'flex' }}>
                            {selectedChats.map((chat, i) => (
                                <Collapse key={i} in={true} unmountOnExit timeout={400}>
                                    {renderItem({ chat, handleRemoveChat })}
                                </Collapse>
                            ))}
                        </TransitionGroup>
                        {chats.map((chat, i) => {
                            let lastMessage;
                            let date;
                            if (chat.messages) {
                                lastMessage = chat?.messages?.length !== 0 ? chat.messages[chat?.messages?.length - 1] : null;
                                date = chat?.messages.length !== 0 ? moment(chat.messages[0].date).format('MMM DD') : '';
                            } else {
                                lastMessage = '...';
                                date = '...';
                            }

                            return (
                                <React.Fragment key={i}>
                                    <ButtonBase
                                        sx={{ width: '100%' }}
                                        disabled={selectedChats.find((selectedChat) => selectedChat.id === chat.id) !== undefined}
                                        onClick={() => handleAddChat(chat)}
                                    >
                                        <ChatItem
                                            icon={
                                                chat.isGroup ? (
                                                    <GroupAvatar img={chat.image} />
                                                ) : (
                                                    <UserAvatar status={chat.status} img={chat.image} />
                                                )
                                            }
                                            title={chat.groupName}
                                            desc={<MessageType message={lastMessage} />}
                                            topRight={date}
                                            bottomRight={<Notification notifications={chat.notifications} />}
                                        />
                                    </ButtonBase>
                                    <Divider />
                                </React.Fragment>
                            );
                        })}
                    </React.Fragment>
                ],
                actions: (
                    <React.Fragment>
                        <Button onClick={handleClose}>Close</Button>
                        <Button onClick={handleForward} variant="contained" disabled={selectedChats.length === 0}>
                            Forward
                        </Button>
                    </React.Fragment>
                ),
                name
            }}
        />
    );
};

export default ForwardDialog;
