import { HISTORY_ACTIONS } from 'constant';
import { addHistoryRecord, getItemFromArray } from 'helpers';
/**
 * Edits one or more cells in a SpreadSheet table in the state and adds a history record for the edit.
 * @param {object} state - The state object.
 * @param {object} payload - An object containing an array of cell objects to edit and the ID of the SpreadSheet table.
 */
export const editSpreadSheetCell = (state, { payload }) => {
    const { cells, tableId } = payload;

    // Find the SpreadSheet table to update
    let table = getItemFromArray({ array: state.basic, id: tableId });
    /*     const oldTable = { ...table };
     */ // Update the specified cells in the table
    for (let i = 0; i < cells.length; i++) {
        let row = cells[i][0];
        let col = cells[i][1];
        let value = cells[i][3];
        table.rows[row][col] = value;
    }

    /*     // Add a history record for the edit
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.EDIT_BASIC,
            description: 'Edited cells in SpreadSheet table',
            from: oldTable,
            to: table
        }); */
};
