import React, { lazy } from 'react';

// Component
import Loadable from 'ui-component/Loadable';

const DesignFileTemplate = Loadable(lazy(() => import('views/FileTemplates/DesignFileTemplate')));
const FileTemplatesWithId = Loadable(lazy(() => import('views/FileTemplates/DesignFileTemplate/FileTemplatesWithId')));
const MemberCategoryFileTemplate = Loadable(lazy(() => import('views/FileTemplates/DesignFileTemplate/UpdateMemberCategoryFileTemplate')));
const FileTemplates = Loadable(lazy(() => import('views/FileTemplates')));
const routes = [
    {
        path: '/file-template',
        element: <FileTemplates />
    },
    {
        path: '/file-template/blank',
        element: <DesignFileTemplate />
    },
    { path: '/file-template/edit-file', element: <FileTemplatesWithId /> },
    { path: '/member-categories/member-category-custom-file', element: <MemberCategoryFileTemplate /> }
];
export default routes;
