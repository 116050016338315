import { deleteBasic } from './deleteBasic';

/**
 * Deletes multiple basic items from the state and adds a history record for the deletion.
 * @param {object} state - The state object.
 * @param {array} payload - An array of items to delete.
 */
export const deleteMultipleBasic = (state, { payload }) => {
    // Call deleteBasic function for each item in the payload array
    payload?.items?.forEach((item) => {
        deleteBasic(state, { payload: { itemId: item.id, withOutHistroyRecord: payload.withOutHistroyRecord } });
    });
    state.selectedBasic = [];
};
