import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// constants
import { handleOpenContextMenu, INITIAL_MENU, TOGGLE } from 'constant';

// MUI Components
import Box from '@mui/material/Box';

// store
import { useDispatch, useSelector } from 'react-redux';
import { selectMessage, toggle } from 'features/store';

// ============= || components || ============= //

import ContextMenu from '../ContextMenu';
import SelectCheckbox from './SelectCheckbox';
import MessageContent from './MessageContent';

const Message = ({ message, lastSenderIsSame }) => {
    // store
    const starredMessagesOpen = useSelector((state) => state.chat.starredMessagesOpen);
    const pinnedMessagesOpen = useSelector((state) => state.chat.pinnedMessagesOpen);
    const selectMode = useSelector((state) => state.chat.selectMode);

    // state
    const [menu, setMenu] = useState(INITIAL_MENU);
    const [selected, setSelected] = useState(false);

    const dispatch = useDispatch();

    // functions
    const handleRightClick = (e) => handleOpenContextMenu(e, setMenu);
    const handleClick = () => {
        if (starredMessagesOpen && !selectMode) dispatch(toggle({ type: TOGGLE.STARRED_MESSAGES }));
        if (pinnedMessagesOpen && !selectMode) dispatch(toggle({ type: TOGGLE.PINNED_MESSAGES }));
        if (selectMode) {
            setSelected(!selected);
            dispatch(selectMessage(message));
        }
    };

    // data for props
    const senderIsYou = message.accountId === localStorage.getItem('accountId');

    useEffect(() => {
        if (!selectMode) setSelected(false);
    }, [selectMode]);

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: senderIsYou ? 'flex-start' : 'flex-end',
                    cursor: 'context-menu',
                    alignItems: 'center',
                    transition: '.2s all',
                    backgroundColor: selected ? 'rgba(233,233,233,0.7)' : 'transparent',
                    borderRadius: '2px'
                }}
                onClick={handleClick}
            >
                <SelectCheckbox {...{ setSelected, selected, senderIsYou, transform: 'translate(0,-50%)', message }} />
                <Box
                    onContextMenu={handleRightClick}
                    sx={{ transition: '.3s all !important', translate: selectMode ? (senderIsYou ? '50px' : '-50px') : 0 }}
                >
                    <MessageContent {...{ message, lastSenderIsSame }} />
                </Box>
                <SelectCheckbox {...{ setSelected, selected, senderIsYou: !senderIsYou, transform: 'translate(-100%,-50%)', message }} />
                <ContextMenu {...{ menu, setMenu, message, setSelected }} />
            </Box>
        </React.Fragment>
    );
};

Message.propTypes = {
    message: {
        accountId: PropTypes.string,
        forward: PropTypes.object,
        type: PropTypes.string
    },
    lastSenderIsSame: PropTypes.bool
};

export default Message;
