import { BASIC_TYPES } from 'constant';

export const getDataSeries = ({ table, col }) => {
    const arr = [];
    table?.rows?.forEach((row) => {
        const value = Number(row[table?.type === BASIC_TYPES.DATAGRID ? col?.header : col?.id]);
        if (value.toString() !== 'NaN') arr.push(value);
    });
    return arr;
};
