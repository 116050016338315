import React from 'react';

// MUI Components
import Grow from '@mui/material/Grow';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

// hooks
import useGetUser from 'views/chat/hooks/useGetUser';

let User = ({ reaction }) => {
    let user = useGetUser()(reaction.value);
    return (
        <Grow in={true}>
            <Box sx={{ marginRight: 3 }}>
                <Avatar src={'http://emilcarlsson.se/assets/mikeross.png'} sx={{ margin: 'auto', display: 'block' }} />
                <Typography sx={{ width: '100%', textAlign: 'center' }}>{user.customeName}</Typography>
            </Box>
        </Grow>
    );
};

export default User;
