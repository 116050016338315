import { COLORS } from './theme';

export const LEFT_BORDER_STYLE = {
    padding: '5px !important',
    borderLeft: `10px solid ${COLORS.MAIN} !important`,
    background: `${COLORS.SECONDARY} !important`,
    marginBottom: '10px !important'
};

export const LEFT_STYLE = { padding: '5px', background: COLORS.SECONDARY, marginBottom: '15px' };
/* ffd9fe */
