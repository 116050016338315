import { HISTORY_ACTIONS } from 'constant';
import { TablehistoryDescriptionTranslation } from 'constant/FileTemplate/tablehistoryDescriptionTranslation';
import { addHistoryRecord, generateRandomTableColId, getItemFromArray } from 'helpers';

/**
 * Adds a column to a data grid in the state.
 * @param {object} state - The current state of the application.
 * @param {object} action - The action object with properties type and payload.
 * @param {object} action.payload - An object containing the properties tableId, col, and fromRight.
 * @param {string} action.payload.tableId - The ID of the data grid table to add the column to.
 * @param {object} action.payload.col - The column object to add to the data grid table.
 * @param {boolean} action.payload.fromRight - Whether to add the column to the right (true) or left (false) of the table.
 */
export const addDataGridCol = (state, { payload }) => {
    const { tableId, col, fromRight } = payload;
    // Retrieve the data grid table object with the given tableId from the basic array in the state using the getItemFromArray function
    const table = getItemFromArray({ array: state.basic, id: tableId });
    const oldItem = { ...table };

    const newCol = { ...col, id: generateRandomTableColId() };
    // Add the new column to the table's cols array either to the right or left depending on the value of fromRight
    if (fromRight) table.cols = [...table.cols, newCol];
    else table.cols = [newCol, ...table.cols];
    const action = HISTORY_ACTIONS.EDIT_BASIC;
    const description = TablehistoryDescriptionTranslation.AddedColToDataGrid;
    const from = oldItem;
    const to = state.basic.find(({ id }) => id === tableId);

    // Add a history record for the update
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action,
            description,
            from,
            to
        });
};
