import React from 'react';

let Text = ({ message, senderIsYou }) => (
    <div style={{ textAlign: senderIsYou ? 'left' : 'right' }}>
        {message.text}
        <br />
    </div>
);

export default Text;
