import React from 'react';

// MUI components
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

// NPM packages
import moment from 'moment';

// components
import VideoSkeleton from './VideoSkeleton';
import LoadMore from '../../Components/LoadMore';

// hooks
import useGetMedia from 'layout/ChatLayout/hooks/useGetMedia';

// constants
import { MESSAGE_TYPE } from 'constant';

/* eslint-disable jsx-a11y/media-has-caption */

let Videos = () => {
    const { isFetching, isError, media, disabled, setPage, page } = useGetMedia(MESSAGE_TYPE.VIDEO);
    if (isError) return 'error';
    return (
        <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', position: 'relative' }}>
            {isFetching
                ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, i) => <VideoSkeleton key={i} />)
                : media.map((video, i) => (
                      <Box sx={{ width: '100%', padding: '10px' }} key={i}>
                          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
                              <video controls style={{ width: '90%' }}>
                                  <source src={video.url} />
                              </video>
                          </Box>
                          <Box textAlign="right" sx={{ width: '100%' }}>
                              {moment(video.date).format('LT')}
                          </Box>
                          <Divider />
                      </Box>
                  ))}
            <LoadMore {...{ isFetching, disabled, setPage, page }} />
        </Box>
    );
};

export default Videos;
