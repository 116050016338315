import React from 'react';

// constants
import { INITIAL_MENU } from 'constant';

// MUI Components
import Menu from '@mui/material/Menu';

// components
import ContextForward from '../ContextMenu/Forward';
import ContextReply from '../ContextMenu/Reply';
import ContextReaction from '../ContextMenu/Reaction';
import ContextCopy from '../ContextMenu/Copy';
import ContextStar from '../ContextMenu/Star';
import ContextDelete from '../ContextMenu/Delete';
import ContextPin from '../ContextMenu/Pin';
import ContextEdit from '../ContextMenu/Edit';
import ContextSelect from './Select';
import useGetUser from 'views/chat/hooks/useGetUser';

let ContextMenu = ({ menu, setMenu, message, setSelected }) => {
    const senderIsYou = message.accountId === localStorage.getItem('accountId');
    const sender = useGetUser()(message.accountId);

    let contextMenuProps = {
        setMenu,
        message,
        menu,
        senderIsYou
    };

    const handleClose = () => {
        setMenu(INITIAL_MENU);
    };

    return (
        <Menu
            sx={{
                '.MuiPaper-root': {
                    overflow: 'visible',
                    background: 'filter',
                    width: '165px',
                    borderRadius: '10px',
                    padding: '5px',
                    '& ul': {
                        padding: 0
                    },
                    '& li': {
                        padding: '5px',
                        minHeight: 'auto !important'
                    }
                }
            }}
            open={menu.mouseY !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={menu.mouseY !== null && menu.mouseX !== null ? { top: menu.mouseY, left: menu.mouseX } : undefined}
        >
            <ContextReply {...{ ...contextMenuProps, sender }} />
            <ContextCopy {...contextMenuProps} />
            <ContextStar {...contextMenuProps} />
            <ContextPin {...contextMenuProps} />
            <ContextForward {...contextMenuProps} />
            <ContextEdit {...{ ...contextMenuProps, sender }} />
            <ContextSelect {...{ ...contextMenuProps, setSelected }} />
            <ContextDelete {...contextMenuProps} />
            <ContextReaction {...contextMenuProps} />
        </Menu>
    );
};

export default ContextMenu;
