import { HISTORY_ACTIONS } from 'constant';
import { addHistoryRecord } from 'helpers';

export const setApiOptions = (state, { payload }) => {
    const oldApiOptions = state.apiOptions;
    state.apiOptions = payload;
    // Add a history record for the insertion action.
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.EDIT_API_OPTIONS,
            description: HISTORY_ACTIONS.EDIT_API_OPTIONS.description,
            from: oldApiOptions,
            to: payload
        });
};
