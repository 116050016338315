import * as React from 'react';

// Components
import Wallpaper from './Wallpaper';

// MUI components
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';

// MUI Icons
import DarkMode from '@mui/icons-material/DarkMode';
import ArrowBack from '@mui/icons-material/ArrowBack';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { toggle } from 'features/store';

// Constants
import { TOGGLE } from 'constant';

let Settings = () => {
    let settingsOpen = useSelector((state) => state.chat.settingsOpen);
    let dispatch = useDispatch();

    let handleClose = () => {
        dispatch(toggle({ type: TOGGLE.SETTINGS }));
    };

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: settingsOpen ? '350px' : 0 }} aria-label="mailbox folders">
            <Drawer
                variant={'persistent'}
                anchor="left"
                open={settingsOpen}
                sx={(theme) => ({
                    '& .MuiDrawer-paper': {
                        width: '350px',
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary
                    }
                })}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                <List sx={{ paddingTop: 0 }}>
                    <Grow in={settingsOpen} timeout={400} unmountOnExit>
                        <ListItem disablePadding sx={{ padding: '10px', background: '#301b42' }}>
                            <IconButton
                                onClick={handleClose}
                                sx={{
                                    '.MuiSvgIcon-root': {
                                        color: '#FFF'
                                    }
                                }}
                            >
                                <ArrowBack />
                            </IconButton>
                            <Typography variant="h4" pl={1} color="#fff">
                                Settings
                            </Typography>
                        </ListItem>
                    </Grow>
                    <Divider />
                    <Wallpaper />
                    <Divider />
                    <Grow in={settingsOpen} timeout={1200} unmountOnExit>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <DarkMode />
                                </ListItemIcon>
                                <ListItemText primary={'Dark Mode'} />
                                <Switch />
                            </ListItemButton>
                        </ListItem>
                    </Grow>
                </List>
            </Drawer>
        </Box>
    );
};

export default Settings;
