export const FT_ICONS = {
    ASSIGN_ITEM: 'assign',
    DIR: 'dir',
    INSERT: 'Insert',
    DUPLICATE: 'Duplicate',
    EDIT: 'Edit',
    DELETE: 'Delete',
    SWIPE: 'Swipe',
    ANIMATE: 'Animated',
    CONDITIONS: 'conditions',
    MARGIN: 'margin',
    HIDE_IN_PRINT: 'Hide in print',
    VIEW_IN_PRINT: 'View in print',
    INSERT_BEFORE: 'insert Before Basic',
    INSERT_AFTER: 'insert After Basic',
    LTR: 'ltr',
    RTL: 'rtl',
    STYLES: 'Styles',
    HEADER: 'Header',
    FOOTER: 'Footer',
    PAGE_SETTINGS: 'Page Settings',
    WATERMARK: 'Watermark',
    TEXTFIELD: 'TextField',
    CHART: 'chart',
    TEXT: 'Text',
    TABLE: 'Table',
    SPREADSHEET: 'Spreadsheet',
    DATAGRID: 'Datagrid',
    TREE: 'Tree',
    TIMELINE: 'Timeline',
    EXPAND: 'Expand',
    GRID: 'Grid',
    FLEX_LAYOUT: 'Flex Layout',
    SUBMIT_BUTTON: 'Submit Button',
    RESET_BUTTON: 'Reset Button',
    VIEW: 'View',
    INIT: 'Initialized',
    HISTORY: 'History',
    SELECT: 'Select',
    TABS: 'Tabs',
    SET_CURRENT_ITEM: 'Set current item',
    EXTERNAL_DATA_SOURCE: 'External Data Source',
    API_OPTIONS: 'API Options',
    CHECK: 'Check',
    CHECK_BOX: 'Check Box',
    REPLACE: 'Replace',
    FILTER_OFF: 'Filter Of',
    FILTER: 'Filter',
    MORE: 'More',
    LESS: 'Less',
    ARROW_UP: 'Arrow up',
    ARROW_DOWN: 'Arrow down'
};
