import * as React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from '@react-spring/web';

// MUI Components
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

// UI Components
import AnimatedElements from 'ui-component/AnimatedElements';
import HCDialog from 'ui-component/HCDialog';

export const DialogEnterTransition = React.forwardRef(function Fade(props, ref) {
    const { children, in: open, onClick, onEnter, onExited, ownerState, ...other } = props;
    const style = useSpring({
        from: { opacity: 0, translateX: 'calc(50vw - 100%)' },
        to: { opacity: open ? 1 : 0, translateX: open ? 'calc(50vw - 50%)' : 'calc(50vw - 100%)' },
        onStart: () => {
            if (open && onEnter) {
                onEnter(null, true);
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited(null, true);
            }
        }
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {React.cloneElement(children, { onClick })}
        </animated.div>
    );
});

DialogEnterTransition.propTypes = {
    children: PropTypes.element.isRequired,
    in: PropTypes.bool,
    onClick: PropTypes.any,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    ownerState: PropTypes.any
};

export const CUSTOM_DIALOG_SX = {
    borderRadius: (theme) => theme?.shape?.borderRadius,
    boxShadow: 0,
    p: 1
};

function ConfirmModal({
    title = 'Delete',
    desc = 'Are You Sure You Want To Delete This Member?',
    open,
    handleClose = () => {},
    modalSx = {},
    varinat = 'warning',
    callback = () => {}
}) {
    return (
        <HCDialog open={open} onClose={handleClose}>
            <AnimatedElements timeout={100} open={open} startWith={8} withoutWrapper={true}>
                <DialogTitle variant="h3">{title}</DialogTitle>
                <DialogContent
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}
                >
                    {varinat === 'warning' ? (
                        <ErrorRoundedIcon
                            sx={{
                                fontSize: '110px'
                            }}
                            color="warning"
                        />
                    ) : (
                        <TaskAltRoundedIcon
                            color="success"
                            sx={{
                                fontSize: '130px',
                                mb: 3
                            }}
                        />
                    )}
                    <Typography variant="h3">{desc}</Typography>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button onClick={handleClose} variant="contained" color="error" fullWidth>
                                Close
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                color={'success'}
                                onClick={() => {
                                    handleClose();
                                    callback();
                                }}
                                variant="contained"
                                fullWidth
                            >
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </AnimatedElements>
        </HCDialog>
    );
}

export default ConfirmModal;
