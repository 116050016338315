import { getItemFromArray } from 'helpers';

/**
 * Sets the settings for a spreadsheet table in the state.
 * @param {object} state - The current state of the application.
 * @param {object} action - The action object with properties type and payload.
 * @param {object} action.payload - An object containing the properties settings and tableId.
 * @param {object} action.payload.settings - The new settings to apply to the table object.
 * @param {string} action.payload.tableId - The ID of the table object to update.
 */
export const setSpreadSheetSettings = (state, action) => {
    const { settings, tableId } = action.payload;

    // Retrieve the table object with the given tableId from the basic array in the state using the getItemFromArray function
    const table = getItemFromArray({ array: state.basic, id: tableId });

    // Update the table's settings property with the new settings
    table.settings = settings;
};
