import React from 'react';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { cellWithoutFilters } from './cellWithoutFilters';
export const deleteCell = ({ handleOpen, rows, excludeFunc = () => false, label }) => ({
    header: label,
    size: 100,
    accessorKey: 'delete',
    ...cellWithoutFilters,
    Cell: ({ row }) => {
        return (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Button
                    onClick={() => {
                        handleOpen(rows[row?.id]);
                    }}
                    variant={'contained'}
                    color="error"
                    disabled={excludeFunc(row.original)}
                    startIcon={<DeleteRoundedIcon />}
                >
                    {label}
                </Button>
            </Box>
        );
    }
});

export default deleteCell;
