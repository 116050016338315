let changeId = (state, { payload: { oldId, newId, chatId } }) => {
    if (state.currentChat.id === chatId) {
        let message = state.currentChat.messages.find((message) => message.id === oldId);
        state.currentChat.messages[state.currentChat.messages.indexOf(message)].id = newId;
    }
    let chat = state.chats.find((chat) => chat.id === chatId);
    let message = chat.messages.find((message) => message.id === oldId);
    chat.messages[chat.messages.indexOf(message)].id = newId;
};

export default changeId;
