export const generateSkeletons = () => {
    let skeletons = [];
    for (let i = 0; i < 20; i++) {
        skeletons.push({
            senderIsYou: i % 2 == 0,
            lastSenderIsSame: false,
            width: `${Math.random() * 200 + 100}px`,
            lines: [1, 2, 3, 4]
        });
    }

    return skeletons;
};
