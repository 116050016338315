export const DataSourceRequestsPageConstant = {
    dataSourceRequests: 'Settings_File_Templates_Data_Sources_Requests.datasourcerequests',
    dataSourceName: 'Settings_File_Templates_Data_Sources_Requests.datasourcename',
    options: 'Settings_File_Templates_Data_Sources_Requests.options',
    confirm: 'Settings_File_Templates_Data_Sources_Requests.confirm',
    replace: 'Settings_File_Templates_Data_Sources_Requests.replace',
    replaceDataSource: 'Settings_File_Templates_Data_Sources_Requests.replacedatasource',
    save: 'Settings_File_Templates_Data_Sources_Requests.save',
    cancel: 'Settings_File_Templates_Data_Sources_Requests.cancel',
    merge: 'Settings_File_Templates_Data_Sources_Requests.merge',
    optionText: 'Settings_File_Templates_Data_Sources_Requests.optiontext',
    optionValue: 'Settings_File_Templates_Data_Sources_Requests.optionvalue',
    code: 'Settings_File_Templates_Data_Sources_Requests.code',
    edit: 'Settings_File_Templates_Data_Sources_Requests.edit',
    delete: 'Settings_File_Templates_Data_Sources_Requests.delete'
};
