import React, { lazy } from 'react';

// Components
import MinimalLoader from 'ui-component/MinimalLoader';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = MinimalLoader(lazy(() => import('views/authentication/authentication3/Login3')));
const RequestResetPassword = MinimalLoader(lazy(() => import('views/authentication/RequestResetPassword')));
const ResetPassword = MinimalLoader(lazy(() => import('views/authentication/ResetPassword')));
const UpdateEmail = MinimalLoader(lazy(() => import('views/authentication/UpdateEmail')));
const WaitingToConfirmAccount = MinimalLoader(lazy(() => import('views/authentication/WaitingToConfirmAccount')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin3 />
        },
        {
            path: '/request-reset-password',
            element: <RequestResetPassword />
        },
        {
            path: '/reset-password',
            element: <ResetPassword />
        },
        {
            path: '/update-email-confirmation',
            element: <UpdateEmail />
        },
        {
            path: '/waiting-to-confirm-account',
            element: <WaitingToConfirmAccount />
        }
    ]
};

export default AuthenticationRoutes;
