/**
 * Toggles the edit column mode in the state and updates the edited column and localStorage accordingly.
 * @param {object} state - The state object.
 * @param {object} payload - An object containing the header of the column being edited.
 */
export const editDataGridColToggle = (state, { payload }) => {
    // Update the edited column in the state
    if (!state.editColMode) state.editedCol = payload;

    // Toggle the edit column mode in the state
    state.editColMode = !state.editColMode;
};
