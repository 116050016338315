import { BASIC_TYPES, HISTORY_ACTIONS } from 'constant';
import { addHistoryRecord, getItemFromArray } from 'helpers';
import { setCurrentBasic } from '../common';
import { addBasicFunc } from '.';
import { addBasicDescriptionTranslation } from '../functions/history/addOrEditBasicDescriptionTranslation';

/**
 * Calls the addBasicFunc function to add a new item to the basic array in state and sets the current basic item.
 * If the added item is a spreadsheet or datagrid, adds a history record to the state object.
 * @param {object} state - The state object.
 * @param {object} payload - An object containing the properties of the new item.
 */
export const addBasic = (state, { payload }) => {
    // Call the addBasicFunc function to add the new item to the basic array
    addBasicFunc(state, payload);

    // Set the current basic item to the newly added item
    const id = state.basic[state.basic.length - 1].id;
    setCurrentBasic(state, { payload: id });

    // If the added item is a spreadsheet or datagrid, add a history record for the insertion
    if (payload.type === BASIC_TYPES.SPREADSHEET || payload.type === BASIC_TYPES.DATAGRID) {
        if (!payload.withOutHistroyRecord)
            addHistoryRecord({
                state,
                action: HISTORY_ACTIONS.INSERT_BASIC,
                description: addBasicDescriptionTranslation(`${payload.type}`),
                from: null,
                to: {
                    layoutItem: getItemFromArray({ array: state.layout, id: state.currentLayoutId }),
                    basicItem: state.basic[state.basic.length - 1]
                }
            });
    }
};
