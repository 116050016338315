import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// MUI components
import useTheme from '@mui/material/styles/useTheme';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';

// Assets
import { IconLogout, IconSettings } from '@tabler/icons';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { successLogOut } from 'features/Auth/authSlice';
import { useDialog } from 'hooks/useDialog';
import ConfirmModal from 'ui-component/Modal/ConfirmModal';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(false);
    const dialog = useDialog();
    const handleLogout = async () => {
        dispatch(successLogOut());
    };

    const { account, error, loading } = useSelector((state) => state.auth);

    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);

        if (route && route !== '') {
            navigate(route);
        }
    };

    return (
        <React.Fragment>
            <IconButton
                sx={{
                    cursor: 'pointer'
                }}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="inherit"
                onClick={handleOpen}
            >
                <Avatar
                    sx={{
                        width: '30px',
                        height: '30px'
                    }}
                    src={account.profile_image_url}
                />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Box sx={{ p: 2 }}>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">Good Morning,</Typography>
                        <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                            {account?.fullname}
                        </Typography>
                    </Stack>
                    <Typography variant="subtitle2">Copy Writer</Typography>
                </Box>
                <Divider />
                <div
                    style={{
                        height: '100%',
                        maxHeight: 'calc(100vh - 250px)',
                        overflowX: 'hidden'
                    }}
                >
                    <List
                        component="nav"
                        sx={{
                            padding: 0,
                            width: '100%',
                            maxWidth: 350,
                            minWidth: 300,
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: '10px',
                            [theme.breakpoints.down('md')]: {
                                minWidth: '100%'
                            },
                            '& .MuiListItemButton-root': {
                                mt: 0.5
                            }
                        }}
                    >
                        <ListItemButton
                            selected={selectedIndex === 0}
                            onClick={(event) => handleListItemClick(event, 0, '/dashboard/Settings')}
                        >
                            <ListItemIcon>
                                <IconSettings stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">Settings</Typography>} />
                        </ListItemButton>
                        <ListItemButton selected={selectedIndex === 4} onClick={dialog.handleOpen}>
                            <ListItemIcon>
                                <IconLogout stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                        </ListItemButton>
                    </List>
                </div>
            </Menu>
            <ConfirmModal title="Logout" desc="Are you sure you want to logout" variant="warning" {...dialog} callback={handleLogout} />
        </React.Fragment>
    );
};

export default ProfileSection;
