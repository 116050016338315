import Swal from 'sweetalert2';
export const Notification = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: true,
    timer: 3000,
    padding: '10px',
    grow: 'row',
    showClass: {
        popup: 'animate__animated animate__fadeInRight animate__faster'
    },
    hideClass: {
        popup: 'animate__animated animate__fadeOutRight animate__faster'
    },
    timerProgressBar: true,
    didOpen: (toast) => {
        document.getElementsByClassName('swal2-container')[0].style.zIndex = '100000000';
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
});
