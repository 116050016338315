import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';

// chat
import changeChat from './features/chat/changeChat';
import addChat from './features/chat/addChat';
import setMessagesStatus from './features/message/setMessagesStatus';
import setChatMessages from './features/chat/setChatMessages';
import initChats from './features/chat/initChats';
import resetNotifications from './features/chat/resetNotifications';
import loadChat from './features/chat/loadChat';
import changeChatInfo from './features/chat/changeChatInfo';
import setImages from './features/chat/setImages';

// ======== || message || ========= //

// react
import reactToMessage from './features/message/reactToMessage';
import unReactToMessage from './features/message/unReactToMessage';

// add delete edit
import addMessage from './features/message/addMessage';
import deleteMessage from './features/message/deleteMessage';
import editMessage from './features/message/editMessage';

// pin
import unPinMessage from './features/message/unPinMessage';
import pinMessage from './features/message/pinMessage';

// star
import starMessage from './features/message/starMessage';
import unStarMessage from './features/message/unStarMessage';
import initStars from './features/chat/initStars';

// others
import changeId from './features/message/changeId';
import selectMessage from './features/message/selectMessage';
import setEditedMessage from './features/message/setEditedMessage';
import replyTo from './features/message/replyTo';

// ======== || end-message || ========= //

// layout
import changeWallpaperColor from './features/layout/changeWallpaperColor';
import changeWallpaperImage from './features/layout/changeWallpaperImage';
import toggle from './features/layout/toggle';
import setPhotoIndex from './features/chat/setPhotoIndex';
import setConnection from './features/chat/setConnection';
import toggleDialog from './features/layout/toggleDialog';
import addDialog from './features/layout/addDialog';
import setSearchedMessags from './features/chat/setSearchedMessages';

let members = [{ name: 'ahmad' }, { name: 'mohammad' }, { name: 'Yousef' }, { name: 'mohannad' }];

let calls = [
    {
        id: 1,
        caller: { type: 'person', name: 'Ahmad', image: 'http://emilcarlsson.se/assets/mikeross.png' },
        date: moment().format('MMMM Do YYYY, h:mm:ss a'),
        status: 'online',
        type: 'missed'
    },
    {
        id: 2,
        caller: {
            type: 'person',
            name: 'Mohammed',
            image: 'https://images.pexels.com/photos/1212984/pexels-photo-1212984.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
        },
        date: moment().format('MMMM Do YYYY, h:mm:ss a'),
        status: 'online',
        type: 'notAnswerd'
    },
    {
        id: 1,
        caller: {
            name: 'Tai',
            type: 'group',
            members: members,
            image: 'https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
        },
        date: moment().format('MMMM Do YYYY, h:mm:ss a'),
        type: 'answerd'
    },
    {
        id: 1,
        caller: {
            name: 'Mahmod',
            type: 'group',
            members: members,
            image: 'https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
        },
        date: moment().format('MMMM Do YYYY, h:mm:ss a'),
        type: 'recived'
    }
];

let chatSlice = createSlice({
    name: 'chat',
    initialState: {
        // chats
        chats: [],
        loadedChats: [],
        starredMessages: [],
        selectedMessages: [],
        searchedMessages: [],
        calls: calls,
        stars: [],
        currentChat: null,
        connection: 'Connecting...',
        // modes
        replyMode: false,
        editMode: false,
        selectMode: false,
        // reply and edit messages
        replyMessage: null,
        editedMessage: null,
        // open
        imageViewerOpen: false,
        leftDrawerOpen: true,
        starsOpen: false,
        pinsOpen: false,
        settingsOpen: false,
        chatInfoOpen: false,
        emojisOpen: false,
        pinsOpen: false,
        searchOpen: false,
        // wallpaper
        wallpaperColor: '#fff2cd',
        wallpaperImage: 'https://images.pexels.com/photos/9954278/pexels-photo-9954278.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=1500',
        photoIndex: 0,
        // when loading messages
        messagesStatus: {
            isFetching: false,
            isError: false
        },
        images: [],
        dialogs: {}
    },
    reducers: {
        // chats
        initChats: initChats,
        initStars: initStars,
        resetNotifications: resetNotifications,
        setImages: setImages,
        setMessagesStatus: setMessagesStatus,
        addChat: addChat,
        changeChat: changeChat,
        changeChatInfo: changeChatInfo,
        loadChat: loadChat,
        setChatMessages: setChatMessages,
        setSearchedMessages: setSearchedMessags,
        setConnection: setConnection,
        // ======== || message || ========= //
        // add delete edit
        addMessage: addMessage,
        deleteMessage: deleteMessage,
        editMessage: editMessage,
        // pins
        pinMessage: pinMessage,
        unPinMessage: unPinMessage,
        // star
        starMessage: starMessage,
        unStarMessage: unStarMessage,
        // react
        reactToMessage: reactToMessage,
        unReactToMessage: unReactToMessage,
        // others
        replyTo: replyTo,
        selectMessage: selectMessage,
        setEditedMessage: setEditedMessage,
        changeId: changeId,
        // ======== || end-message || ========= //
        // layout
        toggleDialog: toggleDialog,
        addDialog: addDialog,
        toggle: toggle,
        changeWallpaperColor: changeWallpaperColor,
        changeWallpaperImage: changeWallpaperImage,
        setPhotoIndex: setPhotoIndex
    }
});

export default chatSlice;
