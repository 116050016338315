const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'

    defaultPath: '/',
    fontFamily: `Neo Sans`,
    borderRadius: 20
};

export default config;
