/* eslint-disable no-unused-vars */
import * as React from 'react';

// MUI Components
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CopyIcon from '@mui/icons-material/CopyAll';
import Grow from '@mui/material/Grow';

// constants
import { INITIAL_MENU, MESSAGE_TYPE } from 'constant';

let ContextCopy = ({ message, setMenu, menu }) => {
    const handleCopy = () => {
        navigator.clipboard.writeText(message.text);
        setMenu(INITIAL_MENU);
    };

    return (
        <Grow unmountOnExit in={menu.mouseX !== null && message.type === MESSAGE_TYPE.TEXT} timeout={400}>
            <MenuItem onClick={handleCopy}>
                <ListItemIcon>
                    <CopyIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Copy</ListItemText>
            </MenuItem>
        </Grow>
    );
};

export default ContextCopy;
