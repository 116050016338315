let reactToMessage = (state, { payload: { messageId, accountId, key, chatId } }) => {
    if (state.currentChat !== null && state.currentChat.id === chatId) {
        let message = state.currentChat.messages.find((message) => message.id === messageId);
        if (message.reactions === null) message.reactions = [];
        message.reactions.push({ key, value: accountId });
    }

    let chat = state.chats.find((chat) => chat.id == chatId);
    let message = chat.messages.find((message) => message.id === messageId);
    if (message.reactions === null) message.reactions = [];
    message.reactions.push({ key, value: accountId });
};

export default reactToMessage;
