export const MedicalVisitTypePageConstant = {
    medicalVisitType: 'Reception_Management_Medical_Visit_Type.medicalvisittype',
    medicalVisit: 'Reception_Management_Medical_Visit_Type.medicalvisit',
    name: 'Reception_Management_Medical_Visit_Type.name',
    icon: 'Reception_Management_Medical_Visit_Type.icon',
    image: 'Reception_Management_Medical_Visit_Type.image',
    description: 'Reception_Management_Medical_Visit_Type.description',
    title: 'Reception_Management_Medical_Visit_Type.title',
    parentName: 'Reception_Management_Medical_Visit_Type.parentname',
    code: 'Reception_Management_Medical_Visit_Type.code',
    shape: 'Reception_Management_Medical_Visit_Type.shape',
    isActive: 'Reception_Management_Medical_Visit_Type.isactive',
    treeList: 'Reception_Management_Medical_Visit_Type.treelist',
    info: 'Reception_Management_Medical_Visit_Type.info',
    uploadIcon: 'Reception_Management_Medical_Visit_Type.uploadicon',
    edit: 'Reception_Management_Medical_Visit_Type.edit',
    delete: 'Reception_Management_Medical_Visit_Type.delete',
    treeViewer: 'Reception_Management_Medical_Visit_Type.treeviewer',
    treeViewerAdd: 'Reception_Management_Medical_Visit_Type.treevieweradd',
    treeViewerDelete: 'Reception_Management_Medical_Visit_Type.treeviewerdelete',
    treeViewerEdit: 'Reception_Management_Medical_Visit_Type.treevieweredit',
    treeViewerActions: 'Reception_Management_Medical_Visit_Type.treevieweractions',
    actions: 'Reception_Management_Medical_Visit_Type.actions',
    search: 'Reception_Management_Medical_Visit_Type.search',
    reset: 'Reception_Management_Medical_Visit_Type.reset',
    actions: 'Reception_Management_Medical_Visit_Type.actions',
    selectLanguage: 'Reception_Management_Medical_Visit_Type.selectlanguage',
    medicalVisitTypeParent: 'Reception_Management_Medical_Visit_Type.medicalvisittypeparent',
    history: 'Reception_Management_Medical_Visit_Type.history'
};
