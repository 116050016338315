import { BASIC_TYPES, LAYOUT_TYPES } from 'constant';

export const AssignItemToTargetDescriptionTranslation = {
    Page: {
        AssignedGridToPage: 'Assigned Grid To Page',
        AssignedExpandToPage: 'Assigned Expand To Page',
        AssignedFlexLayoutToPage: 'Assigned Flex Layout To Page'
    },
    Layout: {
        AssignedTextToLayout: 'Assigned Text To Layout',
        AssignedTextFieldToLayout: 'Assigned TextField To Layout',
        AssignedTableToLayout: 'Assigned Table To Layout',
        AssignedSpreadsheetToLayout: 'Assigned Spreadsheet To Layout',
        AssignedDatagridToLayout: 'Assigned Datagrid To Layout',
        AssignedTreeToLayout: 'Assigned Tree To Layout',
        AssignedChartToLayout: 'Assigned Chart To Layout',
        AssignedTimelineToLayout: 'Assigned Timeline To Layout',
        AssignedSubmitButtonToLayout: 'Assigned Submit-Button To Layout'
    }
};

export const assignItemToTargetDescriptionTranslation = (messageTarget, type) => {
    const itemType = {
        ['Page']: {
            [LAYOUT_TYPES.GRID]: AssignItemToTargetDescriptionTranslation.Page.AssignedGridToPage,
            [LAYOUT_TYPES.EXPAND]: AssignItemToTargetDescriptionTranslation.Page.AssignedExpandToPage,
            [LAYOUT_TYPES.FLEX_LAYOUT]: AssignItemToTargetDescriptionTranslation.Page.AssignedFlexLayoutToPage
        },
        ['Layout']: {
            [BASIC_TYPES.TEXT]: AssignItemToTargetDescriptionTranslation.Layout.AssignedTextToLayout,
            [BASIC_TYPES.TEXTFIELD]: AssignItemToTargetDescriptionTranslation.Layout.AssignedTextFieldToLayout,
            [BASIC_TYPES.TABLE]: AssignItemToTargetDescriptionTranslation.Layout.AssignedTableToLayout,
            [BASIC_TYPES.SPREADSHEET]: AssignItemToTargetDescriptionTranslation.Layout.AssignedSpreadsheetToLayout,
            [BASIC_TYPES.DATAGRID]: AssignItemToTargetDescriptionTranslation.Layout.AssignedDatagridToLayout,
            [BASIC_TYPES.TREE]: AssignItemToTargetDescriptionTranslation.Layout.AssignedTreeToLayout,
            [BASIC_TYPES.CHART]: AssignItemToTargetDescriptionTranslation.Layout.AssignedChartToLayout,
            [BASIC_TYPES.TIMELINE]: AssignItemToTargetDescriptionTranslation.Layout.AssignedTimelineToLayout,
            [BASIC_TYPES.SUBMIT_BUTTON]: AssignItemToTargetDescriptionTranslation.Layout.AssignedSubmitButtonToLayout
        }
    };

    return itemType[messageTarget][type];
};
