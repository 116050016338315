// MUI Components
import { useTheme } from '@mui/material/styles';
import React from 'react';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 *
 *
 */

// ==============================|| LOGO SVG ||============================== //
import logo from 'assets/images/logo.png';
const Logo = () => {
    const theme = useTheme();

    return <img src={logo} width={'50px'} />;
};

export default Logo;
