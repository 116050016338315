/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

// MUI components
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';

// Redux
import { useSelector } from 'react-redux';

// Components
import Chats from './Chats';
import Calls from './Calls';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            style={{ height: 'calc(100% - 148px)', position: 'relative' }}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ height: '100%' }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

let Content = () => {
    const [value, setValue] = useState(0);
    let leftDrawerOpen = useSelector((state) => state.chat.leftDrawerOpen);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <React.Fragment>
            <Grow in={leftDrawerOpen} unmountOnExit timeout={1200}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="CHATS" {...a11yProps(0)} sx={{ width: '50%' }} />
                    <Tab label="CALLS" {...a11yProps(1)} sx={{ width: '50%' }} />
                </Tabs>
            </Grow>
            <TabPanel value={value} index={0}>
                <Chats />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Calls />
            </TabPanel>
        </React.Fragment>
    );
};

export default Content;
