import { HISTORY_ACTIONS } from 'constant';
import { addHistoryRecord } from 'helpers';

export const setWaterMark = (state, { payload }) => {
    const oldWaterMark = state.view.waterMark;
    state.view.waterMark = payload;

    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.EDIT_WATERMARK,
            description: HISTORY_ACTIONS.EDIT_WATERMARK.description,
            from: oldWaterMark,
            to: payload
        });
};
