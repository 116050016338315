import { COLORS } from 'constant/Global';

export const LAYOUT_TYPES = {
    GRID: 'Grid',
    EXPAND: 'Expand',
    FLEX_LAYOUT: 'Flex Layout',
    TABS: 'Tabs'
};

export const DEFAULT_GRID = {
    type: LAYOUT_TYPES.GRID,
    responsive: {
        xs: '12',
        sm: '12',
        md: '12',
        lg: '12',
        xl: '12'
    },
    spacing: 0,
    items: []
};

export const DEFAULT_EXPAND = {
    responsive: {
        xs: '12',
        sm: '12',
        md: '12',
        lg: '12',
        xl: '12'
    },
    spacing: 0,
    items: [],
    title: 'Expand',
    type: LAYOUT_TYPES.EXPAND,
    withLeftBorder: false
};

export const DEFAULT_FLEX_LAYOUT = {
    items: [],
    type: LAYOUT_TYPES.FLEX_LAYOUT,
    alignItems: 'center',
    justifyContent: 'flex-start'
};

export const DEFAULT_TABS = {
    type: LAYOUT_TYPES.TABS,
    items: [],
    tabs: [],
    tabHeight: 100
};
