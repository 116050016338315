export const SocialMediaPageConstant = {
    socialMedias: 'Settings_Profile_Social_Media.socialmedias',
    socialMedia: 'Settings_Profile_Social_Media.socialmedia',
    logo: 'Settings_Profile_Social_Media.logo',
    name: 'Settings_Profile_Social_Media.name',
    code: 'Settings_Profile_Social_Media.code',
    inputType: 'Settings_Profile_Social_Media.inputtype',
    max: 'Settings_Profile_Social_Media.max',
    description: 'Settings_Profile_Social_Media.description',
    edit: 'Settings_Profile_Social_Media.edit',
    delete: 'Settings_Profile_Social_Media.delete'
};
