// Define a function that takes an image url and a callback
export async function toDataUrl(url, callback) {
    // Use fetch to get the image from the URL through the proxy
    fetch(url)
        .then((response) => response.blob()) // Convert the response to a blob
        .then((blob) => {
            // Create a blob URL from the blob
            const blobUrl = URL.createObjectURL(blob);
            // Call the callback with the blob URL
            callback(blobUrl);
        })
        .catch((error) => {
            // Handle any errors
            console.error(error);
        });
}
