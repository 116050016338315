import { createContext, useContext } from 'react';

export const DataContext = createContext({
    languages: [],
    systemLanguages: [],
    pages: [],
    getDefaultLanguagesList: [],
    defaultLanguagesList: [],
    refetch: () => {}
});

export const useDataContext = () => useContext(DataContext);
