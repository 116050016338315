import React from 'react';

// MUI Components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// store
import { useSelector } from 'react-redux';

// components
import MessageType from '../MessageType';

// constants
import { MESSAGE_TYPE } from 'constant';

let Reply = ({ message, senderIsYou }) => {
    let accounts = useSelector((state) => state.chat.currentChat?.chatAccounts);
    let messages = useSelector((state) => state.chat.currentChat?.messages);
    if (!accounts) return '';
    if (message.reply === null) return '';
    let repliedTo = messages.find((m) => {
        return m.id === message?.reply?.messageId && m.id !== undefined;
    });

    if (!repliedTo) return '';
    let replySender = accounts.find((account) => account.accountId === repliedTo.accountId);
    if (!replySender) return '';

    let messageTypeStyle = () => {
        switch (message.type) {
            case MESSAGE_TYPE.TEXT:
                return {};
            case MESSAGE_TYPE.IMAGE:
                return {
                    padding: '10px',
                    margin: '0 5px 5px'
                };
            case MESSAGE_TYPE.VIDEO:
                return {
                    padding: '10px',
                    margin: '0 5px 5px'
                };
            case MESSAGE_TYPE.AUDIO:
                return {
                    margin: '10px'
                };
            case MESSAGE_TYPE.FILE:
                return {
                    margin: '10px'
                };
        }
    };
    return (
        <Box
            sx={{
                ...messageTypeStyle(),
                borderLeft: '4px solid #AAA',
                background: senderIsYou ? '#EEE' : '#333',
                color: senderIsYou ? '#333' : '#EEE',
                height: '100%',
                padding: '10px',
                position: 'relative',
                marginBottom: '10px',
                borderRadius: '5px'
            }}
        >
            <Typography fontWeight="bold">{replySender.customeName}</Typography>
            <MessageType message={repliedTo} />
        </Box>
    );
};

export default Reply;
