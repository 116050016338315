/**
 * Adds or removes an item from the selected DataGrid Cols Array in the state based on whether the item is already selected.
 *
 * @param {Object} state - The current state object.
 * @param {Object} payload - An object containing the ID of the item to select or deselect.
 */
export const selectDataGridCol = (state, { payload }) => {
    let isSelected = state.selectedDataGridCols.find(({ id }) => payload.id === id);
    if (!isSelected) state.selectedDataGridCols.push(payload);
    else state.selectedDataGridCols = state.selectedDataGridCols.filter((item) => item.id !== payload.id);
};
