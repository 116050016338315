import { HISTORY_ACTIONS } from 'constant';
import { TablehistoryDescriptionTranslation } from 'constant/FileTemplate/tablehistoryDescriptionTranslation';
import { addHistoryRecord, getItemFromArray } from 'helpers';
/**
 * Deletes multiple columns from a SpreadSheet table in the state and adds a history record for the deletion.
 * @param {object} state - The state object.
 * @param {object} payload - An object containing an array of indexes of the columns to delete and the ID of the SpreadSheet table.
 */
export const deleteMultipleSpreadSheetCols = (state, { payload }) => {
    const { indexes, tableId } = payload;

    // Find the SpreadSheet table to update
    let table = getItemFromArray({ array: state.basic, id: tableId });
    const oldTable = { ...table };
    // Remove the columns from the table
    table.cols = table.cols.filter((col, index) => {
        for (let i = 0; i < indexes.length; i++) {
            if (index === indexes[i]) {
                return false;
            }
        }
        return true;
    });

    // Update the IDs of the remaining columns
    table.cols.forEach((col, i) => {
        col.id = i;
    });

    // Add a history record for the deletion
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.EDIT_BASIC,
            description: TablehistoryDescriptionTranslation.DeletedMultipleColumnsInSpreadSheetTable,
            from: oldTable,
            to: table
        });
};
