export const objectToArrayWithKeyValue = (object) => {
    if (!object) {
        return [];
    }
    const array = Object.keys(object)?.map((key, value) => ({
        key: key.toLowerCase().replaceAll('_', ' '),
        value: object[key]
    }));
    return array;
};
