// MUI Components
import { styled } from '@mui/material/styles';

// ==============================|| AUTHENTICATION 1 WRAPPER ||============================== //

const AuthWrapper1 = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
        backgroundColor: '#FFF'
    },
    minHeight: '100vh',
    height: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative'
}));

export default AuthWrapper1;
