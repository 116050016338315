import React from 'react';

// MUI components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grow from '@mui/material/Grow';

// MUI Icons
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import ClearIcon from '@mui/icons-material/Clear';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { toggle } from 'features/store';

// Constants
import { TOGGLE } from 'constant';

let ChatInfoHeader = () => {
    let chatInfoOpen = useSelector((state) => state.chat.chatInfoOpen);
    let dispatch = useDispatch();

    let handleCloseChatInfo = () => {
        dispatch(toggle({ type: TOGGLE.CHAT_INFO }));
    };

    return (
        <Grow in={chatInfoOpen} unmountOnExit timeout={400}>
            <Box
                id="infoHeader"
                sx={{
                    padding: '0 1vw',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '60px',
                    '& .MuiInputBase-input': {
                        padding: '7px !important'
                    }
                }}
            >
                <Box display="flex" alignItems="center" color="text.secondary">
                    {' '}
                    <InfoOutlined sx={{ ml: 1 }} />
                    <Typography ml={1} color="text.secondary">
                        Profile
                    </Typography>
                </Box>
                <IconButton onClick={handleCloseChatInfo}>
                    <ClearIcon fontSize="small" />{' '}
                </IconButton>
            </Box>
        </Grow>
    );
};

export default ChatInfoHeader;
