let editMessage = (state, { payload }) => {
    try {
        let { chatId, messageId, text } = payload;
        if (state.currentChat.id === chatId) {
            let message = state.currentChat.messages.find((message) => message.id === messageId);
            state.currentChat.messages[state.currentChat.messages.indexOf(message)].text = text;
        }
        let chat = state.chats.find((chat) => chat.id === chatId);
        let message = chat.messages.find((message) => message.id === payload.messageId);
        chat.messages[chat.messages.indexOf(message)].text = text;
    } catch (e) {
        console.log(e);
    }
};

export default editMessage;
