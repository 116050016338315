import React from 'react';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import { HistoryPageConstant } from 'constant/Translations/history/history-page';
import { useTranslation } from 'react-i18next';
const useHistory = () => {
    const { t } = useTranslation();
    return {
        id: 'history',
        title: t(HistoryPageConstant.history),
        type: 'group',
        icon: <HistoryRoundedIcon />,
        children: [
            {
                id: 'history',
                title: 'General Historical Record',
                type: 'item',
                icon: HistoryRoundedIcon,
                url: '/history',
                breadcrumbs: false
            },
            {
                id: 'patient-historical-record',
                title: 'Patient Historical Record',
                type: 'item',
                icon: HistoryRoundedIcon,
                url: '/patient-historical-record',
                breadcrumbs: false
            },
            {
                id: 'surgical-historical-record',
                title: 'Surgical Historical Record',
                type: 'item',
                icon: HistoryRoundedIcon,
                url: '/surgical-historical-record',
                breadcrumbs: false
            }
        ]
    };
};

export default useHistory;
