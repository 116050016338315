import { Notification, addHistoryRecord, getItemFromArray } from 'helpers';
import { deleteMultipleLayout } from '../layout';
import { HISTORY_ACTIONS } from 'constant';

/**
 * Deletes a page from the page array in the state object by its ID, and any associated layout items.
 *
 * @param {Object} state - The current state object.
 * @param {Object} payload - An object containing the ID of the page to delete.
 */
export const deletePage = (state, { payload }) => {
    const deletedPage = getItemFromArray({ array: state.page, id: payload.itemId });
    // Delete any associated layout items
    const items = getItemFromArray({ array: state.page, id: payload.itemId })?.items;

    deleteMultipleLayout(state, { payload: { items: items.map((id) => ({ id })), withOutHistroyRecord: true } });

    // Remove the page from the page array in state
    state.page = state.page.filter((item) => item.id !== payload.itemId);

    // Add a history record for the deletion of the page
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.DELETE_PAGE,
            description: HISTORY_ACTIONS.DELETE_PAGE.description, // We add 1 to the ID to make it human-readable
            from: deletedPage,
            to: null
        });
};
