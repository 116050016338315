import React from 'react';
// MUI Components
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Checkbox from '@mui/material/Checkbox';

// store
import { useSelector } from 'react-redux';

const SelectCheckbox = ({ senderIsYou, selected, transform }) => {
    let selectMode = useSelector((state) => state.chat.selectMode);
    if (!senderIsYou) return '';
    return (
        <Grow in={selectMode} timeout={400} unmountOnExit>
            <Box position="relative">
                <Checkbox
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: transform
                    }}
                    checked={selected}
                />
            </Box>
        </Grow>
    );
};

export default SelectCheckbox;
