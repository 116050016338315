export const MembersPageConstant = {
    members: 'Human_Resources_Members.members',
    member: 'Human_Resources_Members.member',
    card: 'Human_Resources_Members.card',
    fullName: 'Human_Resources_Members.fullname',
    sureName: 'Human_Resources_Members.surename',
    updateJobTitles: 'Human_Resources_Members.updatejobtitles',
    updateJobTitle: 'Human_Resources_Members.updatejobtitle',
    updateMemberCategory: 'Human_Resources_Members.updatemembercategory',
    memberCategory: 'Human_Resources_Members.membercategory',
    thisCategoryDoesNotContainPersonalFileMessage: 'Human_Resources_Members.thiscategorydoesnotcontainpersonalfilemessage',
    about: 'Human_Resources_Members.about',
    aboutMember: 'Human_Resources_Members.aboutmember',
    locatedText: 'Human_Resources_Members.locatedtext',
    birthDateAndEducationLevel: 'Human_Resources_Members.birthdateandeducationlevel',
    birthDate: 'Human_Resources_Members.birthdate',
    birthPlace: 'Human_Resources_Members.birthplace',
    country: 'Human_Resources_Members.country',
    state: 'Human_Resources_Members.state',
    city: 'Human_Resources_Members.city',
    extraAttachments: 'Human_Resources_Members.extraattachments',
    uploadFile: 'Human_Resources_Members.uploadfile',
    general: 'Human_Resources_Members.general',
    gender: 'Human_Resources_Members.gender',
    bloodType: 'Human_Resources_Members.bloodtype',
    alamana: 'Human_Resources_Members.alamana',
    alqaid: 'Human_Resources_Members.alqaid',
    alqaidNumber: 'Human_Resources_Members.alqaidnumber',
    nationalIdNumber: 'Human_Resources_Members.nationalidnumber',
    identityNumber: 'Human_Resources_Members.identitynumber',
    passportNumber: 'Human_Resources_Members.passportnumber',
    martialStatus: 'Human_Resources_Members.martialstatus',
    childNumber: 'Human_Resources_Members.childnumber',
    category: 'Human_Resources_Members.category',
    name: 'Human_Resources_Members.name',
    nickname: 'Human_Resources_Members.nickname',
    fathername: 'Human_Resources_Members.fathername',
    motherName: 'Human_Resources_Members.mothername',
    personalPicture: 'Human_Resources_Members.personalpicture',
    personalPic: 'Human_Resources_Members.personalpic',
    religionAndDoctrine: 'Human_Resources_Members.religionanddoctrine',
    religion: 'Human_Resources_Members.religion',
    doctrine: 'Human_Resources_Members.doctrine',
    personalInformation: 'Human_Resources_Members.personalinformation',
    contact: 'Human_Resources_Members.contact',
    carrerFile: 'Human_Resources_Members.carrerfile',
    generalCarrerFile: 'Human_Resources_Members.generalcarrerfile',
    scientificFile: 'Human_Resources_Members.scientificfile',
    professionalFile: 'Human_Resources_Members.professionalfile',
    memberFiles: 'Human_Resources_Members.memberfiles',
    certificates: 'Human_Resources_Members.certificates',
    accounts: 'Human_Resources_Members.accounts',
    sections: 'Human_Resources_Members.sections',
    jobTitles: 'Human_Resources_Members.jobtitles',
    information: 'Human_Resources_Members.information',
    educationLevel: 'Human_Resources_Members.educationlevel',
    rating: 'Human_Resources_Members.rating',
    salary: 'Human_Resources_Members.salary',
    hours: 'Human_Resources_Members.hours',
    cancel: 'Human_Resources_Members.cancel',
    update: 'Human_Resources_Members.update',
    unassignToJobTitle: 'Human_Resources_Members.unassigntojobtitle',
    jobTitle: 'Human_Resources_Members.jobtitle',
    unassign: 'Human_Resources_Members.unassign',
    assignToJobTitle: 'Human_Resources_Members.assigntojobtitle',
    chooseJobTitles: 'Human_Resources_Members.choosejobtitles',
    pickJobTitlesFromTree: 'Human_Resources_Members.pickjobtitlesfromtree',
    assign: 'Human_Resources_Members.assign',
    userName: 'Human_Resources_Members.username',
    email: 'Human_Resources_Members.email',
    accountName: 'Human_Resources_Members.accountname',
    isActive: 'Human_Resources_Members.isactive',
    activationFrom: 'Human_Resources_Members.activationfrom',
    activationTo: 'Human_Resources_Members.activationto',
    viewAccounts: 'Human_Resources_Members.viewaccounts',
    code: 'Human_Resources_Members.code',
    viewSections: 'Human_Resources_Members.viewsections',
    title: 'Human_Resources_Members.title',
    viewJobTitles: 'Human_Resources_Members.viewjobtitles',
    edit: 'Human_Resources_Members.edit',
    delete: 'Human_Resources_Members.delete',
    info: 'Human_Resources_Members.info',
    profile: 'Human_Resources_Members.profile',
    sectionMembers: 'Human_Resources_Members.sectionmembers',
    none: 'Human_Resources_Members.none',
    yearsOld: 'Human_Resources_Members.yearsold',
    age: 'Human_Resources_Members.age',
    treeViewer: 'Human_Resources_Members.treeviewer',
    treeViewerAdd: 'Human_Resources_Members.treevieweradd',
    treeViewerDelete: 'Human_Resources_Members.treeviewerdelete',
    treeViewerEdit: 'Human_Resources_Members.treevieweredit',
    treeViewerActions: 'Human_Resources_Members.treevieweractions',
    withAdmins: 'Human_Resources_Members.withadmins',
    search: 'Human_Resources_Members.search',
    reset: 'Human_Resources_Members.reset',
    type: 'Human_Resources_Members.type',
    course: 'Human_Resources_Members.course',
    skill: 'Human_Resources_Members.skill',
    courseOrSkill: 'Human_Resources_Members.courseorskill',
    courseName: 'Human_Resources_Members.coursename',
    courseDescription: 'Human_Resources_Members.coursedescription',
    courseIcon: 'Human_Resources_Members.courseicon',
    skillName: 'Human_Resources_Members.skillname',
    skillDescription: 'Human_Resources_Members.skilldescription',
    skillIcon: 'Human_Resources_Members.skillicon',
    professionalParty: 'Human_Resources_Members.professionalparty',
    partyName: 'Human_Resources_Members.partyname',
    partyDescription: 'Human_Resources_Members.partydescription',
    partyIcon: 'Human_Resources_Members.partyicon',
    level: 'Human_Resources_Members.level',
    fromDate: 'Human_Resources_Members.fromdate',
    toDate: 'Human_Resources_Members.todate',
    tillNow: 'Human_Resources_Members.tillnow',
    address: 'Human_Resources_Members.address',
    description: 'Human_Resources_Members.description',
    isOnline: 'Human_Resources_Members.isonline',
    experiance: 'Human_Resources_Members.experiance',
    experianceName: 'Human_Resources_Members.experiancename',
    experianceDescription: 'Human_Resources_Members.experiancedescription',
    experianceIcon: 'Human_Resources_Members.experianceicon',
    posters: 'Human_Resources_Members.posters',
    posterName: 'Human_Resources_Members.postername',
    posterDescription: 'Human_Resources_Members.posterdescription',
    posterIcon: 'Human_Resources_Members.postericon',
    professionalPostingPlace: 'Human_Resources_Members.professionalpostingplace',
    newPostingPlaceName: 'Human_Resources_Members.newpostingplacename',
    newPostingPlaceDescription: 'Human_Resources_Members.newpostingplacedescription',
    newPostingPlaceIcon: 'Human_Resources_Members.newpostingplaceicon',
    view: 'Human_Resources_Members.view',
    status: 'Human_Resources_Members.status',
    selectLanguage: 'Human_Resources_Members.selectlanguage',
    actions: 'Human_Resources_Members.actions',
    identityInfo: 'Human_Resources_Members.identityinfo',
    blood: 'Human_Resources_Members.blood',
    nationalNumber: 'Human_Resources_Members.nationalnumber',
    maritalStatus: 'Human_Resources_Members.maritalstatus',
    personalInfo: 'Human_Resources_Members.personalinfo',
    phoneNumber: 'Human_Resources_Members.phonenumber',
    zipCode: 'Human_Resources_Members.zipcode',
    addressSecound: 'Human_Resources_Members.addresssecound',
    street: 'Human_Resources_Members.street',
    area: 'Human_Resources_Members.area'
};
