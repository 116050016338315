import React from 'react';

// MUI components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Components
import SideBarChat from './SideBarChat';
import ItemSkeleton from 'ui-component/Skeletons/ItemSkeleton';

// Hooks
import useGetChats from 'layout/ChatLayout/hooks/useGetChats';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const Chats = () => {
    let { isLoading, isError, chats } = useGetChats();
    if (isLoading) return [1, 2, 3, 4, 5, 6, 8, 9, 10].map((I, i) => <ItemSkeleton key={i} />);
    if (isError || chats?.length === 0)
        return (
            <Box
                sx={{
                    flexWrap: 'wrap',
                    width: '100%',
                    height: 'calc(100% - 50px)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <img
                            src="https://img.icons8.com/external-flatarticons-blue-flatarticons/800/null/external-no-chatting-hacking-flatarticons-blue-flatarticons.png"
                            style={{ width: '50%', display: 'block' }}
                        />{' '}
                    </Box>
                    <Typography variant="h4" fontWeight="900" textAlign="center" color="text.secondary" sx={{ width: '100%' }}>
                        No Chats Yet !
                    </Typography>
                </Box>
            </Box>
        );
    if (chats) return chats.map((chat, i) => <SideBarChat chat={chat} key={i} timeout={(i + 4) * 200} />);
    return '';
};

export default Chats;
