export const convertArrayToTree = (dataList, parentId = null) => {
    let map = {},
        node,
        hierarchy = [];
    dataList?.map((item, index) => {
        map[item.id] = index;
        dataList[index].children = [];
        return;
    });
    dataList?.map((item, index) => {
        node = item;
        if (node.parentId !== null) {
            if (map[node.parentId] || map[node.parentId] === 0) dataList[map[node.parentId]].children.push(node);
            else hierarchy.push(node);
        } else {
            hierarchy.push(node);
        }
        return;
    });
    return hierarchy;
};
