import React from 'react';

// MUI components
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { toggle } from 'features/store';

// Constants
import { TOGGLE } from 'constant';

// Components
import SelectMessages from 'layout/ChatLayout/Components/SelectMessages';

const Header = () => {
    const selectMode = useSelector((state) => state.chat.selectMode);
    const dispatch = useDispatch();
    const handleToggle = () => dispatch(toggle({ type: TOGGLE.STARS }));

    return (
        <Box sx={{ height: '60px' }}>
            <Collapse in={!selectMode} timeout={400} unmountOnExit>
                <Box>
                    <ListItem disablePadding sx={{ padding: '10px', background: '#301b42' }}>
                        <IconButton
                            onClick={handleToggle}
                            sx={{
                                '.MuiSvgIcon-root': {
                                    color: '#FFF'
                                }
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                        <Typography variant="h4" pl={1} color="#fff">
                            Stars
                        </Typography>
                    </ListItem>
                </Box>
            </Collapse>
            <SelectMessages />
        </Box>
    );
};

export default Header;
