import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

let ItemSkeleton = () => {
    return (
        <React.Fragment>
            <Box sx={{ width: '100%', paddingTop: '10px', paddingBottom: '10px' }}>
                <Grid container display="flex" justifyContent="center">
                    <Grid item xs={2} sm={1} md={2} display="flex" justifyContent="center">
                        <Skeleton variant="circular" width={40} height={40} />
                    </Grid>
                    <Grid item xs={7} sm={9} md={7} sx={{ textAlign: 'left', padding: { sm: '0 10px', md: 0 } }}>
                        <Skeleton width="30%" />
                        <Skeleton width="90%" />
                    </Grid>
                    <Grid item container xs={3} sm={2} md={3} sx={{ justifyContent: 'flex-end', paddingRight: '15px' }}>
                        <Skeleton width="50%" height={20} />
                    </Grid>
                </Grid>
            </Box>
            <Divider />
        </React.Fragment>
    );
};

export default ItemSkeleton;
