import React, { useEffect, useCallback } from 'react';

// MUI components
import IconButton from '@mui/material/IconButton';
import Grow from '@mui/material/Grow';
import SendIcon from '@mui/icons-material/Send';
import Mic from '@mui/icons-material/Mic';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { toggle } from 'features/store';
import { TOGGLE } from 'constant';

// Custom hooks
import useSendMessage from 'layout/ChatLayout/hooks/useSendMessage';
import useEditMessage from 'layout/ChatLayout/hooks/useEditMessage';

const SendButton = ({ recording, isTextMessage, start, stop, isUploading, messageInput, setIsTextMessage }) => {
    const emojisOpen = useSelector((state) => state.chat.emojisOpen);
    const editMode = useSelector((state) => state.chat.editMode);

    const sendMessage = useSendMessage();
    const editMessage = useEditMessage();
    const dispatch = useDispatch();

    const handleSendMessage = useCallback((e) => {
        if (e) e.preventDefault();
        if (messageInput.current.value) {
            if (editMode) {
                editMessage(messageInput.current.value);
            } else {
                sendMessage({
                    text: messageInput.current.value,
                    accountId: localStorage.getItem('accountId'),
                    type: 0,
                    forwardMessageId: null
                });
            }
        }
        if (emojisOpen) dispatch(toggle({ type: TOGGLE.EMOJIS }));
        setIsTextMessage(false);
        messageInput.current.value = '';
    });

    useEffect(() => {
        window.onkeydown = (e) => {
            if (e.key === 'Enter' && !emojisOpen) handleSendMessage();
        };
    }, [emojisOpen, handleSendMessage]);

    const handleClick = () => {
        if (isTextMessage) {
            handleSendMessage();
            return;
        }
        if (!recording) {
            start();
        } else {
            stop();
        }
    };

    return (
        <Grow in={!recording} unmountOnExit>
            <IconButton disabled={isUploading} onClick={handleClick}>
                <Grow in={!isTextMessage && !recording ? true : false} sx={{ display: !isTextMessage && !recording ? 'block' : 'none' }}>
                    <Mic />
                </Grow>
                <Grow in={isTextMessage} sx={{ display: isTextMessage ? 'block' : 'none' }}>
                    <SendIcon />
                </Grow>
            </IconButton>
        </Grow>
    );
};

export default SendButton;
