import React from 'react';

// MUI components
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

// NPM packages
import EmojiPicker, { EmojiStyle, Theme } from 'emoji-picker-react';

// Redux
import { useSelector } from 'react-redux';

let Emojis = ({ setIsTextMessage, isTextMessage, messageInput }) => {
    const emojisOpen = useSelector((state) => state.chat.emojisOpen);

    let handleClick = (emojiData) => {
        messageInput.current.value += emojiData.emoji;
        setIsTextMessage(isTextMessage + emojiData.emoji);
    };

    return (
        <Fade in={emojisOpen} unmountOnExit>
            <Box sx={{ margin: '5px' }}>
                <EmojiPicker onEmojiClick={handleClick} autoFocusSearch={false} theme={Theme.LIGHT} emojiStyle={EmojiStyle.GOOGLE} />
            </Box>
        </Fade>
    );
};

export default Emojis;
