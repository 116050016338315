import * as React from 'react';
import PropTypes from 'prop-types';

// store
import { useDispatch, useSelector } from 'react-redux';
import { reactToMessage, unReactToMessage } from 'features/store';

// MUI Components
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import Zoom from '@mui/material/Zoom';

// constants
import { INITIAL_MENU, REACTIONS } from 'constant';

// connection
import { connection } from 'layout/ChatLayout';

let user = {
    img: 'https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    name: 'tai'
};

let reactions = [
    {
        key: REACTIONS.LIKE,
        label: '👍'
    },
    {
        key: REACTIONS.FUNNY,
        label: '😂'
    },
    {
        key: REACTIONS.AMAZE,
        label: '😮'
    },
    {
        key: REACTIONS.SAD,
        label: '😢'
    },
    {
        key: REACTIONS.WISH,
        label: '🙏'
    },
    {
        key: REACTIONS.LOVE,
        label: '❤️'
    }
];

let ContextReaction = ({ message, setMenu, menu }) => {
    // store
    let starredMessagesOpen = useSelector((state) => state.chat.starredMessagesOpen);
    let pinnedMessagesOpen = useSelector((state) => state.chat.pinnedMessagesOpen);
    let dispatch = useDispatch();

    const handleReact = async ({ key }) => {
        let found = false;
        if (message.reactions !== null) {
            await message.reactions.forEach((r) => {
                if (r.value === localStorage.getItem('accountId')) {
                    dispatch(
                        unReactToMessage({
                            key,
                            accountId: localStorage.getItem('accountId'),
                            messageId: message.id,
                            chatId: localStorage.getItem('currentChatId')
                        })
                    );
                    connection.invoke(
                        'UnReactMessage',
                        localStorage.getItem('currentChatId'),
                        localStorage.getItem('accountId'),
                        r.key,
                        message.id
                    );
                    if (r.key === key) found = true;
                }
            });
        }
        if (!found) {
            connection.invoke('ReactMessage', localStorage.getItem('currentChatId'), localStorage.getItem('accountId'), key, message.id);
            dispatch(
                reactToMessage({
                    messageId: message.id,
                    key,
                    accountId: localStorage.getItem('accountId'),
                    chatId: localStorage.getItem('currentChatId')
                })
            );
        }
        setMenu(INITIAL_MENU);
    };

    if (starredMessagesOpen || pinnedMessagesOpen) return '';

    return (
        <Box
            sx={{
                display: 'flex',
                backgroundColor: '#fff',
                left: '-50px',
                top: '-6px',
                width: '40px',
                position: 'absolute',
                borderRadius: '20px',
                flexWrap: 'wrap',
                boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
                '& *': {
                    width: '100%',
                    padding: '5px 10px',
                    display: 'block'
                }
            }}
        >
            {reactions.map(({ label, key }, i) => (
                <Zoom unmountOnExit in={menu.mouseX !== null} key={i} timeout={(i + 1) * 200}>
                    <ButtonBase onClick={() => handleReact({ user, key })}>{label}</ButtonBase>
                </Zoom>
            ))}
        </Box>
    );
};

ContextReaction.propTypes = {
    message: PropTypes.object,
    setMenu: PropTypes.func,
    menu: PropTypes.object
};

export default ContextReaction;
