import * as React from 'react';

// MUI components
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';

// MUI icons
import CallEndIcon from '@mui/icons-material/CallEnd';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import Phone from '@mui/icons-material/Phone';

// NPM packages
import Peer from 'peerjs';

// Redux
import { useSelector } from 'react-redux';

/* eslint-disable jsx-a11y/media-has-caption */

let Call = ({ socket }) => {
    const [open, setOpen] = React.useState(false);
    const [video, setVideo] = React.useState(true);
    const [audio, setAudio] = React.useState(true);
    const [myVideoStream, setMyVideoStream] = React.useState(true);
    const [callerVideoPlaying, setCallerVideoPlaying] = React.useState(false);
    const currentChat = useSelector((state) => state.chat.currentChat);
    const handleMicOff = () => {
        setAudio(audio ? false : true);
        if (!myVideoStream) return;
        if (audio) {
            if (myVideoStream.getAudioTracks()[0].enabled) {
                myVideoStream.getAudioTracks()[0].enabled = false;
                setAudio(false);
            }
        } else {
            myVideoStream.getAudioTracks()[0].enabled = true;
            setAudio(true);
        }
    };

    const handleVideocamOff = () => {
        if (!myVideoStream) return;
        if (video) {
            if (myVideoStream.getVideoTracks()[0].enabled) {
                myVideoStream.getVideoTracks()[0].enabled = false;
                setVideo(false);
            }
        } else {
            myVideoStream.getVideoTracks()[0].enabled = true;
            setVideo(true);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        myVideoStream.getVideoTracks()[0].enabled = false;
        setTimeout(
            () =>
                myVideoStream.getTracks().forEach(function (track) {
                    track.stop();
                }),
            200
        );

        setOpen(false);
    };

    React.useEffect(() => {
        if (open) {
            const addVideoStream = (video, stream) => {
                video.srcObject = stream;
                video.addEventListener('loadedmetadata', () => {
                    video.play();
                    if (video.id === 'myVideo') video.muted = 'muted';
                    if (video.id === 'callerVideo') setCallerVideoPlaying(true);
                });
            };
            setTimeout(() => {
                var pos1 = 0,
                    pos2 = 0,
                    pos3 = 0,
                    pos4 = 0;

                let elmnt = document.getElementById('myVideoContainer');
                elmnt.onmousedown = dragMouseDown;

                function dragMouseDown(e) {
                    e = e || window.event;
                    e.preventDefault();
                    // get the mouse cursor position at startup:
                    pos3 = e.clientX;
                    pos4 = e.clientY;
                    document.onmouseup = closeDragElement;
                    // call a function whenever the cursor moves:
                    document.onmousemove = elementDrag;
                }

                function elementDrag(e) {
                    e = e || window.event;
                    e.preventDefault();
                    // calculate the new cursor position:
                    pos1 = pos3 - e.clientX;
                    pos2 = pos4 - e.clientY;
                    pos3 = e.clientX;
                    pos4 = e.clientY;
                    // set the element's new position:
                    elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
                    elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';
                }

                function closeDragElement() {
                    // stop moving when mouse button is released:
                    document.onmouseup = null;
                    document.onmousemove = null;
                }
            }, 1000);
            setTimeout(async () => {
                let peer = new Peer({
                    port: 443,
                    path: '/'
                });
                let myVideo = document.getElementById('myVideo');
                let callerVideo = document.getElementById('callerVideo');
                myVideo.onload = () => {};
                let stream;
                stream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: true
                });
                setMyVideoStream(stream);
                addVideoStream(myVideo, stream);

                peer.on('call', (call) => {
                    call.answer(stream);

                    call.on('stream', (userVideoStream) => {
                        addVideoStream(callerVideo, userVideoStream);
                    });
                });

                socket.on('user-connected', (userId) => {
                    const call = peer.call(userId, stream);
                    call.on('stream', (userVideoStream) => {
                        addVideoStream(callerVideo, userVideoStream);
                    });
                });
                peer.on('open', (id) => {
                    socket.emit('join-room', 'hello', id, `abdo${Math.random() * 10}`);
                });
            }, 1000);
        }
    }, [open]);

    return (
        <div>
            <Grow in={true} timeout={1400}>
                <IconButton onClick={handleClickOpen}>
                    <Phone />
                </IconButton>
            </Grow>
            <Dialog fullScreen={true} open={open}>
                <Box
                    container
                    sx={{
                        height: '100vh',
                        overflow: 'hidden',
                        position: 'relative',
                        backgroundColor: '#111'
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '5%',
                            left: '5%',
                            zIndex: 10000000,
                            cursor: 'move',
                            boxShadow: !video ? '.1vw .1vw 1vw .1vw #000' : 'none',
                            borderRadius: '20px '
                        }}
                        id="myVideoContainer"
                    >
                        <Box
                            sx={{
                                position: 'relative',
                                backgroundColor: video ? 'transparent' : '#222',
                                width: '200px',
                                borderRadius: '20px'
                            }}
                        >
                            <video
                                id="myVideo"
                                style={{ width: '100%', borderRadius: '20px', boxShadow: video ? '.1vw .1vw 1vw .1vw #000' : 'none' }}
                            ></video>{' '}
                            <Avatar
                                src={currentChat?.image}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '100px',
                                    height: '100px',
                                    border: '5px solid #FFF',
                                    visibility: video ? 'hidden' : 'visible'
                                }}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                        <Box sx={{ height: '70%', background: '#222', borderRadius: '20px', position: 'relative' }}>
                            <video id="callerVideo" style={{ height: '100%', borderRadius: '20px' }}></video>{' '}
                            <Avatar
                                src={currentChat?.image}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '100px',
                                    height: '100px',
                                    border: '5px solid #FFF',
                                    visibility: callerVideoPlaying ? 'hidden' : 'visible'
                                }}
                            />
                        </Box>
                    </Box>
                    <Slide direction="up" in={true}>
                        <Box
                            sx={{
                                width: '100%',
                                position: 'fixed',
                                height: '15%',
                                bottom: 0,
                                left: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Box sx={{ width: '40%', display: 'flex', justifyContent: 'space-between' }}>
                                <IconButton onClick={handleClose}>
                                    <CallEndIcon color="error" fontSize="large" />
                                </IconButton>
                                <IconButton onClick={handleMicOff}>
                                    <Grow in={!audio ? false : true} sx={{ display: !audio ? 'none' : 'block' }}>
                                        <MicOffIcon color="error" fontSize="large" />
                                    </Grow>
                                    <Grow in={!audio ? true : false} sx={{ display: !audio ? 'block' : 'none', color: '#FFF' }}>
                                        <MicIcon fontSize="large" />
                                    </Grow>
                                </IconButton>
                                <IconButton onClick={handleVideocamOff}>
                                    <Grow in={!video ? false : true} sx={{ display: !video ? 'none' : 'block' }}>
                                        <VideocamOffIcon color="error" fontSize="large" />
                                    </Grow>
                                    <Grow in={!video ? true : false} sx={{ display: !video ? 'block' : 'none', color: '#FFF' }}>
                                        <VideocamIcon fontSize="large" />
                                    </Grow>
                                </IconButton>
                            </Box>
                        </Box>
                    </Slide>
                </Box>
            </Dialog>
        </div>
    );
};

export default Call;
