export const CentersMangmentPageConstant = {
    centersMangment: 'System_Administrator_Centers_Mangment.centersmangment',
    centers: 'System_Administrator_Centers_Mangment.centers',
    logo: 'System_Administrator_Centers_Mangment.logo',
    title: 'System_Administrator_Centers_Mangment.title',
    country: 'System_Administrator_Centers_Mangment.country',
    sections: 'System_Administrator_Centers_Mangment.sections',
    languages: 'System_Administrator_Centers_Mangment.languages',
    display: 'System_Administrator_Centers_Mangment.display',
    centerAccount: 'System_Administrator_Centers_Mangment.centeraccount',
    mainInformaion: 'System_Administrator_Centers_Mangment.maininformaion',
    socialMedia: 'System_Administrator_Centers_Mangment.socialmedia',
    ownerInformaions: 'System_Administrator_Centers_Mangment.ownerinformaions',
    centerNumberingPattern: 'System_Administrator_Centers_Mangment.centernumberingpattern',
    aboutUs: 'System_Administrator_Centers_Mangment.aboutus',
    theme: 'System_Administrator_Centers_Mangment.theme',
    mainAccount: 'System_Administrator_Centers_Mangment.mainaccount',
    username: 'System_Administrator_Centers_Mangment.username',
    accountName: 'System_Administrator_Centers_Mangment.accountname',
    password: 'System_Administrator_Centers_Mangment.password',
    email: 'System_Administrator_Centers_Mangment.email',
    activation: 'System_Administrator_Centers_Mangment.activation',
    activationFrom: 'System_Administrator_Centers_Mangment.activationfrom',
    activationTo: 'System_Administrator_Centers_Mangment.activationto',
    isActive: 'System_Administrator_Centers_Mangment.isactive',
    centerInformation: 'System_Administrator_Centers_Mangment.centerinformation',
    affiliatedwith: 'System_Administrator_Centers_Mangment.affiliatedwith',
    area: 'System_Administrator_Centers_Mangment.area',
    address: 'System_Administrator_Centers_Mangment.address',
    website: 'System_Administrator_Centers_Mangment.website',
    sector: 'System_Administrator_Centers_Mangment.sector',
    state: 'System_Administrator_Centers_Mangment.state',
    city: 'System_Administrator_Centers_Mangment.city',
    emailAddress: 'System_Administrator_Centers_Mangment.emailaddress',
    value: 'System_Administrator_Centers_Mangment.value',
    from: 'System_Administrator_Centers_Mangment.from',
    to: 'System_Administrator_Centers_Mangment.to',
    main: 'System_Administrator_Centers_Mangment.main',
    personalInformation: 'System_Administrator_Centers_Mangment.personalinformation',
    fullName: 'System_Administrator_Centers_Mangment.fullname',
    sureName: 'System_Administrator_Centers_Mangment.surename',
    fatherName: 'System_Administrator_Centers_Mangment.fathername',
    motherName: 'System_Administrator_Centers_Mangment.mothername',
    birthDay: 'System_Administrator_Centers_Mangment.birthday',
    nickname: 'System_Administrator_Centers_Mangment.nickname',
    gender: 'System_Administrator_Centers_Mangment.gender',
    image: 'System_Administrator_Centers_Mangment.image',
    identityInformation: 'System_Administrator_Centers_Mangment.identityinformation',
    nationalNumber: 'System_Administrator_Centers_Mangment.nationalnumber',
    identityNumber: 'System_Administrator_Centers_Mangment.identitynumber',
    alamana: 'System_Administrator_Centers_Mangment.alamana',
    alqaid: 'System_Administrator_Centers_Mangment.alqaid',
    alqaidNumber: 'System_Administrator_Centers_Mangment.alqaidnumber',
    passportNumber: 'System_Administrator_Centers_Mangment.passportnumber',
    religion: 'System_Administrator_Centers_Mangment.religion',
    doctrine: 'System_Administrator_Centers_Mangment.doctrine',
    blood: 'System_Administrator_Centers_Mangment.blood',
    maritalStatus: 'System_Administrator_Centers_Mangment.maritalstatus',
    childNumber: 'System_Administrator_Centers_Mangment.childnumber',
    street: 'System_Administrator_Centers_Mangment.street',
    addressSecound: 'System_Administrator_Centers_Mangment.addresssecound',
    zipCode: 'System_Administrator_Centers_Mangment.zipcode',
    language: 'System_Administrator_Centers_Mangment.language',
    isMain: 'System_Administrator_Centers_Mangment.ismain',
    numberingPattern: 'System_Administrator_Centers_Mangment.numberingpattern',
    primaryColor: 'System_Administrator_Centers_Mangment.primarycolor',
    secondaryColor: 'System_Administrator_Centers_Mangment.secondarycolor',
    borderRadius: 'System_Administrator_Centers_Mangment.borderradius',
    socialMedias: 'System_Administrator_Centers_Mangment.socialmedias',
    discard: 'System_Administrator_Centers_Mangment.discard',
    save: 'System_Administrator_Centers_Mangment.save',
    edit: 'System_Administrator_Centers_Mangment.edit',
    delete: 'System_Administrator_Centers_Mangment.delete',
    numberingPatternFirstNumber: 'System_Administrator_Centers_Mangment.numberingpatternfirstnumber',
    numberingPatternNumberingType: 'System_Administrator_Centers_Mangment.numberingpatternnumberingtype',
    numberingPatternNumberOfDigits: 'System_Administrator_Centers_Mangment.numberingpatternnumberofdigits',
    numberingPatternAlphabetTypes: 'System_Administrator_Centers_Mangment.numberingpatternalphabettypes',
    numberingPatternCharSequence: 'System_Administrator_Centers_Mangment.numberingpatterncharsequence',
    numberingPatternNumberOfSmallLetterCharachters: 'System_Administrator_Centers_Mangment.numberingpatternnumberofsmalllettercharachters',
    numberingPatternNumberOfCapitalLetterCharachters:
        'System_Administrator_Centers_Mangment.numberingpatternnumberpfcapitallettercharachters',
    numberingPatternIsCharFirst: 'System_Administrator_Centers_Mangment.numberingpatternischarfirst',
    numberingPatternIsCapitalFirst: 'System_Administrator_Centers_Mangment.numberingpatterniscapitalfirst',
    numberingPatternHasEquivalentIncrease: 'System_Administrator_Centers_Mangment.numberingpatternhasequivalentincrease',
    numberingPatternHasExpandChar: 'System_Administrator_Centers_Mangment.numberingpatternhasexpandchar'
};
