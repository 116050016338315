import React from 'react';

// MUI Components
import Box from '@mui/material/Box';

// store
import { useDispatch } from 'react-redux';
import { toggle, setPhotoIndex } from 'features/store';

// hooks
import useGetChatImages from 'hooks/api/useGetChatImages';

// constants
import { TOGGLE } from 'constant';

let Image = ({ message, lastSenderIsSame }) => {
    let {
        images,
        query: { isFetching, isError }
    } = useGetChatImages();
    let dispatch = useDispatch();
    if (isFetching || isError) return '';

    let handleOpenImage = () => {
        let index = 0;
        for (let i = 0; i < images.length; i++) {
            if (images[i].id === message.id) {
                index = i;
            }
        }
        dispatch(setPhotoIndex(index));
        dispatch(toggle({ type: TOGGLE.IMAGE_VIEWER }));
    };
    return (
        <Box
            sx={{
                padding: '3px',
                width: '100%',
                marginTop: lastSenderIsSame ? '-10px' : 0
            }}
            onClick={handleOpenImage}
        >
            <img src={message.url} alt="uploaded" style={{ width: '100%', borderRadius: '5px' }} />
        </Box>
    );
};

export default Image;
