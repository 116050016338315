import { HISTORY_ACTIONS } from 'constant';
import { TablehistoryDescriptionTranslation } from 'constant/FileTemplate/tablehistoryDescriptionTranslation';
import { addHistoryRecord, getItemFromArray } from 'helpers';

/**
 * Deletes a column from a DataGrid table in the state and adds a history record for the deletion.
 * @param {object} state - The state object.
 * @param {object} payload - An object containing the index of the column to delete and the ID of the DataGrid table.
 */
export const deleteDataGridCol = (state, { payload }) => {
    const { id, tableId } = payload;
    // Find the DataGrid table to update
    let table = getItemFromArray({ array: state.basic, id: tableId });
    const oldTable = { ...table };
    // Remove the column from the table
    table.cols = table.cols.filter((col) => col.id !== id);

    // Add a history record for the deletion
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.EDIT_BASIC,
            description: TablehistoryDescriptionTranslation.DeletedDataGridCol,
            from: oldTable,
            to: table
        });
};
