export const BranchesMangmentPageConstant = {
    branches: 'System_Administrator_Branches_Mangment.branches',
    branch: 'System_Administrator_Branches_Mangment.branch',
    branchsMangment: 'System_Administrator_Branches_Mangment.branchsmangment',
    title: 'System_Administrator_Branches_Mangment.title',
    branchMainInformation: 'System_Administrator_Branches_Mangment.branchmaininformation',
    aboutUs: 'System_Administrator_Branches_Mangment.aboutus',
    theme: 'System_Administrator_Branches_Mangment.theme',
    branchAccount: 'System_Administrator_Branches_Mangment.branchaccount',
    center: 'System_Administrator_Branches_Mangment.center',
    username: 'System_Administrator_Branches_Mangment.username',
    accountName: 'System_Administrator_Branches_Mangment.accountname',
    password: 'System_Administrator_Branches_Mangment.password',
    email: 'System_Administrator_Branches_Mangment.email',
    information: 'System_Administrator_Branches_Mangment.information',
    remark: 'System_Administrator_Branches_Mangment.remark',
    affiliatedWith: 'System_Administrator_Branches_Mangment.affiliatedwith',
    constNumbringPattern: 'System_Administrator_Branches_Mangment.constnumbringpattern',
    country: 'System_Administrator_Branches_Mangment.country',
    state: 'System_Administrator_Branches_Mangment.state',
    city: 'System_Administrator_Branches_Mangment.city',
    area: 'System_Administrator_Branches_Mangment.area',
    address: 'System_Administrator_Branches_Mangment.address',
    settings: 'System_Administrator_Branches_Mangment.settings',
    fromGlobalLocation: 'System_Administrator_Branches_Mangment.fromgloballocation',
    isMain: 'System_Administrator_Branches_Mangment.ismain',
    isActive: 'System_Administrator_Branches_Mangment.isactive',
    socialMedia: 'System_Administrator_Branches_Mangment.socialmedia',
    facebook: 'System_Administrator_Branches_Mangment.facebook',
    telegram: 'System_Administrator_Branches_Mangment.telegram',
    webSite: 'System_Administrator_Branches_Mangment.website',
    emailAddress: 'System_Administrator_Branches_Mangment.emailaddress',
    activation: 'System_Administrator_Branches_Mangment.activation',
    activationFrom: 'System_Administrator_Branches_Mangment.activationfrom',
    activationTo: 'System_Administrator_Branches_Mangment.activationto',
    subscription: 'System_Administrator_Branches_Mangment.subscription',
    subscriptionmonths: 'System_Administrator_Branches_Mangment.subscriptionmonths',
    subscriptionType: 'System_Administrator_Branches_Mangment.subscriptiontype',
    subscriptionUnitFee: 'System_Administrator_Branches_Mangment.subscriptionunitfee',
    edit: 'System_Administrator_Branches_Mangment.edit',
    delete: 'System_Administrator_Branches_Mangment.delete'
};
