import React from 'react';

// Icons
import { IconUser, IconUserCircle, IconUserPlus, IconBriefcase, IconNewSection } from '@tabler/icons';

import { roles } from 'env/roles';

const icons = { IconUserCircle, IconUserPlus, IconUser, IconNewSection, IconBriefcase };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';

const useTechnologyAndInformationManagement = () => {
    return {
        id: 'technology-and-information-management',
        title: 'Technology and Information Management',
        type: 'group',
        icon: <ReceiptLongRoundedIcon />,
        children: [
            {
                id: 'health-information-technology-management',
                title: 'Health Information Technology Management',
                type: 'group',
                icon: <ReceiptLongRoundedIcon />,
                role: [roles.Admin],
                children: [
                    {
                        id: 'patients',
                        title: 'Patients',
                        type: 'group',
                        icon: <ReceiptLongRoundedIcon />,
                        role: [roles.Admin],
                        children: [
                            {
                                id: 'medical-history',
                                title: 'Medical History',
                                type: 'group',
                                icon: <ReceiptLongRoundedIcon />,
                                role: [roles.Admin],
                                children: [
                                    {
                                        id: 'complaint',
                                        title: 'Complaint',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/complaint',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'detailed-complaint',
                                        title: 'Detailed Complaint',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/detailed-complaint',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'surgical-history',
                                        title: 'Surgical History',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/surgical-history',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'medical-history',
                                        title: 'Medical History',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/medical-history',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'medication-history',
                                        title: 'Medication History',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/medication-history',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'family-history',
                                        title: 'Family History',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/family-history',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'allergy-history',
                                        title: 'Allergy History',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/allergy-history',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'past-treatments',
                                        title: 'Past Treatments',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/past-treatments',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'accidents-and-injuries',
                                        title: 'Accidents and Injuries',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/accidents-and-injuries',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'behavioral-and-lifestyle-history',
                                        title: 'Behavioral and Lifestyle History',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/behavioral-and-lifestyle-history',
                                        breadcrumbs: false
                                    }
                                ]
                            },
                            {
                                id: 'clinical-examination',
                                title: 'Clinical Examination',
                                type: 'group',
                                icon: <ReceiptLongRoundedIcon />,
                                role: [roles.Admin],
                                children: [
                                    {
                                        id: 'vital-signs',
                                        title: 'Vital Signs',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/vital-signs',
                                        breadcrumbs: false
                                    }
                                ]
                            },
                            {
                                id: 'differential-diagnosis',
                                title: 'Differential Diagnosis',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/differential-diagnosis',
                                breadcrumbs: false
                            },
                            {
                                id: 'medical-investigations',
                                title: 'Medical Investigations',
                                type: 'group',
                                icon: <ReceiptLongRoundedIcon />,
                                role: [roles.Admin],
                                children: [
                                    {
                                        id: 'echo',
                                        title: 'ECHO',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/echo',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'mammogram',
                                        title: 'Mammogram',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/mammogram',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'radiological-imaging',
                                        title: 'Radiological Imaging',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/radiological-imaging',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'ultrasound',
                                        title: 'Ultrasound',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/ultrasound',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'mri',
                                        title: 'MRI',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/mri',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'ct-scan',
                                        title: 'CT Scan',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/ct-scan',
                                        breadcrumbs: false
                                    }
                                ]
                            },
                            {
                                id: 'clinical-impression',
                                title: 'Clinical Impression',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/clinical-impression',
                                breadcrumbs: false
                            },
                            {
                                id: 'proposed-management',
                                title: 'Proposed Management',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/proposed-management',
                                breadcrumbs: false
                            },
                            {
                                id: 'final-diagnosis',
                                title: 'Final Diagnosis',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/final-diagnosis',
                                breadcrumbs: false
                            },
                            {
                                id: 'patient-admission',
                                title: 'Patient Admission',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/patient-admission',
                                breadcrumbs: false
                            },
                            {
                                id: 'medical-tests',
                                title: 'Medical Tests',
                                type: 'group',
                                icon: <ReceiptLongRoundedIcon />,
                                role: [roles.Admin],
                                children: [
                                    {
                                        id: 'blood-tests',
                                        title: 'Blood Tests',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/blood-tests',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'urinalysis',
                                        title: 'Urinalysis',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/urinalysis',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'stool-analysis',
                                        title: 'Stool Analysis',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/stool-analysis',
                                        breadcrumbs: false
                                    }
                                ]
                            },
                            {
                                id: 'pathological-anatomy',
                                title: 'Pathological Anatomy',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/pathological-anatomy',
                                breadcrumbs: false
                            },
                            {
                                id: 'chemotherapy',
                                title: 'Chemotherapy',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/chemotherapy',
                                breadcrumbs: false
                            },
                            {
                                id: 'vaccinations',
                                title: 'Vaccinations',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/vaccinations',
                                breadcrumbs: false
                            },
                            {
                                id: 'medical-observation',
                                title: 'Medical Observation',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/medical-observation',
                                breadcrumbs: false
                            },
                            {
                                id: 'healthcare-note',
                                title: 'Healthcare Note',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/healthcare-note',
                                breadcrumbs: false
                            },
                            ,
                            {
                                id: 'medical-psychiatric-record',
                                title: 'Medical Psychiatric Record',
                                type: 'group',
                                icon: <ReceiptLongRoundedIcon />,
                                role: [roles.Admin],
                                children: [
                                    {
                                        id: 'psychological-medical-record',
                                        title: 'Psychological Medical Record',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/psychological-medical-record',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'psychological-consultations',
                                        title: 'Psychological Consultations',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/psychological-consultations',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'previous-psychological-treatments',
                                        title: 'Previous Psychological Treatments',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/previous-psychological-treatments',
                                        breadcrumbs: false
                                    }
                                ]
                            },
                            {
                                id: 'level-physical-activity',
                                title: 'Level of Physical Activity',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/level-physical-activity',
                                breadcrumbs: false
                            },
                            {
                                id: 'food-preferences-allergies',
                                title: 'Food Preferences and Allergies',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/food-preferences-allergies',
                                breadcrumbs: false
                            }
                        ]
                    },
                    {
                        id: 'operations',
                        title: 'Operations',
                        type: 'group',
                        icon: <ReceiptLongRoundedIcon />,
                        role: [roles.Admin],
                        children: [
                            {
                                id: 'surgical-intervention-name',
                                title: 'Surgical Intervention Name',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/surgical-intervention-name',
                                breadcrumbs: false
                            },
                            {
                                id: 'surgical-intervention-type',
                                title: 'Surgical Intervention Type',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/surgical-intervention-type',
                                breadcrumbs: false
                            },
                            {
                                id: 'surgical-intervention-details',
                                title: 'Surgical Intervention Details',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/surgical-intervention-details',
                                breadcrumbs: false
                            },
                            {
                                id: 'surgical-procedure-difficulties',
                                title: 'Surgical Procedure Difficulties',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/surgical-procedure-difficulties',
                                breadcrumbs: false
                            },
                            {
                                id: 'degree-surgical-intervention-difficulty',
                                title: 'Degree Of Surgical Intervention Difficulty',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/degree-surgical-intervention-difficulty',
                                breadcrumbs: false
                            },
                            {
                                id: 'surgical-complications',
                                title: 'Surgical Complications',
                                type: 'group',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                children: [
                                    {
                                        id: 'during-surgery',
                                        title: 'During Surgery',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/during-surgery',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'about-surgery',
                                        title: 'About Surgery',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/about-surgery',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'after-surgery',
                                        title: 'After Surgery',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/after-surgery',
                                        breadcrumbs: false
                                    }
                                ]
                            },
                            {
                                id: 'patient-following-surgical-intervention',
                                title: 'Patient is health status following surgical intervention',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/patient-following-surgical-intervention',
                                breadcrumbs: false
                            },
                            {
                                id: 'surgical-pain-level',
                                title: 'Surgical Pain Level',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/surgical-pain-level',
                                breadcrumbs: false
                            },
                            {
                                id: 'surgical-intervention-documentation',
                                title: 'Surgical Intervention Documentation',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/surgical-intervention-documentation',
                                breadcrumbs: false
                            },
                            {
                                id: 'surgical-operations-timeline',
                                title: 'Surgical Operations Timeline',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/surgical-operations-timeline',
                                breadcrumbs: false
                            },
                            {
                                id: 'surgical-instruments-techniques',
                                title: 'Surgical Instruments and Techniques',
                                type: 'group',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                children: [
                                    {
                                        id: 'surgical-instruments-used',
                                        title: 'Surgical Instruments Used',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/surgical-instruments-used',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'surgical-techniques-used',
                                        title: 'Surgical Techniques Used',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/surgical-techniques-used',
                                        breadcrumbs: false
                                    }
                                ]
                            },
                            {
                                id: 'additional-resources-operation',
                                title: 'Additional Resources Operation',
                                type: 'group',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                children: [
                                    {
                                        id: 'specialized-medical-devices-used',
                                        title: 'Specialized Medical Devices Used',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/specialized-medical-devices-used',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'procedure-specific-consumables',
                                        title: 'Procedure Specific Consumables',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/procedure-specific-consumables',
                                        breadcrumbs: false
                                    }
                                ]
                            },
                            {
                                id: 'postoperative-patient-instructions',
                                title: 'Postoperative Patient Instructions',
                                type: 'group',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                children: [
                                    {
                                        id: 'postoperative-rest-activity-instructions',
                                        title: 'Postoperative Rest and Activity Instructions',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/postoperative-rest-activity-instructions',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'self-care-recommendations',
                                        title: 'Self-Care Recommendations for the Patient',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/self-care-recommendations',
                                        breadcrumbs: false
                                    },
                                    {
                                        id: 'postoperative-fluid-nutrition-recommendations',
                                        title: 'Postoperative Fluid and Nutrition Recommendations',
                                        type: 'item',
                                        icon: icons.IconBriefcase,
                                        role: [roles.Admin],
                                        url: '/postoperative-fluid-nutrition-recommendations',
                                        breadcrumbs: false
                                    }
                                ]
                            },
                            {
                                id: 'postoperative-medical-follow-up',
                                title: 'Postoperative Medical Follow-up',
                                type: 'item',
                                icon: icons.IconBriefcase,
                                role: [roles.Admin],
                                url: '/postoperative-medical-follow-up',
                                breadcrumbs: false
                            }
                        ]
                    }
                ]
            },
            {
                id: 'health-applications',
                title: 'Health Applications',
                type: 'item',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                url: '/health-applications',
                breadcrumbs: false
            }
        ]
    };
};

export default useTechnologyAndInformationManagement;
