import { HISTORY_ACTIONS } from 'constant';
import { TablehistoryDescriptionTranslation } from 'constant/FileTemplate/tablehistoryDescriptionTranslation';
import { addHistoryRecord, getItemFromArray } from 'helpers';

/**
 * Swaps the positions of two selected DataGrid Cols in the given array in the state object.
 *
 * @param {Object} state - The current state object.
 * @param {Array} payload - An array containing the two items to swap.
 */
export const swipeDataGridCols = (state, { payload }) => {
    // Destructure the payload array to get the two items to swap
    const { item1, item2, id } = payload;
    const table = getItemFromArray({ array: state.basic, id });
    const oldTable = { ...table };

    // Swap the positions of the two selected items in the array
    table.cols = table.cols.map(({ id, ...rest }) => {
        if (id === item1.id) return item2;
        else if (id === item2.id) return item1;
        else return { id, ...rest };
    });

    state.selectedDataGridCols = [];
    // Add a history record for Swipe
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.EDIT_BASIC,
            description: TablehistoryDescriptionTranslation.SwipedBetween2DataGridCols,
            from: oldTable,
            to: table
        });
};
