import { authAxios } from 'config/axios';
import { useQuery } from 'react-query';

export const useFetchDataPost = (key, url, options, data) => {
    const fetch = () => authAxios.post(url, data);
    return useQuery(key, fetch, { ...options, refetchOnWindowFocus: false, retry: false });
};

function useFetchData(key, url, options, params, headers = {}) {
    const fetch = () => authAxios.get(url, { params, headers });
    return useQuery(key, fetch, { ...options, refetchOnWindowFocus: false, retry: false });
}

export default useFetchData;
