import React, { useRef, useState, useMemo } from 'react';

// MUI components
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';

// NPM packages
import { useRecorder } from 'voice-recorder-react';

// UI components
import UploadFileProgress from 'ui-component/UploadFileProgress';

// Components
import AttachFile from './AttachFile';
import Recording from './Recording';
import Edit from './Edit';
import Reply from './Reply';
import Emojis from './Emojis';
import MessageField from './MessageField';
import SendButton from './SendButton';

// Redux
import { useSelector } from 'react-redux';

/* eslint-disable no-unused-vars */

const formWrapperStyle = {
    overflow: 'hidden',
    position: 'fixed',
    justifyContent: 'space-between',
    width: '100%',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    bottom: 0,
    '& #innerForm': {
        position: 'relative',
        padding: '1vw',
        background: '#fff',
        display: 'flex',
        height: '80px',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    }
};

const ChatForm = () => {
    const currentChat = useSelector((state) => state.chat.currentChat);
    const [progress, setProgress] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [isTextMessage, setIsTextMessage] = useState(false);

    const messageInput = useRef();

    const { data, stop, start, recording, time } = useRecorder();

    const uploadFileProgress = useMemo(
        () => <UploadFileProgress {...{ isUploading, progress, uploadedFile }} />,
        [isUploading, progress, uploadedFile]
    );

    const reply = useMemo(() => <Reply />, []);
    const edit = useMemo(() => <Edit />, []);
    const emojis = useMemo(() => <Emojis {...{ isTextMessage, setIsTextMessage, messageInput }} />, []);

    const messageField = useMemo(() => <MessageField {...{ setIsTextMessage, recording, messageInput }} />, [recording]);
    const attachFile = useMemo(() => <AttachFile {...{ setIsUploading, setUploadedFile, setProgress, recording }} />, [recording]);
    const sendButton = useMemo(
        () => <SendButton {...{ recording, isTextMessage, start, stop, isUploading, messageInput, setIsTextMessage }} />,
        [isUploading, isTextMessage, recording, start, stop]
    );
    const recordingComponent = useMemo(
        () => (
            <Recording
                {...{
                    recording,
                    stop,
                    time,
                    data,
                    setIsUploading,
                    setProgress,
                    setUploadedFile
                }}
            />
        ),
        [data, recording, stop, time]
    );

    return (
        <Slide direction="up" in={currentChat !== null} timeout={400}>
            <Box sx={formWrapperStyle} id="chatForm">
                {uploadFileProgress}
                {reply}
                {edit}
                {emojis}
                <Box id="innerForm">
                    {messageField}
                    {attachFile}
                    {sendButton}
                    {recordingComponent}
                </Box>
            </Box>
        </Slide>
    );
};

export default ChatForm;
