import React from 'react';

// Icons
import { IconUser, IconUserCircle, IconUserPlus, IconBriefcase, IconUsers, IconId } from '@tabler/icons';
import LocalHospitalRoundedIcon from '@mui/icons-material/LocalHospitalRounded';

// Constants
import { roles } from 'env/roles';
import { useTranslation } from 'react-i18next';
import { FileTemplatesPageConstant } from 'constant/Translations/file-templates/file-templates-page';

const icons = { IconUserCircle, IconUserPlus, IconUser, IconBriefcase, IconUsers, IconId };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const useFileTemplates = () => {
    const { t } = useTranslation();
    return {
        id: 'fileTemplates',
        title: 'File Templates',
        type: 'group',
        caption: 'fileTemplates',
        role: [roles.Admin],
        icon: <LocalHospitalRoundedIcon />,
        children: [
            {
                id: 'fileTemplates',
                title: t(FileTemplatesPageConstant.fileTemplates),
                type: 'item',
                url: '/file-template',
                role: [roles.Admin],
                icon: icons.IconUser,
                breadcrumbs: false
            }
        ]
    };
};

export default useFileTemplates;
