/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import Box from '@mui/material/Box';

let Video = ({ message, lastSenderIsSame }) => (
    <Box
        sx={{
            padding: '3px',
            width: '100%',
            marginTop: lastSenderIsSame ? '-10px' : 0
        }}
    >
        <video controls="controls" style={{ width: '100%', borderRadius: '5px' }}>
            <source src={message.url} type="video/mp4" />
            Does Not Work
        </video>
    </Box>
);

export default Video;
