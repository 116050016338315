import { Notification, generateRandomBasicId } from 'helpers';

/**
 * Adds a new basic item to the state and associates it with the current layout item.
 * @param {object} state - The state object.
 * @param {object} payload - The item to add to the state.
 */
export const addBasicFunc = (state, payload) => {
    // Check if currentLayoutId is set in state
    if (state.currentLayoutId === null) {
        // Display error notification and return
        Notification.fire({ text: 'Choose Layout Element First', icon: 'error' });
        return;
    }

    // Clone payload and generate new id
    const basicItem = { ...payload };
    basicItem.id = generateRandomBasicId();

    // Add item to basic array in state
    state.basic.push(basicItem);

    // Add the New Basic Element to the layout Array in State
    state.layout = state.layout.map((layoutItem) => {
        if (layoutItem.id === state.currentLayoutId)
            return {
                ...layoutItem,
                items: [...layoutItem.items, basicItem.id]
            };
        return layoutItem;
    });
};
