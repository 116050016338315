import React, { lazy } from 'react';

// Components
import Loadable from 'ui-component/Loadable';

const MedicalCompanies = Loadable(lazy(() => import('views/medical-management/pharmacy/medical-companies')));
const MedicalWarehouses = Loadable(lazy(() => import('views/medical-management/pharmacy/medical-warehouses')));
const DrugComposition = Loadable(lazy(() => import('views/medical-management/pharmacy/drug-composition')));
const Medications = Loadable(lazy(() => import('views/medical-management/pharmacy/medications')));
const MedicationsTypes = Loadable(lazy(() => import('views/medical-management/pharmacy/medicine-type')));

const pharmacyRoutes = [
    {
        path: '/medical-companies',
        element: <MedicalCompanies />
    },
    {
        path: '/medical-warehouses',
        element: <MedicalWarehouses />
    },
    {
        path: '/medical-formulations',
        element: <DrugComposition />
    },
    {
        path: '/medications',
        element: <Medications />
    },
    {
        path: '/medications-types',
        element: <MedicationsTypes />
    }
];
export default pharmacyRoutes;
