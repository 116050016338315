import React from 'react';

// Server

// MUI components
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { toggle } from 'features/store';

// Constants
import { TOGGLE } from 'constant';

// Components
import SelectMessages from 'layout/ChatLayout/Components/SelectMessages';

const Header = ({ setText, refetch, isFetching }) => {
    const selectMode = useSelector((state) => state.chat.selectMode);
    const dispatch = useDispatch();

    const handleToggle = () => dispatch(toggle({ type: TOGGLE.SEARCH }));

    return (
        <Box sx={{ height: '60px' }}>
            <Collapse in={!selectMode} timeout={400} unmountOnExit>
                <Box>
                    <ListItem disablePadding sx={{ padding: '10px', background: '#301b42' }}>
                        <IconButton
                            onClick={handleToggle}
                            sx={{
                                '.MuiSvgIcon-root': {
                                    color: '#FFF'
                                }
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                        <TextField
                            type="search"
                            fullWidth
                            placeholder="Search.."
                            sx={{
                                mr: 1,
                                '& .MuiInputBase-input': {
                                    padding: '9px !important'
                                }
                            }}
                            onChange={(e) => {
                                setText(e.target.value);
                            }}
                        />
                        <Button
                            disabled={isFetching}
                            onClick={() => refetch()}
                            sx={{ background: '#FFF', color: '#301b42', '&:hover': { color: '#FFF', background: '#301b42' } }}
                        >
                            Search
                        </Button>
                    </ListItem>
                </Box>
            </Collapse>
            <SelectMessages />
        </Box>
    );
};

export default Header;
