import React from 'react';

// MUI icons
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';

// MUI components
import IconButton from '@mui/material/IconButton';
import Grow from '@mui/material/Grow';
import CircularProgress from '@mui/material/CircularProgress';

const LoadMore = ({ page, isFetching, disabled, setPage }) => (
    <Grow in={!disabled}>
        <IconButton
            sx={{
                background: '#301b42',
                color: '#FFF',
                position: 'absolute',
                left: '50%',
                translate: '-50%',
                bottom: '10px',
                '&:hover': {
                    background: '#FFF',
                    color: '#301b42'
                }
            }}
            onClick={() => setPage(page + 1)}
            disabled={isFetching}
        >
            <Grow in={!isFetching} sx={{ display: !isFetching ? 'block' : 'none' }}>
                <ArrowDownwardRoundedIcon />
            </Grow>
            <Grow in={isFetching} sx={{ display: isFetching ? 'block' : 'none' }}>
                <CircularProgress />
            </Grow>
        </IconButton>
    </Grow>
);

export default LoadMore;
