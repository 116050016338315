import React from 'react';

// MUI components
import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

// Third-party libraries
// import div from 'react-perfect-scrollbar';
import { BrowserView } from 'react-device-detect';

// Project imports
import Message from 'views/chat/Components/Messages/Message';
import Header from './Header';

// Redux
import { useSelector } from 'react-redux';

// Custom hooks
import useStars from 'layout/ChatLayout/hooks/useStars';

/* eslint-disable no-unused-vars */

const Stars = () => {
    const wallpaperColor = useSelector((state) => state.chat.wallpaperColor);
    const wallpaperImage = useSelector((state) => state.chat.wallpaperImage);
    const { stars, starsOpen } = useStars();
    const theme = useTheme();
    const drawer = (
        <React.Fragment>
            <Header />
            <BrowserView>
                <div
                    style={{
                        height: 'calc(100vh -  60px)',
                        backgroundImage: wallpaperImage && `url(${wallpaperImage})`,
                        backgroundAttachment: 'fixed',
                        backgroundSize: 'cover',
                        backgroundColor: wallpaperColor
                    }}
                >
                    <Box sx={{ overflowY: 'auto', height: '100%', padding: '10px' }}>
                        {stars && stars.map((message, i) => message && <Message message={message} i={i} key={i} />)}
                    </Box>
                </div>
            </BrowserView>
        </React.Fragment>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box
            component="nav"
            sx={(theme) => ({
                transition: theme.transitions.create('all', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen
                }),
                flexShrink: { md: 0 },
                width: { md: starsOpen ? '100%' : 0 }
            })}
        >
            <Drawer
                container={container}
                variant={'temporary'}
                anchor="right"
                open={starsOpen}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': {
                        background: theme.paconstte?.background?.default,
                        color: theme.paconstte?.text?.primary,
                        borderRight: 'none',
                        width: '100% !important',
                        overflow: 'hidden'
                    }
                }}
                color="inherit"
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="persistent"
                anchor="right"
                sx={{
                    display: { xs: 'none', md: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%', overflow: 'hidden' }
                }}
                open={starsOpen}
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

export default Stars;
