import { BASIC_TYPES } from 'constant';
import { isArray } from 'lodash';

const getFields = (data) => {
    if (isArray(data)) return data.map((field) => getFields(field));
    else
        return {
            OnlyPrinted: data.onlyPrinted,
            Name: data.name,
            TreeName: data.treeName,
            Variant: data.variant,
            Control: data.control,
            Label: {
                Value: data.label.value,
                Thesaurus: data.label.thesaurus
            },
            DataSource: {
                Key: data.dataSource.key,
                Id: data.dataSource.id
            },
            Fields: getFields(data.fields),
            ItemName: {
                Value: data.itemName.value,
                Thesaurus: data.itemName.thesaurus
            },
            HintText: {
                Value: data.hintText.value,
                Thesaurus: data.hintText.thesaurus
            },
            Filter: {
                IsfilteredAccordingToOtherField: data.filter.isfilteredAccordingToOtherField,
                FilteringField: data.filter.filteringField
            },
            Print: data.Print,
            Valdation: {
                IsRequired: data.isRequired,
                IsHidden: data.isHidden,
                MinTime: data.minTime,
                MaxTime: data.maxTime,
                MinDateTime: data.minDateTime,
                MaxDate: data.maxDate,
                MinDate: data.minDate,
                MaxDateTime: data.maxDateTime,
                Min: data.min,
                Max: data.max,
                MinLength: data.minLength,
                MaxLength: data.maxLength
            }
        };
};
export const getFileTemplateItems = (basic) => {
    const itemsWithoutStringify = basic.filter((item) => item.type === BASIC_TYPES.TEXTFIELD);
    return itemsWithoutStringify.map((item) => ({
        ...item,
        fields: { ...item.fields, fields: getFields(item?.fields?.fields).map((field) => JSON.stringify(field)) }
    }));
};
