import { generateRandomLayoutId } from 'helpers';

/**
 * Adds a new layout item to the layout array and sets it as the current layout item.
 * @param {object} state - The state object.
 * @param {object} payload - An object containing the properties of the new layout item.
 */
export const addLayoutFunc = (state, { payload }) => {
    // Clone payload and generate new id
    const layoutItem = { ...payload };
    layoutItem.id = generateRandomLayoutId();

    // Add the new layout item to the items array of the first page
    state.page[0]?.items.push(layoutItem.id);

    // Add the new layout item to the layout array
    state.layout.push(layoutItem);
};
