import { TOGGLE } from 'constant';
import { scrollEle } from 'helpers';

let toggle = (state, { payload: { type } }) => {
    switch (type) {
        case TOGGLE.EMOJIS:
            state.emojisOpen = !state.emojisOpen;
            break;
        case TOGGLE.IMAGE_VIEWER:
            state.imageViewerOpen = !state.imageViewerOpen;
            setTimeout(() => {
                document.getElementsByClassName('ReactModal__Overlay')[0].style.zIndex = 1000000000;
            }, 100);
            break;
        // drawers
        case TOGGLE.CHAT_INFO:
            state.chatInfoOpen = !state.chatInfoOpen;
            break;
        case TOGGLE.LEFT_DRAWER:
            state.leftDrawerOpen = !state.leftDrawerOpen;
            break;
        case TOGGLE.PINS:
            state.pinsOpen = !state.pinsOpen;
            break;
        case TOGGLE.STARS:
            state.starsOpen = !state.starsOpen;
            break;
        case TOGGLE.SETTINGS:
            state.settingsOpen = !state.settingsOpen;
            break;
        case TOGGLE.SEARCH:
            state.searchOpen = !state.searchOpen;
            break;
        // modes
        case TOGGLE.EDIT_MODE:
            state.editMode = !state.editMode;
            if (state.editMode) {
                state.pinsOpen = false;
                state.searchOpen = false;
            }
            scrollEle(document.getElementById('chatBox'), 350, 'toBottom');
            break;
        case TOGGLE.REPLY_MODE:
            state.replyMode = !state.replyMode;
            if (state.replyMode) {
                state.pinsOpen = false;
                state.searchOpen = false;
            }
            scrollEle(document.getElementById('chatBox'), 350, 'toBottom');
            break;
        case TOGGLE.SELECT_MODE:
            state.selectMode = !state.selectMode;
            state.selectedMessages = [];
            break;
        default:
            return;
    }
};

export default toggle;
