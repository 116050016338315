import { initStars } from 'features/store';
import { authAxios } from 'config/axios';
import { GET_STARS } from 'env/server';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

const useStars = () => {
    const dispatch = useDispatch();
    const starsOpen = useSelector((state) => state.chat.starsOpen);
    const stars = useSelector((state) => state.chat.stars);
    const query = useQuery('getStars', async () => authAxios.get(GET_STARS), {
        onSuccess: (data) => {
            dispatch(initStars(data.data.data));
        }
    });
    return { stars, starsOpen };
};

export default useStars;
