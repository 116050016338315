/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import Box from '@mui/material/Box';

let Audio = ({ message }) => (
    <Box>
        <Box
            sx={{
                marginBottom: '-5px',
                padding: '5px',

                '& audio::-webkit-media-controls-panel': {
                    backgroundColor: '#fff',
                    padding: '0'
                }
            }}
        >
            <audio controls="controls" src={message.url} style={{ height: '30px' }}>
                Does Not Work
            </audio>
        </Box>
    </Box>
);

export default Audio;
