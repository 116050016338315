export const TablehistoryDescriptionTranslation = {
    AddedColToDataGrid: 'Added Col ToData Grid',
    DeletedDataGridCol: 'Deleted Data Grid Col',
    DeletedRowInDataGrid: 'Deleted row in DataGrid',
    SwipedBetween2DataGridCols: 'Swiped Between 2 DataGrid Cols',
    AddedColsToTable: 'Added Cols to Table',
    AddedRowsToTable: 'Added Rows to Table',
    DeletedMultipleColumnsInSpreadSheetTable: 'Deleted multiple columns in SpreadSheet table',
    DeletedMultipleRowsInSpreadSheetTable: 'Deleted multiple rows in SpreadSheet table',
    DeletedColumnInSpreadSheetTable: 'Deleted column in SpreadSheet table',
    DeletedRowInSpreadSheetTable: 'Deleted row in SpreadSheet table',
    InsertedColumnInSpreadSheetTable: 'Inserted column in SpreadSheet table',
    InsertedRowInSpreadSheetTable: 'Inserted row in SpreadSheet table',
    editedColumnDataGrid: 'edited column data grid'
};
