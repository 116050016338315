import React from 'react';

// Redux
import { changeChat, toggle } from 'features/store';
import { useDispatch, useSelector } from 'react-redux';

// Components
import GroupAvatar from 'views/chat/Components/GroupAvatar';
import UserAvatar from 'views/chat/Components/UserAvatar';
import MessageType from 'views/chat/Components/Messages/Message/MessageType';
import Notification from './Notifications';

// MUI components
import Divider from '@mui/material/Divider';
import ChatItem from 'ui-component/ChatItem';
import Grow from '@mui/material/Grow';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Hooks
import useGetMessages from 'layout/ChatLayout/hooks/useGetMessages';

// Third-party packages
import moment from 'moment';

// Constants
import { TOGGLE } from 'constant';

const SideBarChat = ({ chat, timeout }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const loadedChats = useSelector((state) => state.chat.loadedChats);
    const { refetch } = useGetMessages();

    const handleClick = () => {
        if (matchDownMd) dispatch(toggle({ type: TOGGLE.LEFT_DRAWER }));
        const isLoaded = loadedChats.indexOf(chat.id) !== -1;
        dispatch(changeChat({ id: chat.id }));
        localStorage.setItem('currentChatId', chat.id);
        if (!isLoaded) refetch();
    };

    let lastMessage;
    let date;
    if (chat.messages) {
        lastMessage = chat?.messages?.length !== 0 ? chat.messages[chat?.messages?.length - 1] : null;
        date = chat?.messages.length !== 0 ? moment(chat.messages[0].date).format('MMM DD') : '';
    } else {
        lastMessage = '...';
        date = '...';
    }

    return (
        <Grow in={true} timeout={timeout} unmountOnExit>
            <Box>
                <ButtonBase sx={{ width: '100%' }} onClick={handleClick}>
                    <ChatItem
                        icon={chat.isGroup ? <GroupAvatar img={chat.image} /> : <UserAvatar status={chat.status} img={chat.image} />}
                        title={chat.groupName}
                        desc={<MessageType message={lastMessage} />}
                        topRight={date}
                        bottomRight={<Notification notifications={chat.notifications} />}
                    />
                </ButtonBase>
                <Divider />
            </Box>
        </Grow>
    );
};

export default SideBarChat;
