// redux
import { createSlice } from '@reduxjs/toolkit';

// constants
import { LAYOUT_TYPES, DEFAULT_TEXT_SETTINGS, DEFAULT_VIEW, STYLES_TYPE, INITIALIAZE_HISTORY_RECORD, USE_KEYS } from 'constant';
import { generateRandomLayoutId, generateRandomPageId } from 'helpers';

// reducers
import * as basicReducers from './basic';
import * as layoutReducers from './layout';
import * as pageReducers from './page';
import * as viewReducers from './view';
import * as dataGridReducers from './table/dataGrid';
import * as spreadSheetReducers from './table/spreadSheet';
import * as globalReducers from './global';
import * as commonReducers from './common';

export const DEFAULT_STYLE = {
    type: STYLES_TYPE,
    id: 'styleId0',
    name: 'defaultStyle',
    settings: DEFAULT_TEXT_SETTINGS
};

const FIRST_LAYOUT_ID = generateRandomLayoutId();
const FIRST_PAGE_ID = generateRandomPageId();
const INITIAL_STATE = {
    serverData: {
        id: null,
        name: null,
        type: null,
        description: null,
        isDefault: false,
        image: ''
    },
    historyId: 0,
    history: [INITIALIAZE_HISTORY_RECORD],
    fileTemplatesMode: null,
    apiOptions: {
        get: null,
        add: null,
        update: null,
        useKeys: USE_KEYS.ADD
    },
    basic: [],
    layout: [
        {
            id: FIRST_LAYOUT_ID,
            type: LAYOUT_TYPES.GRID,
            responsive: { xs: '12', sm: '12', md: '12', lg: '12', xl: '12' },
            items: []
        }
    ],
    page: [
        {
            id: FIRST_PAGE_ID,
            items: [FIRST_LAYOUT_ID]
        }
    ],

    currentLayoutId: FIRST_LAYOUT_ID,
    currentBasicId: null,
    currentPageId: FIRST_PAGE_ID,

    currentBasic: null,
    currentLayout: null,
    currentPage: null,

    selectedBasic: [],
    selectedLayout: [],
    selectedPage: [],
    selectedDataGridCols: [],
    view: DEFAULT_VIEW,
    styles: [DEFAULT_STYLE],
    editColMode: false,
    editedCol: null
};
const INITIAL_STATE_WITH_HISTORY = INITIAL_STATE;

const fileTemplateSlice = createSlice({
    name: 'fileTemplates',
    initialState: INITIAL_STATE_WITH_HISTORY,
    reducers: {
        // ======== || Basic || ========= //
        ...basicReducers,
        // ======== || Layout || ========= //
        ...layoutReducers,
        // ======== || Page || ========= //
        ...pageReducers,
        // ======== || Table || ========= //
        ...dataGridReducers,
        ...spreadSheetReducers,
        // ======== || View || ========= //
        ...viewReducers,
        // ======== || Global || ========= //
        ...globalReducers,
        // ======== || Common || ========= //
        ...commonReducers
    }
});

export default fileTemplateSlice;
