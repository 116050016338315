import React from 'react';

// MUI components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import Collapse from '@mui/material/Collapse';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useTheme } from '@mui/styles';

// Components
import GroupAvatar from 'views/chat/Components/GroupAvatar';
import VideoCall from './Call';
import PinsButton from './PinsButton';
import Accounts from './Accounts';
import SelectMessages from '../SelectMessages';

// Constants
import { TOGGLE } from 'constant';

// Redux
import { toggle } from 'features/store';
import { useDispatch, useSelector } from 'react-redux';

const ChatBar = () => {
    // store
    const currentChat = useSelector((state) => state.chat.currentChat);
    const chatInfoOpen = useSelector((state) => state.chat.chatInfoOpen);
    const selectMode = useSelector((state) => state.chat.selectMode);
    const dispatch = useDispatch();

    // theme
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    // functions
    const handleOpenSearch = () => {
        dispatch(toggle({ type: TOGGLE.SEARCH }));
    };
    const handleBack = () => {
        dispatch(toggle({ type: TOGGLE.LEFT_DRAWER }));
    };
    const handleOpenChatInfo = () => {
        dispatch(toggle({ type: TOGGLE.CHAT_INFO }));
    };

    return (
        <Slide in={currentChat !== null} timeout={400}>
            <Box>
                <Collapse in={!selectMode}>
                    <Grid
                        container
                        id="chatBar"
                        sx={{
                            background: '#fff',
                            padding: '10px',
                            height: '60px'
                        }}
                    >
                        {matchDownMd && (
                            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Grow in={true} timeout={600}>
                                    <IconButton onClick={handleBack}>
                                        <ArrowBack />
                                    </IconButton>
                                </Grow>
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={1}
                            md={1}
                            sx={{ display: 'flex', alignItems: 'center', pl: chatInfoOpen ? 2 : 0 }}
                            justifyContent={{ xs: 'left', md: 'center' }}
                        >
                            <GroupAvatar img={currentChat?.image ? currentChat.image : null} />
                        </Grid>
                        <Grid
                            item
                            xs={7}
                            sm={7}
                            md={8}
                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                            pl={{ xs: 2, sm: 0 }}
                        >
                            <Grow in={true} timeout={1000}>
                                <Box sx={{ pl: chatInfoOpen ? 2 : 0 }}>
                                    <Typography component="p" fontSize="small">
                                        {currentChat?.groupName ? currentChat?.groupName : '...'}
                                    </Typography>
                                    <Accounts />
                                </Box>
                            </Grow>
                        </Grid>
                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <PinsButton />
                            <VideoCall />
                            <Grow in={chatInfoOpen ? false : true} timeout={1600} sx={{ display: chatInfoOpen && 'none' }}>
                                <IconButton onClick={handleOpenSearch}>
                                    <SearchIcon />
                                </IconButton>
                            </Grow>
                            <Grow in={chatInfoOpen ? false : true} timeout={1800} sx={{ display: chatInfoOpen && 'none' }}>
                                <IconButton onClick={handleOpenChatInfo}>
                                    <InfoIcon />
                                </IconButton>
                            </Grow>
                        </Grid>
                    </Grid>
                </Collapse>
                <SelectMessages />
            </Box>
        </Slide>
    );
};

export default ChatBar;
