/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

export default function themePalette(theme) {
    const isDarkMode = theme?.customization.darkMode;
    const mode = isDarkMode ? 'dark' : 'light';
    return {
        mode,
        common: {
            black: theme.colors?.darkPaper
        },
        primary: {
            light: theme.colors?.primaryLight,
            main: isDarkMode ? theme.colors?.primaryLight : theme.colors?.primaryMain,
            dark: theme.colors?.primaryDark,
            200: theme.colors?.primary200,
            800: theme.colors?.primary800
        },
        secondary: {
            light: theme.colors?.secondaryLight,
            main: isDarkMode ? theme.colors?.secondaryDark : theme.colors?.secondaryMain,
            dark: theme.colors?.secondaryDark,
            200: theme.colors?.secondary200,
            800: theme.colors?.secondary800
        },
        error: {
            light: theme.colors?.errorLight,
            main: theme.colors?.errorMain,
            dark: theme.colors?.errorDark,
            contrastText: '#FFFFFF'
        },
        orange: {
            light: theme.colors?.orangeLight,
            main: theme.colors?.orangeMain,
            dark: theme.colors?.orangeDark,
            contrastText: '#FFFFFF'
        },
        warning: {
            light: theme.colors?.warningLight,
            main: theme.colors?.warningMain,
            dark: theme.colors?.warningDark,
            contrastText: '#FFFFFF'
        },
        info: {
            light: theme.colors?.infoLight,
            main: theme.colors?.infoMain,
            dark: theme.colors?.infoDark,
            contrastText: '#FFFFFF'
        },
        success: {
            light: theme.colors?.successLight,
            200: theme.colors?.success200,
            main: theme.colors?.successMain,
            dark: theme.colors?.successDark,
            contrastText: '#FFFFFF'
        },
        grey: {
            50: theme.colors?.grey50,
            100: theme.colors?.grey100,
            500: theme.darkTextSecondary,
            600: theme.heading,
            700: theme.darkTextPrimary,
            900: theme.textDark
        },
        dark: {
            light: theme.colors?.darkTextPrimary,
            main: theme.colors?.darkLevel1,
            dark: theme.colors?.darkLevel2,
            800: theme.colors?.darkBackground,
            900: theme.colors?.darkPaper
        },
        text: {
            primary: isDarkMode ? '#FFF' : theme.colors?.textPrimary,
            secondary: isDarkMode ? theme.colors?.darkTextSecondary : theme.colors?.textSecondary,
            dark: isDarkMode ? theme.textDark : theme.colors?.textPrimary,
            hint: theme.colors?.grey100
        },
        background: {
            paper: isDarkMode ? theme.colors?.darkPaper : theme.paper,
            default: isDarkMode ? theme.colors?.darkPaper : theme.paper,
            boxShadow: isDarkMode
                ? '0px 2px 2px -2px rgba(0,0,0,0.2), 0px 2px 3px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
                : '0px 2px 2px -2px rgba(0,0,0,0.2), 0px 2px 3px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
        }
    };
}
