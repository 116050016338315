import { FT_ICONS, HISTORY_ACTIONS } from 'constant';
import { addHistoryRecord, getItemFromArray } from 'helpers';

/**
 * Edits a cell in a DataGrid table in the state and adds a history record for the edit.
 * @param {object} state - The state object.
 * @param {object} payload - An object containing the ID of the table, ID and value of the cell to edit, and the accessor key of the column.
 */
export const editDataGridCell = (state, { payload }) => {
    // Find the DataGrid table to update
    let table = getItemFromArray({ array: state.basic, id: payload.tableId });
    /*     const oldTable = { ...table };
     */ // Update the value of the specified cell

    if (table.rows[payload.cell.id]) table.rows[payload.cell.id][payload.cell.accessorKey] = payload.cell.value;

    /*     // Add a history record for the deletion
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.EDIT_BASIC,
            description: `Edited cell in column ${payload.cell.accessorKey}, ID: ${payload.cell.id}`,
            from: oldTable,
            to: table
        }); */
};
