import ReactDOM from 'react-dom';
import React from 'react';
// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import App from 'App';
import store from 'features/store';

// style + assets
import 'assets/scss/style.scss';
import 'animate.css';
import 'handsontable/dist/handsontable.full.min.css';
import 'leaflet/dist/leaflet.css';

// ==============================|| REACT DOM RENDER  ||============================== //
ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
