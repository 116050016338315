let starMessage = (state, { payload: { messageId, chatId } }) => {
    let chat = state.chats.find((chat) => chat.id == chatId);
    let message = chat.messages.find((message) => message.id === messageId);
    let starred = state.stars.find((message) => message.id === messageId);
    if (!starred) {
        state.stars.push(message);
    }
};

export default starMessage;
