export const DEFAULT_TEXT_SETTINGS = {
    fontSize: 16,
    fontStyle: 'normal',
    color: '#000',
    backgroundColor: 'rgba(255, 255, 255, 0)',
    textDecoration: 'none',
    fontWeight: '400'
};

export const TEXT_SETTINGS = {
    FONT_SIZE: 'fontSize',
    FONT_STYLE: 'fontStyle',
    COLOR: 'color',
    BACKGROUND_COLOR: 'backgroundColor',
    TEXT_DECORATION: 'textDecoration',
    FONT_WEIGHT: 'fontWeight',
    RESET: 'reset'
};
