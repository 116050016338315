let unReactToMessage = (state, { payload: { messageId, key, chatId, accountId } }) => {
    if (state.currentChat !== null && state.currentChat.id === chatId) {
        let message = state.currentChat.messages.find((message) => message.id === messageId);
        if (message.reactions === null) message.reactions = [];
        message.reactions = message.reactions.filter((reaction) => {
            return reaction.value !== accountId && reaction.key !== key;
        });
    }

    let chat = state.chats.find((chat) => chat.id == chatId);
    let message = chat.messages.find((message) => message.id === messageId);
    if (message.reactions === null) message.reactions = [];
    message.reactions = message.reactions.filter((reaction) => {
        return reaction.value !== accountId && reaction.key !== key;
    });
};

export default unReactToMessage;
