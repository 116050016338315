import { HISTORY_ACTIONS } from 'constant';
import { setCurrentLayout, swipeBasic, swipeLayout, swipePage } from '../common';
import { setStyles } from './setStyles';
import { setFooter, setHeader, setPageSettings, setWaterMark } from '../view';
import { deleteBasic, editBasic } from '../basic';
import { editLayout, deleteLayout } from '../layout';
import { addPage, deletePage, editPage } from '../page';
import { setApiOptions } from './setApiOptions';

export const undo = (state, { payload: { historyRecord } }) => {
    const { from, to } = historyRecord;
    const withOutHistroyRecord = true;
    const UNDO_MAP = {
        [HISTORY_ACTIONS.ASSIGN_LAYOUT_TO_PAGE.label]: () => {
            editPage(state, { payload: { item: from.oldParent, withOutHistroyRecord } });
            if (to.oldParent.id !== to.newParent.id) editPage(state, { payload: { item: from.newParent, withOutHistroyRecord } });
        },
        [HISTORY_ACTIONS.ASSIGN_BASIC_TO_LAYOUT.label]: () => {
            editLayout(state, { payload: { item: from.oldParent, withOutHistroyRecord } });
            if (to.oldParent.id !== to.newParent.id) editLayout(state, { payload: { item: from.newParent, withOutHistroyRecord } });
        },
        // INSERT
        [HISTORY_ACTIONS.INSERT_BASIC.label]: () => deleteBasic(state, { payload: { itemId: to.basicItem.id, withOutHistroyRecord } }),
        [HISTORY_ACTIONS.INSERT_LAYOUT.label]: () => deleteLayout(state, { payload: { itemId: to.layoutItem.id, withOutHistroyRecord } }),
        [HISTORY_ACTIONS.INSERT_BEFORE_BASIC.label]: () => {
            editLayout(state, { payload: { item: from.oldParent, withOutHistroyRecord } });
            if (to.oldParent.id !== to.newParent.id) editLayout(state, { payload: { item: from.newParent, withOutHistroyRecord } });
        },
        [HISTORY_ACTIONS.INSERT_AFTER_BASIC.label]: () => {
            editLayout(state, { payload: { item: from.oldParent, withOutHistroyRecord } });
            if (to.oldParent.id !== to.newParent.id) editLayout(state, { payload: { item: from.newParent, withOutHistroyRecord } });
        },
        [HISTORY_ACTIONS.INSERT_BEFORE_LAYOUT.label]: () => {
            editPage(state, { payload: { item: from.oldParent, withOutHistroyRecord } });
            if (to.oldParent.id !== to.newParent.id) editPage(state, { payload: { item: from.newParent, withOutHistroyRecord } });
        },
        [HISTORY_ACTIONS.INSERT_AFTER_LAYOUT.label]: () => {
            editPage(state, { payload: { item: from.oldParent, withOutHistroyRecord } });
            if (to.oldParent.id !== to.newParent.id) editPage(state, { payload: { item: from.newParent, withOutHistroyRecord } });
        },
        [HISTORY_ACTIONS.INSERT_PAGE.label]: () => deletePage(state, { payload: { itemId: to.id, withOutHistroyRecord } }),
        // EDIT
        [HISTORY_ACTIONS.EDIT_BASIC.label]: () => editBasic(state, { payload: { item: from, withOutHistroyRecord } }),
        [HISTORY_ACTIONS.EDIT_LAYOUT.label]: () => editLayout(state, { payload: { item: from, withOutHistroyRecord } }),
        [HISTORY_ACTIONS.EDIT_STYLES.label]: () => setStyles(state, { payload: { ...from, withOutHistroyRecord } }),
        [HISTORY_ACTIONS.EDIT_API_OPTIONS.label]: () => setApiOptions(state, { payload: { ...from, withOutHistroyRecord } }),
        [HISTORY_ACTIONS.EDIT_HEADER.label]: () => setHeader(state, { payload: { ...from, withOutHistroyRecord } }),
        [HISTORY_ACTIONS.EDIT_FOOTER.label]: () => setFooter(state, { payload: { ...from, withOutHistroyRecord } }),
        [HISTORY_ACTIONS.EDIT_PAGE_SETTINGS.label]: () => setPageSettings(state, { payload: { ...from, withOutHistroyRecord } }),
        [HISTORY_ACTIONS.EDIT_WATERMARK.label]: () => setWaterMark(state, { payload: { ...from, withOutHistroyRecord } }),
        // DELETE
        [HISTORY_ACTIONS.DELETE_BASIC.label]: () => {
            setCurrentLayout(state, { payload: from.layoutItem.id });
            state.basic.push(from.basicItem);
            editLayout(state, { payload: { item: from.layoutItem, withOutHistroyRecord } });
        },
        [HISTORY_ACTIONS.DELETE_LAYOUT.label]: () => {
            state.layout.push(from.layoutItem);
            setCurrentLayout(state, { payload: from.layoutItem.id });
            editLayout(state, { payload: { item: from.layoutItem, withOutHistroyRecord } });
            editPage(state, { payload: { item: from.pageItem, withOutHistroyRecord } });
        },
        [HISTORY_ACTIONS.DELETE_PAGE.label]: () => state.page.push(from),
        // DUPLICATE
        [HISTORY_ACTIONS.DUPLICATE_BASIC.label]: () => deleteBasic(state, { payload: { itemId: to.basicItem.id, withOutHistroyRecord } }),
        [HISTORY_ACTIONS.DUPLICATE_LAYOUT.label]: () =>
            deleteLayout(state, { payload: { itemId: to.layoutItem.id, withOutHistroyRecord } }),
        // SWIPE
        [HISTORY_ACTIONS.BASIC_SWIPE.label]: () =>
            swipeBasic(state, {
                payload: {
                    ...to,
                    withOutHistroyRecord
                }
            }),
        [HISTORY_ACTIONS.LAYOUT_SWIPE.label]: () =>
            swipeLayout(state, {
                payload: {
                    ...to,
                    withOutHistroyRecord
                }
            }),
        [HISTORY_ACTIONS.PAGE_SWIPE.label]: () =>
            swipePage(state, {
                payload: {
                    ...to,
                    withOutHistroyRecord
                }
            })
    };
    if (state.historyId !== 0) {
        UNDO_MAP[historyRecord?.action?.label]?.();
        state.historyId = state.historyId - 1;
    }
};
