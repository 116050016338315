import React from 'react';

// Icons
import { IconBuilding } from '@tabler/icons';

// Constant
const icons = { IconBuilding };
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';
import { useTranslation } from 'react-i18next';
import { CentersMangmentPageConstant } from 'constant/Translations/system-administrator/centers-mangment-page';
import { BranchesMangmentPageConstant } from 'constant/Translations/system-administrator/branch-management-page';
import { SectionsManagementPageConstant } from 'constant/Translations/system-administrator/sections-management-page';
import { AccountPageConstant } from 'constant/Translations/system-administrator/account-page';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const useSystemAdministrator = () => {
    const { t } = useTranslation();
    return {
        //need translation
        id: 'executiveManagement',
        title: 'Executive Management',
        type: 'group',
        caption: 'Executive Management',
        icon: <SupervisorAccountRoundedIcon />,
        children: [
            {
                id: 'centers',
                title: t(CentersMangmentPageConstant.centersMangment),
                type: 'item',
                icon: icons.IconBuilding,
                url: '/centers',
                breadcrumbs: false
            },
            {
                id: 'Branches',
                title: t(BranchesMangmentPageConstant.branchsMangment),
                type: 'item',
                icon: icons.IconBuilding,
                url: '/branches',
                breadcrumbs: false
            },
            {
                id: 'accounts',
                title: t(AccountPageConstant.accounts),
                type: 'item',
                icon: icons.IconBuilding,
                url: '/accounts',
                breadcrumbs: false
            },
            {
                //need translation
                id: 'subscriptions',
                title: 'Subscriptions',
                type: 'item',
                icon: icons.IconBuilding,
                url: '/subscriptions',
                breadcrumbs: false
            },
            {
                //need translation
                id: 'permissionsAndAuthorizations',
                title: 'Permissions and Authorizations',
                type: 'item',
                icon: icons.IconBuilding,
                url: '/permissions',
                breadcrumbs: false
            }
        ]
    };
};

export default useSystemAdministrator;
