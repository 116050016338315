import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import authReducer from './Auth/authSlice';
import staticReducer from './Static/staticSlice';
import themeSlice from './Theme/themeSlice';
import sectionsReducer from './Sections/sectionsSlice';
import systemLanguagesReducer from './SystemLanguages/systemLanguagesSlice';
import chatSlice from './Chat/chatSlice';
import fileTemplateSlice from './FileTemplate/fileTemplateSlice';
import { Notification } from 'helpers';

const saveMiddleware = (store) => (next) => (action) => {
    localStorage.setItem('store', JSON.stringify(store.getState()));
    return next(action);
};
const errorCatcher = (store) => (next) => (action) => {
    try {
        return next(action);
    } catch (err) {
        const msg = 'An error occurred:' + err.message;
        console.error(err);
        Notification.fire({ text: msg, icon: 'error', timer: 5000 });
        // You can also dispatch an error action here or do something else
    }
};
const store = configureStore({
    reducer: {
        static: staticReducer,
        auth: authReducer,
        sections: sectionsReducer,
        systemLanguages: systemLanguagesReducer,
        theme: themeSlice.reducer,
        fileTemplates: fileTemplateSlice.reducer,
        chat: chatSlice.reducer
    },
    middleware: [thunk, errorCatcher]
});

export const {
    // chats
    initChats,
    initStars,
    resetNotifications,
    setImages,
    setMessagesStatus,
    addChat,
    changeChat,
    changeChatInfo,
    loadChat,
    setChatMessages,
    setSearchedMessages,
    setConnection,
    // ======== || message || ========= //
    // add delete edit
    addMessage,
    deleteMessage,
    editMessage,
    // pins
    pinMessage,
    unPinMessage,
    // star
    starMessage,
    unStarMessage,
    // react
    reactToMessage,
    unReactToMessage,
    // others
    replyTo,
    selectMessage,
    setEditedMessage,
    changeId,
    // ======== || end-message || ========= //
    // layout
    toggleDialog,
    addDialog,
    toggle,
    changeWallpaperColor,
    changeWallpaperImage,
    setPhotoIndex
} = chatSlice.actions;

export const {
    addAttrBasic,
    addAttrLayout,
    addBasic,
    addBasicFunc,
    addLayout,
    addLayoutFunc,
    addPage,
    assignBasicToLayout,
    assignLayoutToPage,
    deleteBasic,
    deleteDataGridCol,
    deleteLayout,
    deleteMultipleBasic,
    deleteMultipleLayout,
    deleteMultiplePage,
    addMultipleSpreadSheetCols,
    addMultipleSpreadSheetRows,
    deleteMultipleSpreadSheetCols,
    deleteMultipleSpreadSheetRows,
    deletePage,
    deleteSpreadSheetCol,
    deleteSpreadSheetRow,
    deleteDataGridRow,
    duplicateBasic,
    duplicateLayout,
    editBasic,
    editDataGridCell,
    editDataGridColToggle,
    editLayout,
    editSpreadSheetCell,
    insertAfterBasic,
    insertAfterLayout,
    insertBeforeBasic,
    insertBeforeLayout,
    insertSpreadSheetCol,
    insertSpreadSheetRow,
    setPageSettings,
    selectBasic,
    selectLayout,
    selectPage,
    selectDataGridCol,
    setCurrentBasic,
    setCurrentLayout,
    setCurrentPage,
    setFileTemplate,
    setFileTemplatesMode,
    setFooter,
    setHeader,
    setStyles,
    setView,
    setWaterMark,
    setApiOptions,
    swipeBasic,
    swipeLayout,
    swipePage,
    swipeDataGridCols,
    uploadFileTemplate,
    setSpreadSheetSettings,
    addDataGridCol,
    editDataGridCol,
    undo,
    redo,
    setHistoryRecordId
} = fileTemplateSlice.actions;
export const { menuOpen, setBorderRadius, setFontFamily, setMenu, toggleSideBar, toggleDarkMode } = themeSlice.actions;

export default store;
