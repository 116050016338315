export const PriorityPageConstant = {
    priorities: 'Settings_Accessories_Priority.priorities',
    priority: 'Settings_Accessories_Priority.priority',
    name: 'Settings_Accessories_Priority.name',
    code: 'Settings_Accessories_Priority.code',
    color: 'Settings_Accessories_Priority.color',
    description: 'Settings_Accessories_Priority.description',
    edit: 'Settings_Accessories_Priority.edit',
    delete: 'Settings_Accessories_Priority.delete'
};
