import * as React from 'react';
import { useState } from 'react';

// Constants
import { STATIC_FILES_URL } from 'env/server';

// MUI components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import useTheme from '@mui/styles/useTheme';
import Box from '@mui/material/Box';

// Hooks
import useCreateChat from 'layout/ChatLayout/hooks/useCreateChat';
import useUploadFile from 'hooks/api/useUploadFile';

// UI components
import Progress from 'ui-component/Progress';

let NewGroup = () => {
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [name, setName] = useState(null);
    let createChat = useCreateChat();
    let { upload, progress } = useUploadFile();
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    let handleChangeChatName = (e) => {
        setName(e.target.value);
    };

    let handleChangeInfoImage = (e) => {
        const reader = new FileReader();
        reader.onloadend = (url) => {
            setImage(url.currentTarget.result);
        };
        reader.readAsDataURL(e.target.files[0]);
        setImageFile(e.target.files[0]);
    };

    let handleCreate = async () => {
        await upload.mutateAsync(imageFile, {
            onSuccess: async (data) => {
                await createChat.mutateAsync(
                    {
                        name: name,
                        image: STATIC_FILES_URL + data.data.data
                    },
                    { onSuccess: () => setOpen(false) }
                );
            }
        });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <MenuItem onClick={handleClickOpen}>New Group</MenuItem>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                    '.MuiPaper-root': {
                        width: !fullScreen ? '60%' : '100%'
                    }
                }}
                fullScreen={fullScreen}
            >
                <Grow in={open} timeout={400} unmountOnExit>
                    <DialogTitle>Create A New Group</DialogTitle>
                </Grow>
                <DialogContent>
                    <Grow in={open} timeout={600} unmountOnExit>
                        <Typography padding={'10px'} color="text.secondary">
                            Name
                        </Typography>
                    </Grow>
                    <Grow in={open} timeout={800} unmountOnExit>
                        <TextField type="text" fullWidth onChange={handleChangeChatName} />
                    </Grow>
                    <Grow in={open} timeout={1000} unmountOnExit>
                        <Typography padding={'10px'} color="text.secondary">
                            Chat Image
                            <IconButton onClick={() => document.getElementById('chatInfoImage').click()}>
                                <AddSharpIcon />
                            </IconButton>
                        </Typography>
                    </Grow>
                    <Grow in={image} timeout={500} unmountOnExit>
                        <img src={image} style={{ width: '100%', height: 'auto', borderRadius: '20px' }} alt="none" />
                    </Grow>
                    <input type="file" id="chatInfoImage" style={{ display: 'none' }} onChange={handleChangeInfoImage} />
                    <Grow in={progress !== 0} unmountOnExit timeout={500}>
                        <Box>
                            <Progress value={progress} />
                        </Box>
                    </Grow>
                </DialogContent>
                <Grow in={open} timeout={1200} unmountOnExit>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                        <Button onClick={handleCreate}>Create</Button>
                    </DialogActions>
                </Grow>
            </Dialog>
        </React.Fragment>
    );
};

export default NewGroup;
