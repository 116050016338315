import { LAYOUT_TYPES } from 'constant';

export const AddLayoutDescriptionTranslation = {
    AddedGrid: 'Added Grid',
    AddedExpand: 'Added Expand',
    AddedFlexLayout: 'Added Flex Layout',
    AddedTabs: 'Added Tabs'
};

export const EditLayoutDescriptionTranslation = {
    EditGrid: 'Edit Grid',
    EditExpand: 'Edit Expand',
    EditFlexLayout: 'Edit Flex Layout',
    EditTabs: 'Edit Tabs'
};

export const DeleteLayoutDescriptionTranslation = {
    DeletedGrid: 'Deleted Grid',
    DeletedExpand: 'Deleted Expand',
    DeletedFlexLayout: 'Deleted Flex Layout',
    DeletedTabs: 'Deleted Tabs'
};

export const DuplicatedLayoutDescriptionTranslation = {
    DuplicatedGrid: 'Duplicated Grid',
    DuplicatedExpand: 'Duplicated Expand',
    DuplicatedFlexLayout: 'Duplicated Flex Layout',
    DuplicatedTabs: 'Duplicated Tabs'
};

export const addLayoutDescriptionTranslation = (input) => {
    const layoutType = {
        [LAYOUT_TYPES.GRID]: AddLayoutDescriptionTranslation.AddedGrid,
        [LAYOUT_TYPES.EXPAND]: AddLayoutDescriptionTranslation.AddedExpand,
        [LAYOUT_TYPES.FLEX_LAYOUT]: AddLayoutDescriptionTranslation.AddedFlexLayout,
        [LAYOUT_TYPES.TABS]: AddLayoutDescriptionTranslation.AddedTabs
    };

    return layoutType[input];
};

export const editLayoutDescriptionTranslation = (input) => {
    const layoutType = {
        [LAYOUT_TYPES.GRID]: EditLayoutDescriptionTranslation.EditGrid,
        [LAYOUT_TYPES.EXPAND]: EditLayoutDescriptionTranslation.EditExpand,
        [LAYOUT_TYPES.FLEX_LAYOUT]: EditLayoutDescriptionTranslation.EditFlexLayout,
        [LAYOUT_TYPES.TABS]: EditLayoutDescriptionTranslation.EditTabs
    };

    return layoutType[input];
};

export const deleteLayoutDescriptionTranslation = (input) => {
    const layoutType = {
        [LAYOUT_TYPES.GRID]: DeleteLayoutDescriptionTranslation.DeletedGrid,
        [LAYOUT_TYPES.EXPAND]: DeleteLayoutDescriptionTranslation.DeletedExpand,
        [LAYOUT_TYPES.FLEX_LAYOUT]: DeleteLayoutDescriptionTranslation.DeletedFlexLayout,
        [LAYOUT_TYPES.TABS]: DeleteLayoutDescriptionTranslation.DeletedTabs
    };

    return layoutType[input];
};

export const duplicatedLayoutDescriptionTranslation = (input) => {
    const layoutType = {
        [LAYOUT_TYPES.GRID]: DuplicatedLayoutDescriptionTranslation.DuplicatedGrid,
        [LAYOUT_TYPES.EXPAND]: DuplicatedLayoutDescriptionTranslation.DuplicatedExpand,
        [LAYOUT_TYPES.FLEX_LAYOUT]: DuplicatedLayoutDescriptionTranslation.DuplicatedFlexLayout,
        [LAYOUT_TYPES.TABS]: DuplicatedLayoutDescriptionTranslation.DuplicatedTabs
    };

    return layoutType[input];
};
