/* eslint-disable no-unused-vars */
import * as React from 'react';

// MUI Components
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

// store
import { selectMessage, toggle } from 'features/store';
import { useDispatch, useSelector } from 'react-redux';
// constants
import { INITIAL_MENU, TOGGLE } from 'constant';

let ContextSelect = ({ message, setMenu, menu, setSelected }) => {
    const selectMode = useSelector((state) => state.chat.selectMode);
    const starsOpen = useSelector((state) => state.chat.starsOpen);
    const dispatch = useDispatch();
    const handleSelect = () => {
        setMenu(INITIAL_MENU);
        if (!selectMode) dispatch(toggle({ type: TOGGLE.SELECT_MODE }));
        setSelected(true);
        dispatch(selectMessage(starsOpen ? { ...message, id: message.messageId } : message));
    };

    return (
        <Grow unmountOnExit in={menu.mouseX !== null} timeout={1400}>
            <MenuItem onClick={handleSelect}>
                <ListItemIcon>
                    <TaskAltIcon fontSize="small" />
                </ListItemIcon>
                <Typography color="#000">Select</Typography>
            </MenuItem>
        </Grow>
    );
};

export default ContextSelect;
