import React from 'react';

// MUI components
import Skeleton from '@mui/material/Skeleton';
import ImageListItem from '@mui/material/ImageListItem';

let ImageSkeleton = () => (
    <ImageListItem>
        <Skeleton sx={{ width: '100%', height: '164px', transform: 'none' }} />
    </ImageListItem>
);

export default ImageSkeleton;
