import React, { lazy } from 'react';

// Components
import Loadable from 'ui-component/Loadable';

const SystemLanguages = Loadable(lazy(() => import('views/SystemLanguages')));
const routes = [
    {
        path: '/system-languages',
        element: <SystemLanguages />
    }
];
export default routes;
