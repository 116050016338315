import React from 'react';
import { useEffect } from 'react';

// MUI components
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';

// Redux
import { toggle } from 'features/store';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

// constant
import { TOGGLE } from 'constant';

const MessageField = ({ recording, setIsTextMessage, messageInput }) => {
    const editMode = useSelector((state) => state.chat.editMode);
    const dispatch = useDispatch();
    const handleOpenEmojis = () => {
        dispatch(toggle({ type: TOGGLE.EMOJIS }));
    };

    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        messageInput.current = document.getElementById('message');
        messageInput.current.focus();
        if (messageInput.current.value) setIsTextMessage(true);
    }, [messageInput, recording, editMode, setIsTextMessage]);

    return (
        <React.Fragment>
            <Grow in={!recording} unmountOnExit>
                <IconButton onClick={handleOpenEmojis}>
                    <EmojiEmotionsIcon />
                </IconButton>
            </Grow>
            <Grow in={!recording} unmountOnExit>
                <Box
                    sx={{
                        width: matchDownMd ? '70%' : '80%',
                        '& *:focus-visible, & *, & *:focus': { border: 'none !important', outline: 'none !important' }
                    }}
                >
                    <TextField
                        type="text"
                        placeholder="Message..."
                        id="message"
                        sx={{
                            width: '100%',
                            backgroundColor: 'rgb(244,244,244)',
                            '& fieldset': {
                                border: 0
                            },
                            '& input:focus-visible, & input:focus, &:focus-visible, &:focus': {
                                outline: 'none !important'
                            }
                        }}
                        onChange={(e) => {
                            if (e.target.value) setIsTextMessage(true);
                            else setIsTextMessage(false);
                        }}
                    />
                </Box>
            </Grow>
        </React.Fragment>
    );
};

export default MessageField;
