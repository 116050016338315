let unPinMessage = (state, { payload: { messageId, chatId } }) => {
    if (state.currentChat !== null && state.currentChat.id === chatId) {
        let pinned = state.currentChat.pins.find((message) => message.id === messageId);
        if (pinned) {
            state.currentChat.pins = state.currentChat.pins.filter((message) => message.id !== messageId);
        }
    }
    let chat = state.chats.find((chat) => chat.id == chatId);
    let pinned = chat.pins.find((message) => message.id === messageId);
    if (pinned) {
        chat.pins = chat.pins.filter((message) => message.id !== messageId);
    }
};

export default unPinMessage;
