import { FORMIK_CONTROL_TYPES } from '../Global/formikControlTypes';

export const PERSONAL_FILE_FIELDS = [
    {
        name: 'Pic',
        required: false,
        hidden: false,
        type: 'file'
    },
    {
        name: 'Name',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.TEXT
    },
    {
        name: 'Father Name',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.TEXT
    },
    {
        name: 'Mother Name',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.TEXT
    },
    {
        name: 'Birth Date',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.DATE
    },
    {
        name: 'Educational Level',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.SELECT
    },
    {
        name: 'Gender',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.SELECT
    },
    {
        name: 'National Number',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.NUMBER
    },
    {
        name: 'Honesty',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.NUMBER
    },
    {
        name: 'Constarint',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.NUMBER
    },
    {
        name: 'Religion',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.SELECT
    },
    {
        name: 'Doctrine',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.SELECT
    },
    {
        name: 'Blood Type',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.SELECT
    },
    {
        name: 'Social Status',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.SELECT
    },
    {
        name: 'Childs Number',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.NUMBER
    },
    { name: 'Passport Number', required: false, hidden: false, type: FORMIK_CONTROL_TYPES.NUMBER },
    {
        name: 'Birth Country',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.TEXT
    },
    {
        name: 'Birth state',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.TEXT
    },
    {
        name: 'Birth City',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.TEXT
    },
    {
        name: 'Not Sentenced Document',
        required: false,
        hidden: false,
        type: 'file'
    },
    {
        name: 'Employee/Non-Employee Document',
        required: false,
        hidden: false,
        type: 'file'
    },
    {
        name: 'Identity Document Pic',
        required: false,
        hidden: false,
        type: 'file'
    },
    {
        name: 'Union Document Pic',
        required: false,
        hidden: false,
        type: 'file'
    },
    {
        name: 'About',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.TEXT
    }
];
