import { FT_ICONS } from './icons';

export const messageMap = {
    animate: 'Animated Element',
    conditions: 'Added Condition',
    margin: 'Added Margin',
    dir: 'Changed Direction',
    hideInPrint: 'Hide In Print'
};
export const iconMap = {
    dir: FT_ICONS.DIR,
    hideInPrint: FT_ICONS.HIDE_IN_PRINT
};

export const HISTORY_ACTIONS = {
    // ASSIGN
    ASSIGN_LAYOUT_TO_PAGE: { label: 'Assign Layout To Page', icon: FT_ICONS.INSERT },
    ASSIGN_BASIC_TO_LAYOUT: { label: 'Assign Basic To Layout', icon: FT_ICONS.INSERT },

    // INSERT
    INSERT_BASIC: {
        label: 'Insert Basic',
        icon: FT_ICONS.INSERT
    },
    INSERT_BEFORE_BASIC: { label: 'Insert Before Basic', icon: FT_ICONS.INSERT_BEFORE, description: 'Inserted Basic Before Basic' },
    INSERT_AFTER_BASIC: { label: 'Insert After Basic', icon: FT_ICONS.INSERT_AFTER, description: 'Inserted Basic After Basic' },
    INSERT_LAYOUT: {
        label: 'Insert Layout',
        icon: FT_ICONS.INSERT
    },

    INSERT_BEFORE_LAYOUT: { label: 'Insert Before Layout', icon: FT_ICONS.INSERT_BEFORE, description: 'Inserted Layout Before Layout' },
    INSERT_AFTER_LAYOUT: { label: 'Insert After Layout', icon: FT_ICONS.INSERT_AFTER, description: 'Inserted Layout After Layout' },
    INSERT_PAGE: { label: 'Insert Page', icon: FT_ICONS.INSERT, description: 'Added Page' },

    // EDIT
    EDIT_BASIC: {
        label: 'Edit Basic',
        icon: FT_ICONS.EDIT,
        description: 'Edit'
    },
    EDIT_LAYOUT: {
        label: 'Edit Layout',
        icon: FT_ICONS.EDIT
    },
    EDIT_STYLES: { label: 'Edit Styles', icon: FT_ICONS.EDIT, description: 'Edited Styles' },
    EDIT_API_OPTIONS: { label: 'Edit Api Options', icon: FT_ICONS.API_OPTIONS, description: 'Edited Api Options' },
    EDIT_HEADER: { label: 'Edit Header', icon: FT_ICONS.EDIT, description: 'Edited The Header' },
    EDIT_FOOTER: { label: 'Edit Footer', icon: FT_ICONS.EDIT, description: 'Edited The Footer' },
    EDIT_PAGE_SETTINGS: { label: 'Edit Page Settings', icon: FT_ICONS.EDIT, description: 'Edited The Page Settings' },
    EDIT_WATERMARK: { label: 'Edit Watermark', icon: FT_ICONS.WATERMARK, description: 'Edited The Watermark' },
    // DELETE
    DELETE_BASIC: {
        label: 'Delete Basic',
        icon: FT_ICONS.DELETE
    },
    DELETE_LAYOUT: {
        label: 'Delete Layout',
        icon: FT_ICONS.DELETE
    },
    DELETE_PAGE: { label: 'Delete Page', icon: FT_ICONS.DELETE, description: 'Deleted Page' },

    // DUPLICATE
    DUPLICATE_BASIC: { label: 'Duplicate Basic', icon: FT_ICONS.DUPLICATE },
    DUPLICATE_LAYOUT: { label: 'Duplicate Layout', icon: FT_ICONS.DUPLICATE },

    // SWIPE
    BASIC_SWIPE: { label: 'Swipe Basic', icon: FT_ICONS.SWIPE, description: 'Swiped Between Two Basic Items' },
    LAYOUT_SWIPE: { label: 'Swipe Layout', icon: FT_ICONS.SWIPE, description: 'Swiped Between Two Layout Items' },
    PAGE_SWIPE: { label: 'Page Swipe', icon: FT_ICONS.SWIPE, description: 'Swiped Between Two Pages' },
    // INIT
    INIT: { icon: FT_ICONS.INIT, label: 'initialization' }
};

export const INITIALIAZE_HISTORY_RECORD = {
    action: HISTORY_ACTIONS.INIT,
    historyId: 0,
    description: 'Initialize The Application'
};

/* 
    Schema For Action
    {
        label : string,
        icon : string,
    }
*/

/* 
    History Record Schema 
    {
        action : HistoryAction,
        description : string,
        from : object, nullable For Example Insert 
        to : object, nullable For Example Delete
    }
*/
