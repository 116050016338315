import React from 'react';

// MUI components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grow from '@mui/material/Grow';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';

// Redux
import { useSelector } from 'react-redux';

let ChatImage = () => {
    let theme = useTheme();
    let matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    let currentChat = useSelector((state) => state.chat.currentChat);
    let chatInfoOpen = useSelector((state) => state.chat.chatInfoOpen);
    return !matchDownMd ? (
        <Grow in={chatInfoOpen} timeout={600} unmountOnExit>
            <Box
                sx={{
                    width: '100%',
                    height: '60vh',
                    position: 'relative',
                    boxShadow: '.1vw .1vw 1vw .1vw #ccc'
                }}
            >
                {' '}
                <img src={currentChat.image} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />
                <Box sx={{ position: 'absolute', bottom: '1vw', left: '1vw', color: '#fff' }}>
                    <Typography component="p" fontWeight="bold" fontSize="larger">
                        {currentChat.groupName}
                    </Typography>
                    {!currentChat.isGroup && <Typography mr={1}></Typography>}
                </Box>
            </Box>
        </Grow>
    ) : (
        <React.Fragment>
            <Grow in={chatInfoOpen} timeout={600} unmountOnExit>
                <Box
                    sx={{
                        width: '100%',
                        height: '60vh',
                        position: 'relative'
                    }}
                >
                    {' '}
                    <img
                        src={currentChat.image}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)',
                            width: '200px',
                            boxShadow: '.1vw .1vw 1vw .1vw #ccc',
                            height: '200px',
                            borderRadius: '50%'
                        }}
                    />
                </Box>
            </Grow>
            <Grow in={chatInfoOpen} timeout={800} unmountOnExit>
                <Box p={1}>
                    <Typography variant="h2" fontWeight="100">
                        {currentChat.name}
                    </Typography>
                    {currentChat.type === 'person' && <Typography mr={1}>{currentChat.status}</Typography>}
                </Box>
            </Grow>
            <Divider />
        </React.Fragment>
    );
};

export default ChatImage;
