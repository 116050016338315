import { HISTORY_ACTIONS } from 'constant';
import { setCurrentLayout, setCurrentPage, swipeBasic, swipeLayout, swipePage } from '../common';
import { setStyles } from './setStyles';
import { setFooter, setHeader, setPageSettings, setWaterMark } from '../view';
import { deleteBasic, editBasic } from '../basic';
import { deleteLayout, editLayout } from '../layout';
import { deletePage, editPage } from '../page';
import { setApiOptions } from './setApiOptions';

const setHistoryRecord = (state, record) => {
    state.history = state.history.map(({ historyId, ...rest }) => {
        if (historyId === record.historyId) return record;
        else return { historyId, ...rest };
    });
};
export const redo = (state, { payload: { historyRecord } }) => {
    const { from, to } = historyRecord;
    const withOutHistroyRecord = true;
    const REDO_MAP = {
        [HISTORY_ACTIONS.ASSIGN_LAYOUT_TO_PAGE.label]: () => {
            editPage(state, { payload: { item: to.oldParent, withOutHistroyRecord } });
            editPage(state, { payload: { item: to.newParent, withOutHistroyRecord } });
        },
        [HISTORY_ACTIONS.ASSIGN_BASIC_TO_LAYOUT.label]: () => {
            editLayout(state, { payload: { item: to.oldParent, withOutHistroyRecord } });
            editLayout(state, { payload: { item: to.newParent, withOutHistroyRecord } });
        },

        // INSERT
        [HISTORY_ACTIONS.INSERT_BASIC.label]: () => {
            state.basic.push(to.basicItem);
            editLayout(state, { payload: { item: to.layoutItem, withOutHistroyRecord } });
        },
        [HISTORY_ACTIONS.INSERT_LAYOUT.label]: () => {
            state.layout.push(to.layoutItem);
            editLayout(state, { payload: { item: to.layoutItem, withOutHistroyRecord } });
            setCurrentLayout(state, { payload: to.layoutItem.id });
            editPage(state, { payload: { item: to.pageItem, withOutHistroyRecord } });
        },
        [HISTORY_ACTIONS.INSERT_BEFORE_BASIC.label]: () => {
            editLayout(state, { payload: { item: to.oldParent, withOutHistroyRecord } });
            editLayout(state, { payload: { item: to.newParent, withOutHistroyRecord } });
        },
        [HISTORY_ACTIONS.INSERT_AFTER_BASIC.label]: () => {
            editLayout(state, { payload: { item: to.oldParent, withOutHistroyRecord } });
            editLayout(state, { payload: { item: to.newParent, withOutHistroyRecord } });
        },
        [HISTORY_ACTIONS.INSERT_BEFORE_LAYOUT.label]: () => {
            editPage(state, { payload: { item: to.oldParent, withOutHistroyRecord } });
            editPage(state, { payload: { item: to.newParent, withOutHistroyRecord } });
        },
        [HISTORY_ACTIONS.INSERT_AFTER_LAYOUT.label]: () => {
            editPage(state, { payload: { item: to.oldParent, withOutHistroyRecord } });
            editPage(state, { payload: { item: to.newParent, withOutHistroyRecord } });
        },
        [HISTORY_ACTIONS.INSERT_PAGE.label]: () => state.page.push(to),

        // EDIT
        [HISTORY_ACTIONS.EDIT_BASIC.label]: () => editBasic(state, { payload: { item: to, withOutHistroyRecord } }),
        [HISTORY_ACTIONS.EDIT_LAYOUT.label]: () => editLayout(state, { payload: { item: to, withOutHistroyRecord } }),
        [HISTORY_ACTIONS.EDIT_STYLES.label]: () => setStyles(state, { payload: { ...to, withOutHistroyRecord } }),
        [HISTORY_ACTIONS.EDIT_API_OPTIONS.label]: () => setApiOptions(state, { payload: { ...to, withOutHistroyRecord } }),
        [HISTORY_ACTIONS.EDIT_HEADER.label]: () => setHeader(state, { payload: { ...to, withOutHistroyRecord } }),
        [HISTORY_ACTIONS.EDIT_FOOTER.label]: () => setFooter(state, { payload: { ...to, withOutHistroyRecord } }),
        [HISTORY_ACTIONS.EDIT_PAGE_SETTINGS.label]: () => setPageSettings(state, { payload: { ...to, withOutHistroyRecord } }),
        [HISTORY_ACTIONS.EDIT_WATERMARK.label]: () => setWaterMark(state, { payload: { ...to, withOutHistroyRecord } }),

        // DELETE
        [HISTORY_ACTIONS.DELETE_BASIC.label]: () => deleteBasic(state, { payload: { itemId: from.basicItem.id, withOutHistroyRecord } }),
        [HISTORY_ACTIONS.DELETE_LAYOUT.label]: () => deleteLayout(state, { payload: { itemId: from.layoutItem.id, withOutHistroyRecord } }),
        [HISTORY_ACTIONS.DELETE_PAGE.label]: () => deletePage(state, { payload: { itemId: from.id, withOutHistroyRecord } }),
        // DUPLICATE
        [HISTORY_ACTIONS.DUPLICATE_BASIC.label]: () => {
            setCurrentLayout(state, { payload: to.layoutItem.id });
            state.basic.push(to.basicItem);
            editLayout(state, { payload: { item: to.layoutItem, withOutHistroyRecord } });
        },
        [HISTORY_ACTIONS.DUPLICATE_LAYOUT.label]: () => {
            setCurrentPage(state, { payload: to.pageItem.id });
            state.layout.push(to.layoutItem);
            state.basic = [...state.basic, ...to.basicItems];
            editLayout(state, { payload: { item: to.layoutItem, withOutHistroyRecord } });
            setCurrentLayout(state, { payload: to.layoutItem.id });
            editPage(state, { payload: { item: to.pageItem, withOutHistroyRecord } });
        },

        [HISTORY_ACTIONS.BASIC_SWIPE.label]: () =>
            swipeBasic(state, {
                payload: {
                    ...to,
                    withOutHistroyRecord
                }
            }),
        [HISTORY_ACTIONS.LAYOUT_SWIPE.label]: () =>
            swipeLayout(state, {
                payload: {
                    ...to,
                    withOutHistroyRecord
                }
            }),
        [HISTORY_ACTIONS.PAGE_SWIPE.label]: () =>
            swipePage(state, {
                payload: {
                    ...to,
                    withOutHistroyRecord
                }
            })
    };
    if (state.historyId < state.history.length) {
        REDO_MAP[historyRecord?.action?.label]?.();
        state.historyId = state.historyId + 1;
    }
};
