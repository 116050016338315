import React, { useState } from 'react';

// MUI components
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// Pages
import Images from './Tabs/Images';
import Audios from './Tabs/Audios';
import Videos from './Tabs/Videos';
import Docs from './Tabs/Docs';

/* eslint-disable no-unused-vars */

let empty = (
    <Box
        sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flexWrap: 'wrap'
        }}
    >
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <img src="https://cdn-icons-png.flaticon.com/512/7486/7486747.png" style={{ width: '40%' }} />
            </Box>
            <Typography variant="h4" fontWeight="900" textAlign="center" color="text.secondary" sx={{ width: '100%' }}>
                Empty
            </Typography>
        </Box>
    </Box>
);
function TabPanel(props) {
    const { children, value, index, height, ...other } = props;

    return (
        <div
            role="tabpanel"
            style={{ height: height }}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ minHeight: '50vh', paddingBottom: '65px' }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

let ChatInfoTabs = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <React.Fragment>
            <Tabs value={value} onChange={handleChange}>
                <Tab label="Images" {...a11yProps(0)} />
                <Tab label="Videos" {...a11yProps(1)} />
                <Tab label="Audios" {...a11yProps(2)} />
                <Tab label="Docs" {...a11yProps(3)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Images />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Videos />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Audios />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Docs />
            </TabPanel>
        </React.Fragment>
    );
};

export default ChatInfoTabs;
