export const PatientScientificPageConstant = {
    scientific: 'Settings_Patient_Scientific.scientific',
    mainScientific: 'Settings_Patient_Scientific.mainscientific',
    pendingScientific: 'Settings_Patient_Scientific.pendingscientific',
    name: 'Settings_Patient_Scientific.name',
    member: 'Settings_Patient_Scientific.member',
    code: 'Settings_Patient_Scientific.code',
    icon: 'Settings_Patient_Scientific.icon',
    count: 'Settings_Patient_Scientific.count',
    description: 'Settings_Patient_Scientific.description',
    confirm: 'Settings_Patient_Scientific.confirm',
    replace: 'Settings_Patient_Scientific.replace',
    replaceScientific: 'Settings_Patient_Scientific.replacescientific',
    save: 'Settings_Patient_Scientific.save',
    cancel: 'Settings_Patient_Scientific.cancel',
    uniqueCode: 'Settings_Patient_Scientific.uniquecode',
    actions: 'Settings_Patient_Scientific.actions',
    edit: 'Settings_Patient_Scientific.edit',
    delete: 'Settings_Patient_Scientific.delete'
};
