import React from 'react';

// constants
import { MESSAGE_TYPE } from 'constant';

// MUI Components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
import StarIcon from '@mui/icons-material/Star';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneIcon from '@mui/icons-material/Done';

// third-party
import moment from 'moment';

// store
import { useSelector } from 'react-redux';

let Footer = ({ message, senderIsYou }) => {
    const stars = useSelector((state) => state.chat.stars);
    const STARRED = stars.find((star) => star.id === message.id) !== undefined;

    const NOT_SENT = message?.id?.includes('RANDOM_');
    const messageTypeStyle = () => {
        switch (message.type) {
            case MESSAGE_TYPE.TEXT:
                return {};
            case MESSAGE_TYPE.IMAGE:
                return {
                    padding: '5px 5px',
                    marginTop: '-9px'
                };
            case MESSAGE_TYPE.VIDEO:
                return {
                    padding: '5px 5px',
                    marginTop: '-9px'
                };
            case MESSAGE_TYPE.AUDIO:
                return {
                    padding: '0 5px 5px'
                };
            case MESSAGE_TYPE.FILE:
                return {
                    marginRight: '-5px'
                };
        }
    };

    return (
        <Box
            sx={{
                ...messageTypeStyle(),
                '& *': {
                    fontWeight: 'bold',
                    fontSize: 'smaller'
                },
                display: 'flex',
                justifyContent: 'space-between'
            }}
        >
            {senderIsYou ? (
                <React.Fragment>
                    <Typography
                        sx={{
                            fontSize: 'smaller',
                            fontWeight: 'bold',
                            flexGrow: 1,
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <Grow in={STARRED} timeout={400} unmountOnExit>
                            <StarIcon fontSize="smaller" sx={{ marginRight: '3px' }} />
                        </Grow>
                        {moment(message.date).format('LT')}
                        {NOT_SENT ? (
                            <AccessTimeIcon sx={{ marginLeft: '3px', fontSize: '13px' }} />
                        ) : (
                            <DoneIcon sx={{ marginLeft: '3px', fontSize: '13px' }} />
                        )}
                    </Typography>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Typography sx={{ fontSize: 'smaller', fontWeight: 'bold', flexGrow: 1, textAlign: 'left' }}>
                        {moment(message.date).format('LT')}
                        <Grow in={STARRED} timeout={400} unmountOnExit>
                            <StarIcon fontSize="smaller" sx={{ marginLeft: '3px' }} />
                        </Grow>
                    </Typography>
                </React.Fragment>
            )}
        </Box>
    );
};

export default Footer;
