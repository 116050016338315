import React, { useEffect } from 'react';

// MUI components
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import StopIcon from '@mui/icons-material/Stop';
import Circle from '@mui/icons-material/Circle';

// Custom hooks
import useSendMessage from 'layout/ChatLayout/hooks/useSendMessage';

// Custom functions
import FileUploadService from 'config/FileUploadService';
import { Notification } from 'helpers';

// Constants
import { STATIC_FILES_URL } from 'env/server';

let recordingStyle = {
    width: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '80px'
};

let Recording = ({ recording, stop, time, data, setIsUploading, setUploadedFile, setProgress }) => {
    let sendMessage = useSendMessage();

    const handleUpload = () => {
        setIsUploading(true);
        setUploadedFile({ name: 'voice' });

        Notification.fire({
            icon: 'info',
            title: 'Uploading File'
        });

        setProgress(0);
        let reader = new FileReader();
        reader.addEventListener('loadend', () => {
            let file = new File([reader.result], 'VoiceRecord.wav', [{ type: 'audio/wav' }]);
            FileUploadService.upload(file, localStorage.getItem('token'), (event) => {
                setProgress(Math.round((100 * event.loaded) / event.total));
            })
                .then((response) => {
                    Notification.fire({
                        title: response.data.message,
                        timer: 2000,
                        icon: 'success'
                    });
                    sendMessage({
                        text: null,
                        type: 3,
                        url: STATIC_FILES_URL + response.data.data,
                        replyMessageId: null,
                        forwardMessageId: null
                    });
                    setIsUploading(false);
                })
                .catch((e) => {
                    setProgress(0);
                    Notification.fire({
                        title: 'did not uploaded',
                        timer: 2000,
                        icon: 'error'
                    });
                    setIsUploading(false);
                });
        });
        reader.readAsArrayBuffer(data.blob);
    };

    // uploading Audio
    useEffect(() => {
        if (data.url) handleUpload(data);
    }, [data]);

    return (
        <Grow in={recording} unmountOnExit>
            <Box sx={recordingStyle}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Circle color="error" sx={{ marginRight: '5px' }} />
                    Recording
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {time.m}:{time.s}
                </Box>
                <IconButton
                    onClick={() => {
                        if (recording) {
                            stop();
                        }
                    }}
                >
                    <StopIcon />
                </IconButton>
            </Box>
        </Grow>
    );
};

export default Recording;
