import React, { useState } from 'react';
import PropTypes from 'prop-types';

// MUI components
import useTheme from '@mui/material/styles/useTheme';
import Grow from '@mui/material/Grow';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';

// Components
import NavItem from '../NavItem';

// MUI Icons
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';

// Custom hooks
import useSystemLanguages from 'hooks/systemLanguages/useSystemLanguages';

// Redux state hooks
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level, handleClose }) => {
    const theme = useSelector((state) => state.theme);

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    const getTranslation = useSystemLanguages();
    const handleClick = () => {
        setOpen(!open);
        setSelected(!selected ? menu.id : null);
    };

    // menu collapse & item
    const menus = menu.children?.map((item) => {
        switch (item.type) {
            case 'collapse':
                return <NavCollapse handleClose={handleClose} key={item.id} menu={item} level={level + 1} />;
            case 'item':
                return <NavItem handleClose={handleClose} key={item.id} item={item} level={level + 1} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icon = menu.icon;
    const menuIcon = menu.icon ? (
        <Icon strokeWidth={1.5} size="1.3rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selected === menu.id ? 8 : 6,
                height: selected === menu.id ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );
    const isCollapseChildrensSelected = window.location.pathname.split('/').filter((e) => e != '');
    const isSelectedAsMainUrl =
        window.location.pathname
            .split('/')
            .filter((e) => e != '')[0]
            ?.toLowerCase() === menu?.id?.toLowerCase();
    const isSelected = selected === menu.id || isSelectedAsMainUrl;
    return (
        <React.Fragment>
            <Grow in={true} timeout={menu.timeout}>
                <ListItemButton
                    sx={(theme) => ({
                        borderRadius: `${theme.borderRadius}px`,
                        mb: 0.5,
                        alignItems: 'flex-start',
                        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                        color: level > 1 ? '#fff' : '#56376e',
                        py: level > 1 ? 1 : 1.25,
                        pl: `${level * 24}px`,
                        '&:hover svg.icon': { color: `${theme.palette.primary.main} important` }
                    })}
                    selected={isSelected}
                    onClick={handleClick}
                >
                    <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography
                                variant={selected === menu.id ? 'h5' : 'body1'}
                                color="inherit"
                                sx={(theme) => ({
                                    my: 'auto',
                                    color: selected || isSelectedAsMainUrl ? `${theme.palette.primary.main} !important` : 'inherit'
                                })}
                            >
                                {menu.title}
                            </Typography>
                        }
                        secondary={
                            menu.caption && (
                                <Typography
                                    variant="caption"
                                    sx={(theme) => ({ ...theme.typography.subMenuCaption })}
                                    display="block"
                                    gutterBottom
                                >
                                    {menu.caption}
                                </Typography>
                            )
                        }
                    />
                    {open ? (
                        <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                    ) : (
                        <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                    )}
                </ListItemButton>
            </Grow>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    disablePadding
                    sx={(theme) => ({
                        position: 'relative',
                        '&:after': {
                            content: "''",
                            position: 'absolute',
                            left: '32px',
                            top: 0,
                            height: '100%',
                            width: '1px',
                            opacity: 1,
                            background: theme.palette.primary.main
                        }
                    })}
                >
                    {menus}
                </List>
            </Collapse>
        </React.Fragment>
    );
};

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number
};

export default NavCollapse;
