import { useRoutes } from 'react-router-dom';

// Routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import ChatRoutes from './ChatRoutes';

// ==============================|| ROUTING RENDER ||============================== //
export default function ThemeRoutes() {
    return useRoutes([MainRoutes, ChatRoutes, AuthenticationRoutes]);
}
