import { HISTORY_ACTIONS } from 'constant';
import { addHistoryRecord } from '../common/fileTemplatesHelpers';
import { getItemFromArray } from 'helpers';
import {
    addLayoutDescriptionTranslation,
    editLayoutDescriptionTranslation
} from '../functions/history/addOrEditLayoutDescriptionTranslation';

export const editLayout = (state, { payload }) => {
    // Get the existing item to update
    const itemIndex = state.layout.findIndex((item) => item.id === payload.item.id);
    const item = state.layout[itemIndex];
    const oldItem = { ...item };
    // Replace the existing item with the updated item in the array in state
    state.layout[itemIndex] = {
        ...item,
        ...payload.item
    };

    // if not is Edit we need to To Make sure that the item History Record is Not Insert
    const isEdit = state.fileTemplatesMode?.includes('Edit');
    const action = isEdit ? HISTORY_ACTIONS.EDIT_LAYOUT : HISTORY_ACTIONS.INSERT_LAYOUT;
    const description = isEdit
        ? editLayoutDescriptionTranslation(`${payload.item.type}`)
        : addLayoutDescriptionTranslation(`${payload.item.type}`);
    const from = isEdit ? oldItem : null;
    const to = isEdit
        ? state.layout[itemIndex]
        : {
              layoutItem: state.layout[itemIndex],
              pageItem: getItemFromArray({ array: state.page, id: state.currentPageId })
          };
    // Add a history record for the update
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action,
            description,
            from,
            to
        });
};
