import React, { lazy } from 'react';

// Components
import Loadable from 'ui-component/Loadable';

// Decision
const AllDecision = Loadable(lazy(() => import('views/Decisions/AllDecisions')));
const AddDecision = Loadable(lazy(() => import('views/Decisions/CreateDecision')));
const EditDecision = Loadable(lazy(() => import('views/Decisions/EditDecision')));
const Decision = Loadable(lazy(() => import('views/Decisions/Decision')));

// Task

const Tasks = Loadable(lazy(() => import('views/Tasks')));

// Goal
const AllGoal = Loadable(lazy(() => import('views/Goals/AllGoal')));
const AddGoal = Loadable(lazy(() => import('views/Goals/AddGoal')));
const EditGoal = Loadable(lazy(() => import('views/Goals/EditGoal')));
// Accounts
const AllAccounts = Loadable(lazy(() => import('views/Accounts')));
const MemberAccounts = Loadable(lazy(() => import('views/MemberAccounts')));
const routes = [
    {
        path: '/decisions',
        element: <AllDecision />
    },
    {
        path: '/decision/add',
        element: <AddDecision />
    },
    {
        path: '/decisions/edit/:centerid/:id',
        element: <EditDecision />
    },
    {
        path: '/decisions/:centerid/decision/:id',
        element: <Decision />
    },
    {
        path: '/tasks/add',
        element: <Tasks />
    },
    {
        path: '/goals',
        element: <AllGoal />
    },
    {
        path: '/goals/add',
        element: <AddGoal />
    },
    {
        path: '/goals/edit/:centerid/:id',
        element: <EditGoal />
    },
    {
        path: '/accounts',
        element: <AllAccounts />
    },
    {
        path: '/member-accounts',
        element: <MemberAccounts />
    }
];
export default routes;
