import { CONDITION_TYPES } from 'constant';

export let conditionTrue = ({ condition, value }) => {
    if (!condition) return;
    let conditionValue = condition.effectValue;
    if (Number.isInteger(value)) {
        value = Number(value);
        conditionValue = Number(conditionValue);
    }
    switch (condition.conditionType) {
        case CONDITION_TYPES.EQUALS:
            if (conditionValue === value) return true;
            break;
        case CONDITION_TYPES.NOT_EQUALS:
            if (conditionValue !== value) return true;
            break;
        case CONDITION_TYPES.LESSER:
            if (conditionValue > value) return true;
            break;
        case CONDITION_TYPES.LESSER_OR_EQUAL:
            if (conditionValue >= value) return true;
            break;
        case CONDITION_TYPES.GREATER:
            if (conditionValue < value) return true;
            break;
        case CONDITION_TYPES.GREATER_OR_EQUAL:
            if (conditionValue <= value) return true;
            break;
        default:
            return false;
    }
    return false;
};

export const evalSingleCondition = ({ textFields, conditions, getFieldProps }) => {
    const value = getFieldProps(textFields.find(({ id }) => id === conditions?.textFieldId)?.fields.name).value;
    return conditionTrue({
        condition: conditions,
        value
    });
};
export const evalConditions = ({ textFields, conditions, getFieldProps }) => {
    if (!conditions) return false;
    if (conditions.isGroup === false) return evalSingleCondition({ conditions, textFields, getFieldProps });
    if (conditions.isGroup) {
        let result = false;
        if (conditions.relation === 'or')
            conditions.conditions.forEach((c) => {
                if (evalConditions({ conditions: c, textFields, getFieldProps })) result = true;
            });
        else if (conditions.relation === 'and') {
            result = true;
            conditions.conditions.forEach((c) => {
                if (!evalConditions({ conditions: c, textFields, getFieldProps })) result = false;
            });
        }
        return result;
    }
};
