import React from 'react';

// MUI components
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';

const Notification = ({ notifications }) => {
    return (
        <Grow in={notifications !== 0} timeout={400} unmountOnExit>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '22px',
                    height: '22px',
                    background: '#36b50b',
                    borderRadius: '50%',
                    color: '#FFF'
                }}
            >
                <Box>{notifications}</Box>
            </Box>
        </Grow>
    );
};

export default Notification;
