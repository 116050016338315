import React from 'react';
import { Link, useLocation } from 'react-router-dom';

// MUI components
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// MUI icons
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import Icons from './Icons';

// Hooks
import useTheme from '@mui/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UrlKeys } from 'constant/Global/urlKeys';
import { CentersMangmentPageConstant } from 'constant/Translations/system-administrator/centers-mangment-page';
import { BranchesMangmentPageConstant } from 'constant/Translations/system-administrator/branch-management-page';
import { SectionsManagementPageConstant } from 'constant/Translations/system-administrator/sections-management-page';
import { jobTitlesPageConstant } from 'constant/Translations/hr/job-titles-page';
import { MembersPageConstant } from 'constant/Translations/hr/members-page';
import { LanguagesPageConstant } from 'constant/Translations/languages-page';
import { TranslationsPageConstant } from 'constant/Translations/translations-page';
import { FileTemplatesPageConstant } from 'constant/Translations/file-templates/file-templates-page';
import { ConstantPageConstant } from 'constant/Translations/settings/file-templates/constant-page';
import { DataSourcePageConstant } from 'constant/Translations/settings/file-templates/data-source-page';
import { DataSourceRequestsPageConstant } from 'constant/Translations/settings/file-templates/data-source-requests-page';
import { MemberCategoriesPageConstant } from 'constant/Translations/settings/member-categories/member-categories-page';
import { CountryPageConstant } from 'constant/Translations/settings/address/country-page';
import { StatePageConstant } from 'constant/Translations/settings/address/state-page';
import { CityPageConstant } from 'constant/Translations/settings/address/city-page';
import { SocialMediaPageConstant } from 'constant/Translations/settings/profile/social-media-page';
import { ReligionPageConstant } from 'constant/Translations/settings/profile/religion-page';
import { DoctrinePageConstant } from 'constant/Translations/settings/profile/doctrine-page';
import { MaritalStatusPageConstant } from 'constant/Translations/settings/profile/marital-status-page';
import { NicknamePageConstant } from 'constant/Translations/settings/profile/nickname-page';
import { AcademyPageConstant } from 'constant/Translations/settings/profile/academy-page';
import { PriorityPageConstant } from 'constant/Translations/settings/accessories/priority/indes';
import { HistoryPageConstant } from 'constant/Translations/history/history-page';
import { MyAcoountsPageConstant } from 'constant/Translations/profile/my-acoounts-page';

const WRAPPER_SX = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 10px',
    borderRadius: (theme) => theme.shape.borderRadius,
    boxShadow: 2,
    backgroundColor: (theme) => theme.palette.primary.main,
    '& *': {
        color: '#FFFFFF !important'
    }
};

const Header = () => {
    // hooks
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const path = useLocation();
    const { t } = useTranslation();
    const { i18n } = useTranslation('admin');
    const { languages } = useSelector((state) => state.static);

    // data
    const selectedLanguage = languages?.find((language) => language.key == i18n.language);
    const isRtl = selectedLanguage?.direction == 'rtl';
    const paths = path.pathname.split('/').filter((e) => e != '');
    const links = paths.map((path, i, array) => {
        if (path === 'add' || path == 'edit') return { url: `${array[i - 1]}/${path}`, label: path };
        return { url: path, label: path };
    });
    // add home page
    links.unshift({
        url: '',
        label: 'home',
        icon: <HouseRoundedIcon style={{ margin: isRtl ? '0 0 0 .5rem' : '0 .5rem 0 0', fontSize: '1.5rem' }} />
    });

    const convertUrlToKeysTranslation = {
        [UrlKeys.centers]: t(CentersMangmentPageConstant.centers),
        [UrlKeys.branches]: t(BranchesMangmentPageConstant.branches),
        [UrlKeys.sections]: t(SectionsManagementPageConstant.sections),
        [UrlKeys.jobTitles]: t(jobTitlesPageConstant.jobTitles),
        [UrlKeys.members]: t(MembersPageConstant.members),
        [UrlKeys.languages]: t(LanguagesPageConstant.languages),
        [UrlKeys.translations]: t(TranslationsPageConstant.translations),
        [UrlKeys.fileTemplate]: t(FileTemplatesPageConstant.fileTemplate),
        [UrlKeys.constants]: t(ConstantPageConstant.constants),
        [UrlKeys.dataSources]: t(DataSourcePageConstant.dataSources),
        [UrlKeys.dataSourcesRequests]: t(DataSourceRequestsPageConstant.dataSourceRequests),
        [UrlKeys.memberCategories]: t(MemberCategoriesPageConstant.memberCategories),
        [UrlKeys.countries]: t(CountryPageConstant.countries),
        [UrlKeys.states]: t(StatePageConstant.states),
        [UrlKeys.cities]: t(CityPageConstant.cities),
        [UrlKeys.socialMedias]: t(SocialMediaPageConstant.socialMedias),
        [UrlKeys.religions]: t(ReligionPageConstant.religions),
        [UrlKeys.doctrines]: t(DoctrinePageConstant.doctrines),
        [UrlKeys.maritalStatuses]: t(MaritalStatusPageConstant.maritalStatuses),
        [UrlKeys.nicknames]: t(NicknamePageConstant.nicknames),
        [UrlKeys.academics]: t(AcademyPageConstant.academics),
        [UrlKeys.priorities]: t(PriorityPageConstant.priorities),
        [UrlKeys.history]: t(HistoryPageConstant.history),
        [UrlKeys.myProfile]: t(MyAcoountsPageConstant.myProfile)
    };

    return (
        <Box sx={WRAPPER_SX}>
            <Breadcrumbs
                separator={isRtl ? <KeyboardArrowLeftRoundedIcon fontSize="small" /> : <KeyboardArrowRightRoundedIcon fontSize="small" />}
                aria-label="breadcrumb"
                sx={{
                    textAlign: 'center',
                    width: '100%',
                    '& *': { fontSize: matchUpMd ? '1.1rem' : '.8rem' }
                }}
            >
                {links.map((path, i, array) => {
                    if (i === 0)
                        return (
                            <Link
                                key={i}
                                to={path.url}
                                style={{
                                    textDecoration: 'none',
                                    textTransform: 'capitalize',
                                    fontWeight: 'bold'
                                }}
                            >
                                <Typography
                                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    variant="h4"
                                    color="text.primary"
                                >
                                    {path.icon}
                                    {convertUrlToKeysTranslation[path.label] ?? path.label}
                                </Typography>
                            </Link>
                        );
                    if (i === array.length - 1)
                        return (
                            <Typography
                                key={i}
                                to={path.url}
                                style={{
                                    textDecoration: 'none',
                                    textTransform: 'capitalize',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontWeight: 'bold'
                                }}
                                color="text.primary"
                            >
                                {convertUrlToKeysTranslation[path.label] ?? path.label?.replaceAll('-', ' ')}
                            </Typography>
                        );
                    return (
                        <Link
                            key={i}
                            to={path.url}
                            style={{
                                textDecoration: 'none',
                                textTransform: 'capitalize',
                                fontWeight: 'bold'
                            }}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {convertUrlToKeysTranslation[path.label] ?? path.label?.replaceAll('-', ' ')}
                            </Box>
                        </Link>
                    );
                })}
            </Breadcrumbs>
            <Icons />
        </Box>
    );
};

export default React.memo(Header);
