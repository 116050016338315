import { IconBuilding } from '@tabler/icons';

function toNested(data, pid = null) {
    return data?.reduce((r, e) => {
        if (pid == e?.sectionId && e.id !== e?.sectionId) {
            const object = { ...e, type: 'item', url: '/come-soon', icon: IconBuilding, title: e.name.value, isSection: true };
            const children = toNested(data, e.id);

            if (children.length) {
                object.type = 'collapse';
                object.children = children;
            }

            r.push(object);
        }

        return r;
    }, []);
}

const groupingSections = (sections) => {
    const groupingSections = sections?.map((section) => {
        if (section.children) {
            return {
                ...section,
                id: section.id,
                type: 'group',
                caption: section.description.value
            };
        }
        return section;
    });
    return groupingSections;
};
const combinFormToSectionForm = (sections, cominedSections) => {
    const newFormSections = cominedSections?.map((section) => {
        const _section = sections.find((s) => s.id == section?.sectionId);
        return {
            name: section.combineName,
            description: {},
            sectionId: _section?.sectionId,
            id: section?.sectionId + section?.withSectionId
        };
    });
    return newFormSections;
};
const sectionIfComined = (section, cominedSections) => {
    const isCombined = cominedSections.find(
        (cominedSection) => cominedSection?.sectionId == section.id || cominedSection.withSectionId == section.id
    );
    return Boolean(isCombined);
};
const removeSectionIfComined = (sections, cominedSections) => {
    const filtredSections = sections.filter((section) => !sectionIfComined(section, cominedSections));
    return filtredSections;
};

export const sectionMapper = (centerSections, centerCombinSections) => {
    let byGroupinngSections = [];
    if (centerSections && centerCombinSections) {
        const sectionWithOutCombinedSections = removeSectionIfComined(centerSections, centerCombinSections);
        const combinedSections = combinFormToSectionForm(centerSections, centerCombinSections);
        byGroupinngSections = groupingSections(toNested([...sectionWithOutCombinedSections, ...combinedSections]));
    }

    return byGroupinngSections;
};
