export const getFileData = (message) => {
    let getSize = (size) => {
        if (size.length < 7) return `${(Number(size) / 1024).toFixed(4)}KB`;
        return `${(Number(size) / 1024 / 1000).toFixed(4)}MB`;
    };
    if (message.text) {
        let index = message.text.indexOf('THIS_IS_SEPREATOR');
        if (index === -1) return { name: message.text.slice(0, index), size: '' };
        let size = getSize(message.text.slice(index + 'THIS_IS_SEPREATOR'.length, message.text.length));
        return {
            name: message.text.slice(0, index),
            size: size
        };
    } else return { name: 'File', size: '' };
};
