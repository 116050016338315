import { HISTORY_ACTIONS } from 'constant';
import { TablehistoryDescriptionTranslation } from 'constant/FileTemplate/tablehistoryDescriptionTranslation';
import { addHistoryRecord, getItemFromArray } from 'helpers';
/**
 * Deletes multiple rows from a SpreadSheet table in the state and adds a history record for the deletion.
 * @param {object} state - The state object.
 * @param {object} payload - An object containing an array of indexes of the rows to delete and the ID of the SpreadSheet table.
 */
export const deleteMultipleSpreadSheetRows = (state, { payload }) => {
    const { indexes, tableId } = payload;

    // Find the SpreadSheet table to update
    let table = getItemFromArray({ array: state.basic, id: tableId });
    const oldTable = { ...table };
    // Remove the rows from the table
    table.rows = table.rows.filter((row, index) => {
        for (let i = 0; i < indexes.length; i++) {
            if (index === indexes[i]) {
                return false;
            }
        }
        return true;
    });

    // Update the IDs of the remaining rows
    table.rows.forEach((row, i) => {
        row.id = i;
    });

    // Add a history record for the deletion
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.EDIT_BASIC,
            description: TablehistoryDescriptionTranslation.DeletedMultipleRowsInSpreadSheetTable,
            from: oldTable,
            to: table
        });
};
