export const jobTitlesPageConstant = {
    jobTitles: 'Human_Resources_Job_Title.jobtitles',
    jobTitle: 'Human_Resources_Job_Title.jobtitle',
    title: 'Human_Resources_Job_Title.title',
    parentName: 'Human_Resources_Job_Title.parentname',
    code: 'Human_Resources_Job_Title.code',
    section: 'Human_Resources_Job_Title.section',
    members: 'Human_Resources_Job_Title.members',
    relatedCategories: 'Human_Resources_Job_Title.relatedcategories',
    description: 'Human_Resources_Job_Title.description',
    shape: 'Human_Resources_Job_Title.shape',
    isActive: 'Human_Resources_Job_Title.isactive',
    treeList: 'Human_Resources_Job_Title.treelist',
    info: 'Human_Resources_Job_Title.info',
    jobTitleParent: 'Human_Resources_Job_Title.jobtitleparent',
    uploadIcon: 'Human_Resources_Job_Title.uploadicon',
    actions: 'Human_Resources_Job_Title.actions',
    edit: 'Human_Resources_Job_Title.edit',
    delete: 'Human_Resources_Job_Title.delete',
    viewMemberCategories: 'Human_Resources_Job_Title.viewmembercategories',
    memberCategories: 'Human_Resources_Job_Title.membercategories',
    membersCount: 'Human_Resources_Job_Title.memberscount',
    memberJobTitles: 'Human_Resources_Job_Title.memberjobtitles',
    sectionsJobTitles: 'Human_Resources_Job_Title.sectionsjobtitles',
    jobTitlesCount: 'Human_Resources_Job_Title.jobtitlescount',
    activeMembersCount: 'Human_Resources_Job_Title.activememberscount',
    unactiveMembersCount: 'Human_Resources_Job_Title.unactivememberscount',
    treeViewer: 'Human_Resources_Job_Title.treeviewer',
    treeViewerAdd: 'Human_Resources_Job_Title.treevieweradd',
    treeViewerDelete: 'Human_Resources_Job_Title.treeviewerdelete',
    treeViewerEdit: 'Human_Resources_Job_Title.treevieweredit',
    treeViewerActions: 'Human_Resources_Job_Title.treevieweractions',
    category: 'Human_Resources_Job_Title.category',
    isSectionActive: 'Human_Resources_Job_Title.issectionactive',
    search: 'Human_Resources_Job_Title.search',
    selectLanguage: 'Human_Resources_Job_Title.selectlanguage',
    reset: 'Human_Resources_Job_Title.reset',
    tasks: 'Human_Resources_Job_Title.tasks'
};
