import React, { lazy } from 'react';

// Component
import Loadable from 'ui-component/Loadable';

const Languages = Loadable(lazy(() => import('views/languages')));
const SystemLanguages = Loadable(lazy(() => import('views/SystemLanguages')));
const routes = [
    {
        path: 'languages',
        element: <Languages />
    },
    {
        path: 'translations',
        element: <SystemLanguages />
    }
];
export default routes;
