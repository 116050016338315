import { HISTORY_ACTIONS } from 'constant';
import { addHistoryRecord } from '../common/fileTemplatesHelpers';
import { addBasicDescriptionTranslation, editBasicDescriptionTranslation } from '../functions/history/addOrEditBasicDescriptionTranslation';

export const editPage = (state, { payload }) => {
    // Get the existing item to update
    const itemIndex = state.page.findIndex((item) => item.id === payload.item.id);
    const item = state.page[itemIndex];
    const oldItem = { ...item };
    // Replace the existing item with the updated item in the array in state
    state.page[itemIndex] = {
        ...item,
        ...payload.item
    };

    // if not is Edit we need to To Make sure that the item History Record is Not Insert
    const isEdit = state.fileTemplatesMode?.includes('Edit');
    const action = isEdit ? HISTORY_ACTIONS.EDIT_BASIC : HISTORY_ACTIONS.INSERT_BASIC;
    const description = isEdit
        ? editBasicDescriptionTranslation(`${payload.item.type}`)
        : addBasicDescriptionTranslation(`${payload.item.type}`);
    const from = isEdit ? oldItem : null;
    const to = isEdit
        ? state.page[itemIndex]
        : {
              pageItem: state.page[itemIndex]
          };
    // Add a history record for the update
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action,
            description,
            from,
            to
        });
};
