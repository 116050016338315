export const cellWithoutFilters = {
    enableColumnFilterModes: false,
    columnFilterModeOptions: null,
    enableFilterMatchHighlighting: false,
    enableFilters: false,
    enableColumnFilter: false,
    enableSorting: false,
    enableColumnOrdering: false,
    enableColumnDragging: false
};
