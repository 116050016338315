let pinMessage = (state, { payload: { messageId, chatId } }) => {
    if (state.currentChat !== null && state.currentChat.id === chatId) {
        let message = state.currentChat.messages.find((message) => message.id === messageId);
        let pinned = state.currentChat.pins.find((message) => message.id === messageId);
        if (!pinned) state.currentChat.pins.push(message);
    }
    let chat = state.chats.find((chat) => chat.id == chatId);
    let message = chat.messages.find((message) => message.id === messageId);
    let pinned = chat.pins.find((message) => message.id === messageId);
    if (!pinned) {
        chat.pins.push(message);
    }
};

export default pinMessage;
