let setChatMessages = (state, { payload }) => {
    state.chats.forEach((chat) => {
        if (chat.id === state.currentChat.id) {
            chat.messages = payload.messages;
            chat.pins = payload.pins;
        }
    });
    state.currentChat.messages = payload.messages;
    state.currentChat.pins = payload.pins;
};

export default setChatMessages;
