import { DEVELOP_OR_PRODUCTION, ENUMS, SWAGGER_JSON } from 'env/server';
import { getAllKeys, getComponentDto } from 'helpers';
import useFetchData from '../query/useFetchData';

export const useSwaggerJson = () => {
    const { data: swaggerData, isFetching: swaggerIsFetching } = useFetchData('Swagger', SWAGGER_JSON,  {
        enabled: DEVELOP_OR_PRODUCTION.DEVELOP
    });
    const { data: enumsData, isFetching: enumsIsFetching } = useFetchData('enums', ENUMS);
    if (swaggerIsFetching && enumsIsFetching) return { apis: [], isFetching: enumsIsFetching && swaggerIsFetching };
    localStorage.setItem('swaggerData', JSON.stringify(swaggerData?.data));
    localStorage.setItem('enumsData', JSON.stringify(enumsData?.data?.data));
    return { isFetching: false };
};
export const useGetSwaggerFromStorage = (filterMethod, isGet) => {
    try {
        const swaggerJson = JSON.parse(localStorage.getItem('swaggerData'));
        const enums = JSON.parse(localStorage.getItem('enumsData'));
        const SOURCES = [];
        for (const key in swaggerJson?.paths) if (swaggerJson?.paths[key]?.[filterMethod]) SOURCES.push(key);
        return {
            apis: SOURCES.map((source) => {
                const SCEHMA = isGet
                    ? swaggerJson?.paths[source]?.[filterMethod]?.responses['200'].content?.['application/json']?.schema
                    : swaggerJson?.paths[source]?.[filterMethod]?.requestBody?.content?.['application/json']?.schema;
                const path = SCEHMA?.items?.['$ref'] ?? SCEHMA?.['$ref'];
                const value = {
                    source,
                    keys: getAllKeys({ object: getComponentDto(path, swaggerJson)?.properties, fatherKey: null, swaggerJson, enums })
                };
                return { key: source, value };
            })
        };
    } catch (e) {
        return {
            apis: []
        };
    }
};

export const useSwaggerUpdate = () => useGetSwaggerFromStorage('put');
export const useSwaggerAdd = () => useGetSwaggerFromStorage('post');

export const useSwaggerGet = () => {
    const { apis, ...rest } = useGetSwaggerFromStorage('get', true);
    return {
        apis: apis.map((api) => {
            const newEndpoint = api?.key.replaceAll('/{id}', '').replaceAll('/{memberId}', '').replaceAll('/{centerId}', '');
            const newApi = {
                key: newEndpoint,
                value: { ...api?.value, source: newEndpoint }
            };
            return newApi;
        }),
        ...rest
    };
};
export const useSwaggerDelete = () => {
    const { apis, ...rest } = useGetSwaggerFromStorage('delete', true);
    return {
        apis: apis.map((api) => {
            const newEndpoint = api?.key.replaceAll('/{id}', '').replaceAll('/{memberId}', '').replaceAll('/{centerId}', '');
            const newApi = {
                key: newEndpoint,
                value: { ...api?.value, source: newEndpoint }
            };
            return newApi;
        }),
        ...rest
    };
};
