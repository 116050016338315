import React from 'react';

// MUI components
import ImageList from '@mui/material/ImageList';
import Box from '@mui/material/Box';

// Components
import Image from '../../Components/Image';
import ImageSkeleton from './ImageSkeleton';
import LoadMore from '../../Components/LoadMore';

// Hooks
import useGetMedia from 'layout/ChatLayout/hooks/useGetMedia';

// Constants
import { MESSAGE_TYPE } from 'constant';

let Images = () => {
    let { isFetching, isError, media, disabled, setPage, page } = useGetMedia(MESSAGE_TYPE.IMAGE);
    if (isError) return 'error';
    return (
        <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', position: 'relative' }}>
            <Box sx={{ width: '100%' }}>
                <ImageList
                    cols={3}
                    rowHeight={164}
                    sx={{
                        margin: 0,
                        '& li': {
                            margin: '0 !important'
                        }
                    }}
                >
                    {isFetching
                        ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, i) => <ImageSkeleton key={i} />)
                        : media.map((img, i) => <Image src={img.url} i={i} key={i} timeout={(i + 8) * 100} />)}
                </ImageList>
            </Box>
            <LoadMore {...{ isFetching, disabled, setPage, page }} />
        </Box>
    );
};

export default Images;
