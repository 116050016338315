/* eslint-disable no-unused-vars */
import * as React from 'react';

// MUI Components
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';

// constants
import { INITIAL_MENU } from 'constant';

// hooks
import useDeleteMessage from 'views/chat/hooks/useDeleteMessage';

let ContextDelete = ({ message, setMenu, menu }) => {
    let deleteMessage = useDeleteMessage();
    const handleDelete = () => {
        setMenu(INITIAL_MENU);
        deleteMessage([message]);
    };

    return (
        <Grow unmountOnExit in={menu.mouseX !== null} timeout={1600}>
            <MenuItem onClick={handleDelete}>
                <ListItemIcon>
                    <DeleteIcon fontSize="small" color="error" />
                </ListItemIcon>
                <Typography color="error">Delete</Typography>
            </MenuItem>
        </Grow>
    );
};

export default ContextDelete;
