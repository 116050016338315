import { deleteLayout } from './deleteLayout';

/**
 * Deletes multiple layout items from the layout array in the state object.
 * @param {object} state - The state object.
 * @param {array} payload - An array containing the IDs of the layout items to delete.
 */
export const deleteMultipleLayout = (state, { payload }) => {
    // Loop through the payload array and call the deleteLayout function for each item
    payload?.items?.forEach((item) => {
        deleteLayout(state, { payload: { itemId: item.id, withOutHistroyRecord: payload.withOutHistroyRecord } });
    });
    state.selectedLayout = [];
};
