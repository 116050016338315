import * as Yup from 'yup';

export function LanagugesArrayToValidationSchema(languages) {
    let rv = {};
    if (languages) {
        for (let i = 0; i < languages.length; ++i) rv[`${languages[i].key}`] = Yup.string().required(`required`);
    }

    return rv;
}
export function LnagugesArrayToInitialValues(languages) {
    let rv = {};
    if (languages) {
        for (let i = 0; i < languages.length; ++i) rv[`${languages[i].key}`] = '';
    }
    return rv;
}
export const objectHasValueRequired = () => {
    return Yup.object().shape({
        value: Yup.string().required('required')
    });
};
