import { useQuery } from 'react-query';
import { authAxios } from 'config/axios';
import { GET_CHAT_MEDIA } from 'env/server';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setImages } from 'features/store';

let useGetChatImages = () => {
    let dispatch = useDispatch();
    let images = useSelector((state) => state.chat.images);
    let query = useQuery(
        'getChatImges',
        () => {
            return authAxios.get(GET_CHAT_MEDIA(localStorage.getItem('currentChatId'), 1));
        },
        {
            enabled: images.length !== 0 ? images[0].chatId !== localStorage.getItem('currentChatId') : true,
            onSuccess: (data) => {
                dispatch(setImages(data.data.data));
            }
        }
    );

    return { query, images };
};

export default useGetChatImages;
