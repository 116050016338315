import { HISTORY_ACTIONS } from 'constant';
import { addHistoryRecord } from 'helpers';

export const setFooter = (state, { payload }) => {
    const oldFooter = state.view.footer;
    state.view.footer = payload;

    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.EDIT_FOOTER,
            description: HISTORY_ACTIONS.EDIT_FOOTER.description,
            from: oldFooter,
            to: payload
        });
};
