import { toggleSideBar } from 'features/store';
import { useDispatch, useSelector } from 'react-redux';

const useSidebar = () => {
    const open = useSelector((state) => state.theme.sidebarOpen);
    const dispatch = useDispatch();
    const handleToggle = () => dispatch(toggleSideBar());

    return {
        open,
        handleToggle
    };
};
export default useSidebar;
