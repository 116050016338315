import * as React from 'react';

// MUI components
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Components
import Settings from './Settings';
import NewGroup from './NewGroup';

// Redux
import { useDispatch } from 'react-redux';
import { toggle } from 'features/store';
import { successLogOut } from 'features/Auth/authSlice';

// Constants
import { TOGGLE } from 'constant';

const More = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch();

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(successLogOut());
    };

    const handleToggleSettings = () => dispatch(toggle({ type: TOGGLE.SETTINGS }));
    const handleToggleStarredMessages = () => dispatch(toggle({ type: TOGGLE.STARS }));
    return (
        <Box>
            <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                sx={{
                    zIndex: 1000000000,
                    '.MuiPaper-root': {
                        overflow: 'visible',
                        background: 'filter',
                        width: '165px',
                        borderRadius: '10px',
                        padding: '5px',
                        '& ul': {
                            padding: 0
                        },
                        '& li': {
                            padding: '5px'
                        }
                    }
                }}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            >
                <NewGroup />
                <MenuItem onClick={handleToggleStarredMessages}>Stars</MenuItem>
                <MenuItem onClick={handleToggleSettings}>Settings</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            <Settings />
        </Box>
    );
};

export default More;
