import React, { lazy } from 'react';

// Components
import Loadable from 'ui-component/Loadable';

const ReceptionDepartments = Loadable(lazy(() => import('views/reception-management/reception/reception-departments')));
const MedicalVisits = Loadable(lazy(() => import('views/reception-management/reception/medical-visits')));
const MedicalVisitType = Loadable(lazy(() => import('views/reception-management/reception/medical-visit-type')));
const MedicalVisitDescription = Loadable(lazy(() => import('views/reception-management/reception/medical-visit-description')));
const AppointmentType = Loadable(lazy(() => import('views/reception-management/reception/appointment-type')));
const ReservationType = Loadable(lazy(() => import('views/reception-management/reception/reservation-type')));

const routes = [
    {
        path: '/reception-departments',
        element: <ReceptionDepartments />
    },
    {
        path: '/medical-visits',
        element: <MedicalVisits />
    },
    {
        path: '/medical-visit-description',
        element: <MedicalVisitDescription />
    },
    {
        path: '/medical-visit-type',
        element: <MedicalVisitType />
    },
    {
        path: '/appointment-type',
        element: <AppointmentType />
    }
    // ,{
    //     path: '/reservation-type',
    //     element: <ReservationType />
    // }
];
export default routes;
