import React from 'react';

// MUI components
import ImageListItem from '@mui/material/ImageListItem';
import Grow from '@mui/material/Grow';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setPhotoIndex, toggle } from 'features/store';

// Constant
import { TOGGLE } from 'constant';

let Image = ({ src, timeout, i }) => {
    let chatInfoOpen = useSelector((state) => state.chat.chatInfoOpen);

    let dispatch = useDispatch();
    let handleOpenImageViewer = () => {
        dispatch(setPhotoIndex(i));
        dispatch(toggle({ type: TOGGLE.IMAGE_VIEWER }));
    };

    return (
        <ImageListItem>
            <Grow in={chatInfoOpen} onClick={handleOpenImageViewer} timeout={timeout} unmountOnExit>
                <img src={src} style={{ width: '100%', height: '100%' }} loading="lazy" />
            </Grow>
        </ImageListItem>
    );
};

export default Image;
