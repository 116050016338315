import { HISTORY_ACTIONS } from 'constant';
import { addHistoryRecord } from 'helpers';

export const setPageSettings = (state, { payload }) => {
    const oldPageSettings = state.view.pageSettings;
    state.view.pageSettings = payload;

    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.EDIT_PAGE_SETTINGS,
            description: HISTORY_ACTIONS.EDIT_PAGE_SETTINGS.description,
            from: oldPageSettings,
            to: payload
        });
};
