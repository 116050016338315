import React from 'react';

// MUI components
import Grow from '@mui/material/Grow';
import ButtonBase from '@mui/material/ButtonBase';

// MUI icons
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

// UI components
import ChatItem from 'ui-component/ChatItem';

// NPM packages
import moment from 'moment';

// Helpers
import { downloadFile, getFileData } from 'helpers';

// Redux
import { useSelector } from 'react-redux';

let Doc = ({ doc, timeout }) => {
    let chatInfoOpen = useSelector((state) => state.chat.chatInfoOpen);
    let { name, size } = getFileData(doc);
    return (
        <Grow direction="up" in={chatInfoOpen} timeout={timeout} unmountOnExit>
            <ButtonBase sx={{ width: '100%' }} onClick={() => downloadFile(doc.url, name)}>
                <ChatItem
                    icon={
                        <InsertDriveFileIcon
                            color="info"
                            sx={{
                                backgroundColor: '#EEE',
                                borderRadius: '50%',
                                padding: '8px',
                                fontSize: '40px'
                            }}
                        />
                    }
                    title={name}
                    desc={size}
                    topRight={moment(doc.date).format('MMM DD')}
                />
            </ButtonBase>
        </Grow>
    );
};

export default Doc;
