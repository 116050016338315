import { FORMIK_CONTROL_TYPES, COLORS } from 'constant/Global';
import {
    AUTOCOMPLETE_TYPE,
    CHECKBOX_TYPE,
    DATE_TYPE,
    NUMERIC_TYPE,
    TEXT_TYPE,
    TIME_TYPE
} from 'views/FileTemplates/components/Components/BasicComponent/SpreadSheet/TableComponent/cellTypes';
import { DEFAULT_TEXT_SETTINGS } from './textSettings';
import { arrayToArrayWithKeyAndValue } from 'helpers';

export const BASIC_TYPES = {
    TEXTFIELD: 'TextField',
    TEXT: 'Text',
    OPTION: 'Option',
    TABLE: 'Table',
    SPREADSHEET: 'Spreadsheet',
    DATAGRID: 'Datagrid',
    COL: 'COL',
    TREE: 'Tree',
    RESET_BUTTON: 'ResetButton',
    COL_TYPES: {
        TEXT: TEXT_TYPE,
        NUMBER: NUMERIC_TYPE,
        CHECKBOX: CHECKBOX_TYPE,
        DATE: DATE_TYPE,
        TIME: TIME_TYPE,
        AUTOCOMPLETE: AUTOCOMPLETE_TYPE,
        MULTI_SELECT: 'multiselect',
        DATETIME: 'Date & Time'
    },
    DATAGRID_COL_TYPES: {
        NUMBER: 'number',
        TEXT: 'text',
        SELECT: 'select',
        DATE: 'date',
        DATETIME: 'datetime-local',
        TIME: 'time',
        CHECKBOX: 'checkbox'
    },
    ROW: 'Row',
    CHART: 'Chart',
    CHARTS: {
        LINE: 'line',
        AREA: 'area',
        BAR: 'bar',
        DONUT: 'donut',
        RADAR: 'radar'
        /*         RADIALBAR: 'radialBar'
         */
    },
    TIMELINE: 'Timeline',
    SUBMIT_BUTTON: 'Submit-Button'
};
export const DEFAULT_TEXT = {
    text: '',
    type: BASIC_TYPES.TEXT
};
export const DEFAULT_ROW = {
    type: BASIC_TYPES.ROW,
    id: 0
};
export const DEFAULT_COL = {
    type: BASIC_TYPES.COL,
    id: 0
};
export const DEFAULT_SPREADSHEET = {
    type: BASIC_TYPES.SPREADSHEET,
    rows: [DEFAULT_ROW],
    cols: [DEFAULT_COL],
    settings: {
        colHeaders: true,
        rowHeaders: true,
        enablePagination: false,
        rowsPerPage: 5
    }
};
export const DEFAULT_DATAGRID = {
    type: BASIC_TYPES.DATAGRID,
    rows: [],
    cols: [],
    dataSource: {
        enable: false,
        get: null,
        delete: {
            enable: false,
            endpoint: null
        },
        enableUpdate: false,
        enableAdd: false,
        isAdvanced: false,
        dialogTitle: '',
        basic: {
            fullScreen: false,
            form: null,
            add: {
                enable: false,
                endpoint: null
            },
            update: {
                enable: false,
                endpoint: null
            }
        },
        advanced: {
            add: {
                enable: false,
                form: '',
                fullScreen: false,
                withoutActions: false,
                withoutTitle: false
            },
            update: {
                enable: false,
                form: '',
                fullScreen: false,
                withoutActions: false,
                withoutTitle: false
            }
        }
    }
};
export const DEFAULT_CHECKBOX = {
    text: '',
    type: BASIC_TYPES.CHECKBOX
};
export const DEFAULT_RADIO_GROUP = {
    type: BASIC_TYPES.RADIO_GROUP,
    options: [],
    text: ''
};
export const DEFAULT_TIMELINE = {
    type: BASIC_TYPES.TIMELINE,
    items: [],
    position: 'right',
    layout: '2-columns',
    lineColor: 'rgb(33, 150, 243)',
    dataSource: {
        enable: false,
        get: null,
        date: '',
        items: [],
        backgroundColor: '#FFF',
        iconColor: '#FFF'
    }
};

export const DEFAULT_TIMELINE_DATASOURCE_ITEM = {
    arrayCols: [],
    type: '',
    accessorKey: {},
    header: ''
};
export const DEFAULT_CHART = {
    type: BASIC_TYPES.CHART,
    chartType: BASIC_TYPES.CHARTS.LINE,
    tableId: '',
    data: '',
    labels: []
};
export const DEFAULT_DATAGRID_COL = {
    conditions: [],
    arrayCols: [],
    type: '',
    size: 100,
    cellSettings: DEFAULT_TEXT_SETTINGS,
    dataSource: null,
    header: ''
};
export const DEFAULT_TEXTFIELD = {
    type: BASIC_TYPES.TEXTFIELD,
    fields: {
        onlyPrinted: false,
        name: '',
        treeName: 'fields',
        variant: 'text',
        control: FORMIK_CONTROL_TYPES.TEXT,
        label: {
            value: '',
            thesaurus: {
                en: ''
            }
        },
        dataSource: {
            key: null,
            id: null
        },
        fields: [],
        itemName: {
            value: '',
            thesaurus: {}
        },
        hintText: {
            value: '',
            thesaurus: {}
        },
        filter: { isfilteredAccordingToOtherField: false, filteringField: '{}' },
        print: {
            enableLabel: true,
            defaultLabel: true,
            label: '',
            valueStyle: DEFAULT_TEXT_SETTINGS,
            format: null
        },
        valdation: {
            isRequired: true,
            isHidden: false,
            minTime: null,
            maxTime: null,
            minDate: null,
            maxDate: null,
            minDateTime: null,
            maxDateTime: null,
            min: null,
            max: null,
            minLength: null,
            maxLength: null
        }
    }
};
export const DEFAULT_TREE = {
    type: BASIC_TYPES.TREE,
    children: {
        name: 'Tree',
        children: []
    },
    pathFunc: 'diagonal',
    orientation: 'vertical',
    styles: {
        rootColor: '#333',
        branchColor: COLORS.MAIN,
        leafColor: COLORS.SECONDARY
    },
    nodeSize: {
        x: 100,
        y: 100
    },
    separation: {
        siblings: 2,
        nonSiblings: 2
    },
    circleRadius: 50,
    dataSource: {
        enable: false,
        get: null,
        width: 100,
        backgroundColor: '#333',
        renderedValues: []
    }
};
export const DEFAULT_SUBMIT_BUTTON = {
    text: {
        value: 'Submit',
        thesaurus: {
            en: 'Submit'
        }
    },
    type: BASIC_TYPES.SUBMIT_BUTTON,
    variant: 'contained',
    color: 'success',
    fullWidth: true
};

export const DEFAULT_RESET_BUTTON = {
    text: {
        value: 'Reset',
        thesaurus: {
            en: 'Reset'
        }
    },
    type: BASIC_TYPES.RESET_BUTTON,
    variant: 'contained',
    color: 'success',
    fullWidth: true
};
export const ORIENTATIONS = ['horizontal', 'vertical'];
export const PATH_FUNCS = ['diagonal', 'elbow', 'straight'];
export const TREE_SHAPES = {
    TRINGALE: 'triangle',
    PENTAGON: 'pentagon',
    HEXAGON: 'hexagon',
    OCTAGON: 'octagon',
    RHOMBUS: 'rhombus',
    PARALLELOGRAM: 'parallelogram',
    CIRCLE: 'circle'
};
export const RESPOSIVE_OPTIONS = arrayToArrayWithKeyAndValue(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']);
