export const OperationsDepartmentsPageConstant = {
    operationsDepartments: 'Medical_Management_Operations_Departments.operationsdepartments',
    name: 'Medical_Management_Operations_Departments.name',
    icon: 'Medical_Management_Operations_Departments.icon',
    image: 'Medical_Management_Operations_Departments.image',
    description: 'Medical_Management_Operations_Departments.description',
    title: 'Medical_Management_Operations_Departments.title',
    parentName: 'Medical_Management_Operations_Departments.parentname',
    code: 'Medical_Management_Operations_Departments.code',
    shape: 'Medical_Management_Operations_Departments.shape',
    tasks: 'Medical_Management_Operations_Departments.tasks',
    isActive: 'Medical_Management_Operations_Departments.isactive',
    treeList: 'Medical_Management_Operations_Departments.treelist',
    info: 'Medical_Management_Operations_Departments.info',
    operationsDepartmentParent: 'Medical_Management_Clinical_Departments.operationsdepartmentparent',
    uploadIcon: 'Medical_Management_Operations_Departments.uploadicon',
    edit: 'Medical_Management_Operations_Departments.edit',
    delete: 'Medical_Management_Operations_Departments.delete',
    treeViewer: 'Medical_Management_Operations_Departments.treeviewer',
    treeViewerAdd: 'Medical_Management_Operations_Departments.treevieweradd',
    treeViewerDelete: 'Medical_Management_Operations_Departments.treeviewerdelete',
    treeViewerEdit: 'Medical_Management_Operations_Departments.treevieweredit',
    treeViewerActions: 'Medical_Management_Operations_Departments.treevieweractions',
    isSectionActive: 'Medical_Management_Operations_Departments.issectionactive',
    actions: 'Medical_Management_Operations_Departments.actions',
    search: 'Medical_Management_Operations_Departments.search',
    selectLanguage: 'Medical_Management_Operations_Departments.selectlanguage',
    reset: 'Medical_Management_Operations_Departments.reset'
};
