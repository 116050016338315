import React from 'react';
import Button from '@mui/material/Button';
import { ErrorBoundary } from 'react-error-boundary';

function Fallback({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre style={{ color: 'red' }}>{error.message}</pre>
            <Button variant="contained" color="error" onClick={resetErrorBoundary}>
                reset
            </Button>
        </div>
    );
}

const logError = (error, info) => {
    // Do something with the error, e.g. log to an external API
};

const HCErrorBoundary = (props) => (
    <ErrorBoundary FallbackComponent={Fallback} onError={logError} onReset={(detalis) => console.log(detalis)} {...props} />
);

export default HCErrorBoundary;
