import { HISTORY_ACTIONS } from 'constant';
import { addHistoryRecord, generateRandomPageId } from 'helpers';
import { setCurrentPage } from '../common';

/**
 * Adds a new page to the page array in the state object, with a randomly generated ID.
 *
 * @param {Object} state - The current state object.
 * @param {Object} payload.item - The properties of the new page to add.
 */
export const addPage = (state, { payload }) => {
    // Generate a new ID for the page
    const id = generateRandomPageId();
    const item = { ...payload.item };
    // Add the ID to the payload.item and push the new page to the page array in state
    item.id = id;
    state.page.push(item);

    // Set the current page ID to the new page's ID
    setCurrentPage(state, { payload: id });
    // Add a history record for the addition of the new page
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.INSERT_PAGE,
            description: HISTORY_ACTIONS.INSERT_PAGE.description, // We add 1 to the ID to make it human-readable
            from: null,
            to: item
        });
};
