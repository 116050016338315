import React, { useEffect } from 'react';

// hooks
import { useSelector } from 'react-redux';
import { useDataContext } from 'context';

// I18N
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

function I18n({ children }) {
    // hooks
    const { activeLanguage } = useSelector((state) => state.static);
    const { languages, systemLanguages, pages, getDefaultLanguagesList, defaultLanguagesList } = useDataContext();
    const token = useSelector((state) => state.auth.token);

    // Functions
    const getTranslation = ({ lang, page }) => {
        let thisPageKeys = [];
        if (token) thisPageKeys = systemLanguages?.filter(({ pageKey }) => Number(pageKey) === Number(page));
        else thisPageKeys = getDefaultLanguagesList?.filter(({ pageKey }) => Number(pageKey) === Number(page));
        let translation = {};
        thisPageKeys.forEach((t) => {
            translation[t?.key] =
                t.values?.length > 0
                    ? t.values
                          ?.map((item) => {
                              if (item.thesaurus?.thesaurus?.[lang]) return item.thesaurus?.thesaurus?.[lang];
                              return;
                          })
                          .find((item) => item)
                    : t?.key;
        });
        return translation;
    };

    // data
    let resources = {};
    pages.map((pageKey) => {
        if (token)
            languages.forEach((lang) => {
                resources[lang?.key] = {
                    ...resources[lang?.key],
                    translation: {
                        ...resources[lang?.key]?.translation,
                        [pageKey.name.value]: getTranslation({
                            lang: lang?.key,
                            page: pageKey.numericValue
                        })
                    }
                };
            });
        else
            defaultLanguagesList.forEach((lang) => {
                resources[lang?.key] = {
                    ...resources[lang?.key],
                    translation: {
                        ...resources[lang?.key]?.translation,
                        [pageKey.name.value]: getTranslation({
                            lang: lang?.key,
                            page: pageKey.numericValue
                        })
                    }
                };
            });
    });

    // init
    useEffect(() => {
        i18next.init({
            interpolation: { escapeValue: false },
            lng: activeLanguage || 'en',
            resources
        });
    });

    return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>;
}

export default I18n;
