import { isString } from 'lodash';

// Error
export * from './Errors/getErrorMassage';
// fromTo
export * from './FromTo';
// get
export * from './Get';
// roles
export * from './roles';
// sections
export * from './sections/sectionsMapper';
// Tables
export * from './Tables';
// ui
export * from './Ui';

// other
export * from './Other';

export * from './Validation';

export * from '../features/FileTemplate/common/fileTemplatesHelpers';
export const safeJsonParse = (jsonToParse) => (isString(jsonToParse) ? JSON.parse(jsonToParse) : null);
