import React from 'react';

// MUI components
import Grow from '@mui/material/Grow';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Phone from '@mui/icons-material/Phone';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';

// Components
import UserAvatar from 'views/chat/Components/UserAvatar';
import GroupAvatar from 'views/chat/Components/GroupAvatar';
import ChatItem from 'ui-component/ChatItem';

let Call = ({ call, timeout }) => {
    let callType = () => {
        if (call.type === 'missed') return <PhoneMissedIcon color="error" fontSize="small" />;
        else if (call.type === 'notAnswerd') return <CallMadeIcon color="error" fontSize="small" />;
        else if (call.type === 'answerd') return <CallMadeIcon color="success" fontSize="small" />;
        else if (call.type === 'recived') return <CallReceivedIcon color="success" fontSize="small" />;
    };
    return (
        <Grow direction="up" in={true} timeout={timeout} unmountOnExit>
            <Box sx={{ position: 'relative' }}>
                <ChatItem
                    icon={
                        call.caller.type === 'person' ? (
                            <UserAvatar status={call.status} img={call.caller.img} />
                        ) : (
                            <GroupAvatar img={call.caller.img} />
                        )
                    }
                    title={call.caller.name}
                    desc={
                        <Box component="span" display="flex">
                            <Box component="span" marginRight="5px">
                                {callType()}
                            </Box>{' '}
                            {call.date}
                        </Box>
                    }
                    topRight={
                        <IconButton sx={{ position: 'absolute', right: '10px', top: '10px' }}>
                            <Phone />
                        </IconButton>
                    }
                />
                <Divider />
            </Box>
        </Grow>
    );
};

export default Call;
