import React from 'react';

// hooks
import { useSelector } from 'react-redux';
import { useSwaggerJson } from 'hooks/swaggerApi';

import { Outlet, Navigate } from 'react-router-dom';

// MUI components
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

// Project components
import BreadCrumbs from './Header';
import Header from './Header';
import Sidebar from './Sidebar';
import LoaderCircularProgress from 'ui-component/LoaderCircularProgress';

// Constants
import { DRAWER_WIDTH } from 'constant';
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...{
        overflowY: 'auto',
        marginRight: 0,
        backgroundColor: theme?.palette?.background?.paper,
        height: '100vh'
    },
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            padding: '10px 10px 0 0'
        },
        [theme.breakpoints.down('md')]: {
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            padding: '10px'
        },
        [theme.breakpoints.down('sm')]: {
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            padding: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        [theme.breakpoints.up('md')]: {
            padding: '10px 10px 0 0'
        },
        [theme.breakpoints.down('md')]: {
            padding: '10px'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '10px'
        }
    })
}));

const StyledBox = styled('div')(() => ({
    boxShadow: 2
}));

// ==============================|| COMPONENTS ||============================== //

const WrappedHeader = () => (
    <StyledBox>
        <Header />
    </StyledBox>
);
const WrappedBreadcrumbs = () => (
    <StyledBox>
        <BreadCrumbs />
    </StyledBox>
);
const WrappedHeaderMemo = React.memo(WrappedHeader);
const WrappedBreadcrumbsMemo = React.memo(WrappedBreadcrumbs);

// ==============================|| MAIN LAYOUT ||============================== //

const RenderMainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const token = useSelector((state) => state.auth.token);

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.theme.sidebarOpen);

    return !token ? (
        <Navigate to="/login" />
    ) : (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Sidebar />
            <Main theme={theme} open={leftDrawerOpened}>
                {matchDownMd && <WrappedHeaderMemo />}
                {!matchDownMd && <WrappedBreadcrumbsMemo />}
                <Outlet />
            </Main>
        </Box>
    );
};
const MainLayout = () => {
    const { isFetching } = useSwaggerJson();
    return (
        <>
            {isFetching && <LoaderCircularProgress />} <RenderMainLayout />
        </>
    );
};
export default React.memo(MainLayout);
