import { FORMIK_CONTROL_TYPES } from 'constant';
import { get, isObject, isString, set } from 'lodash';
import { getActiveValue } from './getActiveValue';

const TRANSLATE_OBJECT = '#/components/schemas/TranslateObject';
const NUMBERING_PATTERN_OPTION_DTO = '#/components/schemas/NumberingPatternOptionDto';
const TIME_SPAN = '#/components/schemas/TimeSpan';

/*
 * A recursive function that takes an object and returns an array of its keys.
 * The function can also handle nested objects with "$ref" properties.
 * The "swaggerJson" parameter is an object that contains the entire Swagger API specification.
 */

export function getComponentDto(PATH, swaggerJson) {
    try {
        if (PATH) {
            const CUT_PATH = PATH?.slice(2, PATH?.length)?.replaceAll('/', '.');
            const KEYS = get(swaggerJson, CUT_PATH);
            if (Boolean(KEYS?.properties?.totalItems)) {
                return {
                    type: 'object',
                    properties: getComponentDto(KEYS?.properties?.data?.items?.$ref, swaggerJson)?.properties,
                    additionalProperties: false
                };
            }

            return KEYS;
        } else return {};
    } catch (e) {
        console.log(e);
    }
}
export function getAllKeys({ object, fatherKey = null, swaggerJson, fatherRefs = [], enums }) {
    // Initialize an empty array to store the keys
    let keys = [];

    try {
        // Loop through the object's own properties
        for (let key in object) {
            // Check if the current property is a "$ref" property for a nested object
            const currentKey = Boolean(fatherKey) ? `${fatherKey}.${key}` : key;

            const nestedObjectRef = object[key]['$ref'];
            if (
                isObject(object[key]) &&
                Boolean(nestedObjectRef) &&
                key !== '$ref' &&
                object[key] !== '$ref' &&
                nestedObjectRef !== TRANSLATE_OBJECT &&
                nestedObjectRef !== NUMBERING_PATTERN_OPTION_DTO &&
                nestedObjectRef !== TIME_SPAN &&
                fatherRefs.find((ref) => ref === nestedObjectRef) === undefined
            ) {
                // Get the nested object definition from the Swagger specification
                const nestedObject = getComponentDto(nestedObjectRef, swaggerJson);
                // If the nested object is an "object" type, recursively call this function to get its keys
                if (nestedObject?.enum) {
                    const options = enums?.find((e) => Boolean(e[nestedObjectRef.slice(21) + 'Dto']) === true)?.[
                        nestedObjectRef.slice(21) + 'Dto'
                    ];
                    keys = keys.concat({
                        key: currentKey,
                        type: FORMIK_CONTROL_TYPES.SELECT,
                        id: options?.dataSource?.id,
                        isEnum: true
                    });
                }
                if (nestedObject?.type === 'object') {
                    keys = keys.concat(
                        ...getAllKeys({
                            object: nestedObject?.properties,
                            fatherKey: currentKey,
                            swaggerJson,
                            fatherRefs: [...fatherRefs, nestedObjectRef]
                        })
                    );
                }
            } else if (key !== 'type' && key !== 'id' && key !== 'nullable' && key !== 'readOnly' && key !== 'required') {
                // If the current property is not a "$ref" property, add its key to the array of keys
                const getArrayItems = () => {
                    if (fatherRefs[fatherRefs.length - 1] === object[key]?.items['$ref']) {
                        return nestedObjectRef;
                    } else {
                        return getAllKeys({
                            object: object[key],
                            enums,
                            swaggerJson,
                            fatherRefs: [...fatherRefs, nestedObjectRef]
                        });
                    }
                };
                if (object[key]?.type === 'array')
                    keys.push({
                        key,
                        type: FORMIK_CONTROL_TYPES.ARRAY,
                        items: getArrayItems()
                    });
                else if (nestedObjectRef === TRANSLATE_OBJECT) keys.push({ key: currentKey, type: FORMIK_CONTROL_TYPES.MULTI_TEXT });
                else if (nestedObjectRef === NUMBERING_PATTERN_OPTION_DTO)
                    keys.push({ key: currentKey, type: FORMIK_CONTROL_TYPES.NUMBRING_PATTERN });
                else if (nestedObjectRef === TIME_SPAN) keys.push({ key: currentKey, type: FORMIK_CONTROL_TYPES.TIME, format: 'time' });
                else if (isString(object[key])) keys.push({ key: currentKey, type: FORMIK_CONTROL_TYPES.TEXT });
                else keys.push({ key: currentKey, ...object[key] });
            }
        }
    } catch (error) {
        console.log(error);
    }
    // Return the array of keys
    return keys;
}

// Define a recursive function to flatten a nested object
export const processSwaggerObject = (obj) => {
    // Initialize an empty object to store the result
    let result = {};
    // Loop through the keys of the input object
    for (let key of Object.keys(obj ?? {})) {
        // Check if the value of the current key is an object
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
            // If yes, recursively call the flatten function on the value and assign it to the result object
            if (obj[key]?.thesaurus) set(result, key, getActiveValue(obj[key]));
            else set(result, key, processSwaggerObject(obj[key]));
        } else {
            if (Array.isArray(obj[key])) set(result, key, obj[key] || []);
            else set(result, key, obj[key]);
        }
    }
    // Return the result object
    return result;
};
