export const HistoryPageConstant = {
    history: 'History.history',
    chooseItem: 'History.chooseitem',
    type: 'History.type',
    filter: 'History.filter',
    close: 'History.close',
    confirm: 'History.confirm',
    table: 'History.table',
    timeline: 'History.timeline',
    viewHistory: 'History.viewhistory',
    classification: 'History.classification',
    view: 'History.view',
    reason: 'History.reason',
    assginedFrom: 'History.assginedfrom',
    assginedTo: 'History.assginedto',
    dateCreated: 'History.datecreated',
    dateUpdated: 'History.dateupdated',
    card: 'History.card',
    noHistoryToView: 'History.nohistorytoview',
    memberCategoryHistory: 'History.membercategoryhistory',
    sectionsHistroy: 'History.sectionshistroy'
};
