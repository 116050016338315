import Avatar from '@mui/material/Avatar';
import React from 'react';
let SenderAvatar = ({ lastSenderIsSame }) => {
    return (
        <Avatar
            src={'http://emilcarlsson.se/assets/mikeross.png'}
            sx={{
                margin: '4px',
                borderRadius: '50%',
                borderRadius: '50%',
                visibility: !lastSenderIsSame ? 'visible' : 'hidden'
            }}
        />
    );
};

export default SenderAvatar;
