import React, { useState } from 'react';

// MUI components
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// Components
import MessageContent from 'views/chat/Components/Messages/Message/MessageContent';

// Hooks
import usePinMessage from 'layout/ChatLayout/hooks/usePinMessage';

// UI components
import AnimatedDialog from 'ui-component/AnimatedDialog';

// Constants
import { DIALOGS } from 'constant';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { toggleDialog } from 'features/store';

const PinDialog = () => {
    const currentChat = useSelector((state) => state.chat.currentChat);
    const name = DIALOGS.PIN;
    const message = useSelector((state) => state.chat.dialogs[name]?.data);
    const pins = useSelector((state) => state.chat.currentChat?.pins);
    const pinned = pins?.find((pin) => pin?.id === message?.id);
    const [checked, setChecked] = useState(true);
    const pinMessage = usePinMessage({ justForUser: pinned ? true : checked, messageId: message?.id, pinned });
    const dispatch = useDispatch();
    const handlePin = () => {
        dispatch(toggleDialog({ name, data: message }));
        pinMessage({ messagesIds: message?.id, justForUser: checked });
    };
    if (!currentChat) return '';
    return (
        <React.Fragment>
            <AnimatedDialog
                {...{
                    title: 'Pin Message',
                    contents: !pinned
                        ? [
                              <Box
                                  sx={{
                                      translate: localStorage.getItem('accountId') === message?.accountId ? '-48px' : 0,
                                      border: localStorage.getItem('accountId') === message?.accountId ? 'none' : '1px solid #777',
                                      borderRadius: '20px'
                                  }}
                              >
                                  <MessageContent message={message} lastSenderIsSame={true} />
                              </Box>,
                              <FormControlLabel
                                  label="Just For You ?"
                                  control={<Checkbox value="" checked={checked} onChange={() => setChecked(!checked)} color="primary" />}
                              />
                          ]
                        : [
                              <Box
                                  sx={{
                                      translate: localStorage.getItem('accountId') === message?.accountId ? '-48px' : 0,
                                      border: localStorage.getItem('accountId') === message?.accountId ? 'none' : '1px solid #777',
                                      borderRadius: '20px'
                                  }}
                              >
                                  <MessageContent message={message} lastSenderIsSame={true} />
                              </Box>
                          ],
                    actions: (
                        <React.Fragment>
                            <Button onClick={() => dispatch(toggleDialog({ name, data: message }))} color="primary">
                                cancel
                            </Button>
                            <Button onClick={handlePin} color="primary" variant="contained">
                                {pinned ? 'Un Pin' : 'Pin'}
                            </Button>
                        </React.Fragment>
                    ),
                    name
                }}
            />
        </React.Fragment>
    );
};

export default PinDialog;
