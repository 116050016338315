import { DEFAULT_COL, FT_ICONS, HISTORY_ACTIONS } from 'constant';
import { TablehistoryDescriptionTranslation } from 'constant/FileTemplate/tablehistoryDescriptionTranslation';
import { addHistoryRecord, getItemFromArray } from 'helpers';

/**
 * Adds multiple cols to a spreadsheet table in the state.
 * @param {object} state - The current state of the application.
 * @param {object} payload - An object containing the properties number, fromRight, and tableId.
 * @param {number} payload.number - The number of cols to add.
 * @param {boolean} payload.fromRight - Whether to add the cols to the top (true) or bottom (false) of the table.
 * @param {string} payload.tableId - The ID of the table to add the cols to.
 */
export const addMultipleSpreadSheetCols = (state, { payload }) => {
    const { number, fromRight, tableId } = payload;

    // Retrieve the table object with the given tableId from the basic array in the state using the getItemFromArray function
    let table = getItemFromArray({ array: state.basic, id: tableId });
    const oldTable = { ...table };
    // Create an empty array called cols
    const cols = [];
    // Loop number times and push a copy of the DEFAULT_COL object to the cols array each time
    for (let i = 0; i < number; i++) cols.push(DEFAULT_COL);
    // If fromRight is true, prepend the cols array to the table.cols array using the spread operator, and then map over the resulting array to add an id property to each row object
    if (fromRight) table.cols = [...cols, ...table.cols].map((row, i) => ({ ...row, id: i }));
    // If fromRight is false, append the cols array to the table.cols array using the spread operator, and then map over the resulting array to add an id property to each row object
    else {
        table.cols = [...table.cols, ...cols].map((row, i) => ({ ...row, id: i }));
        // pushing the Values Of the Rows
        table.rows = table.rows.map((row) => {
            let newRow = { ...row };
            for (let i = table.cols.length - 1; i >= 0; i--) newRow[i] = newRow[i - number];
            return newRow;
        });
    }

    // Call the addHistoryRecord function with an object that contains state and a message object with an icon and a message indicating that number cols were added to the table with ID tableId
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.EDIT_BASIC,
            description: TablehistoryDescriptionTranslation.AddedColsToTable,
            from: oldTable,
            to: table
        });
};
