/* eslint-disable no-unused-vars */
import * as React from 'react';

// MUI Components
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grow from '@mui/material/Grow';
import ReplyIcon from '@mui/icons-material/Reply';

// constants
import { INITIAL_MENU, DIALOGS } from 'constant';

// store
import { toggleDialog } from 'features/store';
import { useDispatch } from 'react-redux';

let ContextForward = ({ message, menu, setMenu }) => {
    const dispatch = useDispatch();
    const handleClickOpen = () => {
        dispatch(toggleDialog({ data: [message], name: DIALOGS.FORWARD }));
        setMenu(INITIAL_MENU);
    };

    return (
        <React.Fragment>
            <Grow unmountOnExit in={menu.mouseX !== null} timeout={1000}>
                <MenuItem onClick={handleClickOpen} sx={{ zIndex: 1000000000 }}>
                    <ListItemIcon>
                        <ReplyIcon fontSize="small" sx={{ transform: 'rotateY(180deg)' }} />
                    </ListItemIcon>
                    <ListItemText>Forward</ListItemText>
                </MenuItem>
            </Grow>
        </React.Fragment>
    );
};

export default ContextForward;
