export const COMPONENT_TYPES = {
    BASIC: 'basic',
    LAYOUT: 'layout',
    PAGE: 'page',
    VIEW: 'view'
};

export const COMPONENT_ARRAY_NAMES = {
    BASIC_COMPONENT: 'basic',
    LAYOUT_COMPONENT: 'layout',
    PAGE_COMPONENT: 'page'
};
