import React from 'react';
import Grow from '@mui/material/Grow';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

let MessageSkeleton = ({ senderIsYou, width, lastSenderIsSame, lines }) => {
    let skeletonColor = senderIsYou ? '#fff' : '#435f7a';
    return (
        <Grow in={true} timeout={500} unmountOnExit>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: senderIsYou ? 'flex-start' : 'flex-end',
                    marginBottom: '2px',
                }}
            >
                <Grid
                    container
                    sx={{
                        fontSize: '0.9em',
                        width: 'auto !important'
                    }}
                >
                    <Grid item order={{ xs: senderIsYou ? 1 : 2 }}>
                        <Skeleton
                            variant="circular"
                            sx={{
                                marginRight: senderIsYou ? '5px' : 0,
                                marginLeft: senderIsYou ? 0 : '5px',
                                width: '40px',
                                bgcolor: '#301b42',
                                height: '40px',
                                visibility: lastSenderIsSame ? 'hidden' : 'visible'
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        order={{ xs: senderIsYou ? 2 : 1 }}
                        sx={{
                            padding: 2,
                            background: senderIsYou ? '#435f7a' : '#fff',
                            color: senderIsYou ? '#f5f5f5' : '#333',
                            display: ' inline-block',
                            padding: '10px 15px',
                            borderRadius: '20px',
                            lineHeight: ' 130%',
                            wordBreak: 'break-word'
                        }}
                    >
                        <Box sx={{ margin: '4px 0', overflowY: 'hidden' }}>
                            {!lastSenderIsSame && (
                                <Skeleton sx={{ bgcolor: skeletonColor, width: '50px', float: senderIsYou ? 'left' : 'right' }} />
                            )}
                            <div style={{ clear: 'both' }}></div>
                            {lines.map((line, i) => (
                                <Skeleton sx={{ bgcolor: skeletonColor, width: width }} key={i} />
                            ))}
                            <Skeleton sx={{ bgcolor: skeletonColor, width: '30px', float: senderIsYou ? 'right' : 'left' }} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Grow>
    );
};

export default MessageSkeleton;
