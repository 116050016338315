import { HISTORY_ACTIONS } from 'constant';
import { TablehistoryDescriptionTranslation } from 'constant/FileTemplate/tablehistoryDescriptionTranslation';
import { addHistoryRecord, getItemFromArray } from 'helpers';
/**
 * Deletes a row from a DataGrid table in the state and adds a history record for the deletion.
 * @param {object} state - The state object.
 * @param {object} payload - An object containing the index of the row to delete and the ID of the DataGrid table.
 */
export const deleteDataGridRow = (state, { payload }) => {
    const { id, tableId } = payload;

    // Find the DataGrid table to update
    let table = getItemFromArray({ array: state.basic, id: tableId });
    const oldTable = { ...table };
    // Remove the row from the table
    table.rows = table.rows.filter((row, i) => id !== row.id);

    // Update the IDs of the remaining rows
    table.rows.forEach((row, i) => {
        row.id = i;
    });

    // Add a history record for the deletion
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.EDIT_BASIC,
            description: TablehistoryDescriptionTranslation.DeletedRowInDataGrid,
            from: oldTable,
            to: table
        });
};
