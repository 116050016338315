import { getActiveValue } from 'helpers';

export const toTree = ({ data = [], parentId = 'parentId', key = 'name', defaultValue = null }) => {
    try {
        const tree = [[]];
        const levels = [];

        data.forEach((item) => {
            if (!item[parentId]) tree[0].push({ name: getActiveValue(item[key]), id: item?.id });
        });

        for (let i = 0; true; i++) {
            if (!Array.isArray(tree[i + 1])) tree[i + 1] = [];
            data.forEach((item) =>
                tree[i].forEach((parent) => {
                    if (item?.[parentId] === parent?.id)
                        tree[i + 1].push({ name: getActiveValue(item[key]), id: item?.id, parentId: item?.[parentId] });
                })
            );
            if (tree[i + 1].length === 0) break;
        }

        tree.forEach(() => levels.push(''));
        let arr = [defaultValue];

        if (defaultValue) {
            while (data.find((value) => value.id === defaultValue)) {
                const parent = data.find((value) => value.id === defaultValue)?.[parentId];
                if (parent) arr.push(parent);
                defaultValue = parent;
            }
        }
        arr.reverse();
        tree.pop();
        return { tree, levels: levels.map((value, i) => arr[i] || '') };
    } catch (e) {
        console.log(e);
    }
};
