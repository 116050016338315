import React, { lazy } from 'react';

// Components
import Loadable from 'ui-component/Loadable';

const Accounts = Loadable(lazy(() => import('views/Accounts')));
const MyProfile = Loadable(lazy(() => import('views/MyProfile')));
const routes = [
    {
        path: '/accounts',
        element: <Accounts />
    },
    {
        path: '/my-profile',
        element: <MyProfile />
    }
];
export default routes;
