import React from 'react';

// MUI components
import Grow from '@mui/material/Grow';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import Zoom from '@mui/material/Zoom';

// constants
import { ANIMATE_TYPES } from 'constant';

// Use an object to map the type to the corresponding component
const typeToComponent = {
    [ANIMATE_TYPES.COLLAPSE]: Collapse,
    [ANIMATE_TYPES.FADE]: Fade,
    [ANIMATE_TYPES.GROW]: Grow,
    [ANIMATE_TYPES.SLIDE]: Slide,
    [ANIMATE_TYPES.ZOOM]: Zoom
};

export const Type = ({ type = ANIMATE_TYPES.GROW, ...rest }) => {
    // Use the object to get the component by type
    const Component = typeToComponent[type] || Grow;
    return <Component {...rest} />;
};

const AnimatedElements = ({
    open,
    children,
    type,
    timeout = 400,
    withoutWrapper,
    boxSx,
    startWith,
    elementsWithoutWrapper,
    reverse = false
}) => {
    // Use React.Children.map to iterate over children and handle edge cases
    return React.Children.map(children, (e, i) => {
        const normalDuration = timeout * (i + (startWith ? startWith : 1));
        const totalDuration = timeout * React.Children.count(children);
        const reversedDuration = totalDuration - normalDuration;
        const duration = reverse ? reversedDuration : normalDuration;
        return withoutWrapper || elementsWithoutWrapper?.find((e) => i === e) ? (
            <Type type={type} in={open !== undefined ? open : true} unmountOnExit timeout={duration} key={i}>
                {e}
            </Type>
        ) : (
            <Type type={type} in={open !== undefined ? open : true} unmountOnExit timeout={duration} key={i}>
                <Box sx={boxSx}>{e}</Box>
            </Type>
        );
    });
};

export default AnimatedElements;
