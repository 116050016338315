import React, { lazy } from 'react';

// Components
import Loadable from 'ui-component/Loadable';

const ComingSoon = Loadable(lazy(() => import('views/coming-soon')));

const routes = [
    {
        path: '/accounting',
        element: <ComingSoon />
    },
    {
        path: '/budget-financial-planning-management',
        element: <ComingSoon />
    },
    {
        path: '/accounts-financial-reporting-management',
        element: <ComingSoon />
    }
];
export default routes;
