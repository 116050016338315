import { getItemFromArray, addHistoryRecord } from 'helpers';
import { addLayoutFunc } from '.';
import { addBasicFunc } from '../basic';
import { HISTORY_ACTIONS } from 'constant';
import { setCurrentLayout, setCurrentPage } from '../common';
import { DuplicatedLayoutDescriptionTranslation } from '../functions/history/addOrEditLayoutDescriptionTranslation';

/**
 * Creates a duplicate of a layout and adds it to the state object, along with any associated basic functions.
 * @param {Object} state - The state object to modify.
 * @param {Object} payload.item - An object containing the layout to duplicate, as well as any other required data.
 * @param {string} payload.item.type - The type of layout to duplicate.
 * @param {Array} payload.item.items - An array of IDs for any basic functions associated with the layout.
 */
export const duplicateLayout = (state, { payload }) => {
    // Create a duplicate of the layout with an empty items array and add it to the state object.
    const duplicatedLayout = {
        ...payload.item,
        items: []
    };

    setCurrentPage(state, {
        payload: state.page.find((p) => {
            if (p.items.find((id) => id === payload.item.id) !== undefined) return true;
            else return false;
        })?.id
    });
    addLayoutFunc(state, { payload: duplicatedLayout });
    setCurrentLayout(state, { payload: state.layout[state.layout.length - 1]?.id });
    // Add any associated basic functions to the duplicated layout.
    payload.item.items.forEach((itemId) => {
        const basicFunc = getItemFromArray({ array: state.basic, id: itemId });
        addBasicFunc(state, basicFunc);
    });
    const layoutItem = state.layout[state.layout.length - 1];
    // Add a history record for the Duplication
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.DUPLICATE_LAYOUT,
            description: DuplicatedLayoutDescriptionTranslation[`${payload.item.type}`],
            from: payload.item,
            to: {
                pageItem: getItemFromArray({ array: state.page, id: state.currentPageId }),
                layoutItem,
                basicItems: state.basic.filter((basicItem) => {
                    if (layoutItem.items.find((item) => item === basicItem.id) !== undefined) return true;
                    else return false;
                })
            }
        });
};
