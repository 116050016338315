import React from 'react'
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { grey } from '@mui/material/colors';

let UserAvatar = ({ status, img }) => {
    let color = (status) => (status === 'online' ? '#31A24C' : grey['300']);

    return (
        <Badge
            sx={{
                '& .MuiBadge-badge': {
                    border: `1px solid #111`,
                    background: color(status)
                }
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
            variant="dot"
            overlap="circular"
        >
            <Avatar src={img} />
        </Badge>
    );
};

export default UserAvatar;
