import { HISTORY_ACTIONS } from 'constant';
import { addHistoryRecord } from 'helpers';

export const setStyles = (state, { payload }) => {
    const oldStyles = state.styles;
    state.styles = payload;
    // Add a history record for the insertion action.
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.EDIT_STYLES,
            description: HISTORY_ACTIONS.EDIT_STYLES.description,
            from: oldStyles,
            to: payload
        });
};
