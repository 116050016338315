export const INITIAL_MENU = {
    mouseX: null,
    mouseY: null
};
export const handleOpenContextMenu = (event, setMenu) => {
    event.preventDefault();
    setMenu({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4
    });
};
