import React, { useMemo, useState } from 'react';

// Server
import { useQuery } from 'react-query';
import { authAxios } from 'config/axios';
import { GET_MESSAGES } from 'env/server';

// MUI components
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

// Third-party packages
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView } from 'react-device-detect';

// Components
import Message from 'views/chat/Components/Messages/Message';
import Header from './Header';
import MessageSkeleton from 'views/chat/Components/Messages/Message/MessageSkeleton';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setSearchedMessages } from 'features/store';

// Helpers
import { generateSkeletons } from 'helpers';

/* eslint-disable no-unused-vars */

const Search = () => {
    const [text, setText] = useState('');
    const dispatch = useDispatch();

    const { isFetching, isError, refetch } = useQuery(
        'getFilterdMessages',
        async () => {
            return authAxios.get(GET_MESSAGES(localStorage.getItem('currentChatId'), null, text));
        },
        {
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                dispatch(setSearchedMessages(data.data.data));
            }
        }
    );
    let skeletons = useMemo(() => generateSkeletons().map((skeleton, i) => <MessageSkeleton {...skeleton} key={i} />), []);
    const wallpaperColor = useSelector((state) => state.chat.wallpaperColor);
    const wallpaperImage = useSelector((state) => state.chat.wallpaperImage);
    const searchOpen = useSelector((state) => state.chat.searchOpen);
    const searchedMessages = useSelector((state) => state.chat.searchedMessages);
    const theme = useTheme();

    const drawer = (
        <React.Fragment>
            <Header {...{ refetch, isFetching, setText }} />
            <BrowserView>
                <div
                    style={{
                        height: 'calc(100vh -  60px)',
                        backgroundImage: wallpaperImage && `url(${wallpaperImage})`,
                        backgroundAttachment: 'fixed',
                        backgroundSize: 'cover',
                        backgroundColor: wallpaperColor
                    }}
                >
                    {isError ? (
                        'error'
                    ) : (
                        <Box sx={{ overflowY: 'auto', height: '100%', padding: '10px' }}>
                            {isFetching
                                ? skeletons
                                : searchedMessages.map((message, i) => message && <Message message={message} i={i} key={i} />)}
                        </Box>
                    )}
                </div>
            </BrowserView>
        </React.Fragment>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box
            component="nav"
            sx={(theme) => ({
                transition: theme.transitions.create('all', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen
                }),
                flexShrink: { md: 0 },
                width: { md: searchOpen ? '100%' : 0 }
            })}
        >
            <Drawer
                container={container}
                variant={'temporary'}
                anchor="right"
                open={searchOpen}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': {
                        background: theme.paconstte?.background?.default,
                        color: theme.paconstte?.text?.primary,
                        borderRight: 'none',
                        width: '100% !important',
                        overflow: 'hidden'
                    }
                }}
                color="inherit"
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="persistent"
                anchor="right"
                sx={{
                    display: { xs: 'none', md: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%', overflow: 'hidden' }
                }}
                open={searchOpen}
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

export default Search;
