import React, { lazy } from 'react';

// Component
import Loadable from 'ui-component/Loadable';

const AllMembers = Loadable(lazy(() => import('views/HR/Members/AllMembers')));
const EditMember = Loadable(lazy(() => import('views/HR/Members/EditMember')));
const MemberCard = Loadable(lazy(() => import('views/HR/Members/MemberCard')));
const JobTitle = Loadable(lazy(() => import('views/HR/JobTitle')));

const routes = [
    {
        path: '/members',
        element: <AllMembers />
    },
    {
        path: '/members/card',
        element: <MemberCard />
    },
    {
        path: '/members/edit',
        element: <EditMember />
    },
    {
        path: '/job-titles',
        element: <JobTitle />
    }
];
export default routes;
