import { HISTORY_ACTIONS } from 'constant';
import { Notification, addHistoryRecord } from 'helpers';

/**
 * Inserts a basic element after the currently selected basic element in the state object, and updates the related layouts.
 * @param {Object} state - The state object to modify.
 * @param {Object} payload - An object containing the ID of the basic element to insert after the currently selected basic element.
 * @param {string} payload.itemId - The ID of the basic element to insert after the currently selected basic element.
 */
export const insertAfterBasic = (state, { payload }) => {
    // If the basic element to insert has the same ID as the currently selected basic element, show an error message and return.
    if (payload.itemId === state.currentBasicId) {
        Notification.fire({ text: 'You need To Choose another Basic Element', icon: 'error' });
        return;
    }

    const oldParent = { ...state.layout.find((item) => item.items.find((item) => item === state.currentBasicId) !== undefined) };
    // Remove the basic element to insert from its current position in the related layouts.
    for (let i = 0; i < state.layout.length; i++) {
        state.layout[i].items = state.layout[i].items.filter((item) => item !== state.currentBasicId);
    }

    // Insert the basic element after the currently selected basic element in the related layouts.
    state.layout.forEach((item) => {
        const newItems = [];
        item.items.forEach((id) => {
            newItems.push(id);
            if (id === payload.itemId) newItems.push(state.currentBasicId);
        });
        item.items = newItems;
    });

    const newParent = { ...state.layout.find((item) => item.items.find((item) => item === state.currentBasicId) !== undefined) };
    // Add a history record for the insertion action.
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.INSERT_AFTER_BASIC,
            description: HISTORY_ACTIONS.INSERT_AFTER_BASIC.description,
            from: { oldParent, newParent: { ...newParent, items: newParent.items.filter((item) => item !== state.currentBasicId) } },
            to: { oldParent: { ...oldParent, items: oldParent.items.filter((item) => item !== state.currentBasicId) }, newParent }
        });
};
