import {
    COUNTRY,
    STATE,
    CITY,
    SOCIAL_MEDIA,
    RELIGION,
    DOCTRINE,
    MARITAL_STATUS,
    MEMBER_CATEGORY,
    MEMBER,
    NICK_NAME,
    CENTER,
    SECTION,
    DEPARTMENT,
    BRANCH
} from 'env/server';

export const DATA_SOURCE = {
    CENTERS: {
        get: CENTER.GET,
        key: 'title'
    },
    BRANCHES: {
        get: BRANCH.GET,
        key: 'title'
    },
    SECTIONS: {
        get: SECTION.GET,
        key: 'name'
    },
    DEPARTMENT: {
        get: DEPARTMENT.GET,
        key: 'name'
    },
    COUNTRIES: {
        get: COUNTRY.GET,
        key: 'name'
    },
    STATE: {
        get: STATE.GET,
        key: 'name'
    },
    CITY: {
        get: CITY.GET,
        key: 'name'
    },
    SOCIAL_MEDIA: {
        get: SOCIAL_MEDIA.GET,
        key: 'name'
    },
    RELIGION: {
        get: RELIGION.GET,
        key: 'name'
    },
    DOCTRINE: {
        get: DOCTRINE.GET,
        key: 'name'
    },
    MARITAL_STATUS: {
        get: MARITAL_STATUS.GET,
        key: 'name'
    },
    MEMBER_CATEGORY: {
        get: MEMBER_CATEGORY.GET,
        key: 'title'
    },
    MEMBER: {
        get: MEMBER.GET,
        key: 'fullname'
    },
    NICKNAME: {
        get: NICK_NAME.GET,
        key: 'name'
    }
};

export const CONSTANT_DATA_SOURCE = {
    BLOOD: {
        O_N: 0,
        O_P: 1,
        A_N: 2,
        A_P: 3,
        B_N: 4,
        B_P: 5,
        AB_N: 7,
        AB_P: 8
    },
    GENDER: {
        Female: 0,
        Male: 1
    }
};
