import { HISTORY_ACTIONS } from 'constant';
import { TablehistoryDescriptionTranslation } from 'constant/FileTemplate/tablehistoryDescriptionTranslation';
import { addHistoryRecord, getItemFromArray } from 'helpers';
/**
 * Deletes a column from a SpreadSheet table in the state and adds a history record for the deletion.
 * @param {object} state - The state object.
 * @param {object} payload - An object containing the index of the column to delete and the ID of the SpreadSheet table.
 */
export const deleteSpreadSheetCol = (state, { payload }) => {
    const { index, tableId } = payload;

    // Find the SpreadSheet table to update
    let table = getItemFromArray({ array: state.basic, id: tableId });
    const oldTable = { ...table };
    // Remove the column from the table
    table.cols = table.cols.filter((col, i) => i !== index);

    // Update the IDs of the remaining columns
    table.cols.forEach((col, i) => {
        col.id = i;
    });

    // Add a history record for the deletion
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.EDIT_BASIC,
            description: TablehistoryDescriptionTranslation.DeletedColumnInSpreadSheetTable,
            from: oldTable,
            to: table
        });
};
