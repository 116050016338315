import { deleteMessage } from 'features/store';
import { connection } from 'layout/ChatLayout';
import { useDispatch, useSelector } from 'react-redux';

let useDeleteMessage = () => {
    let currentChat = useSelector((state) => state.chat.currentChat);
    let dispatch = useDispatch();
    localStorage.setItem('chatId', currentChat?.id);

    return (messages) => {
        messages.forEach((message) => {
            connection.invoke('DeleteMessage', localStorage.getItem('currentChatId'), localStorage.getItem('accountId'), message.id);
            dispatch(
                deleteMessage({
                    messageId: message.id,
                    chatId: localStorage.getItem('currentChatId'),
                    accountId: localStorage.getItem('accountId')
                })
            );
        });
    };
};

export default useDeleteMessage;
