import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';

const useMatchDownMd = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    return matchDownMd;
};

export default useMatchDownMd;
