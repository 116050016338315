export const ReceptionDepartmentsPageConstant = {
    receptionDepartments: 'Reception_Management_Reception_Departments.receptiondepartments',
    name: 'Reception_Management_Reception_Departments.name',
    icon: 'Reception_Management_Reception_Departments.icon',
    image: 'Reception_Management_Reception_Departments.image',
    description: 'Reception_Management_Reception_Departments.description',
    title: 'Reception_Management_Reception_Departments.title',
    parentName: 'Reception_Management_Reception_Departments.parentname',
    code: 'Reception_Management_Reception_Departments.code',
    shape: 'Reception_Management_Reception_Departments.shape',
    tasks: 'Reception_Management_Reception_Departments.tasks',
    isActive: 'Reception_Management_Reception_Departments.isactive',
    treeList: 'Reception_Management_Reception_Departments.treelist',
    info: 'Reception_Management_Reception_Departments.info',
    uploadIcon: 'Reception_Management_Reception_Departments.uploadicon',
    clinicalDepartments: 'Reception_Management_Reception_Departments.clinicaldepartments',

    updateClinicalDepartments: 'Reception_Management_Reception_Departments.updateclinicaldepartments',
    unassign: 'Reception_Management_Reception_Departments.unassign',
    assign: 'Reception_Management_Reception_Departments.assign',
    chooseClinicalDepartment: 'Reception_Management_Reception_Departments.chooseclinicaldepartment',
    clinicalDepartmentParent: 'Reception_Management_Reception_Departments.clinicaldepartmentparent',
    clinicalDepartmentsCount: 'Reception_Management_Reception_Departments.clinicaldepartmentscount',

    edit: 'Reception_Management_Reception_Departments.edit',
    delete: 'Reception_Management_Reception_Departments.delete',
    treeViewer: 'Reception_Management_Reception_Departments.treeviewer',
    treeViewerAdd: 'Reception_Management_Reception_Departments.treevieweradd',
    treeViewerDelete: 'Reception_Management_Reception_Departments.treeviewerdelete',
    treeViewerEdit: 'Reception_Management_Reception_Departments.treevieweredit',
    treeViewerActions: 'Reception_Management_Reception_Departments.treevieweractions',
    actions: 'Reception_Management_Reception_Departments.actions',
    search: 'Reception_Management_Reception_Departments.search',
    reset: 'Reception_Management_Reception_Departments.reset',
    selectLanguage: 'Reception_Management_Reception_Departments.selectlanguage',
    history: 'Reception_Management_Reception_Departments.history'
};
