/* eslint-disable react/display-name */
import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

// MUI Components
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
// MUI icons
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// features
import { menuOpen, toggleSideBar } from 'features/store';

// hooks
import useSystemLanguages from 'hooks/systemLanguages/useSystemLanguages';
import { useDispatch, useSelector } from 'react-redux';
import useMatchDownMd from 'hooks/useMatchDownMd';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.theme);
    const matchDownMd = useMatchDownMd();
    const getTranslation = useSystemLanguages();
    const Icon = item.icon;
    const itemIcon = item?.icon ? (
        <Icon stroke={1.5} size="1.3rem" />
    ) : (
        <FiberManualRecordIcon
            sx={(theme) => ({
                width: theme.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
                height: theme.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6
            })}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    const urls = window.location.pathname
        .split('/')
        .filter((e) => e != '')
        .reverse();
    const isPageOpened = urls.length === 2 ? urls.join('-') === item.id?.toLowerCase() : `all-${urls[0]}` === item.id.toLowerCase();

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = {
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to={`${item.url}`} target={itemTarget} />)
    };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }
    const isSelected = theme.isOpen.findIndex((id) => id === item.id) > -1 || isPageOpened;
    const itemHandler = (id) => {
        // to close the menu if open
        dispatch(menuOpen(id));
        if (matchDownMd) dispatch(toggleSideBar());
    };
    const location = useLocation();
    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch(menuOpen(item.id));
        }
        // eslint-disable-next-line
    }, [location]);

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={(theme) => ({
                borderRadius: `${theme.shape.borderRadius}px`,
                alignItems: 'flex-start',
                backgroundColor: level > 1 ? 'transparent' : 'inherit',
                pl: `${level * 24}px`,
                '&:hover *': { color: theme.palette.primary.light },
                color: isSelected ? `${theme.palette.primary.main} !important` : 'inherit',
                '& *': { color: isSelected && `${theme.palette.primary.main} !important` }
            })}
            selected={isSelected}
            onClick={() => {
                itemHandler(item.id);
            }}
        >
            {item.title === 'Copyright © 2023 Cyber Digital Technology' ? (
                ''
            ) : (
                <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
            )}
            <ListItemText
                primary={
                    <Typography variant={theme.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'} color="text.primary">
                        {getTranslation(item.title)}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography variant="caption" sx={(theme) => ({ ...theme.typography.subMenuCaption })} display="block" gutterBottom>
                            {getTranslation(item.caption)}
                        </Typography>
                    )
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number,
    afterFunc: PropTypes.func
};

export default NavItem;
