import { useSelector } from 'react-redux';

const useGetUser = () => {
    const chatAccounts = useSelector((state) => state.chat?.currentChat?.chatAccounts);
    const starsOpen = useSelector((state) => state.chat.starsOpen);
    const user = (accountId) => {
        return starsOpen || !chatAccounts ? { customeName: '...' } : chatAccounts?.find((account) => account.accountId === accountId);
    };
    return user;
};

export default useGetUser;
