import { BASIC_TYPES } from 'constant';

export const AddBasicDescriptionTranslation = {
    AddedText: 'Added Text',
    AddedTextField: 'Added TextField',
    AddedTable: 'Added Table',
    AddedSpreadsheet: 'Added Spreadsheet',
    AddedDatagrid: 'Added Datagrid',
    AddedTree: 'Added Tree',
    AddedChart: 'Added Chart',
    AddedTimeline: 'Added Timeline',
    AddedSubmitButton: 'Added Submit-Button'
};

export const EditBasicDescriptionTranslation = {
    EditText: 'Edit Text',
    EditTextField: 'Edit TextField',
    EditTable: 'Edit Table',
    EditSpreadsheet: 'Edit Spreadsheet',
    EditDatagrid: 'Edit Datagrid',
    EditTree: 'Edit Tree',
    EditChart: 'Edit Chart',
    EditTimeline: 'Edit Timeline',
    EditSubmitButton: 'Edit Submit-Button'
};

export const DeleteBasicDescriptionTranslation = {
    DeletedText: 'Deleted Text',
    DeletedTextField: 'Deleted TextField',
    DeletedTable: 'Deleted Table',
    DeletedSpreadsheet: 'Deleted Spreadsheet',
    DeletedDatagrid: 'Deleted Datagrid',
    DeletedTree: 'Deleted Tree',
    DeletedChart: 'Deleted Chart',
    DeletedTimeline: 'Deleted Timeline',
    DeletedSubmitButton: 'Deleted Submit-Button'
};

export const DuplicatedBasicDescriptionTranslation = {
    DuplicatedText: 'Duplicated Text',
    DuplicatedTextField: 'Duplicated TextField',
    DuplicatedTable: 'Duplicated Table',
    DuplicatedSpreadsheet: 'Duplicated Spreadsheet',
    DuplicatedDatagrid: 'Duplicated Datagrid',
    DuplicatedTree: 'Duplicated Tree',
    DuplicatedChart: 'Duplicated Chart',
    DuplicatedTimeline: 'Duplicated Timeline',
    DuplicatedSubmitButton: 'Duplicated Submit-Button'
};

export const addBasicDescriptionTranslation = (input) => {
    const basicType = {
        [BASIC_TYPES.TEXT]: AddBasicDescriptionTranslation.AddedText,
        [BASIC_TYPES.TEXTFIELD]: AddBasicDescriptionTranslation.AddedTextField,
        [BASIC_TYPES.TABLE]: AddBasicDescriptionTranslation.AddedTable,
        [BASIC_TYPES.SPREADSHEET]: AddBasicDescriptionTranslation.AddedSpreadsheet,
        [BASIC_TYPES.DATAGRID]: AddBasicDescriptionTranslation.AddedDatagrid,
        [BASIC_TYPES.TREE]: AddBasicDescriptionTranslation.AddedTree,
        [BASIC_TYPES.CHART]: AddBasicDescriptionTranslation.AddedChart,
        [BASIC_TYPES.TIMELINE]: AddBasicDescriptionTranslation.AddedTimeline,
        [BASIC_TYPES.SUBMIT_BUTTON]: AddBasicDescriptionTranslation.AddedSubmitButton
    };

    return basicType[input];
};

export const editBasicDescriptionTranslation = (input) => {
    const basicType = {
        [BASIC_TYPES.TEXT]: EditBasicDescriptionTranslation.EditText,
        [BASIC_TYPES.TEXTFIELD]: EditBasicDescriptionTranslation.EditTextField,
        [BASIC_TYPES.TABLE]: EditBasicDescriptionTranslation.EditTable,
        [BASIC_TYPES.SPREADSHEET]: EditBasicDescriptionTranslation.EditSpreadsheet,
        [BASIC_TYPES.DATAGRID]: EditBasicDescriptionTranslation.EditDatagrid,
        [BASIC_TYPES.TREE]: EditBasicDescriptionTranslation.EditTree,
        [BASIC_TYPES.CHART]: EditBasicDescriptionTranslation.EditChart,
        [BASIC_TYPES.TIMELINE]: EditBasicDescriptionTranslation.EditTimeline,
        [BASIC_TYPES.SUBMIT_BUTTON]: EditBasicDescriptionTranslation.EditSubmitButton
    };

    return basicType[input];
};

export const deleteBasicDescriptionTranslation = (input) => {
    const basicType = {
        [BASIC_TYPES.TEXT]: DeleteBasicDescriptionTranslation.DeletedText,
        [BASIC_TYPES.TEXTFIELD]: DeleteBasicDescriptionTranslation.DeletedTextField,
        [BASIC_TYPES.TABLE]: DeleteBasicDescriptionTranslation.DeletedTable,
        [BASIC_TYPES.SPREADSHEET]: DeleteBasicDescriptionTranslation.DeletedSpreadsheet,
        [BASIC_TYPES.DATAGRID]: DeleteBasicDescriptionTranslation.DeletedDatagrid,
        [BASIC_TYPES.TREE]: DeleteBasicDescriptionTranslation.DeletedTree,
        [BASIC_TYPES.CHART]: DeleteBasicDescriptionTranslation.DeletedChart,
        [BASIC_TYPES.TIMELINE]: DeleteBasicDescriptionTranslation.DeletedTimeline,
        [BASIC_TYPES.SUBMIT_BUTTON]: DeleteBasicDescriptionTranslation.DeletedSubmitButton
    };

    return basicType[input];
};

export const duplicatedBasicDescriptionTranslation = (input) => {
    const basicType = {
        [BASIC_TYPES.TEXT]: DuplicatedBasicDescriptionTranslation.DuplicatedText,
        [BASIC_TYPES.TEXTFIELD]: DuplicatedBasicDescriptionTranslation.DuplicatedTextField,
        [BASIC_TYPES.TABLE]: DuplicatedBasicDescriptionTranslation.DuplicatedTable,
        [BASIC_TYPES.SPREADSHEET]: DuplicatedBasicDescriptionTranslation.DuplicatedSpreadsheet,
        [BASIC_TYPES.DATAGRID]: DuplicatedBasicDescriptionTranslation.DuplicatedDatagrid,
        [BASIC_TYPES.TREE]: DuplicatedBasicDescriptionTranslation.DuplicatedTree,
        [BASIC_TYPES.CHART]: DuplicatedBasicDescriptionTranslation.DuplicatedChart,
        [BASIC_TYPES.TIMELINE]: DuplicatedBasicDescriptionTranslation.DuplicatedTimeline,
        [BASIC_TYPES.SUBMIT_BUTTON]: DuplicatedBasicDescriptionTranslation.DuplicatedSubmitButton
    };

    return basicType[input];
};
