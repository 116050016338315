import { deletePage } from './deletePage';

/**
 * Deletes multiple pages from the state object.
 *
 * @param {object} state - The state object.
 * @param {object} payload - An object with a 'payload' property containing an array of page items to delete.
 * @param {object[]} payload.payload - An array of page items to delete.
 * @param {string} payload.payload[].id - The ID of the page item to delete.
 */
export const deleteMultiplePage = (state, { payload }) => {
    // Loop through the payload array and call the deleteLayout function for each item
    payload?.items?.forEach((item) => {
        deletePage(state, { payload: { itemId: item.id, withOutHistroyRecord: payload.withOutHistroyRecord } });
    });
    state.selectedPage = [];
};
