import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

// MUI components
import Box from '@mui/material/Box';

// Components
import Sidebar from 'layout/ChatLayout/Components/Sidebar';
import ChatForm from 'layout/ChatLayout/Components/ChatForm';
import ChatInfo from 'layout/ChatLayout/Components/ChatInfo';
import ChatBar from 'layout/ChatLayout/Components/ChatBar';
import ImageViewer from 'layout/ChatLayout/Components/ImageViewer';
import Pins from 'layout/ChatLayout/Components/Pins';
import Stars from 'layout/ChatLayout/Components/Stars';
import PinDialog from 'layout/ChatLayout/Components/Dialogs/Pin';
import ForwardDialog from 'layout/ChatLayout/Components/Dialogs/Forward';
import Search from 'layout/ChatLayout/Components/Search';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/styles';
import store, { setConnection } from 'features/store';

// Connection
import * as signalR from '@microsoft/signalr';

/* eslint-disable no-unused-vars */

window.addEventListener('resize', () => {
    if (location.pathname.includes('chat')) {
        document.getElementsByTagName('main')[0].style.height = `${
            window.innerHeight - document.getElementsByClassName('MuiToolbar-root')[0]?.clientHeight
        }px`;
        document.getElementById('chatBox').style.height = `calc(100% - ${
            document.getElementById('chatBar').clientHeight + document.getElementById('chatForm').clientHeight
        }px)`;
    }
});

let mainStyle = (theme, wallpaperImage, wallpaperColor) => ({
    padding: '0 !important',
    overflow: 'hidden',
    height: '100vh',
    width: '100%',
    contain: 'paint',
    position: 'relative',
    paddingBottom: '3vw',
    backgroundImage: wallpaperImage && `url(${wallpaperImage})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundColor: wallpaperColor,
    transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
    }),
    '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#333'
    }
});

// ==============================|| CHAT LAYOUT ||============================== //

let connection;
if (store.getState().auth.token) {
    connection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Trace)
        .withAutomaticReconnect()
        .withUrl('http://etezaz-001-site1.dtempurl.com/healthcarechathub')
        .build();
}

export { connection };
const ChatLayout = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (connection) {
            dispatch(setConnection(connection.state));
            connection.start().then(() => {
                connection.invoke('Join', localStorage.getItem('accountId'));
                dispatch(setConnection(connection.state));
            });
            connection.onclose = () => dispatch(setConnection(connection.state));
        }
    }, [dispatch]);
    const wallpaperColor = useSelector((state) => state.chat.wallpaperColor);
    const wallpaperImage = useSelector((state) => state.chat.wallpaperImage);
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar />
            <Box component="main" sx={mainStyle(theme, wallpaperImage, wallpaperColor)}>
                <ChatBar />
                <Outlet />
                <ChatForm />
                <Pins />
                <Stars />
                <Search />
            </Box>
            <ChatInfo />
            <ImageViewer />
            {/* Dialogs */}
            <PinDialog />
            <ForwardDialog />
        </Box>
    );
};

export default ChatLayout;
