export const FILE_TEMPLATES_TYPES = {
    Member_Personal_File: 0,
    Member_Scientific_File: 1,
    Member_Contact_File: 2,
    Member_Professional_File: 3,
    Member_Career_File: 4,
    Member_GeneralCareer_File: 5,
    Member_HR_File: 6,
    Patient_File: 7,
    Medical_File: 8
};

export const FILE_TEMPLATES_TYPES_NAMES = {
    Member_Personal_File: {
        name: 'PersonalFile',
        value: FILE_TEMPLATES_TYPES.Member_Personal_File
    },
    Member_Scientific_File: {
        name: 'ScientificFile',
        value: FILE_TEMPLATES_TYPES.Member_Scientific_File
    },
    Member_Contact_File: {
        name: 'ContactFile',
        value: FILE_TEMPLATES_TYPES.Member_Contact_File
    },
    Member_Professional_File: {
        name: 'ProfessionalFile',
        value: FILE_TEMPLATES_TYPES.Member_Professional_File
    },
    Member_Career_File: {
        name: 'CareerFile',
        value: FILE_TEMPLATES_TYPES.Member_Career_File
    },
    Member_GeneralCareer_File: {
        name: 'GeneralCareerFile',
        value: FILE_TEMPLATES_TYPES.Member_GeneralCareer_File
    },
    Member_HR_File: {
        name: 'HRFile',
        value: FILE_TEMPLATES_TYPES.Member_HR_File
    },
    Patient_File: {
        name: 'PatientFile',
        value: FILE_TEMPLATES_TYPES.Patient_File
    },
    Medical_File: {
        name: 'MedicalFile',
        value: FILE_TEMPLATES_TYPES.Medical_File
    }
};
