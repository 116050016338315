import { arrayToArrayWithKeyAndValue, objValuesToArray } from 'helpers';
import * as Yup from 'yup';

export const FORMIK_CONTROL_TYPES = {
    TEXT: 'Text',
    NUMBER: 'Number',
    MULTI_TEXT: 'Multilang text',
    SELECT: 'Select',
    PASSWORD: 'Password',
    CHECKBOX_LABEL: 'Checkbox',
    DATE: 'Date',
    DATETIME: 'Date & time',
    TIME: 'Time',
    SELECT_MULTIPLE_CHIP: 'Select Multiple Chip',
    SELECT_MULTIPLE_AUTOCOMPLETE: 'Select Multiple Auocomplete',
    RADIO_GROUP: 'Radio Group',
    TREE: 'Tree',
    FILE: 'File',
    ARRAY: 'Array',
    COLOR: 'Color',
    TEXT_STYLE: 'Text Style',
    TEXT_EDITOR: 'Text Editor',
    MAP: 'Map',
    IMAGE: 'Image',
    NUMBRING_PATTERN: 'Numbering Pattern',
    PHONE_NUMBER: 'Phone Number',
    EMAIL: 'Email',
    SWITCH: 'Switch'
};

export const ACTIVATION_VALIDATION = (
    { isActiveRequired, activationFromRequired, activationToRequired } = {
        isActiveRequired: 'required',
        activationFromRequired: 'required',
        activationToRequired: 'required'
    }
) => {
    return {
        isActive: Yup.bool().required(isActiveRequired),
        activationFrom: Yup.string().when('isActive', {
            is: false,
            then: Yup.string().required(activationFromRequired), // validation rule for activationFrom
            otherwise: Yup.string().nullable() // no validation rule for activationFrom
        }),
        activationTo: Yup.string().when('isActive', {
            is: false,
            then: Yup.string().required(activationToRequired), // validation rule for activationTo
            otherwise: Yup.string().nullable() // no validation rule for activationTo
        })
    };
};
export const ACTIVATION_INITIAL_VALUES = {
    activationFrom: new Date(),
    activationTo: (() => {
        const nextDay = new Date();
        nextDay.setDate(nextDay.getDate() + 1);
        return nextDay;
    })(),
    isActive: true
};

export const _2ND_ACTIVATION_INITIAL_VALUES = {
    from: new Date(),
    to: (() => {
        const nextDay = new Date();
        nextDay.setDate(nextDay.getDate() + 1);
        return nextDay;
    })(),
    isMain: false
};

export const FORMIK_CONTROL_TYPES_OPTIONS = arrayToArrayWithKeyAndValue(objValuesToArray(FORMIK_CONTROL_TYPES));
export const generateFormikTypeUsingSwaggerKey = (obj) => {
    const type = obj?.type;
    const format = obj?.format;
    if (type === FORMIK_CONTROL_TYPES.MULTI_TEXT) return arrayToArrayWithKeyAndValue([FORMIK_CONTROL_TYPES.MULTI_TEXT]);
    if (type === FORMIK_CONTROL_TYPES.NUMBRING_PATTERN) return arrayToArrayWithKeyAndValue([FORMIK_CONTROL_TYPES.NUMBRING_PATTERN]);
    if (format === 'date-time') return arrayToArrayWithKeyAndValue([FORMIK_CONTROL_TYPES.DATE, FORMIK_CONTROL_TYPES.DATETIME]);
    if (format === 'time') return arrayToArrayWithKeyAndValue([FORMIK_CONTROL_TYPES.TIME]);
    if (type === 'string')
        return arrayToArrayWithKeyAndValue([
            FORMIK_CONTROL_TYPES.TEXT,
            FORMIK_CONTROL_TYPES.PASSWORD,
            FORMIK_CONTROL_TYPES.IMAGE,
            FORMIK_CONTROL_TYPES.FILE,
            FORMIK_CONTROL_TYPES.DATE,
            FORMIK_CONTROL_TYPES.DATETIME,
            FORMIK_CONTROL_TYPES.TIME,
            FORMIK_CONTROL_TYPES.SELECT,
            FORMIK_CONTROL_TYPES.MAP,
            FORMIK_CONTROL_TYPES.COLOR
        ]);
    if (type === 'number') return arrayToArrayWithKeyAndValue([FORMIK_CONTROL_TYPES.NUMBER]);
    if (type === FORMIK_CONTROL_TYPES.ARRAY)
        return arrayToArrayWithKeyAndValue([FORMIK_CONTROL_TYPES.ARRAY, FORMIK_CONTROL_TYPES.SELECT_MULTIPLE_CHIP]);
    else return FORMIK_CONTROL_TYPES_OPTIONS;
};
