import { UPLOAD_FILE } from 'env/server';
import store from 'features/store';
import { authAxios } from '../axios';

let http = authAxios.create({
    baseURL: UPLOAD_FILE,
    headers: {
        'Content-type': 'application/json'
    }
});

const upload = (file, onUploadProgress) => {
    let formData = new FormData();
    formData.append('file', file);

    return http.post('/', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${store.getState().auth.token}`
        },
        onUploadProgress
    });
};

const getFiles = () => {
    return http.get('/files');
};

const FileUploadService = {
    upload,
    getFiles
};

export default FileUploadService;
