export const convertToDateUTC = (item, day, startOrEnd, startDate) => {
    const timeZone = new Date().getTimezoneOffset() / 60;

    let date = new Date(item);
    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes();
    let seconds = date.getUTCSeconds();
    let dateNew = new Date(`2024-06-0${hours > 20 && startOrEnd === 'start' ? 1 + day : 2 + day}`);

    if (startOrEnd === 'end') {
        let dateStart = new Date(startDate);
        let hoursStart = dateStart.getUTCHours();
        dateNew = new Date(`2024-06-0${hoursStart && hours > 20 && startOrEnd === 'end' ? 1 + day : 2 + day}`);
    }

    dateNew.setHours(hours + Math.abs(timeZone));
    dateNew.setMinutes(minutes);
    dateNew.setSeconds(seconds);

    return dateNew.toISOString();
};
