import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { authAxios } from 'config/axios';
import { LANGUAGE } from 'env/server';

const initialState = {
    loading: false,
    error: false,
    systemLanguages: []
};
export const getSystemLanguages = createAsyncThunk('systemLanguages/getSystemLanguages', async () => {
    return authAxios.get(LANGUAGE.GET_ACTIVE_LANGUAGES).then((res) => res.data?.data);
});

export const systemLanguagesSlice = createSlice({
    name: 'systemLanguages',
    initialState,

    extraReducers: (builder) => {
        function onError(state, action) {
            state.loading = false;
            state.error = action.payload;
        }

        function onPending(state, action) {
            state.loading = true;
        }

        // getSystemLanguages
        builder.addCase(getSystemLanguages.pending, onPending);
        builder.addCase(getSystemLanguages.fulfilled, (state, action) => {
            state.systemLanguages = action?.payload?.filter((ln) => !ln.isLanguage);

            state.loading = false;
            state.error = null;
        });
        builder.addCase(getSystemLanguages.rejected, onError);
    }
});

export default systemLanguagesSlice.reducer;
