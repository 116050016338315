export const MedicalVisitsPageConstant = {
    medicalVisits: 'Reception_Management_Medical_Visits.medicalvisits',
    medicalVisit: 'Reception_Management_Medical_Visits.medicalvisit',
    name: 'Reception_Management_Medical_Visits.name',
    icon: 'Reception_Management_Medical_Visits.icon',
    image: 'Reception_Management_Medical_Visits.image',
    description: 'Reception_Management_Medical_Visits.description',
    title: 'Reception_Management_Medical_Visits.title',
    parentName: 'Reception_Management_Medical_Visits.parentname',
    code: 'Reception_Management_Medical_Visits.code',
    shape: 'Reception_Management_Medical_Visits.shape',
    isActive: 'Reception_Management_Medical_Visits.isactive',
    treeList: 'Reception_Management_Medical_Visits.treelist',
    info: 'Reception_Management_Medical_Visits.info',
    uploadIcon: 'Reception_Management_Medical_Visits.uploadicon',
    edit: 'Reception_Management_Medical_Visits.edit',
    delete: 'Reception_Management_Medical_Visits.delete',
    treeViewer: 'Reception_Management_Medical_Visits.treeviewer',
    treeViewerAdd: 'Reception_Management_Medical_Visits.treevieweradd',
    treeViewerDelete: 'Reception_Management_Medical_Visits.treeviewerdelete',
    treeViewerEdit: 'Reception_Management_Medical_Visits.treevieweredit',
    treeViewerActions: 'Reception_Management_Medical_Visits.treevieweractions',
    actions: 'Reception_Management_Medical_Visits.actions',
    search: 'Reception_Management_Medical_Visits.search',
    reset: 'Reception_Management_Medical_Visits.reset',
    actions: 'Reception_Management_Medical_Visits.actions',
    selectLanguage: 'Reception_Management_Medical_Visits.selectlanguage',
    medicalVisitsParent: 'Reception_Management_Medical_Visits.medicalvisitsparent',
    history: 'Reception_Management_Medical_Visits.history'
};
