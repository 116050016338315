export const FT_MODES = {
    API_OPTIONS: 'API Options',
    HISTORY: 'history',
    PREVIEW: 'preview',
    PRINT: 'print',
    GRID: 'Grid',
    EXPAND: 'Expand',
    FLEX_LAYOUT: 'flex layout',
    TABS: 'tabs',
    RADIO_GROUP: 'RadioGroup',
    CHECKBOX: 'Checkbox',
    TEXT: 'Text',
    TREE: 'Tree',
    TEXTFIELD: 'TextField',
    CHART: 'Chart',
    OPTION: 'Option',
    SPREADSHEET: 'Spreadsheet',
    DATAGRID: 'Datagrid',
    TIMELINE: 'Timeline',
    SUBMIT_BUTTON: 'SubmitButton',
    RESET_BUTTON: 'ResetButton',
    STYLES: 'Styles',
    HEADER: 'header',
    FOOTER: 'Footer',
    WATERMARK: 'Watermark',
    PAGE_SETTINGS: 'Page Settings',
    BASIC_CONDITIONS: 'Conditions',
    LAYOUT_CONDITIONS: 'LayoutsConditions',
    FORMAT_SPREADSHEET: 'Format Spreadsheet',
    FORMAT_GROUPS: 'Format Groups',
    // Animate
    BASIC_ANIMATE: 'BasicAnimate',
    LAYOUT_ANIMATE: 'LayoutsAnimate',
    // Margin
    BASIC_MARGIN: 'BasicMargin',
    LAYOUT_MARGIN: 'LayoutMargin',
    // Select
    BASIC_SELECT: 'Basic Select',
    LAYOUT_SELECT: 'Layout Select',
    PAGE_SELECT: 'Page Select',
    // Swipe
    BASIC_SWIPE: 'Basic Swipe',
    LAYOUT_SWIPE: 'Layout Swipe',
    PAGE_SWIPE: 'Page Swipe',
    DATAGRID_SWIPE: 'DataGrid Swipe',
    // Assign
    LAYOUT_ASSIGN: 'Layout Assign',
    PAGE_ASSIGN: 'Page Assign',
    // Insert
    BASIC_INSERT_BEFORE: 'insert Before Basic',
    BASIC_INSERT_AFTER: 'insert After Basic',
    LAYOUT_INSERT_BEFORE: 'insert Before Layout',
    LAYOUT_INSERT_AFTER: 'insert After Layout',
    // Edit
    EDIT_GRID: 'EditGrid',
    EDIT_EXPAND: 'EditExpand',
    EDIT_FLEX_LAYOUT: 'EditFlexLayout',
    EDIT_TABS: 'EditTabs',
    EDIT_RADIO_GROUP: 'EditRadioGroup',
    EDIT_CHECKBOX: 'EditCheckbox',
    EDIT_TEXT: 'EditText',
    EDIT_TREE: 'EditTree',
    EDIT_TEXTFIELD: 'EditTextField',
    EDIT_CHART: 'EditChart',
    EDIT_OPTION: 'EditOption',
    EDIT_SPREADSHEET: 'EditSpreadsheet',
    EDIT_DATAGRID: 'EditDatagrid',
    EDIT_TIMELINE: 'EditTimeline',
    EDIT_SUBMIT_BUTTON: 'EditSubmitButton',
    EDIT_RESET_BUTTON: 'EditResetButton'
};
