import { BASIC_TYPES, HISTORY_ACTIONS } from 'constant';
import { addHistoryRecord } from '../common/fileTemplatesHelpers';
import { getItemFromArray } from 'helpers';
import { addBasicDescriptionTranslation, editBasicDescriptionTranslation } from '../functions/history/addOrEditBasicDescriptionTranslation';

export const editBasic = (state, { payload }) => {
    // Get the existing item to update
    const itemIndex = state.basic.findIndex((item) => item.id === payload.item.id);
    const item = state.basic[itemIndex];
    const oldItem = { ...item };
    const type = payload.item.type;
    // Replace the existing item with the updated item in the array in state
    state.basic[itemIndex] = {
        ...item,
        ...payload.item
    };

    // if not is Edit we need to To Make sure that the item History Record is Not Insert
    const isEdit = state.fileTemplatesMode?.includes('Edit') || type === BASIC_TYPES.SPREADSHEET || type === BASIC_TYPES.DATAGRID;
    const action = isEdit ? HISTORY_ACTIONS.EDIT_BASIC : HISTORY_ACTIONS.INSERT_BASIC;
    const description = isEdit ? editBasicDescriptionTranslation(`${type}`) : addBasicDescriptionTranslation(`${type}`);
    const from = isEdit ? oldItem : null;
    const to = isEdit
        ? state.basic[itemIndex]
        : {
              basicItem: state.basic[itemIndex],
              layoutItem: getItemFromArray({ array: state.layout, id: state.currentLayoutId })
          };
    // Add a history record for the update
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action,
            description,
            from,
            to
        });
};
