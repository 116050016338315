import { COMPONENT_ARRAY_NAMES, HISTORY_ACTIONS } from 'constant';
import { assignItemToTarget, selectItem, setCurrentItem, swapItems, updateAttribute, updateItem } from 'helpers';

export const swipeBasic = (state, { payload }) =>
    swapItems(state, { payload }, COMPONENT_ARRAY_NAMES.BASIC_COMPONENT, HISTORY_ACTIONS.BASIC_SWIPE);
export const swipeLayout = (state, { payload }) =>
    swapItems(state, { payload }, COMPONENT_ARRAY_NAMES.LAYOUT_COMPONENT, HISTORY_ACTIONS.LAYOUT_SWIPE);
export const swipePage = (state, { payload }) =>
    swapItems(state, { payload }, COMPONENT_ARRAY_NAMES.PAGE_COMPONENT, HISTORY_ACTIONS.PAGE_SWIPE);

export const setCurrentBasic = (state, { payload }) => setCurrentItem(state, { payload }, COMPONENT_ARRAY_NAMES.BASIC_COMPONENT);
export const setCurrentLayout = (state, { payload }) => setCurrentItem(state, { payload }, COMPONENT_ARRAY_NAMES.LAYOUT_COMPONENT);
export const setCurrentPage = (state, { payload }) => setCurrentItem(state, { payload }, COMPONENT_ARRAY_NAMES.PAGE_COMPONENT);

export const selectBasic = (state, { payload }) => selectItem(state, { payload }, COMPONENT_ARRAY_NAMES.BASIC_COMPONENT);
export const selectLayout = (state, { payload }) => selectItem(state, { payload }, COMPONENT_ARRAY_NAMES.LAYOUT_COMPONENT);
export const selectPage = (state, { payload }) => selectItem(state, { payload }, COMPONENT_ARRAY_NAMES.PAGE_COMPONENT);

export const addAttrBasic = (state, { payload }) => updateAttribute(state, { payload }, COMPONENT_ARRAY_NAMES.BASIC_COMPONENT);
export const addAttrLayout = (state, { payload }) => updateAttribute(state, { payload }, COMPONENT_ARRAY_NAMES.LAYOUT_COMPONENT);

export const assignBasicToLayout = (state, { payload }) =>
    assignItemToTarget(state, { payload }, COMPONENT_ARRAY_NAMES.LAYOUT_COMPONENT, COMPONENT_ARRAY_NAMES.BASIC_COMPONENT, 'Layout');
export const assignLayoutToPage = (state, { payload }) =>
    assignItemToTarget(state, { payload }, COMPONENT_ARRAY_NAMES.PAGE_COMPONENT, COMPONENT_ARRAY_NAMES.LAYOUT_COMPONENT, 'Page');
