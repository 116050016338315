/* eslint-disable no-unused-vars */
import * as React from 'react';

// MUI Components
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import Grow from '@mui/material/Grow';

// constants
import { INITIAL_MENU } from 'constant';

// store
import { useSelector } from 'react-redux';

// hooks
import useStarMessage from 'views/chat/hooks/useStarMessage';

let ContextStar = ({ message, setMenu, menu }) => {
    const starMessage = useStarMessage();
    const stars = useSelector((state) => state.chat.stars);
    const starred = stars?.find((star) => star?.id === message.id);
    const handleStar = () => {
        starMessage([message]);
        setMenu(INITIAL_MENU);
    };

    return (
        <Grow unmountOnExit in={menu.mouseX !== null} timeout={600}>
            <MenuItem onClick={handleStar}>
                <ListItemIcon>
                    <StarIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{starred ? 'Un Star' : 'Star'}</ListItemText>
            </MenuItem>
        </Grow>
    );
};

export default ContextStar;
