import { addChat } from 'features/store';
import { authAxios } from 'config/axios';
import { CREATE_CHAT } from 'env/server';
import { Notification } from 'helpers';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

function useCreateChat() {
    let dispatch = useDispatch();

    const fetch = ({ name, image }) => {
        return authAxios.post(CREATE_CHAT, {
            groupName: name,
            image: image,
            isGroup: true,
            chatAccounts: [
                {
                    accountId: localStorage.getItem('accountId'),
                    isOwner: true,
                    customeName: 'Ahmad'
                },
                {
                    accountId: '638dd7f2613008ba8db71606',
                    isOwner: true,
                    customeName: 'Mohmmad'
                }
            ]
        });
    };
    let mutaion = useMutation('CreateChat', fetch, {
        refetchOnWindowFocus: false,

        onSuccess: (data) => {
            dispatch(addChat(data.data.data));
            Notification.fire({
                title: `done !`,
                timer: 2000,
                icon: 'success'
            });
        },
        onError: (error) => {
            Notification.fire({
                title: error.message,
                timer: 2000,
                icon: 'error'
            });
        }
    });

    return mutaion;
}

export default useCreateChat;
