import { getItemFromArray, addHistoryRecord } from 'helpers';
import { deleteMultipleBasic } from '../basic';
import { HISTORY_ACTIONS } from 'constant';
import { deleteLayoutDescriptionTranslation } from '../functions/history/addOrEditLayoutDescriptionTranslation';

/**
 * Deletes the specified layout item from the layout array in the state object and deletes all the related basic items from the basic array in the state object.
 * @param {object} state - The state object.
 * @param {object} payload - An object containing the ID of the layout item to delete.
 */
export const deleteLayout = (state, { payload }) => {
    // Obtain a reference to the layout item to delete
    const layoutItem = getItemFromArray({ array: state.layout, id: payload.itemId });
    // Delete all the related basic items from the basic array
    deleteMultipleBasic(state, {
        payload: { items: layoutItem?.items.map((id) => ({ id })), withOutHistroyRecord: payload.withOutHistroyRecord }
    });

    // Remove the layout item from the layout array
    state.layout = state.layout.filter((item) => item.id !== payload.itemId);

    // Set the current layout item to be the first item in the layout array
    state.currentLayoutId = state.layout[0]?.id;
    const oldPage = { ...state.page.find((item) => item.items.find((id) => id === payload.itemId) !== undefined) };

    // delete the current layout item from the Page array
    for (let i = 0; i < state.page.length; i++) {
        state.page[i].items = state.page[i].items.filter((item) => item !== payload.itemId);
    }

    // Add a history record for the deletion
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.DELETE_LAYOUT,
            description: deleteLayoutDescriptionTranslation(`${layoutItem.type}`),
            from: {
                layoutItem,
                pageItem: oldPage
            },
            to: null
        });
};
