import React from 'react';

// MUI Components
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

// helpers
import { downloadFile } from 'helpers';
import { getFileData } from 'helpers';

let File = ({ message }) => {
    let { name, size } = getFileData(message);
    return (
        <Grid
            container
            display="flex"
            justifyContent="center"
            sx={{
                width: '200px',
                marginTop: '5px'
            }}
        >
            <Grid item xs={3} display="flex" onClick={() => downloadFile(message.url, name)}>
                <InsertDriveFileIcon
                    sx={{
                        color: '#EEE',
                        background: '#0288d1',
                        borderRadius: '50%',
                        padding: '8px',
                        fontSize: '40px'
                    }}
                />
            </Grid>
            <Grid item xs={9} sx={{ textAlign: 'left', color: '#333' }}>
                <Typography component="p">{name}</Typography>
                <Typography component="p" color="text.secondary">
                    {size}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default File;
