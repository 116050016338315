import { lazy } from 'react';
import React from 'react';

// Project components
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import systemLanguages from './SystemLanguages';
import languages from './Languages';
import accounts from './accounts';
import hr from './HR';
import adminstrative from './Adminstrative';
import fileTemplates from './FileTemplates';
import settings from './Settings';
import history from './History';
import administrativeManagement from './administrative-management';
import medicalManagement from './medical-management';
import financialManagement from './financial-management';
import receptionDepartments from './reception-departments';

// MUI Components components
import Typography from '@mui/material/Typography';

// Lazy-loaded components

const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// end members
const Settings = Loadable(lazy(() => import('views/AppSettings')));

const Sections = Loadable(lazy(() => import('views/sections')));
const Authenticate = Loadable(lazy(() => import('views/ProjectManagement/Auth/Authenticate')));
const Project = Loadable(lazy(() => import('views/ProjectManagement/Project')));

// branches
const Branches = Loadable(lazy(() => import('views/Branches/AllBranches')));
const EditBranch = Loadable(lazy(() => import('views/Branches/EditBranch')));

// centers
const EditCenter = Loadable(lazy(() => import('views/centers/EditCenter')));
const Centers = Loadable(lazy(() => import('views/centers/AllCenters')));
const DisplayCenter = Loadable(lazy(() => import('views/centers/DisplayCenter')));

const Roles = Loadable(lazy(() => import('views/executive-management/roles')));

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <SamplePage />
        },
        {
            path: '/dashboard/Settings',
            element: <Settings />
        },
        {
            path: '/centers',
            element: <Centers />
        },
        {
            path: '/sections',
            element: <Sections />
        },
        {
            path: '/centers/edit',
            element: <EditCenter />
        },
        {
            path: '/centers/display',
            element: <DisplayCenter />
        },
        {
            path: '/branches',
            element: <Branches />
        },
        {
            path: '/branches/edit',
            element: <EditBranch />
        },
        {
            path: '/sections',
            element: <Sections />
        },
        {
            path: '/permissions',
            element: <Roles />
        },
        {
            path: '/come-soon',
            element: <h1>coming soon</h1>
        },
        {
            path: '/authenticate',
            element: <Authenticate />
        },
        {
            path: '/project-management/project',
            element: <Project />
        },
        ...adminstrative,
        ...fileTemplates,
        ...systemLanguages,
        ...languages,
        ...settings,
        ...history,
        ...accounts,
        ...hr,
        ...administrativeManagement,
        ...financialManagement,
        ...medicalManagement,
        ...receptionDepartments,
        {
            path: '/*',
            element: (
                <Typography variant="h2" color="primary" fontWeight={100} sx={{ padding: '20px' }}>
                    404 Not Found
                </Typography>
            )
        }
    ]
};

export default MainRoutes;
