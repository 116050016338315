import { HISTORY_ACTIONS } from 'constant';
import { TablehistoryDescriptionTranslation } from 'constant/FileTemplate/tablehistoryDescriptionTranslation';
import { addHistoryRecord, getItemFromArray } from 'helpers';

/**
 * Edits a column in a data grid table in the state.
 * @param {object} state - The current state of the application.
 * @param {object} action - The action object with properties type and payload.
 * @param {object} action.payload - An object containing the properties tableId and col.
 * @param {string} action.payload.tableId - The ID of the data grid table to edit the column in.
 * @param {object} action.payload.col - The updated column object to replace the existing column with.
 */
export const editDataGridCol = (state, { payload: { tableId, col, withOutHistroyRecord } }) => {
    // Retrieve the data grid table object with the given tableId from the basic array in the state using the getItemFromArray function
    const table = getItemFromArray({ array: state.basic, id: tableId });
    const oldTable = { ...table };

    // Replace the existing column in the table's cols array with the updated column object
    table.cols = table.cols.map((tableCol) => {
        if (tableCol.id === col?.id) return col;
        return tableCol;
    });

    // Add a history record for the deletion
    if (!withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.EDIT_BASIC,
            description: TablehistoryDescriptionTranslation.editedColumnDataGrid,
            from: oldTable,
            to: table
        });
};
