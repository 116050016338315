import React from 'react';

// MUI components
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/styles';
import CopyIcon from '@mui/icons-material/CopyAll';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplyIcon from '@mui/icons-material/Reply';
import StarIcon from '@mui/icons-material/Star';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { toggle, toggleDialog } from 'features/store';

// Hooks
import useDeleteMessage from 'views/chat/hooks/useDeleteMessage';
import useStarMessage from 'views/chat/hooks/useStarMessage';

// Constants
import { DIALOGS, MESSAGE_TYPE, TOGGLE } from 'constant';

let SelectMessages = () => {
    // store
    const selectMode = useSelector((state) => state.chat.selectMode);
    const selectedMessages = useSelector((state) => state.chat.selectedMessages);
    const messages = useSelector((state) => state.chat.selectedMessages);
    const dispatch = useDispatch();

    const theme = useTheme();

    // hooks
    const deleteMessage = useDeleteMessage();
    const starMessage = useStarMessage();

    let handleToggleSelectMode = () => {
        dispatch(toggle({ type: TOGGLE.SELECT_MODE }));
    };

    let handleDeleteMessages = () => {
        deleteMessage(messages);
        handleToggleSelectMode();
    };

    let handleStarMessages = () => {
        starMessage(messages);
        handleToggleSelectMode();
    };

    let handleForwardMessages = () => {
        dispatch(toggleDialog({ name: DIALOGS.FORWARD, data: selectedMessages }));
    };

    let handleCopyMessages = () => {
        let copiedText = '';
        selectedMessages.forEach(({ text, type }, i) => {
            if (text && type === MESSAGE_TYPE.TEXT) copiedText += `${i !== 0 && i !== selectedMessages.length ? ' ' : ''}${text}`;
        });
        navigator.clipboard.writeText(copiedText);
        handleToggleSelectMode();
    };

    let disabled = selectedMessages.length === 0;

    return (
        <Collapse in={selectMode} unmountOnExit>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px',
                    width: '100%',
                    backgroundColor: '#FFF',
                    borderTop: '1px solid #EEE',
                    height: '60px'
                }}
            >
                <Box
                    sx={{
                        '.MuiIconButton-root': {
                            backgroundColor: '#FFF',
                            color: !disabled && theme.palette.primary.main,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                                color: '#FFF'
                            }
                        }
                    }}
                >
                    <IconButton disabled={disabled} sx={{ marginRight: '10px' }} onClick={handleStarMessages}>
                        <StarIcon fontSize="small" />
                    </IconButton>
                    <IconButton disabled={disabled} sx={{ marginRight: '10px' }} onClick={handleCopyMessages}>
                        <CopyIcon fontSize="small" />
                    </IconButton>
                    <IconButton disabled={disabled} sx={{ marginRight: '10px' }} onClick={handleForwardMessages}>
                        <ReplyIcon fontSize="small" sx={{ transform: 'rotateY(180deg)' }} />
                    </IconButton>
                    <IconButton disabled={disabled} onClick={handleDeleteMessages}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Box>
                <Box>
                    <Button variant="contained" onClick={handleToggleSelectMode}>
                        cancel
                    </Button>
                </Box>
            </Box>
        </Collapse>
    );
};

export default SelectMessages;
