import { addMessage, changeId } from 'features/store';
import { connection } from 'layout/ChatLayout';
import { useDispatch, useSelector } from 'react-redux';

let useForwardMessage = () => {
    let currentChat = useSelector((state) => state.chat.currentChat);
    let dispatch = useDispatch();
    localStorage.setItem('chatId', currentChat?.id);

    let forwardMessage = (messages, chatsIds) => {
        chatsIds.forEach(async (chatId) => {
            messages.forEach(async (message) => {
                let randomId = `RANDOM_${Math.random() * 1000}`;
                dispatch(
                    addMessage({
                        ...message,
                        forward: message,
                        chatId,
                        randomId
                    })
                );
                let id;
                id = await connection.invoke('SendMessage', {
                    ...message,
                    chatId,
                    forwardMessageId: message.id
                });
                dispatch(changeId({ oldId: randomId, newId: id, chatId: localStorage.getItem('chatId') }));
            });
        });
    };

    return forwardMessage;
};

export default useForwardMessage;
