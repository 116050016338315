import * as React from 'react';
import { useRef } from 'react';

// MUI components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grow from '@mui/material/Grow';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { changeWallpaperColor, changeWallpaperImage } from 'features/store';

// MUI icons
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import AddSharpIcon from '@mui/icons-material/AddSharp';

// MUI styles
import useTheme from '@mui/material/styles/useTheme';

let Wallpaper = () => {
    const [open, setOpen] = React.useState(false);
    let settingsOpen = useSelector((state) => state.chat.settingsOpen);

    const theme = useTheme();

    const wallpaperImage = useSelector((state) => state.chat.wallpaperImage);
    const wallpaperColor = useSelector((state) => state.chat.wallpaperColor);

    const [image, setImage] = React.useState(wallpaperImage);
    const [color, setColor] = React.useState(wallpaperColor);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    let dispatch = useDispatch();
    let handleChangeWallpaperColor = (e) => {
        setColor(e.target.value);
    };
    let switchRef = useRef();
    let handleChangeWallpaperImage = (e) => {
        const reader = new FileReader();
        reader.onloadend = (url) => {
            setImage(url.currentTarget.result);
        };
        reader.readAsDataURL(e.target.files[0]);
    };
    let handleSave = () => {
        dispatch(changeWallpaperColor(color));
        dispatch(changeWallpaperImage(image));
        setOpen(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleSwitchWallpaper = (e) => {
        if (wallpaperImage !== null) {
            switchRef.current.checked = false;
            dispatch(changeWallpaperImage(null));
            setImage(null);
        }
    };

    return (
        <React.Fragment>
            <Grow in={settingsOpen} timeout={600} unmountOnExit>
                <Box>
                    <Typography variant="h4" color="text.secondary" padding="20px">
                        Wallpaper Settings
                    </Typography>
                    <Divider />
                </Box>
            </Grow>
            <Grow in={settingsOpen} timeout={800} unmountOnExit>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <WallpaperIcon />
                        </ListItemIcon>
                        <ListItemText primary={'ON/OFF'} />
                        <Switch inputRef={switchRef} checked={wallpaperImage !== null} onChange={handleSwitchWallpaper} />
                    </ListItemButton>
                </ListItem>
            </Grow>
            <Grow in={settingsOpen} timeout={1000} unmountOnExit>
                <ListItem disablePadding onClick={handleClickOpen}>
                    <ListItemButton>
                        <ListItemIcon>
                            <AddSharpIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Add Wallpaper'} />
                    </ListItemButton>
                </ListItem>
            </Grow>

            <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                    '.MuiPaper-root': {
                        width: !fullScreen ? '60%' : '100%'
                    }
                }}
                fullScreen={fullScreen}
            >
                <Grow in={open} timeout={400} unmountOnExit>
                    <DialogTitle>Choose Wallpaper</DialogTitle>
                </Grow>
                <DialogContent>
                    <Grow in={open} timeout={600} unmountOnExit>
                        <Typography padding={'10px'} color="text.secondary">
                            Wallpaper Color
                        </Typography>
                    </Grow>
                    <Grow in={open} timeout={800} unmountOnExit>
                        <TextField type="color" defaultValue={wallpaperColor} fullWidth onChange={handleChangeWallpaperColor} />
                    </Grow>
                    <Grow in={open} timeout={1000} unmountOnExit>
                        <Typography padding={'10px'} color="text.secondary">
                            Wallpaper Image{' '}
                            <IconButton onClick={() => document.getElementById('wallpaper').click()}>
                                <AddSharpIcon />
                            </IconButton>
                        </Typography>
                    </Grow>
                    <Grow in={image !== null} timeout={1200} unmountOnExit>
                        <img style={{ width: '100%', height: 'auto', borderRadius: '20px' }} src={image} alt="none" />
                    </Grow>

                    <input type="file" id="wallpaper" style={{ display: 'none' }} onChange={handleChangeWallpaperImage} />
                </DialogContent>
                <Grow in={open} timeout={1200} unmountOnExit>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                        <Button onClick={handleSave}>Save</Button>
                    </DialogActions>
                </Grow>
            </Dialog>
        </React.Fragment>
    );
};

export default Wallpaper;
