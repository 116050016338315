import React from 'react';

// Icons
import { IconUser, IconUserCircle, IconUserPlus, IconBriefcase, IconNewSection } from '@tabler/icons';

import { roles } from 'env/roles';

const icons = { IconUserCircle, IconUserPlus, IconUser, IconNewSection, IconBriefcase };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';

const useSurgicalOperationsManagement = () => {
    return {
        id: 'surgical-operations-management',
        title: 'Surgical Operations Management',
        type: 'group',
        icon: <ReceiptLongRoundedIcon />,
        children: [
            {
                id: 'surgical-operations',
                title: 'Surgical Operations',
                type: 'item',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                url: '/surgical-operations',
                breadcrumbs: false
            },
            {
                id: 'surgical-rooms',
                title: 'Surgical Rooms',
                type: 'item',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                url: '/surgical-rooms',
                breadcrumbs: false
            },
            {
                id: 'timetable',
                title: 'Timetable',
                type: 'item',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                url: '/timetable',
                breadcrumbs: false
            }
        ]
    };
};

export default useSurgicalOperationsManagement;
