import React, { lazy } from 'react';

// Components
import Loadable from 'ui-component/Loadable';

const History = Loadable(lazy(() => import('views/History')));
const history = [
    {
        path: '/history',
        element: <History />
    }
];
export default history;
