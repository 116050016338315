import * as Yup from 'yup';
// constants
import { BASIC_TYPES, FORMIK_CONTROL_TYPES, MAX_DATE, MIN_DATE } from 'constant';

// helpers
import { multiLangValidation } from 'helpers';

// 3rd party
import moment from 'moment';

export const getValidationSchema = ({ basic }) => {
    const textFields = basic?.filter((b) => b.type === BASIC_TYPES.TEXTFIELD);
    let validationSchema = {};
    try {
        textFields?.forEach((field) => {
            const validation = field.fields.valdation;
            const {
                min = -1 / 0,
                max = 1 / 0,
                minLength = 0,
                maxLength = 1 / 0,
                minTime = MIN_DATE,
                maxTime = MAX_DATE,
                minDate = MIN_DATE,
                maxDate = MAX_DATE,
                minDateTime = MIN_DATE,
                maxDateTime = MAX_DATE,
                isRequired = false
            } = validation;
            const formattedMinTime = moment(minTime).format('h:mm a');
            const formattedMaxTime = moment(maxTime).format('h:mm a');
            const formattedMinDate = moment(minDate).format('YYYY/M/DD');
            const formattedMaxDate = moment(maxDate).format('YYYY/M/DD');
            const formattedMinDateTime = moment(minDateTime).format('YYYY/M/DD h:mm a');
            const formattedMaxDateTime = moment(maxDateTime).format('YYYY/M/DD h:mm a');

            const makeValidation = (control) => {
                const validation = typeToValidation[control];
                if (isRequired)
                    return Boolean(validation) ? validation?.required('Required').nullable() : Yup.string().required('Required');
                else return validation.nullable();
            };

            const typeToValidation = {
                [FORMIK_CONTROL_TYPES.TEXT]: Yup.string()
                    .min(minLength ? minLength : 0)
                    .max(maxLength ? maxLength : 10000),
                [FORMIK_CONTROL_TYPES.NUMBER]: Yup.number()
                    .min(Number(min) ? min : 0)
                    .max(Number(max) ? max : 1000),
                [FORMIK_CONTROL_TYPES.SELECT]: Yup.string(),
                [FORMIK_CONTROL_TYPES.PASSWORD]: Yup.string()
                    .min(Number(minLength) ? minLength : 0)
                    .max(Number(maxLength) ? maxLength : 10000),
                [FORMIK_CONTROL_TYPES.DATE]: Yup.date()
                    .min(new Date(minDate), `must be at Least ${formattedMinDate}`)
                    .max(new Date(maxDate), `must be at Most ${formattedMaxDate}`),
                [FORMIK_CONTROL_TYPES.DATETIME]: Yup.date()
                    .min(new Date(minDateTime), `must be at Least ${formattedMinDateTime}`)
                    .max(new Date(maxDateTime), `must be at Most ${formattedMaxDateTime}`),
                [FORMIK_CONTROL_TYPES.TIME]: Yup.date()
                    .min(new Date(minTime), `must be at Least ${formattedMinTime}`)
                    .max(new Date(maxTime), `must be at Most ${formattedMaxTime}`),
                [FORMIK_CONTROL_TYPES.SELECT_MULTIPLE_CHIP]: Yup.array().of(Yup.string()),
                [FORMIK_CONTROL_TYPES.ARRAY]: Yup.array()
                    .of(
                        getValidationSchema({
                            basic: field?.fields?.fields?.map((field) => ({
                                type: BASIC_TYPES.TEXTFIELD,
                                fields: field
                            }))
                        })
                    )
                    .min(Number(minLength) ? minLength : 0)
                    .max(Number(maxLength) ? maxLength : 10000),
                [FORMIK_CONTROL_TYPES.RADIO_GROUP]: Yup.string(),
                [FORMIK_CONTROL_TYPES.TREE]: Yup.string(),
                [FORMIK_CONTROL_TYPES.FILE]: Yup.string(),
                [FORMIK_CONTROL_TYPES.COLOR]: Yup.array(),
                [FORMIK_CONTROL_TYPES.TEXT_STYLE]: Yup.string(),
                [FORMIK_CONTROL_TYPES.TEXT_EDITOR]: Yup.string(),
                [FORMIK_CONTROL_TYPES.MAP]: Yup.string(),
                [FORMIK_CONTROL_TYPES.IMAGE]: Yup.string(),
                [FORMIK_CONTROL_TYPES.NUMBRING_PATTERN]: Yup.string(),
                [FORMIK_CONTROL_TYPES.PHONE_NUMBER]: Yup.string(),
                [FORMIK_CONTROL_TYPES.EMAIL]: Yup.string().email('Invalid Email')
            };

            validationSchema = {
                ...validationSchema,
                [field.fields.name]:
                    field.fields.control === FORMIK_CONTROL_TYPES.MULTI_TEXT && isRequired
                        ? multiLangValidation()
                        : makeValidation(field.fields.control)
            };
        });
    } catch (e) {
        console.log(e);
    }

    return Yup.object().shape({
        ...validationSchema
    });
};
/* const getInitialValues = ({ basic }) => {
    const textFields = basic?.filter((b) => b.type === BASIC_TYPES.TEXTFIELD);
    let initialValues = {};
    try {
        textFields?.forEach((field) => {
            const typeToInitialValues = {
                [FORMIK_CONTROL_TYPES.TEXT]: '',
                [FORMIK_CONTROL_TYPES.NUMBER]: null,
                [FORMIK_CONTROL_TYPES.SELECT]: '',
                [FORMIK_CONTROL_TYPES.PASSWORD]: '',
                [FORMIK_CONTROL_TYPES.DATE]: new Date(),
                [FORMIK_CONTROL_TYPES.DATETIME]: new Date(),
                [FORMIK_CONTROL_TYPES.TIME]: new Date(),
                [FORMIK_CONTROL_TYPES.SELECT_MULTIPLE_CHIP]: [],
                [FORMIK_CONTROL_TYPES.ARRAY]: [],
                [FORMIK_CONTROL_TYPES.RADIO_GROUP]: '',
                [FORMIK_CONTROL_TYPES.TREE]: '',
                [FORMIK_CONTROL_TYPES.FILE]: '',
                [FORMIK_CONTROL_TYPES.COLOR]: '',
                [FORMIK_CONTROL_TYPES.TEXT_STYLE]: DEFAULT_TEXT_SETTINGS,
                [FORMIK_CONTROL_TYPES.TEXT_EDITOR]: '',
                [FORMIK_CONTROL_TYPES.MAP]: '',
                [FORMIK_CONTROL_TYPES.IMAGE]: '',
                [FORMIK_CONTROL_TYPES.NUMBRING_PATTERN]: {},
                [FORMIK_CONTROL_TYPES.PHONE_NUMBER]: '',
                [FORMIK_CONTROL_TYPES.EMAIL]: ''
            };
            initialValues = {
                ...initialValues,
                [field?.fields?.name]: typeToInitialValues[field?.fields?.control]
            };
        });
    } catch (e) {
        console.log(e);
    }

    return initialValues;
};
 */
