import React from 'react';

// MUI components
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Slide from '@mui/material/Slide';

// Third-party libraries
import { BrowserView, MobileView } from 'react-device-detect';
import PropTypes from 'prop-types';

// Project imports
import Content from './Content';
import Header from './Header';

// Redux
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
    const theme = useTheme();
    let leftDrawerOpen = useSelector((state) => state.chat.leftDrawerOpen);

    const drawer = (
        <Box>
            <BrowserView>
                <Header />
                <div
                    style={{
                        height: '100vh'
                    }}
                >
                    <Content />
                </div>
            </BrowserView>
            <MobileView>
                <Box>
                    <Content />
                </Box>
            </MobileView>
        </Box>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Slide in={true} timeout={400} direction="right">
            <Box component="nav" sx={{ flexShrink: { md: 0 }, width: { md: '350px' } }} aria-label="mailbox folders">
                <Drawer
                    container={container}
                    variant={'temporary'}
                    anchor="left"
                    open={leftDrawerOpen}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': {
                            background: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            borderRight: 'none',
                            width: '100% !important',
                            overflow: 'hidden'
                            // left:auto,
                        }
                    }}
                    color="inherit"
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '350px', overflow: 'hidden' }
                    }}
                    open={leftDrawerOpen}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Slide>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;
