import { FORMIK_CONTROL_TYPES } from '../Global/formikControlTypes';

export const CONTACT_FILE_FIELDS = [
    {
        name: 'Country',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.SELECT
    },
    {
        name: 'State',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.SELECT
    },
    {
        name: 'City',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.SELECT
    },
    {
        name: 'Nighborhood',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.SELECT
    },
    {
        name: 'Street',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.SELECT
    },
    {
        name: 'Address 1',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.TEXT
    },
    {
        name: 'Address 2',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.TEXT
    },
    {
        name: 'Building Number',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.NUMBER
    },
    {
        name: 'ZipCode',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.NUMBER
    },
    {
        name: 'Email Address',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.TEXT
    },
    {
        name: 'WhatsApp number',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.NUMBER
    },
    {
        name: 'Mobile Number 1',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.SELECT
    },
    {
        name: 'Mobile Number 2',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.SELECT
    },
    {
        name: 'Mobile Number 2 Owner',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.NUMBER
    },
    {
        name: 'Phone Number',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.NUMBER
    },
    {
        name: 'Fax Number',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.NUMBER
    },
    {
        name: 'Geolocation',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.TEXT
    },
    {
        name: 'Telegram',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.TEXT
    },
    {
        name: 'Facebook',
        required: false,
        hidden: false,
        type: 'file'
    },
    {
        name: 'LinkedIn',
        required: false,
        hidden: false,
        type: 'file'
    }
];
