export const LanguagesPageConstant = {
    languages: 'Languages_Languages.languages',
    language: 'Languages_Languages.language',
    languagekey: 'Languages_Languages.languagekey',
    icon: 'Languages_Languages.icon',
    languagename: 'Languages_Languages.languagename',
    direction: 'Languages_Languages.direction',
    code: 'Languages_Languages.code',
    key: 'Languages_Languages.key',
    icon: 'Languages_Languages.icon',
    name: 'Languages_Languages.name',
    requiredAllLanguages: 'Languages_Languages.requiredalllanguages',
    edit: 'Languages_Languages.edit',
    delete: 'Languages_Languages.delete'
};
