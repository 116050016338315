import React from 'react';

// Icons
import { IconUser, IconUserCircle, IconUserPlus, IconBriefcase, IconNewSection } from '@tabler/icons';

import { roles } from 'env/roles';

const icons = { IconUserCircle, IconUserPlus, IconUser, IconNewSection, IconBriefcase };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';

const useFinancialManagement = () => {
    return {
        id: 'financial-management',
        title: 'Financial Management',
        type: 'group',
        icon: <ReceiptLongRoundedIcon />,
        children: [
            {
                id: 'accounting',
                title: 'Accounting',
                type: 'item',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                url: '/accounting',
                breadcrumbs: false
            },
            {
                id: 'budget-financial-planning-management',
                title: 'Budget and Financial Planning Management',
                type: 'item',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                url: '/budget-financial-planning-management',
                breadcrumbs: false
            },
            {
                id: 'accounts-financial-reporting-management',
                title: 'Accounts and Financial Reporting Management',
                type: 'item',
                icon: icons.IconBriefcase,
                role: [roles.Admin],
                url: '/accounts-financial-reporting-management',
                breadcrumbs: false
            }
        ]
    };
};

export default useFinancialManagement;
