import { BASIC_TYPES, HISTORY_ACTIONS } from 'constant';
import { TablehistoryDescriptionTranslation } from 'constant/FileTemplate/tablehistoryDescriptionTranslation';
import { addHistoryRecord, getItemFromArray } from 'helpers';
/**
 * Inserts a row into a SpreadSheet table in the state and adds a history record for the insertion.
 * @param {object} state - The state object.
 * @param {object} payload - An object containing the index at which to insert the row, the ID of the SpreadSheet table, and a boolean indicating whether to insert the row from above or below.
 */
export const insertSpreadSheetRow = (state, { payload }) => {
    const { index, tableId, fromAbove } = payload;

    // Find the SpreadSheet table to update
    let table = getItemFromArray({ array: state.basic, id: tableId });
    const oldTable = { ...table };
    // Insert the row into the table
    if (fromAbove) {
        table.rows.splice(index, 0, { type: BASIC_TYPES.ROW, id: 0 });
    } else {
        table.rows.splice(index, 0, { type: BASIC_TYPES.ROW, id: 0 });
    }

    // Update the IDs of the rows
    table.rows.forEach((row, i) => {
        row.id = i;
    });

    // Add a history record for the insertion
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.EDIT_BASIC,
            description: TablehistoryDescriptionTranslation.InsertedRowInSpreadSheetTable,
            from: oldTable,
            to: table
        });
};
