import { BASIC_TYPES, HISTORY_ACTIONS } from 'constant';
import { TablehistoryDescriptionTranslation } from 'constant/FileTemplate/tablehistoryDescriptionTranslation';
import { addHistoryRecord, getItemFromArray } from 'helpers';
/**
 * Inserts a column into a SpreadSheet table in the state and adds a history record for the insertion.
 * @param {object} state - The state object.
 * @param {object} payload - An object containing the index at which to insert the column, the ID of the SpreadSheet table, and a boolean indicating whether to insert the column from the right or left.
 */
export const insertSpreadSheetCol = (state, { payload }) => {
    const { index, tableId, fromRight } = payload;

    // Find the SpreadSheet table to update
    let table = getItemFromArray({ array: state.basic, id: tableId });
    const oldTable = { ...table };
    // Insert the column into the table
    if (fromRight) {
        table.cols.splice(index, 0, { type: BASIC_TYPES.COL, id: 0 });
    } else {
        table.cols.splice(index, 0, { type: BASIC_TYPES.COL, id: 0 });
    }

    // Update the IDs of the columns
    table.cols.forEach((col, i) => {
        col.id = i;
    });

    // Update the rows of the table to include the new column
    table.rows = table.rows.map((row) => {
        let newRow = { ...row }; // make a deep copy of the row
        for (let i = table.cols.length - 1; i >= index + 1; i--) {
            newRow[i] = newRow[i - 1];
        }
        newRow[index] = undefined;
        return newRow;
    });

    // Add a history record for the insertion
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.EDIT_BASIC,
            description: TablehistoryDescriptionTranslation.InsertedColumnInSpreadSheetTable,
            from: oldTable,
            to: table
        });
};
