export const UrlKeys = {
    centers: 'centers',
    branches: 'branches',
    sections: 'sections',
    accounts: 'accounts',
    jobTitles: 'job-titles',
    members: 'members',
    languages: 'languages',
    translations: 'translations',
    fileTemplate: 'file-template',
    constants: 'constants',
    dataSources: 'dataSources',
    dataSourcesRequests: 'dataSources-requests',
    memberCategories: 'member-categories',
    countries: 'countries',
    states: 'states',
    cities: 'cities',
    socialMedias: 'social-medias',
    religions: 'religions',
    doctrines: 'doctrines',
    maritalStatuses: 'marital-statuses',
    nicknames: 'nicknames',
    academics: 'academics',
    priorities: 'priorities',
    history: 'history',
    myProfile: 'my-profile'
};
