export const TOGGLE = {
    EMOJIS: 'emojis',
    IMAGE_VIEWER: 'image-viewer',
    // drawers
    CHAT_INFO: 'chat-info',
    LEFT_DRAWER: 'left-drawer',
    PINS: 'pins',
    STARS: 'stars',
    SETTINGS: 'settings',
    SEARCH: 'search',
    // modes
    EDIT_MODE: 'edit-mode',
    REPLY_MODE: 'reply-mode',
    SELECT_MODE: 'select-mode'
};
