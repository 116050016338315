const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    isSection: true,
    children: [
        {
            id: 'Copyright',
            title: `Copyright © 2023 Cyber Digital Technology`,
            type: 'item',
            url: '/',
            icon: '',
            external: true,
            target: false
        }
    ]
};

export default other;
