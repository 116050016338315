import React from 'react';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
const useMyAccount = () => {
    return {
        id: 'my-account',
        title: 'My Account',
        type: 'group',
        icon: <AccountCircleRoundedIcon />,
        children: [
            {
                id: 'chat',
                title: 'Chat',
                type: 'item',
                icon: QuestionAnswerRoundedIcon,
                url: '/chat',
                breadcrumbs: false
            },
            {
                id: 'notifications',
                title: 'Notifications',
                type: 'item',
                icon: NotificationsRoundedIcon,
                url: '/notifications',
                breadcrumbs: false
            },
            {
                id: 'my-profile',
                title: 'My Profile',
                type: 'item',
                icon: AccountCircleRoundedIcon,
                url: '/my-profile',
                breadcrumbs: false
            }
        ]
    };
};

export default useMyAccount;
