export const getCommonProperties = (row) => ({
    masterId: row.masterId,
    masterType: row.masterType,
    name: row.name,
    type: row.type,
    source: row.source,
    display: row.display,
    canAddMore: row.canAddMore
});
export const getUpdatedOptions = (options, data, isConstantValues) => {
    if (isConstantValues) {
        return [...options, data];
    } else {
        return options;
    }
};
export const getUpdatedTreeOptions = (treeOptions, data, isTree) => {
    if (isTree) {
        return [...treeOptions.map((o) => ({ ...o, id: o.ID })), { parentId: data.parentId, name: data.name, id: data.ID }];
    } else {
        return treeOptions;
    }
};
export const updateItem = (item, data) => {
    if (item.id === data.id) {
        return data;
    } else return { id: item.id, ...item };
};
