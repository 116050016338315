import React from 'react';

// MUI components
import ButtonBase from '@mui/material/ButtonBase';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Store
import { toggle } from 'features/store';

// Constants
import { TOGGLE } from 'constant';

const PinsButton = () => {
    const pins = useSelector((state) => state.chat.currentChat?.pins);
    const dispatch = useDispatch();
    const handleToggle = () => {
        dispatch(toggle({ type: TOGGLE.PINS }));
    };
    if (!pins) return '';
    return (
        <ButtonBase onClick={handleToggle} disabled={pins === null}>
            {pins.length !== 0 && `${pins.length} Pinned`}
        </ButtonBase>
    );
};

export default PinsButton;
