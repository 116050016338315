import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function useSystemLanguages() {
    const { systemLanguages } = useSelector((state) => state.systemLanguages);
    const { i18n } = useTranslation('form');
    const getName = (name) => {
        const _name = name?.thesaurus && name?.thesaurus[`${i18n.language}`] ? name?.thesaurus[i18n.language] : name?.value;
        return _name;
    };
    const getTranslation = (key) => {
        const lanBykey = systemLanguages?.find((ln) => ln.key == key);
        if (!systemLanguages || systemLanguages?.length == 0 || !lanBykey?.name) {
            return key;
        }
        return getName(lanBykey?.name);
    };

    return getTranslation;
}

export default useSystemLanguages;
