export const AppointmentTypePageConstant = {
    appointmentType: 'Reception_Management_Appointment_Type.appointmenttype',
    name: 'Reception_Management_Appointment_Type.name',
    icon: 'Reception_Management_Appointment_Type.icon',
    image: 'Reception_Management_Appointment_Type.image',
    description: 'Reception_Management_Appointment_Type.description',
    title: 'Reception_Management_Appointment_Type.title',
    parentName: 'Reception_Management_Appointment_Type.parentname',
    code: 'Reception_Management_Appointment_Type.code',
    shape: 'Reception_Management_Appointment_Type.shape',
    isActive: 'Reception_Management_Appointment_Type.isactive',
    treeList: 'Reception_Management_Appointment_Type.treelist',
    info: 'Reception_Management_Appointment_Type.info',
    uploadIcon: 'Reception_Management_Appointment_Type.uploadicon',
    edit: 'Reception_Management_Appointment_Type.edit',
    delete: 'Reception_Management_Appointment_Type.delete',
    treeViewer: 'Reception_Management_Appointment_Type.treeviewer',
    treeViewerAdd: 'Reception_Management_Appointment_Type.treevieweradd',
    treeViewerDelete: 'Reception_Management_Appointment_Type.treeviewerdelete',
    treeViewerEdit: 'Reception_Management_Appointment_Type.treevieweredit',
    treeViewerActions: 'Reception_Management_Appointment_Type.treevieweractions',
    actions: 'Reception_Management_Appointment_Type.actions',
    search: 'Reception_Management_Appointment_Type.search',
    reset: 'Reception_Management_Appointment_Type.reset',
    actions: 'Reception_Management_Appointment_Type.actions',
    selectLanguage: 'Reception_Management_Appointment_Type.selectlanguage',
    appointmentTypeParent: 'Reception_Management_Appointment_Type.appointmenttypeparent',
    history: 'Reception_Management_Appointment_Type.history'
};
