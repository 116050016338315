/* eslint-disable no-unused-vars */
import * as React from 'react';

// MUI Components
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grow from '@mui/material/Grow';
import ReplyIcon from '@mui/icons-material/Reply';

// store
import { useDispatch, useSelector } from 'react-redux';
import { replyTo, toggle } from 'features/store';
import { INITIAL_MENU, TOGGLE } from 'constant';

let ContextReply = ({ message, setMenu, menu, sender }) => {
    let dispatch = useDispatch();
    let starsOpen = useSelector((state) => state.chat.starsOpen);
    let replyMode = useSelector((state) => state.chat.replyMode);

    const handleReply = () => {
        dispatch(replyTo({ message, sender }));
        if (!replyMode) dispatch(toggle({ type: TOGGLE.REPLY_MODE }));
        setMenu(INITIAL_MENU);
    };

    return (
        <Grow unmountOnExit in={menu.mouseX !== null && !starsOpen} timeout={400}>
            <MenuItem onClick={handleReply}>
                <ListItemIcon>
                    <ReplyIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Reply</ListItemText>
            </MenuItem>
        </Grow>
    );
};

export default ContextReply;
