import { loadChat, resetNotifications, setChatMessages, setMessagesStatus } from 'features/store';
import { authAxios } from 'config/axios';
import { GET_MESSAGES } from 'env/server';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

let useGetMessages = () => {
    let dispatch = useDispatch();
    let fetch = () => {
        return authAxios.get(GET_MESSAGES(localStorage.getItem('currentChatId')));
    };
    let currentChat = useSelector((state) => state.chat.currentChat);
    const query = useQuery('getMessages', fetch, {
        enabled: false,
        onSuccess: (data) => {
            let messages = [];
            let reversedMessages = data.data.data;
            for (let i = reversedMessages.length - 1; i >= 0; i--) {
                messages.push(reversedMessages[i]);
            }

            const pins = [];
            for (let i = 0; i < currentChat.pins.length; i++) {
                if (messages.find((message) => currentChat.pins[i].messageId === message.id))
                    pins.push(messages.find((message) => currentChat.pins[i].messageId === message.id));
            }
            dispatch(setChatMessages({ messages, pins }));
            dispatch(resetNotifications());
            dispatch(loadChat(localStorage.getItem('currentChatId')));
            setTimeout(() => document.getElementById('chatBox').scrollBy({ top: document.getElementById('chatBox').scrollHeight }), 1000);
        }
    });

    useEffect(
        () => dispatch(setMessagesStatus({ isFetching: query.isFetching, isError: query.isError })),
        [query.isFetching, query.isError, dispatch]
    );

    return query;
};

export default useGetMessages;
