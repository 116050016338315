import { HISTORY_ACTIONS } from 'constant';
import { Notification, addHistoryRecord, getItemFromArray } from 'helpers';

/**
 * Inserts a layout before the currently selected layout in the state object, and updates the related pages.
 * @param {Object} state - The state object to modify.
 * @param {Object} payload - An object containing the ID of the layout to insert before the currently selected layout.
 * @param {string} payload.itemId - The ID of the layout to insert before the currently selected layout.
 */
export const insertBeforeLayout = (state, { payload }) => {
    // If the layout item to insert has the same ID as the currently selected layout item, show an error message and return.
    if (payload.itemId === state.currentLayoutId) {
        Notification.fire({ text: 'You need To Choose another Layout Element', icon: 'error' });
        return;
    }

    // Get the layout item to insert.
    const layoutItem = getItemFromArray({ array: state.layout, id: state.currentLayoutId });

    const oldParent = { ...state.page.find((item) => item.items.find((item) => item === state.currentLayoutId) !== undefined) };
    // Remove the layout item to insert from its current position in the related page items.
    state.page = state.page.map((item) => ({ ...item, items: item.items.filter((id) => id !== state.currentLayoutId) }));

    // Insert the layout item before the currently selected layout item in the related page items.
    state.page.forEach((item) => {
        const newItems = [];
        item.items.forEach((id) => {
            if (id === payload.itemId) newItems.push(state.currentLayoutId);
            newItems.push(id);
        });
        item.items = newItems;
    });

    const newParent = { ...state.page.find((item) => item.items.find((item) => item === state.currentLayoutId) !== undefined) };

    // Add a history record for the insertion action.
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.INSERT_BEFORE_LAYOUT,
            description: HISTORY_ACTIONS.INSERT_BEFORE_LAYOUT.description,
            from: { oldParent, newParent: { ...newParent, items: newParent.items.filter((item) => item !== state.currentLayoutId) } },
            to: { oldParent: { ...oldParent, items: oldParent.items.filter((item) => item !== state.currentLayoutId) }, newParent }
        });
};
