import { authAxios } from 'config/axios';
import { DATASOURCE } from 'env/server';
import { getSelectItems } from './getSelectItems';
import { convertToValidUrl } from './convertToValidUrl';
import { getActiveValue } from './getActiveValue';
import { DATASOURCE_TYPES } from 'constant';
export const getTableSelectOptions = async (id) => {
    const dataSource = await authAxios.get(DATASOURCE.GET + '/?id=' + id);
    const dataSourceValues = dataSource?.data?.data?.[0];
    const isExternalDataSource = DATASOURCE_TYPES.From_Remote_Api === dataSourceValues?.type;
    const isConstantValues = DATASOURCE_TYPES.Constant_Values === dataSourceValues?.type;
    /* const isTreeValues = DATASOURCE_TYPES.Tree === dataSourceValues?.type; */
    let externalData;
    if (isExternalDataSource) externalData = await authAxios.get(convertToValidUrl(dataSourceValues?.source));
    /* const treeOptions = dataSourceValues?.treeOptions || []; */
    const options = dataSourceValues?.options;
    const externalDataValues = externalData?.data?.data;
    /* if (isTreeValues || control === FORMIK_CONTROL_TYPES.TREE) return { treeOptions, display: dataSourceValues?.display }; */
    if (isExternalDataSource) return getSelectItems(externalDataValues || [], dataSourceValues?.display);
    if (isConstantValues) return options?.map(({ optionText, optionValue }) => ({ key: getActiveValue(optionText), value: optionValue }));

    return [];
};
