import React from 'react';

// Redux
import { useSelector } from 'react-redux';

// Components
import Call from './Call';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

let empty = (
    <Box
        sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flexWrap: 'wrap'
        }}
    >
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <img src="https://cdn-icons-png.flaticon.com/512/7486/7486747.png" style={{ width: '40%' }} />
            </Box>
            <Typography variant="h4" fontWeight="900" textAlign="center" color="text.secondary" sx={{ width: '100%' }}>
                Empty
            </Typography>
        </Box>
    </Box>
);
const Calls = () => {
    let calls = useSelector((state) => state.chat.calls);
    if (!calls) return empty;
    return calls.map((call, i) => <Call call={call} key={i} timeout={(i + 3) * 200} />);
};

export default Calls;
