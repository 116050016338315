import { useState } from 'react';

export const useDialog = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const onClose = () => setOpen(false);

    return { open, setOpen, handleOpen, handleClose, onClose };
};
