import React from 'react';

// MUI components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Project components
import NavGroup from './NavGroup';
import NavItem from './NavItem';

// Custom hooks
import useMenuItems from 'layout/MainLayout/Sidebar/menu-items';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/styles/useTheme';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const isSideBarItemHasPrivilege = (item, privilege_key) => {
    if (item == null) {
        return false;
    }
    if (!('privilege' in item)) {
        return false;
    }
    const has = item.privilege.includes(privilege_key);
    if (has) {
        return true;
    }
    return isSideBarItemHasPrivilege(item.privilege, privilege_key);
};

const isSideBarItemHasOneOfPrivileges = (item, privilege_keys) => {
    for (let i = 0; i < privilege_keys.length; i++) {
        const has = isSideBarItemHasPrivilege(item, privilege_keys[i]);
        if (has) {
            return true;
        }
    }
    return false;
};

const MenuList = () => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const menuItems = useMenuItems();
    const navItems = [];
    menuItems.items.forEach((item, i, arr) => {
        /*  if (isSideBarItemParentHasOneOfRoles(item, roles)) {  */
        const new_item = { ...item };
        new_item.children = [];
        for (let j = 0; j < item?.children?.length; j++) {
            /* if (isSideBarItemParentHasOneOfRoles(item.children[j], roles)) { */
            new_item.children.push(item.children[j]);
            /* } */
        }
        switch (new_item.type) {
            case 'group': {
                navItems.push(
                    <NavGroup key={new_item.id} icon={item.icon} item={new_item} isCollapse={i !== arr.length - 1 ? true : false} />
                );
                break;
            }
            case 'item': {
                navItems.push(<NavItem key={new_item.id} item={new_item} level={1} />);
                break;
            }

            default: {
                navItems.push(
                    <Typography key={new_item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
                break;
            }
        }
        /*         }
         */
    });

    return (
        <React.Fragment>
            <Box
                sx={{
                    height: matchUpMd ? 'calc(100vh - 100px)' : '100vh',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                    '& *': {
                        whiteSpace: 'break-spaces !important',
                        boxShadow: 'none !important'
                    }
                }}
            >
                {navItems}
            </Box>
        </React.Fragment>
    );
};

export default React.memo(MenuList);
