const server_errors = [
   
];

const DEFAULT_ERROR_MESSAGE = 'Unknown error';

export const getErrorMessageByCode = (code) => {
    let error = server_errors.find((e) => e.code == code);
    let message = error?.message;
    if (!error) {
        message = DEFAULT_ERROR_MESSAGE;
    }
    return message;
};
