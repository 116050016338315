import * as React from 'react';

// constants
import { INITIAL_MENU, DIALOGS } from 'constant';

// MUI Components
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grow from '@mui/material/Grow';

// icons
import { IconPinnedOff } from '@tabler/icons';
import PushPinIcon from '@mui/icons-material/PushPin';

// store
import { useDispatch, useSelector } from 'react-redux';
import { toggleDialog } from 'features/store';

const ContextPin = ({ message, setMenu, menu }) => {
    // store
    const pins = useSelector((state) => state.chat.currentChat?.pins);
    const pinned = pins?.find((pin) => pin?.id === message?.id);

    // hooks
    const dipatch = useDispatch();

    if (!pins) return '';

    return (
        <React.Fragment>
            <Grow unmountOnExit in={menu.mouseX !== null} timeout={800}>
                <MenuItem
                    onClick={() => {
                        dipatch(toggleDialog({ name: DIALOGS.PIN, data: message }));
                        setMenu(INITIAL_MENU);
                    }}
                >
                    {pinned ? (
                        <React.Fragment>
                            <ListItemIcon>
                                <IconPinnedOff />
                            </ListItemIcon>
                            <ListItemText>Un Pin</ListItemText>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <ListItemIcon>
                                <PushPinIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Pin</ListItemText>
                        </React.Fragment>
                    )}
                </MenuItem>
            </Grow>
        </React.Fragment>
    );
};

export default ContextPin;
