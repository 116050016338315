import { HISTORY_ACTIONS } from 'constant';
import { addHistoryRecord } from 'helpers';

export const setHeader = (state, { payload }) => {
    const oldHeader = state.view.header;
    state.view.header = payload;

    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.EDIT_HEADER,
            description: HISTORY_ACTIONS.EDIT_HEADER.description,
            from: oldHeader,
            to: payload
        });
};
