import { setCurrentLayout } from '../common';
import { addLayoutFunc } from './addLayoutFunc';

/**
 * Calls the addLayoutFunc function to add a new layout item to the layout array in state and sets the current layout item.
 * @param {object} state - The state object.
 * @param {object} payload - An object containing the properties of the new layout item.
 */
export const addLayout = (state, { payload }) => {
    // Call the addLayoutFunc function to add the new layout item to the layout array
    addLayoutFunc(state, { payload });

    // Set the current layout item to the newly added item
    const id = state.layout[state.layout.length - 1].id;
    setCurrentLayout(state, { payload: id });
};
