import React from 'react';

// Icons
import Translate from '@mui/icons-material/Translate';
import { IconUser, IconUserCircle, IconUserPlus, IconBriefcase } from '@tabler/icons';

// Constant
import { roles } from 'env/roles';
import { useTranslation } from 'react-i18next';
import { LanguagesPageConstant } from 'constant/Translations/languages-page';
import { TranslationsPageConstant } from 'constant/Translations/translations-page';

const icons = { IconUserCircle, IconUserPlus, IconUser, IconBriefcase };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const useLanguages = () => {
    const { t } = useTranslation();
    return {
        id: 'Languages',
        title: 'Languages',
        type: 'group',
        caption: 'Languages',
        icon: <Translate />,
        role: [roles.SettingLanguageAdd, roles.SettingLanguageGet, roles.SettingLanguageUpdate],
        children: [
            {
                id: 'languages',
                title: t(LanguagesPageConstant.languages),
                type: 'item',
                url: '/languages',
                role: [roles.Admin],
                icon: icons.IconBriefcase,
                breadcrumbs: false
            },
            {
                id: 'system-languages',
                title: 'System Languages',
                type: 'item',
                url: '/translations',
                role: [roles.Admin],
                icon: icons.IconBriefcase,
                breadcrumbs: false
            }
        ]
    };
};

export default useLanguages;
