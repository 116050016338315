export const ACADEMIC_TYPES = {
    INSTITUTION: 7,
    STUDY: 8,
    DEPARTMENT: 9
};

export const DYNAMIC_FIELDS_TYPES = {
    TEXT: 'text',
    NUMBER: 'number',
    MULTI_TEXT: 'multi text',
    SELECT: 'select',
    PASSWORD: 'password',
    CHECKBOX_LABEL: 'check box lable',
    DATE: 'date',
    SELECT_MULTIPLE_CHIP: 'select multiple chip',
    TEXT_SMALL: 'text small',
    RADIO_GROUP: 'radio group',
    GROUP: 'group'
};

export const DATASOURCE_TYPES = {
    Constant_Values: 0,
    From_Remote_Api: 1,
    Tree: 2
};

export const CREATED_OPTIONS_IN_DATASOURCE = {
    Admin: 0,
    Manual: 1
};

export const ProfessionalLevelType = {
    /// Numbers (examples: [1, 2, 3, 4, 5], [1, 2, 3, ...., 10]])
    Numeric: 0,
    /// Characters (exampls: [A, B, C, ...etc], [Bad, Good, Very Good, ...etc], ...etc)
    Characters: 1,
    /// Stars (exampls: [*, **, ***])
    Stars: 2
};
