import React from 'react';

// MUI components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { FadeTransition } from '../MenuList/NavGroup';

// Components
import LogoSection from 'layout/MainLayout/LogoSection';
import ToggleSidebar from 'layout/MainLayout/Header/ToggleSidebar';

// hooks
import useSidebar from 'hooks/useSidebar';
import useTheme from '@mui/styles/useTheme';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const { open } = useSidebar();
    const theme = useTheme();
    return (
        <React.Fragment>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    padding: open ? '10px 0 10px 10px' : '10px',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <FadeTransition open={open} unmountOnExit style={{ width: '100%' }}>
                    <Grid container spacing={1} sx={{ alignItems: 'center' }}>
                        <Grid item xs={3} display="flex" justifyContent="center">
                            <Box
                                sx={{
                                    padding: '2px',
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: '50%',
                                    width: '56px'
                                }}
                            >
                                <LogoSection />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h4" color="text.primary">
                                CDT
                            </Typography>
                            <Typography variant="h6" color="text.secondary">
                                Cyber Digital Technology
                            </Typography>
                        </Grid>
                        <Grid item xs={3} display="flex" justifyContent="center">
                            <ToggleSidebar />
                        </Grid>
                    </Grid>
                </FadeTransition>
                <FadeTransition
                    open={!open}
                    unmountOnExit
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        '&:hover path': { color: theme.palette.primary.main }
                    }}
                >
                    <ToggleSidebar />
                </FadeTransition>
            </Box>
        </React.Fragment>
    );
};

export default Header;
