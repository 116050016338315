import React from 'react';

// MUI Components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// constants
import { MESSAGE_TYPE } from 'constant';

let Header = ({ lastSenderIsSame, message, senderIsYou, sender }) =>
    senderIsYou ? (
        <Box
            sx={{
                display: 'flex',
                justifyContent: message?.forward !== null ? 'space-between' : 'flex-start',
                alignItems: 'center',
                padding: message.type !== 0 ? '5px' : 0,
                marginBottom: message.type !== 0 ? '-5px' : 0,
                backgroundColor: 'transparent',
                '& *': {
                    fontWeight: 'bold'
                },
                width: '100%'
            }}
        >
            {!lastSenderIsSame && (
                <Typography component={'div'} sx={{ marginRight: !lastSenderIsSame && '20px' }}>
                    {sender?.customeName ? sender.customeName : ''}
                </Typography>
            )}
            {message.forward && (
                <Box sx={{ display: 'flex', alignItems: 'center', color: message.type === MESSAGE_TYPE.TEXT ? '#EEE' : '#333' }}>
                    Forwarded
                </Box>
            )}
        </Box>
    ) : (
        <Box
            sx={{
                display: 'flex',
                justifyContent: message?.forward !== null || lastSenderIsSame ? 'space-between' : 'flex-end',
                alignItems: 'center',
                padding: message.type !== 0 ? '5px' : 0,
                marginBottom: message.type !== 0 ? '-5px' : 0,
                backgroundColor: 'transparent',
                '& *': {
                    fontWeight: 'bold'
                }
            }}
        >
            {message?.forward && (
                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: !lastSenderIsSame && '20px', color: '#666' }}>Forwarded</Box>
            )}
            {!lastSenderIsSame && <Typography component={'div'}>{sender?.customeName ? sender.customeName : ''}</Typography>}
        </Box>
    );

export default Header;
