export const MyAcoountsPageConstant = {
    myAcoounts: 'Profile_My_Accounts.myacoounts',
    myProfile: 'Profile_My_Accounts.myprofile',
    updateAccountNumber: 'Profile_My_Accounts.updateaccountnumber',
    username: 'Profile_My_Accounts.username',
    email: 'Profile_My_Accounts.email',
    accountName: 'Profile_My_Accounts.accountname',
    update: 'Profile_My_Accounts.update',
    titleResetPassword: 'Profile_My_Accounts.titleresetpassword',
    oldPassword: 'Profile_My_Accounts.oldpassword',
    newPassword: 'Profile_My_Accounts.newpassword',
    confirmedNewPassword: 'Profile_My_Accounts.confirmednewpassword',
    resetPassword: 'Profile_My_Accounts.resetpassword',
    close: 'Profile_My_Accounts.close',
    submit: 'Profile_My_Accounts.submit',
    phoneNumber: 'Profile_My_Accounts.phonenumber',
    updatePhoneNumber: 'Profile_My_Accounts.updatephonenumber',
    required: 'Profile_My_Accounts.required',
    updatePhoneNumbers: 'Profile_My_Accounts.updatephonenumbers',
    mobileNumber: 'Profile_My_Accounts.mobilenumber',
    whatsappNumber: 'Profile_My_Accounts.whatsappnumber',
    telegramNumber: 'Profile_My_Accounts.telegramnumber'
};
