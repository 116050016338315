import { FORMIK_CONTROL_TYPES } from '../Global/formikControlTypes';

export const SCIENTIFIC_FILE_FIELDS = [
    {
        name: 'Study',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.TEXT
    },
    {
        name: 'Year of Study',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.NUMBER
    },
    {
        name: 'Certificate Pic',
        required: false,
        hidden: false,
        type: 'file'
    },
    {
        name: 'Graduation Date',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.DATE
    },
    {
        name: 'Graduation Certified',
        required: false,
        hidden: false,
        type: 'file'
    },
    {
        name: 'Franchise Date',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.DATE
    },
    {
        name: 'Franchise Number',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.NUMBER
    },
    {
        name: 'Perpetual License Date',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.DATE
    },
    {
        name: 'Permanent License Number',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.NUMBER
    },
    {
        name: 'Languages',
        required: false,
        hidden: false,
        type: FORMIK_CONTROL_TYPES.SELECT
    },
    {
        name: 'Cv',
        required: false,
        hidden: false,
        type: 'file'
    }
];
