import React, { useState } from 'react';

// MUI components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';

// MUI Icons
import EditIcon from '@mui/icons-material/Edit';
import AddSharpIcon from '@mui/icons-material/AddSharp';

// MUI hooks
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { changeChatInfo } from 'features/store';

let Edit = () => {
    const currentChat = useSelector((state) => state.chat.currentChat);

    const [open, setOpen] = useState(false);
    const [image, setImage] = useState(currentChat.image);
    const [name, setName] = useState(currentChat.name);
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    let dispatch = useDispatch();
    let handleChangeChatName = (e) => {
        setName(e.target.value);
    };
    let handleChangeInfoImage = (e) => {
        const reader = new FileReader();
        reader.onloadend = (url) => {
            setImage(url.currentTarget.result);
        };
        reader.readAsDataURL(e.target.files[0]);
    };
    let handleSave = () => {
        dispatch(changeChatInfo({ image: image, name: name }));
        setOpen(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleClickOpen}>
                <EditIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                    '.MuiPaper-root': {
                        width: !fullScreen ? '60%' : '100%'
                    }
                }}
                fullScreen={fullScreen}
            >
                <Grow in={open} timeout={400} unmountOnExit>
                    <DialogTitle>Edit Chat Info</DialogTitle>
                </Grow>
                <DialogContent>
                    <Grow in={open} timeout={600} unmountOnExit>
                        <Typography padding={'10px'} color="text.secondary">
                            Name
                        </Typography>
                    </Grow>
                    <Grow in={open} timeout={800} unmountOnExit>
                        <TextField type="text" defaultValue={name} fullWidth onChange={handleChangeChatName} />
                    </Grow>
                    <Grow in={open} timeout={1000} unmountOnExit>
                        <Typography padding={'10px'} color="text.secondary">
                            Chat Image
                            <IconButton onClick={() => document.getElementById('chatInfoImage').click()}>
                                <AddSharpIcon />
                            </IconButton>
                        </Typography>
                    </Grow>
                    <Grow in={open} timeout={1200} unmountOnExit>
                        <img style={{ width: '100%', height: 'auto', borderRadius: '20px' }} src={image} alt="none" />
                    </Grow>
                    <input type="file" id="chatInfoImage" style={{ display: 'none' }} onChange={handleChangeInfoImage} />
                </DialogContent>
                <Grow in={open} timeout={1200} unmountOnExit>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                        <Button onClick={handleSave}>Save</Button>
                    </DialogActions>
                </Grow>
            </Dialog>
        </React.Fragment>
    );
};

export default Edit;
