import { scrollEle } from 'helpers';

let addMessage = (state, { payload }) => {
    payload.reactions = [];
    if (state.currentChat !== null && state.currentChat.id === payload.chatId)
        state.currentChat.messages = [...state.currentChat.messages, payload];
    let chat = state.chats.find((chat) => chat.id == payload.chatId);
    chat.messages = [...chat.messages, payload];

    if (chat.id !== state.currentChat?.id || state.currentChat === null) {
        chat.notifications += 1;
    }
    scrollEle(document.getElementById('chatBox'), 100);
};
export default addMessage;
