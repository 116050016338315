import axios from 'axios';
import { initChats } from 'features/store';
import { authAxios } from 'config/axios';
import { GET_CHATS } from 'env/server';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

function useGetChats() {
    let dispatch = useDispatch();
    let chats = useSelector((state) => state.chat.chats);
    const fetch = () => {
        return authAxios.get(GET_CHATS)
    };
    let query = useQuery('chats', fetch, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onSuccess: (data) => {
            let chats = JSON.parse(JSON.stringify(data.data.data));
            chats.forEach((chat) => {
                let messages = [];
                let reversedMessages = chat.messages;
                for (let i = reversedMessages.length - 1; i >= 0; i--) {
                    messages.push(reversedMessages[i]);
                }
                chat.messages = messages;
                chat.notifications = 0;
            });
            dispatch(initChats(chats));
        }
    });

    return { ...query, chats };
}

export default useGetChats;
