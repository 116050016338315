import React from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setPhotoIndex, toggle } from 'features/store';

// Third-Party
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import Lightbox from 'react-image-lightbox';

// Constants
import { TOGGLE } from 'constant';

let ImageViewer = () => {
    let imageViewerOpen = useSelector((state) => state.chat.imageViewerOpen);
    let images = useSelector((state) => state.chat.images);
    let dispatch = useDispatch();
    let photoIndex = useSelector((state) => state.chat.photoIndex);
    let handleCloseImageViewer = () => {
        dispatch(toggle({ type: TOGGLE.IMAGE_VIEWER }));
    };

    if (!images) return '';

    let movePrev = () => dispatch(setPhotoIndex((photoIndex + images.length - 1) % images.length));
    let moveNext = () => dispatch(setPhotoIndex((photoIndex + 1) % images.length));

    return (
        imageViewerOpen && (
            <Lightbox
                mainSrc={images[photoIndex].url}
                nextSrc={images[(photoIndex + 1) % images.length].url}
                prevSrc={images[(photoIndex + images.length - 1) % images.length].url}
                onCloseRequest={handleCloseImageViewer}
                onMovePrevRequest={movePrev}
                onMoveNextRequest={moveNext}
            />
        )
    );
};

export default ImageViewer;
