import { BASIC_TYPES, HISTORY_ACTIONS } from 'constant';
import { addHistoryRecord, generateRandomTableColId, getItemFromArray } from 'helpers';
import { addBasicFunc } from '.';
import { setCurrentLayout } from '../common';
import { DuplicatedBasicDescriptionTranslation } from '../functions/history/addOrEditBasicDescriptionTranslation';

/**
 * Adds a new basic item to the state by duplicating an existing item, and adds a history record for the duplication.
 * @param {object} state - The state object.
 * @param {object} payload.item - The item to duplicate and add to the state.
 */
export const duplicateBasic = (state, { payload }) => {
    setCurrentLayout(state, {
        payload: state.layout.find((l) => {
            if (l.items.find((id) => id === payload.item.id) !== undefined) return true;
            else return false;
        })?.id
    });
    let item = { ...payload.item };
    if (payload.item.type === BASIC_TYPES.DATAGRID)
        item = { ...payload.item, cols: payload?.item?.cols.map((col) => ({ ...col, id: generateRandomTableColId() })) };
    // Call addBasicFunc to add a duplicated item to basic array in state
    addBasicFunc(state, item);

    // Add a history record for the duplication
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.DUPLICATE_BASIC,
            description: DuplicatedBasicDescriptionTranslation[`${payload.item.type}`],
            from: payload.item,
            to: {
                layoutItem: getItemFromArray({ array: state.layout, id: state.currentLayoutId }),
                basicItem: state.basic[state.basic.length - 1]
            }
        });
};
