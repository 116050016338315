export const DataSourcePageConstant = {
    dataSources: 'Settings_File_Templates_Data_Sources.datasources',
    dataSource: 'Settings_File_Templates_Data_Sources.datasource',
    name: 'Settings_File_Templates_Data_Sources.name',
    code: 'Settings_File_Templates_Data_Sources.code',
    displayData: 'Settings_File_Templates_Data_Sources.displaydata',
    type: 'Settings_File_Templates_Data_Sources.type',
    source: 'Settings_File_Templates_Data_Sources.source',
    display: 'Settings_File_Templates_Data_Sources.display',
    optionText: 'Settings_File_Templates_Data_Sources.optiontext',
    optionValue: 'Settings_File_Templates_Data_Sources.optionvalue',
    options: 'Settings_File_Templates_Data_Sources.options',
    parent: 'Settings_File_Templates_Data_Sources.parent',
    text: 'Settings_File_Templates_Data_Sources.text',
    canAddMore: 'Settings_File_Templates_Data_Sources.canaddmore',
    treeOption: 'Settings_File_Templates_Data_Sources.treeoption',
    displayHover: 'Settings_File_Templates_Data_Sources.displayhover',
    titleTooltip: 'Settings_File_Templates_Data_Sources.titletooltip',
    option: 'Settings_File_Templates_Data_Sources.option',
    edit: 'Settings_File_Templates_Data_Sources.edit',
    delete: 'Settings_File_Templates_Data_Sources.delete'
};
