import { setImages } from 'features/store';
import { authAxios } from 'config/axios';
import { MESSAGE_TYPE } from 'constant';
import { GET_CHAT_MEDIA } from 'env/server';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

const useGetMedia = (type) => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [media, setMedia] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const fetch = () => {
        return authAxios.get(GET_CHAT_MEDIA(localStorage.getItem('currentChatId'), type, page));
    };
    const query = useQuery([type, page], fetch, {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        onSuccess: (data) => {
            if (data.data.data.length / 50 !== 1) setDisabled(true);
            setMedia([...media, ...data.data.data]);
            if (type === MESSAGE_TYPE.IMAGE) dispatch(setImages(data.data.data));
        }
    });
    return {
        ...query,
        page,
        media,
        setPage,
        disabled
    };
};

export default useGetMedia;
