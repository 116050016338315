import { FT_MODES } from 'constant';

export const setFileTemplatesMode = (state, { payload }) => {
    state.fileTemplatesMode = payload;
    if (payload === null || payload === FT_MODES.PREVIEW) {
        state.currentBasicId = null;
        state.current = null;
        state.selectedBasic = [];
        state.selectedLayouts = [];
        state.selectedPage = [];
        state.selectedDataGridCols = [];
    }
};
