import { DEFAULT_ROW, HISTORY_ACTIONS } from 'constant';
import { TablehistoryDescriptionTranslation } from 'constant/FileTemplate/tablehistoryDescriptionTranslation';
import { addHistoryRecord, getItemFromArray } from 'helpers';

/**
 * Adds multiple rows to a spreadsheet table in the state.
 * @param {object} state - The current state of the application.
 * @param {object} payload - An object containing the properties number, fromTop, and tableId.
 * @param {number} payload.number - The number of rows to add.
 * @param {boolean} payload.fromTop - Whether to add the rows to the top (true) or bottom (false) of the table.
 * @param {string} payload.tableId - The ID of the table to add the rows to.
 */
export const addMultipleSpreadSheetRows = (state, { payload }) => {
    const { number, fromTop, tableId } = payload;

    // Retrieve the table object with the given tableId from the basic array in the state using the getItemFromArray function
    let table = getItemFromArray({ array: state.basic, id: tableId });
    const oldTable = { ...table };
    // Create an empty array called rows
    const rows = [];
    // Loop number times and push a copy of the DEFAULT_ROW object to the rows array each time
    for (let i = 0; i < number; i++) rows.push(DEFAULT_ROW);
    // If fromTop is true, prepend the rows array to the table.rows array using the spread operator, and then map over the resulting array to add an id property to each row object
    if (fromTop) table.rows = [...rows, ...table.rows].map((row, i) => ({ ...row, id: i }));
    // If fromTop is false, append the rows array to the table.rows array using the spread operator, and then map over the resulting array to add an id property to each row object
    else table.rows = [...table.rows, ...rows].map((row, i) => ({ ...row, id: i }));

    // Call the addHistoryRecord function with an object that contains state and a message object with an icon and a message indicating that number cols were added to the table with ID tableId
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.EDIT_BASIC,
            description: TablehistoryDescriptionTranslation.AddedRowsToTable,
            from: oldTable,
            to: table
        });
};
