import React, { lazy } from 'react';

// Components
import Loadable from 'ui-component/Loadable';
import pharmacyRoutes from './pharmacy';

const ComingSoon = Loadable(lazy(() => import('views/coming-soon')));
const Physicians = Loadable(lazy(() => import('views/medical-management/physicians')));
const Nurses = Loadable(lazy(() => import('views/medical-management/nurses')));
const ClinicalDepartments = Loadable(lazy(() => import('views/medical-management/clinical-departments')));
const OperationsDepartments = Loadable(lazy(() => import('views/medical-management/operations-departments')));

const routes = [
    ...pharmacyRoutes,
    {
        path: '/radiology-and-imaging',
        element: <ComingSoon />
    },
    {
        path: '/physicians',
        element: <Physicians />
    },
    {
        path: '/nurses',
        element: <Nurses />
    },
    {
        path: '/clinical-departments',
        element: <ClinicalDepartments />
    },
    {
        path: '/operations-departments',
        element: <OperationsDepartments />
    }
];
export default routes;
