import { pinMessage, unPinMessage } from 'features/store';
import { authAxios } from 'config/axios';
import { PIN_MESSAGE, UN_PIN_MESSAGE } from 'env/server';
import { connection } from 'layout/ChatLayout';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

let usePinMessage = ({ justForUser, messageId, pinned }) => {
    const dispatch = useDispatch();
    const fetch = async () =>
        await authAxios.put(
            pinned
                ? UN_PIN_MESSAGE(localStorage.getItem('currentChatId'), messageId)
                : PIN_MESSAGE(localStorage.getItem('currentChatId'), messageId)
        );
    const query = useQuery('pinMessage', fetch, {
        enabled: false
    });

    return () => {
        if (justForUser) {
            query.refetch();
        } else {
            if (pinned)
                connection.invoke('UnPinMessage', localStorage.getItem('currentChatId'), localStorage.getItem('accountId'), messageId);
            else connection.invoke('PinMessage', localStorage.getItem('currentChatId'), localStorage.getItem('accountId'), messageId);
        }
        dispatch(
            pinned
                ? unPinMessage({ chatId: localStorage.getItem('currentChatId'), messageId })
                : pinMessage({ chatId: localStorage.getItem('currentChatId'), messageId })
        );
    };};

export default usePinMessage;
