export const PatientRecordsPageConstant = {
    patient: 'Administrative_Patient.patient',
    patients: 'Administrative_Patient.patients',
    patientRecords: 'Administrative_Patient.patientrecords',
    patientRecords: 'Administrative_Patient.patientrecords',
    fileNumber: 'Administrative_Patient.filenumber',
    card: 'Administrative_Patient.card',
    updatedDate: 'Administrative_Patient.updateddate',
    createdDate: 'Administrative_Patient.createddate',
    typeVisit: 'Administrative_Patient.typevisit',
    visits: 'Administrative_Patient.visits',
    personalInfo: 'Administrative_Patient.personalinfo',
    fullName: 'Administrative_Patient.fullname',
    nameSpecialist: 'Administrative_Patient.namespecialist',
    firstName: 'Administrative_Patient.firstname',
    surname: 'Administrative_Patient.surname',
    fathername: 'Administrative_Patient.fathername',
    mothername: 'Administrative_Patient.mothername',
    birthDay: 'Administrative_Patient.birthday',
    nickName: 'Administrative_Patient.nickname',
    gender: 'Administrative_Patient.gender',
    uploadPersonalImage: 'Administrative_Patient.uploadpersonalimage',
    identityInfo: 'Administrative_Patient.identityinfo',
    blood: 'Administrative_Patient.blood',
    nationalNumber: 'Administrative_Patient.nationalnumber',
    identityNumber: 'Administrative_Patient.identitynumber',
    alamana: 'Administrative_Patient.alamana',
    alqaid: 'Administrative_Patient.alqaid',
    alqaidNumber: 'Administrative_Patient.alqaidnumber',
    passportNumber: 'Administrative_Patient.passportnumber',
    religion: 'Administrative_Patient.religion',
    doctrine: 'Administrative_Patient.doctrine',
    maritalStatus: 'Administrative_Patient.maritalstatus',
    childNumber: 'Administrative_Patient.childnumber',
    birthPlace: 'Administrative_Patient.birthplace',
    country: 'Administrative_Patient.country',
    city: 'Administrative_Patient.city',
    state: 'Administrative_Patient.state',
    about: 'Administrative_Patient.about',
    locatedText: 'Administrative_Patient.locatedtext',
    remark: 'Administrative_Patient.remark',
    extraAttachment: 'Administrative_Patient.extraattachment',
    key: 'Administrative_Patient.key',
    uploadAttachment: 'Administrative_Patient.uploadattachment',
    residence: 'Administrative_Patient.residence',
    area: 'Administrative_Patient.area',
    street: 'Administrative_Patient.street',
    address: 'Administrative_Patient.address',
    addressSecound: 'Administrative_Patient.addresssecound',
    zipCode: 'Administrative_Patient.zipcode',
    contactInfo: 'Administrative_Patient.contactinfo',
    phoneNumber: 'Administrative_Patient.phonenumber',
    email: 'Administrative_Patient.email',
    emailKey: 'Administrative_Patient.emailkey',
    emailValue: 'Administrative_Patient.emailvalue',
    contact: 'Administrative_Patient.contact',
    contactKey: 'Administrative_Patient.contactkey',
    contactValue: 'Administrative_Patient.contactvalue',
    socialMedia: 'Administrative_Patient.socialmedia',
    socialMediaKey: 'Administrative_Patient.socialmediakey',
    socialMediaValue: 'Administrative_Patient.socialmediavalue',
    value: 'Administrative_Patient.value',
    professionAndScientific: 'Administrative_Patient.professionandscientific',
    patientProfession: 'Administrative_Patient.patientprofession',
    addProfession: 'Administrative_Patient.addprofession',
    addScientific: 'Administrative_Patient.addscientific',
    patientScientificLevels: 'Administrative_Patient.patientscientificlevels',
    name: 'Administrative_Patient.name',
    description: 'Administrative_Patient.description',
    icon: 'Administrative_Patient.icon',
    actions: 'Administrative_Patient.actions',
    yearsOld: 'Administrative_Patient.yearsold',
    age: 'Administrative_Patient.age',
    isActive: 'Administrative_Patient.isactive',
    male: 'Administrative_Patient.male',
    female: 'Administrative_Patient.female',
    none: 'Administrative_Patient.none',
    visits: 'Administrative_Patient.visits',
    notes: 'Administrative_Patient.notes',
    followUps: 'Administrative_Patient.followups',
    processes: 'Administrative_Patient.processes',
    addVisit: 'Administrative_Patient.addvisit',
    reset: 'Administrative_Patient.reset'
};
