import React from 'react';

// MUI coponents
import ButtonBase from '@mui/material/ButtonBase';

// Components
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={'a'} target="_blank" href="/">
        <Logo />
    </ButtonBase>
);

export default React.memo(LogoSection);
