import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Environment variables
import { inRole, roles } from 'env/roles';

// Features
import { getCenterComibeSections, getCenterSections } from 'features/Sections/sectionsSlice';

// Helpers
import { sectionMapper } from 'helpers';

// Menus
import other from './other';
import useMyAccount from './my-account';
import useSystemAdministrator from './systemAdministrator';
import useAdministrativeManagement from './administrative-management';
import useLanguages from './languages';
import useFileTemplates from './fileTemplates';
import useSettings from './settings';
import useHistory from './history';
import useSurgicalOperationsManagement from './surgical-operations-management';
import useTechnologyAndInformationManagement from './technology-and-information-management';
import useFinancialManagement from './financial-management';
import useMedicalData from './medicalData';
import useReceptionDepartment from './receptionDepartment';

// ==============================s|| MENU ITEMS ||============================== //

const useMenuItems = () => {
    const { centerSections, centerCombinSections } = useSelector((state) => state.sections);
    const myRoles = useSelector((state) => state.auth.account.roles);

    const dispatch = useDispatch();
    useEffect(() => {
        if (inRole(myRoles, roles.Center) || inRole(myRoles, roles.Branch)) {
            dispatch(getCenterSections());
            dispatch(getCenterComibeSections());
        }
    }, []);
    const sectionDrwar = sectionMapper(centerSections, centerCombinSections);
    const systemAdministrator = useSystemAdministrator();
    const administrativeManagement = useAdministrativeManagement();
    const languages = useLanguages();
    const fileTemplates = useFileTemplates();
    const settings = useSettings();
    const history = useHistory();
    const surgicalOperationsManagement = useSurgicalOperationsManagement();
    const technologyAndInformationManagement = useTechnologyAndInformationManagement();
    const financialManagement = useFinancialManagement();
    const myAccount = useMyAccount();
    const medicalData = useMedicalData();
    const receptionDepartment = useReceptionDepartment();
    const menuItems = {
        items: [
            systemAdministrator,
            administrativeManagement,
            medicalData,
            receptionDepartment,
            surgicalOperationsManagement,
            technologyAndInformationManagement,
            financialManagement,
            languages,
            settings,
            history,
            myAccount,
            // patientsDepartment,
            /* sections, */
            /* systemLanguages, */
            fileTemplates,
            ...sectionDrwar,
            other
        ]
    };
    return menuItems;
};

export default useMenuItems;
