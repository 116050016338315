import React from 'react';
import { useState } from 'react';

// MUI Components
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

// constants
import { REACTIONS } from 'constant';

// components
import User from '../User';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', padding: '10px', height: '80px' }}>{children}</Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

let ReactionTabs = ({ message }) => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (!message.reactions || message.reactions?.length === 0) return '';

    let like = [];
    let funny = [];
    let sad = [];
    let amaze = [];
    let love = [];
    let wish = [];

    message.reactions.forEach((reaction) => {
        switch (reaction.key) {
            case REACTIONS.LIKE:
                like.push(reaction);
                break;
            case REACTIONS.FUNNY:
                funny.push(reaction);
                break;
            case REACTIONS.SAD:
                sad.push(reaction);
                break;
            case REACTIONS.AMAZE:
                amaze.push(reaction);
                break;
            case REACTIONS.WISH:
                wish.push(reaction);
                break;
            case REACTIONS.LOVE:
                love.push(reaction);
                break;
        }
    });

    return (
        <React.Fragment>
            <Tabs value={value} onChange={handleChange}>
                <Tab label="👍" {...a11yProps(0)} />
                <Tab label="😂" {...a11yProps(1)} />
                <Tab label="😮" {...a11yProps(2)} />
                <Tab label="😢" {...a11yProps(3)} />
                <Tab label="🙏" {...a11yProps(4)} />
                <Tab label="❤️" {...a11yProps(5)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                {like.map((like, i) => (
                    <User key={i} reaction={like} />
                ))}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {funny.map((funny, i) => (
                    <User key={i} reaction={funny} />
                ))}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {amaze.map((amaze, i) => (
                    <User key={i} reaction={amaze} />
                ))}
            </TabPanel>
            <TabPanel value={value} index={3}>
                {sad.map((sad, i) => (
                    <User key={i} reaction={sad} />
                ))}
            </TabPanel>
            <TabPanel value={value} index={4}>
                {wish.map((wish, i) => (
                    <User key={i} reaction={wish} />
                ))}
            </TabPanel>
            <TabPanel value={value} index={5}>
                {love.map((love, i) => (
                    <User key={i} reaction={love} />
                ))}
            </TabPanel>
        </React.Fragment>
    );
};

export default ReactionTabs;
