import React from 'react';

// MUI components
import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
// Project components
import ProfileSection from '../ProfileSection';

// Hooks
import { useNavigate } from 'react-router';
import useSidebar from 'hooks/useSidebar';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/styles';

// store
import { toggleDarkMode } from 'features/store';

const Icons = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleNavigateToChat = () => navigate('/chat');
    const { handleToggle } = useSidebar();
    const handleToggleDarkMode = () => dispatch(toggleDarkMode());
    return (
        <DialogActions sx={{ padding: 0 }}>
            <IconButton onClick={handleNavigateToChat}>
                <QuestionAnswerRoundedIcon />
            </IconButton>
            <IconButton onClick={handleToggle}>
                <MenuOpenRoundedIcon />
            </IconButton>
            <IconButton onClick={handleToggleDarkMode}>
                <DarkModeRoundedIcon />
            </IconButton>
            <ProfileSection />
        </DialogActions>
    );
};

export default React.memo(Icons);
