// connection
import { connection } from 'layout/ChatLayout';
// store
import { editMessage, toggle } from 'features/store';
import { TOGGLE } from 'constant';
import { useDispatch, useSelector } from 'react-redux';

let useEditMessage = () => {
    const currentChat = useSelector((state) => state.chat.currentChat);
    const editedMessage = useSelector((state) => state.chat.editedMessage);

    const dispatch = useDispatch();

    localStorage.setItem('chatId', currentChat?.id);

    return (text) => {
        connection.invoke(
            'UpdateMessage',
            localStorage.getItem('chatId'),
            localStorage.getItem('accountId'),
            editedMessage.message.id,
            text
        );
        dispatch(toggle({ type: TOGGLE.EDIT_MODE }));
        dispatch(
            editMessage({
                chatId: localStorage.getItem('chatId'),
                accountId: localStorage.getItem('accountId'),
                text,
                messageId: editedMessage.message.id,
                url: editedMessage?.message.url,
                replyMessageId: editedMessage.message.replyMessageId,
                forwardMessageId: editedMessage.message.forwardMessageId,
                id: editedMessage.message.id
            })
        );
    };
};

export default useEditMessage;
