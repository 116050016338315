import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const BooleanFilter = ({ column }) => (
    <FormControlLabel
        label="Filter"
        control={
            <Checkbox
                value=""
                checked={column.getFilterValue()}
                onChange={(e) => {
                    column.setFilterValue(e.target.checked);
                }}
                color="primary"
            />
        }
    />
);

export default BooleanFilter;
