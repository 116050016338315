import { HISTORY_ACTIONS } from 'constant';
import { addHistoryRecord, getItemFromArray } from 'helpers';
import { deleteBasicDescriptionTranslation } from '../functions/history/addOrEditBasicDescriptionTranslation';

/**
 * Deletes a basic item from the state and removes its ID from any layout items that reference it.
 * @param {object} state - The state object.
 * @param {object} payload - An object containing the ID of the item to delete.
 */
export const deleteBasic = (state, { payload }) => {
    // Get the type of the item to delete
    const basicItem = getItemFromArray({ array: state.basic, id: payload.itemId });
    const type = basicItem?.type;
    const oldLayout = { ...state.layout.find((item) => item.items.find((id) => id === payload.itemId) !== undefined) };

    // Remove the ID of the item to delete from any layout items that reference it
    for (let i = 0; i < state.layout.length; i++) {
        state.layout[i].items = state.layout[i].items.filter((item) => item !== payload.itemId);
    }

    // Remove the item to delete from the basic array in state
    state.basic = state.basic.filter((item) => item.id !== payload.itemId);

    // If not in file templates mode, add a history record for the deletion
    if (!payload.withOutHistroyRecord)
        addHistoryRecord({
            state,
            action: HISTORY_ACTIONS.DELETE_BASIC,
            description: deleteBasicDescriptionTranslation(`${type}`),
            from: { basicItem, layoutItem: oldLayout },
            to: null
        });
};
