import FileUploadService from 'config/FileUploadService';
import { Notification } from 'helpers';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

function useUploadFile() {
    let [progress, setProgress] = useState(0);
    const fetch = async (file) => {
        let res;
        await FileUploadService.upload(file, localStorage.getItem('token'), (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total));
        }).then((data) => {
            setProgress(0);
            res = data;
        });
        return res;
    };

    let upload = useMutation('uploadFile', fetch, {
        refetchOnWindowFocus: false,
        onSuccess: () => {
            Notification.fire({
                title: 'uploaded',
                timer: 2000,
                icon: 'success'
            });
        },
        onError: () => {
            Notification.fire({
                title: 'unknown error',
                timer: 2000,
                icon: 'error'
            });
        }
    });

    return { upload, progress };
}

export default useUploadFile;
