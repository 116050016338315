import React, { lazy } from 'react';

// Components
import ChatLayout from 'layout/ChatLayout';
import Loadable from 'ui-component/Loadable';

const Chat = Loadable(lazy(() => import('views/chat')));

const ChatRoutes = {
    path: '/',
    element: <ChatLayout />,
    children: [
        {
            path: '/chat',
            element: <Chat />
        }
    ]
};

export default ChatRoutes;
