import React from 'react';

// MUI components
import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
// import Button from '@mui/material/Button';

// NPM packages
import { BrowserView, MobileView } from 'react-device-detect';

// Components
import ChatInfoHeader from './Header';
import Bio from './Bio';
import ChatInfoTabs from './ChatInfoTabs';
import ChatImage from './ChatImage';

// Redux
import { useSelector } from 'react-redux';

/* eslint-disable no-unused-vars */

const ChatInfo = () => {
    const theme = useTheme();
    let chatInfoOpen = useSelector((state) => state.chat.chatInfoOpen);
    let currentChat = useSelector((state) => state.chat.currentChat);
    const drawer = (
        <React.Fragment>
            <BrowserView>
                <ChatInfoHeader />
                <div
                    style={{
                        height: '100vh'
                    }}
                >
                    <ChatImage />
                    <Box>
                        <Bio />
                        <ChatInfoTabs />
                    </Box>
                </div>
            </BrowserView>
            <MobileView></MobileView>
        </React.Fragment>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        currentChat !== null && (
            <Box
                component="nav"
                sx={(theme) => ({
                    transition: theme.transitions.create('all', {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen
                    }),
                    flexShrink: { md: 0 },
                    width: { md: chatInfoOpen && currentChat !== null ? '350px' : 0 }
                })}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant={'temporary'}
                    anchor="right"
                    open={chatInfoOpen && currentChat !== null}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': {
                            background: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            borderRight: 'none',
                            width: '100% !important',
                            overflow: 'hidden'
                            // right:auto,
                        }
                    }}
                    color="inherit"
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="persistent"
                    anchor="right"
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '350px', overflow: 'hidden' }
                    }}
                    open={chatInfoOpen && currentChat !== null}
                >
                    {drawer}
                </Drawer>
            </Box>
        )
    );
};

export default ChatInfo;
